import orderBy from 'lodash/orderBy'

export const mapToCollectionTourOverview = (
  tourDates = [], toursOnDates, tours,
) => orderBy(
  tourDates.map(tourDate => ({
    ...toursOnDates[tourDate],
    tours: toursOnDates[tourDate].tours.map(tourId => tours[tourId]),
  })),
  'date',
)

export const mapToSelectedTourOverview = (
  tour, stops,
) => tour ? ({
  ...tour,
  stops: tour.stops.map(stopId => stops[stopId]),
}) : null
