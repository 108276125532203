/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import React, { memo } from 'react'
import clsx from 'clsx'
import styles from './styles.module.scss'
import Icon from '../Icon'

export interface IModalBoxProps extends React.HTMLAttributes<any> {
  visible: boolean
  onClose: () => void
}

function ModalBox({
  visible,
  onClose,
  children,
  className,
  ...props
}: IModalBoxProps) {
  return (
    <div
      className={clsx(styles.modalBox, className, !visible && styles.hidden)}
      {...props}
    >
      <div className={styles.modalBody}>
        <span className="close-button" onClick={onClose}>
          <Icon name="close" />
        </span>
        <div className="content">{children}</div>
      </div>
    </div>
  )
}

export default memo(ModalBox)
