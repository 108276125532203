import React from 'react'
import { Card, CardContent, Box } from '@material-ui/core'
import { Marker } from '@react-google-maps/api'

import Typography from 'common/components/base/Typography'
import Icon from 'common/components/base/Icon'
import Map from 'common/components/features/Map'

import useStyles from './useStyles'

export interface ProfileCardProps {
  name: string
  address: string
  postalCode: string
  city: string
  country: string
  latitude: number
  longitude: number
}

const ProfileCard: React.FC<ProfileCardProps> = ({
  name,
  address,
  postalCode,
  city,
  country,
  latitude,
  longitude,
}) => {
  const classes = useStyles()

  return (
    <CardContent className={classes.content}>
      <Box className={classes.items}>
        <Typography variant="headerRegular" transform="none">
          {name}
        </Typography>

        <Box height={32} />
        <Box display="flex">
          <Box marginRight={2}>
            <Icon name="house" />
          </Box>
          <Box>
            <Typography>{address}</Typography>
            <Typography>{postalCode}</Typography>
            <Typography>
              {city} {country}
            </Typography>
            <Box height={16} />
            <Typography>
              {latitude} - {longitude}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box className={classes.items}>
        <Map zoom={13} center={{ lat: latitude, lng: longitude }}>
          <Marker position={{ lat: latitude, lng: longitude }} />
        </Map>
      </Box>
    </CardContent>
  )
}

export default ProfileCard
