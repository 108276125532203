export const TOUR_STATES = {
  TOUR_CREATED: 'TOUR_CREATED',
  TOUR_PLANNED: 'TOUR_PLANNED',
  TOUR_IN_TRANSIT: 'TOUR_IN_TRANSIT',
  TOUR_COMPLETED: 'TOUR_COMPLETED',
  TOUR_DISCARDED: 'TOUR_DISCARDED',
  TOUR_INCOMPLETE: 'TOUR_INCOMPLETE',
  TOUR_PLANNING_LOCKED: 'TOUR_PLANNING_LOCKED',
  TOUR_DRIVER_LOCKED: 'TOUR_DRIVER_LOCKED',
}

export const lockedTourStates = [
  TOUR_STATES.TOUR_PLANNING_LOCKED,
  TOUR_STATES.TOUR_DRIVER_LOCKED,
  TOUR_STATES.TOUR_PLANNED,
  TOUR_STATES.TOUR_IN_TRANSIT,
  TOUR_STATES.TOUR_COMPLETED,
]

export const plannedTourStates = [
  TOUR_STATES.TOUR_PLANNED,
  TOUR_STATES.TOUR_IN_TRANSIT,
  TOUR_STATES.TOUR_COMPLETED,
]
