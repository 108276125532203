import { createSelector } from 'reselect'

import { createFeatureSelector } from '../core'

import * as fromTours from './tours'

import { mapToCollectionTourOverview } from './helpers'

const selectFeature = createFeatureSelector('collection')

export const hasFetchedTours = createSelector(
  selectFeature,
  fromTours.getHasFetched,
)

const getTourDates = createSelector(
  selectFeature,
  fromTours.getTourDates,
)

const getToursOnDates = createSelector(
  selectFeature,
  fromTours.getToursOnDates,
)

const getTourData = createSelector(
  selectFeature,
  fromTours.getData,
)

export const getSelectedTour = createSelector(
  selectFeature,
  fromTours.getSelectedTour,
)

export const getIsLoading = createSelector(
  selectFeature,
  fromTours.getIsLoading,
)

export const getTourDatesOverview = createSelector(
  getTourDates,
  getToursOnDates,
  getTourData,
  mapToCollectionTourOverview,
)
