/* eslint-disable import/no-cycle */

import React from 'react'
import { Marker as GoogleMarker } from '@react-google-maps/api'

import { useSelector, useDispatch } from 'react-redux'
import { selectMultipleCollations } from 'actions/ui'
import { getShipmentTours } from 'selectors/combined'
import { getDepotCoordinates } from 'selectors/profile'
import * as fromGreenRegions from 'selectors/greenRegions'

import {
  getPositionsAndPolylines,
  getValidMarkers,
  googleRectangleOptions,
} from 'common/utils/GoogleMap'

import { ShipmentTour } from 'common/models/typings/Tour'

import GoogleMap from 'common/components/features/Map'
import Marker from './Marker'
import Directions from './Polyline'
import Checkbox from '../../base/forms/Checkbox'

export interface TourMapProps {
  showPolylines?: boolean
  clusterMarkers?: boolean
}

const TourMap: React.FC<TourMapProps> = ({ showPolylines, clusterMarkers }) => {
  const dispatch = useDispatch()
  const [maps, setMaps] = React.useState<google.maps.Map | null>(null)
  const bounds = React.useRef<google.maps.LatLngBounds | null>(null)
  const mouseDownPos = React.useRef<google.maps.LatLng | null>(null)
  const rectangle = React.useRef<google.maps.Rectangle | null>(null)

  const selectedTourId = useSelector((state: any) => state.tours.selectedTourId)
  const hiddenTourIds = useSelector((state: any) => state.tours.hiddenTourIds)
  const tours: ShipmentTour[] = useSelector(getShipmentTours)
  const depotCoordinates = useSelector(getDepotCoordinates)
  const [isShiftPressed, setIsShiftPressed] = React.useState<boolean>(false)
  const [isMouseDown, setIsMouseDown] = React.useState<boolean>(false)

  const greenRegions = useSelector(fromGreenRegions.getGreenRegions) as {
    [key: string]: { id: number; pc3: number; polygon: any }
  }

  /** Hidden tour id map to string */
  const [hidden, setHidden] = React.useState<ShipmentTour['id'][]>([])

  const filteredTours = React.useMemo(() => {
    return tours.filter((tour) => hidden.includes(String(tour.id)) === false)
  }, [hidden, tours])

  const handleGetMarkersInRectangle = React.useCallback(() => {
    if (rectangle.current) {
      const validCollations: string[] = []
      getValidMarkers(filteredTours).forEach((stop) => {
        const { lat, lng } = stop.coord

        if (
          lat &&
          lng &&
          rectangle.current?.getBounds() &&
          rectangle.current
            ?.getBounds()
            ?.contains(new google.maps.LatLng(lat, lng))
        ) {
          validCollations.push(stop.id)
        }
      })
      dispatch(selectMultipleCollations(validCollations))

      rectangle.current.setMap(null)
      rectangle.current = null
    }
  }, [dispatch, filteredTours])

  const handleDraggableMap = React.useCallback(
    (draggable: boolean) => {
      if (maps) {
        if (!isShiftPressed) {
          maps.setOptions({
            draggable: true,
          })
        } else {
          maps.setOptions({
            draggable,
          })
        }
      }
    },
    [isShiftPressed],
  )

  const handleFitMarkersToMap = React.useCallback(
    (paramsMap: google.maps.Map) => {
      bounds.current = new window.google.maps.LatLngBounds()
      const positionsAndPolylines = getPositionsAndPolylines(tours)
      bounds.current.extend(
        new window.google.maps.LatLng(
          depotCoordinates.lat,
          depotCoordinates.lng,
        ),
      )
      positionsAndPolylines.forEach(({ id, position }) => {
        bounds.current?.extend(new window.google.maps.LatLng(position))
      })

      paramsMap.setCenter(bounds.current.getCenter())
      paramsMap.fitBounds(bounds.current)
    },
    [depotCoordinates.lat, depotCoordinates.lng, tours],
  )

  const onLoadMap = React.useCallback(
    (map: google.maps.Map) => {
      setMaps(map)
      handleFitMarkersToMap(map)
    },
    [handleFitMarkersToMap],
  )

  const onMouseDown = React.useCallback(
    (event: google.maps.MapMouseEvent) => {
      if (isShiftPressed && (event.domEvent as MouseEvent).button === 0) {
        mouseDownPos.current = event.latLng
      } else {
        mouseDownPos.current = null
      }
    },
    [isShiftPressed],
  )

  const onMouseMove = React.useCallback(
    (event: google.maps.MapMouseEvent) => {
      const lat = event.latLng?.lat()
      const lng = event.latLng?.lng()
      if (isMouseDown && isShiftPressed) {
        if (maps) {
          if (rectangle.current) {
            // box exists
            const newbounds = new window.google.maps.LatLngBounds(
              mouseDownPos.current,
              undefined,
            )
            newbounds.extend(
              new window.google.maps.LatLng(
                lat as unknown as google.maps.LatLngLiteral,
                lng,
              ),
            )
            rectangle.current.setBounds(newbounds)
          } else {
            rectangle.current = new google.maps.Rectangle({
              map: maps,
              ...googleRectangleOptions,
            })
          }
        }
      }
    },
    [isMouseDown, isShiftPressed],
  )

  const onUnmount = React.useCallback(() => {
    setMaps(null)
    bounds.current = null
    rectangle.current = null
  }, [])

  React.useEffect(() => {
    window.addEventListener('keydown', (event) => {
      setIsShiftPressed(event.shiftKey)
    })
    window.addEventListener('keyup', (event) => {
      mouseDownPos.current = null
      setIsShiftPressed(event.shiftKey)
    })

    // clean up window events
    return () => {
      window.removeEventListener('keydown', (event) => {
        setIsShiftPressed(event.shiftKey)
      })

      window.removeEventListener('keyup', (event) => {
        mouseDownPos.current = null
        setIsShiftPressed(event.shiftKey)
      })
    }
  }, [])

  // assign hidden tour id into 1 array
  React.useEffect(() => {
    const ids = tours.map((ft) => ft.id)
    let hiddenIds: ShipmentTour['id'][] = []
    if (selectedTourId) {
      // eslint-disable-next-line eqeqeq
      hiddenIds = ids.filter((id) => id != selectedTourId)
    } else if (hiddenTourIds) {
      hiddenIds = hiddenTourIds
    } else {
      hiddenIds = []
    }
    setHidden(hiddenIds.map(String))
  }, [selectedTourId, tours, hiddenTourIds])

  React.useEffect(() => {
    window.addEventListener('mouseup', () => {
      setIsMouseDown(false)
      handleDraggableMap(true)
    })

    window.addEventListener('mousedown', () => {
      setIsMouseDown(true)
      handleDraggableMap(false)
    })

    return () => {
      window.removeEventListener('mouseup', () => {
        setIsMouseDown(false)
        handleDraggableMap(true)
      })

      window.removeEventListener('mousedown', () => {
        setIsMouseDown(false)
        handleDraggableMap(false)
      })
    }
  }, [handleDraggableMap])

  React.useEffect(() => {
    const regions = Object.values(greenRegions)
    if (regions.length && maps) {
      regions.map((region) =>
        maps.data.addGeoJson({
          type: 'Feature',
          id: `GR-PC3-${region.pc3}`,
          geometry: region.polygon,
        }),
      )
      maps.data.setStyle((feature) => ({
        strokeColor: '#315e3d',
        fillColor: '#72c587',
        fillOpacity: 0.08,
        strokeOpacity: 0.3,
        strokeWeight: 1,
        clickable: false,
      }))
    }
  }, [greenRegions, maps])

  return (
    <GoogleMap
      onLoad={onLoadMap}
      onMouseDown={onMouseDown}
      onMouseMove={onMouseMove}
      onUnmount={onUnmount}
      onMouseUp={handleGetMarkersInRectangle}
    >
      <GoogleMarker
        position={depotCoordinates}
        icon="https://chart.apis.google.com/chart?chst=d_map_pin_icon&chld=home|BDBDBD|ffffff"
      >
        {React.useMemo(
          () =>
            tours.map((tour) => {
              if (!hidden.includes(String(tour.id))) {
                return (
                  <React.Fragment key={tour.id}>
                    <Marker
                      tour={tour}
                      isSingleTour={Boolean(selectedTourId)}
                      showAsCluster={clusterMarkers}
                      mapBounds={bounds.current}
                    />
                    {showPolylines && (
                      <Directions
                        color={tour.color}
                        collations={tour.collations}
                      />
                    )}
                  </React.Fragment>
                )
              }
              return null
            }),
          [
            tours,
            hidden,
            selectedTourId,
            showPolylines,
            clusterMarkers,
            bounds,
          ],
        )}
      </GoogleMarker>
    </GoogleMap>
  )
}

export default TourMap
