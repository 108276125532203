import { createSelector } from 'reselect'

import { createFeatureSelector } from './core'

import * as fromNetwork from '../reducers/networkType'

const selectFeature = createFeatureSelector('networkType')

export const getNetworkType = createSelector(
  selectFeature,
  fromNetwork.selectNetworkType,
)
