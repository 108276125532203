import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box } from '@material-ui/core'

import Icon from 'common/components/base/Icon'
import Typography from 'common/components/base/Typography'

import { ReactComponent as SetAside } from 'common/assets/images/empty/set-aside.svg'

import useStyles from './useStyles'

const HandoverDialogFailed = () => {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <Box className={classes.content}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        className={classes.error}
      >
        <Icon name="close" fill="currentColor" color="#ffffff" />
        <Typography variant="header" align="center" color="white">
          {t('SET_ASIDE')}
        </Typography>
      </Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        paddingX={3}
      >
        <Typography
          variant="headerRegular"
          transform="none"
          align="center"
          className={classes.errorDescription}
        >
          {t('ATTENTION_SET_ASIDE_MSG')}
        </Typography>
        <SetAside />
      </Box>
    </Box>
  )
}

export default HandoverDialogFailed
