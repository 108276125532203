import * as ActionTypes from '../../constants/ActionTypes'

const initialState = {
  isTickerActive: false,
  tasks: {},
  identifiers: [],
}

const reducer = (
  state = initialState,
  { type, payload },
) => {
  switch (type) {
  case ActionTypes.QUEUE_TASK: {
    const { tasks, identifiers } = state

    const isIdentifierKnown = tasks[payload.id]

    return {
      ...state,
      tasks: {
        ...tasks,
        [payload.id]: payload,
      },
      identifiers: isIdentifierKnown ? [...identifiers] : [...identifiers, payload.id],
    }
  }

  case ActionTypes.START_TASK: {
    const { tasks: { [payload.id]: task } } = state

    return {
      ...state,
      tasks: {
        ...state.tasks,
        [payload.id]: {
          ...task,
          lastAttemptAt: Number(new Date()),
          isRunning: true,
        },
      },
    }
  }

  case ActionTypes.COMPLETE_TASK: {
    const { tasks: oldTasks, identifiers: oldIdentifiers } = state
    const { id: taskId } = payload
    const tasks = { ...oldTasks }
    delete tasks[taskId]

    const identifiers = oldIdentifiers.filter(id => taskId !== id)

    return { ...state, tasks, identifiers }
  }

  case ActionTypes.FAIL_TASK: {
    const { id: taskId, error, tries } = payload
    const { tasks } = state
    const { [taskId]: task } = tasks

    return {
      ...state,
      tasks: {
        ...tasks,
        [taskId]: {
          ...task,
          lastError: error,
          isRunning: false,
          tries,
        },
      },
    }
  }

  case ActionTypes.TASK_TICKER_STARTED: {
    return { ...state, isTickerActive: true }
  }

  case ActionTypes.LOGOFF: {
    return initialState
  }

  default:
    return state
  }
}

export const selectIsTickerActive = (state) => state.isTickerActive
export const selectIdentifiers = (state) => state.identifiers
export const selectTasks = (state) => state.tasks

export default reducer
