import * as actions from '../../constants/ActionTypes'
import { mergeUpdates } from './helpers'

const initialState = {
  hasCollations: false,
  identifiers: [],
  data: {},
}

export default function tours(state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case actions.SET_TOURS: {
      const {
        collations: [identifiers, data],
      } = payload

      return {
        ...state,
        hasCollations: true,
        identifiers,
        data,
      }
    }

    case actions.UPDATE_TOURS: {
      const {
        collations: [updatedIds, updated],
      } = payload

      const [identifiers, data] = mergeUpdates(
        state.identifiers,
        state.data,
        updatedIds,
        updated,
      )

      return {
        ...state,
        hasCollations: !!identifiers.length,
        identifiers,
        data,
      }
    }

    case actions.CHANGE_SUBCO: {
      return initialState
    }

    default:
      return state
  }
}

export const selectIdentifiers = (state) => state.identifiers
export const selectData = (state) => state.data
export const selectHasCollations = (state) => state.hasCollations
