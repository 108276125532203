/* eslint-disable import/no-cycle */

import React from 'react'

import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { getShipmentTours } from 'selectors/combined'
import { ShipmentTour } from 'common/models/typings/Tour'

import TourCard from './Card'
import Typography from '../../base/Typography'

import useStyles from './useStyles'
import { calculateTotalHoursOfTour } from './calculateTotalHoursOfTour'
import moment from 'moment/moment'
import get from 'lodash/get'
import { getLastStopTime, isPastDeadLine } from '../../../../utils'
import { isValidCollation } from '../../../../actions/tours/helpers'

const AnimatedTourCard: React.FC = () => {
  const tours: ShipmentTour[] = useSelector(getShipmentTours)
  const selectedTourId: ShipmentTour['id'] = useSelector(
    (state: any) => state.tours.selectedTourId,
  )
  const classes = useStyles()

  const { t } = useTranslation()

  const overallProductivity = React.useMemo(() => {
    let totalStops = 0
    let totalHours = 0

    tours.forEach((tour, index) => {
      const amountOfStops = tour.collations.length - 2 // excluding start and end stops
      totalStops += amountOfStops

      const { startTime, endTime } = {
        startTime: moment(get(tour.collations, '[0].estimation')).format(
          'HH:mm',
        ),
        endTime: moment(getLastStopTime(tour.collations)).format('HH:mm'),
      }

      const totalDurationInHours = calculateTotalHoursOfTour(startTime, endTime)

      totalHours += totalDurationInHours
    })

    return (totalStops / totalHours).toFixed(1)
  }, [tours])

  return (
    <>
      {!!tours.length && (
        <div className={classes.tourLabelRow}>
          <Typography variant="textBold" className={classes.tourCountLabel}>
            {t('TOUR_COUNT', { count: tours.length })}
          </Typography>
          <Typography variant="textBold" className={classes.tourCountLabel}>
            {t('OVERALL_TOUR_PRODUCTIVITY', {
              count: overallProductivity as unknown as number,
            })}
          </Typography>
        </div>
      )}
      {React.useMemo(() => {
        const items = selectedTourId
          ? // eslint-disable-next-line eqeqeq
            tours.filter((tr) => tr.id == selectedTourId)
          : tours

        return items.map((tour) => (
          <TourCard
            key={tour.id}
            id={tour.id}
            color={tour.color}
            collations={tour.collations}
            hasBermudaStop={tour.hasBermudaStop}
            number={tour.number}
            totalDistance={tour.totalDistance}
            isWorking={tour.isWorking}
            driverId={tour.driverId}
            vehicleType={tour.vehicleType}
            totalDuration={tour.totalDuration}
          />
        ))
      }, [tours, selectedTourId])}
    </>
  )
}

export default AnimatedTourCard
