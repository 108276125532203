/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import React, { memo, useState } from 'react'
import clsx from 'clsx'

import styles from './styles.module.scss'

interface ISwitchProps extends React.DetailedHTMLProps<any, any> {
  onToggle?: (isActive: boolean) => void
  active?: boolean
}

const Switch = memo(
  ({ children, onToggle, className, active, ...props }: ISwitchProps) => {
    const [isActive, setIsActive] = useState(!!active)

    const toggleCheck = () => {
      const nextValue = !isActive
      setIsActive(nextValue)
      if (typeof onToggle === 'function') {
        onToggle(nextValue)
      }
    }

    return (
      <span
        className={clsx(styles.switchContainer, className)}
        onClick={toggleCheck}
        {...props}
      >
        <div className={styles.switch}>
          <input checked={isActive} type="checkbox" />
          <div className={styles.slider} />
        </div>
        {children}
      </span>
    )
  },
)

export default Switch
