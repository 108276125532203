import React from 'react'
import { useTranslation } from 'react-i18next'
import defaultTo from 'lodash/defaultTo'
import get from 'lodash/get'

import { AllShipmentsFilter } from 'common/models/typings/AllShipmentsFilter'
import { Shipment } from 'common/models/typings/Shipment'
import Button from 'common/components/base/Button'
import Icon from 'common/components/base/Icon'

import Tab from 'common/components/base/.raw/Tab'
import {
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
  TableRowHeader,
} from 'common/components/base/.raw/Table'
import ShipmentEmpty from 'common/components/base/EmptyScreens/ShipmentEmpty'
import ShipmentBadge from './ShipmentBadge'

import styles from './styles.module.scss'

export interface ShipmentsTabProps {
  filteredData: Shipment[]
  currentFilter: AllShipmentsFilter
  onFilterAllShipments: () => void
  onFilterCentralSorted: () => void
  onFilterCancelled: () => void
  onReloadShipments: () => void
}

const ShipmentsTab: React.FC<ShipmentsTabProps> = ({
  filteredData,
  currentFilter,
  onFilterAllShipments,
  onFilterCentralSorted,
  onFilterCancelled,
  onReloadShipments,
}) => {
  const { t } = useTranslation()

  return (
    <>
      <div className={styles['tab-container']}>
        <div>
          <Tab active={currentFilter === 'all'} onClick={onFilterAllShipments}>
            {t('SHIPMENTS_OVERVIEW.FILTER_ALL_SHIPMENTS')}
          </Tab>
          <Tab
            active={currentFilter === 'centralSorted'}
            onClick={onFilterCentralSorted}
          >
            {t('SHIPMENTS_OVERVIEW.FILTER_CENTRAL_SORTED')}
          </Tab>
          <Tab
            active={currentFilter === 'cancelled'}
            onClick={onFilterCancelled}
          >
            {t('SHIPMENTS_OVERVIEW.FILTER_CANCELLED')}
          </Tab>
        </div>
        {!!filteredData.length && (
          <div>
            <div className={styles['result-count']}>
              {t('SHIPMENTS_OVERVIEW.RESULT_COUNT', {
                count: filteredData.length,
              })}
            </div>
            <Button
              color="white"
              onClick={onReloadShipments}
              startIcon={<Icon name="retry" />}
            >
              {t('SHIPMENTS_OVERVIEW.REFRESH_RESULTS')}
            </Button>
          </div>
        )}
      </div>
      <Table className={styles['custom-table-style']}>
        {!!filteredData.length && (
          <TableRowHeader className="table-header-row">
            <TableHeader>
              {t('SHIPMENTS_OVERVIEW.TABLE_HEADER_TRUNKRSNR')}
            </TableHeader>
            <TableHeader>
              {t('SHIPMENTS_OVERVIEW.TABLE_HEADER_POSTAL_CODE')}
            </TableHeader>
            <TableHeader>
              {t('SHIPMENTS_OVERVIEW.TABLE_HEADER_ADDRESS')}
            </TableHeader>
            <TableHeader>
              {t('SHIPMENTS_OVERVIEW.TABLE_HEADER_CITY')}
            </TableHeader>
            <TableHeader>
              {t('SHIPMENTS_OVERVIEW.TABLE_HEADER_TOUR')}
            </TableHeader>
            <TableHeader>
              {t('SHIPMENTS_OVERVIEW.TABLE_HEADER_SHIPMENT_STATE')}
            </TableHeader>
          </TableRowHeader>
        )}
        <TableBody>
          {React.useMemo(
            () =>
              filteredData.map((shipment, id) => (
                // eslint-disable-next-line react/no-array-index-key
                <TableRow key={id}>
                  <TableCell>
                    {defaultTo(get(shipment, 'TrunkrsNr'), '-')}
                  </TableCell>
                  <TableCell>
                    {defaultTo(get(shipment, 'Recipient.PostalCode'), '')}
                  </TableCell>
                  <TableCell>
                    {`${defaultTo(
                      get(shipment, 'Recipient.Address'),
                      '',
                    )} ${defaultTo(
                      get(shipment, 'Recipient.HouseNumber'),
                      '',
                    )}`}
                  </TableCell>
                  <TableCell>
                    {defaultTo(get(shipment, 'Recipient.City'), '')}
                  </TableCell>
                  <TableCell>
                    {defaultTo(get(shipment, 'Route'), '-')}
                  </TableCell>
                  <TableCell>
                    <ShipmentBadge shipment={shipment} />
                  </TableCell>
                </TableRow>
              )),
            [filteredData],
          )}
        </TableBody>
      </Table>
      {!filteredData.length && (
        <ShipmentEmpty onReloadShipments={onReloadShipments} />
      )}
    </>
  )
}

export default React.memo(ShipmentsTab)
