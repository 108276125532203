import React from 'react'

import { InventoryScan } from 'planner-upgrades'
import useStyles from './useStyles'

import 'planner-upgrades/dist/style.css'
import moment from 'moment'
import Box from '@material-ui/core/Box'
import InventoryScanDisabled from 'common/components/features/EmptyState/InventoryScanDisabled'
import { getCurrentSubcoId } from 'selectors/profile'
import { useSelector } from 'react-redux'
import { batchHubOut } from 'actions/hubLogs'
import { getIsBatchHubOutDone } from 'selectors/combined'
import { Trans, useTranslation } from 'react-i18next'
import { Toast } from '../../../common/components/base/.raw'
import Snowflake from '../../../common/components/base/vectors/Snowflake'

export const InventoryScanPage: React.FC = () => {
  const { t } = useTranslation()

  const currentSubcoId = useSelector(getCurrentSubcoId)
  const isBatchHubOutDone = useSelector(getIsBatchHubOutDone)

  const [isPageAllowed, setIsPageallowed] = React.useState<boolean | null>(null)
  const classes = useStyles()

  React.useEffect(() => {
    const intervalId = setInterval(() => {
      const current = moment()
      const currentHour = current.hour()
      if (currentHour > 3 && currentHour < 15) {
        setIsPageallowed(true)
      } else {
        setIsPageallowed(false)
      }
    }, 1000)
    return () => {
      if (intervalId) clearInterval(intervalId)
    }
  }, [])

  const handleBatchHubOut = async (data: any) => {
    await batchHubOut(data.payload, true)
  }

  const [modalOpen, setModalOpen] = React.useState(true)

  const handleCloseModal = () => setModalOpen(false)

  React.useEffect(() => {
    setTimeout(() => {
      setModalOpen(false)
    }, 10000)
  }, [])

  if (!isPageAllowed) {
    return (
      <Box mt={10}>
        <InventoryScanDisabled />
      </Box>
    )
  }

  return (
    <>
      <Toast
        open={modalOpen}
        position="top-left"
        onExit={handleCloseModal}
        icon={<Snowflake className={classes.snowflakeIcon} />}
        headerText={t('FREEZER_WARNING.TITLE')}
        subText={t('FREEZER_WARNING.TEXT')}
        variant="error"
      />
      <InventoryScan
        t={t}
        trans={Trans}
        subcoId={currentSubcoId}
        className={classes.inventoryScan}
        onBatchHubOut={handleBatchHubOut}
        isBatchHubOutDone={isBatchHubOutDone}
      />
    </>
  )
}

export default InventoryScanPage
