import React from 'react'
import { useTranslation } from 'react-i18next'
import { CircularProgress } from '@material-ui/core'
import { Formik, Form, FormikHelpers } from 'formik'

import Typography from 'common/components/base/Typography'
import InputFormik from 'common/components/base/forms/Input/InputFormik'
import Icon from 'common/components/base/Icon'
import Button from 'common/components/base/Button'

import { transformPhoneNumber } from 'common/utils/PhoneNumber'
import PhoneNumberSchema from './schema'

import useStyles from './useStyles'

export interface PhoneNumberFormfields {
  phoneNumber: string
}

export interface PhonenumberFormProps {
  isSending?: boolean
  isBtnDisabled?: boolean
  error?: boolean
  value: string
  onChange: (value: string) => void
  onSubmit: (
    values: PhoneNumberFormfields,
    formikHelpers: FormikHelpers<PhoneNumberFormfields>,
  ) => void | Promise<void>
}

const PhonenumberForm: React.FC<PhonenumberFormProps> = ({
  isSending,
  isBtnDisabled,
  error,
  value,
  onChange,
  onSubmit,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const schema = PhoneNumberSchema()

  const handleChange = React.useCallback(
    (
      ev,
      setFieldValue: (
        field: string,
        value: any,
        shouldValidate?: boolean | undefined,
      ) => void,
    ) => {
      setFieldValue('phoneNumber', transformPhoneNumber(ev.target.value))
      onChange(ev.target.value)
    },
    [onChange],
  )

  return (
    <div className={classes.root}>
      <Typography
        variant="text"
        color="theBeast"
        align="center"
        className={classes.sub}
      >
        Hallo! Enter your registered number to access the Planner. You’ll
        receive a 6-digit code after.
      </Typography>
      <Formik
        validateOnMount
        initialValues={{ phoneNumber: '' }}
        onSubmit={onSubmit}
        validationSchema={schema}
      >
        {({ isSubmitting, isValid, setFieldValue }) => (
          <Form style={{ width: '100%' }}>
            <InputFormik
              fullWidth
              name="phoneNumber"
              size="large"
              placeholder="(+31) XXX-XXX-XXXX"
              startAdornment={<Icon name="phone" />}
              disabled={isSubmitting}
              onChange={(ev) => handleChange(ev, setFieldValue)}
              className={classes.input}
            />
            <Button
              fullWidth
              type="submit"
              size="large"
              variant="default"
              color="green"
              disabled={!isValid}
              endIcon={
                isSubmitting && <CircularProgress size={20} thickness={3} />
              }
            >
              {t('SEND_CODE')}
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default React.memo(PhonenumberForm)
