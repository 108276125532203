import React from 'react'
import { Box } from '@material-ui/core'
import clsx from 'clsx'
import get from 'lodash/get'
import drop from 'lodash/drop'

import Icon from '../Icon'

import styles from './styles.module.scss'
import Typography from '../../Typography'

export interface CalloutProps extends React.HTMLAttributes<any> {
  variant?: 'default' | 'primary' | 'success' | 'danger' | 'warning' | string
  icon?: 'default' | 'primary' | 'success' | 'danger' | 'warning' | string
}

const Callout: React.FC<CalloutProps> = ({
  children,
  className,
  variant = 'default',
  title,
  icon,
}) => {
  const currentIcon = React.useMemo(() => {
    switch (variant) {
      case 'primary':
        return <Icon name="info-circle" />
      case 'success':
        return <Icon name="check-circle" />
      case 'danger':
        return <Icon name="danger" />
      case 'warning':
        return <Icon name="warning" />
      default:
        return null
    }
  }, [variant])

  const selectedIcon = React.useMemo(() => {
    switch (icon) {
      case 'primary':
        return <Box display="flex"><Icon name="info-circle" fill="#03b3d9" /></Box>
      case 'success':
        return <Box display="flex"><Icon name="check-circle" fill="#2ce27e" /></Box>
      case 'danger':
        return <Box display="flex"><Icon name="danger" fill="#ff3a37" /></Box>
      case 'warning':
        return <Box display="flex"><Icon name="warning" fill="#ff8f5e" /></Box>
      default:
        return null
    }
  }, [icon])

  const contents = React.Children.toArray(children)

  return (
    <div className={clsx(styles.callout, get(styles, variant), className)}>
      <div>{selectedIcon ? selectedIcon : currentIcon}</div>
      <div>
        {title && <Typography className={styles.header} variant='textBold'>{title}</Typography>}
        {contents.length > 1 ? (
          <>
            <div className={styles.title}>{get(contents, '[0]')}</div>
            {drop(contents)}
          </>
        ) : (
          get(contents, '[0]')
        )}
      </div>
    </div>
  )
}

export default React.memo(Callout)
