import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  IconButton,
} from '@material-ui/core'
import isEmpty from 'lodash/isEmpty'

import { useSelector } from 'react-redux'
import * as fromUi from 'selectors/ui'

import Typography from 'common/components/base/Typography'
import Icon from 'common/components/base/Icon'
import ResultFailed from './failed'
import ResultSuccess from './success'

export interface CreateToursDialogResultProps {
  open: boolean
  onClose: () => void
  onReOptimize: () => void
}

const CreateToursDialogResult: React.FC<CreateToursDialogResultProps> = ({
  open,
  onClose,
  onReOptimize,
}) => {
  const { t } = useTranslation()

  const optimizationResult = useSelector(fromUi.getOptimizeResult)
  const isOptimizationError = optimizationResult?.isOptimizationError
  const isHttpError = optimizationResult?.isHttpError
  const data = optimizationResult?.data

  const isError = React.useMemo(
    () => isOptimizationError || isHttpError,
    [isOptimizationError, isHttpError],
  )

  return (
    <Dialog
      disableEscapeKeyDown
      fullWidth
      maxWidth="sm"
      PaperProps={{ elevation: 0 }}
      open={open}
    >
      <Box position="absolute" top={16} right={16}>
        <IconButton onClick={onClose}>
          <Icon name="close" />
        </IconButton>
      </Box>
      <DialogTitle disableTypography>
        <Typography variant="h3">
          {t(
            isError
              ? 'OPTIMIZATION_RESULT_ERROR_TITLE'
              : 'OPTIMIZATION_RESULT_SUCCESS_TITLE',
          )}
        </Typography>
      </DialogTitle>
      {isError ? (
        <ResultFailed onReOptimize={onReOptimize} onClose={onClose} />
      ) : (
        <ResultSuccess
          elapsedTime={data?.elapsedTime}
          driverCount={data?.driverCount}
          overflowCount={data?.overflowCount}
          onReOptimize={onReOptimize}
          onClose={onClose}
        />
      )}
    </Dialog>
  )
}

export default React.memo(CreateToursDialogResult)
