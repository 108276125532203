import React, { memo } from 'react'
import clsx from 'clsx'

import styles from './styles.module.scss'
import Icon from '../Icon'

function ScanInput(
  props: React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >,
) {
  const { disabled, ...others } = props
  return (
    <div
      className={clsx(styles.scanInputContainer, {
        [styles.disabled]: disabled,
      })}
    >
      <Icon name="barcode" />
      <input type="text" {...others} disabled={disabled} />
    </div>
  )
}

export default memo(ScanInput)
