import {
  createTheme,
  ThemeOptions,
  // eslint-disable-next-line camelcase
  unstable_createMuiStrictModeTheme,
} from '@material-ui/core/styles'

import shadows from './shadows'
import typography from './typography'
import palette from './colors'
import shape from './shape'
import other from './other'

import overrides from './overrides'
import props from './props'

const theme: ThemeOptions = {
  ...other,
  palette,
  typography,
  overrides,
  props,
  shadows,
  shape,
}

export default unstable_createMuiStrictModeTheme(theme)
