import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const styles = ({ spacing, palette }: Theme) =>
  createStyles({
    paper: {
      position: 'relative',
      height: 537,
      padding: 0,
    },
    content: {
      height: '100%',
      display: 'flex',

      '& > div': {
        flex: '1 1 0',
      },
    },

    // error result
    error: {
      backgroundColor: palette.trunkrs.intent.red.base,

      '& svg': {
        height: 237,
        width: 237,
      },
    },
    errorDescription: {
      marginBottom: spacing(3),
    },

    // success
    tourNr: {
      fontSize: 160,
      lineHeight: '160px',
    },
  })

export default makeStyles(styles)
