import createStyles from '@material-ui/core/styles/createStyles'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { Theme } from '@material-ui/core/styles'

const styles = ({ spacing, palette }: Theme) =>
  createStyles({
    root: {
      width: 424,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
    },
    title: {
      marginBottom: spacing(1),
    },
    sub: {
      marginBottom: spacing(4),
    },
    input: {
      marginBottom: 0,
    },
    inputHelper: {
      alignSelf: 'flex-start',
    },
  })

export default makeStyles(styles)
