/* eslint-disable react/display-name, no-unused-vars, import/no-cycle */

import React, { memo, useCallback, useRef, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { moveDriverToDiffRegion, searchDriverByNumber } from 'actions/user'
import { getCurrentSubcoId, getSubcosById } from 'selectors/profile'
import { useSelector } from 'react-redux'
import get from 'lodash/get'
import {
  Button,
  Callout,
  Icon,
  MaskedInput,
  ModalBox,
} from 'common/components/base/.raw'

import styles from './styles.module.scss'
import Toast from 'common/components/base/.raw/Toast'

export enum DRIVER_AVAILABILITY {
  AVAILABLE_NOT_ASSIGNED = 'AVAILABLE_NOT_ASSIGNED',
  AVAILABLE_ASSIGNED = 'AVAILABLE_ASSIGNED',
  NOT_AVAILABLE_ASSIGNED = 'NOT_AVAILABLE_ASSIGNED',
}

const DriverImportModal = memo(
  ({ visible, onClose }: { visible?: boolean; onClose: () => void }) => {
    const { t } = useTranslation()
    const [canBeSubmitted, setCanBeSubmitted] = useState(false)
    const [isDriverNotFound, setIsDriverNotFound] = useState(false)
    const [isImportSuccessful, setIsImportSuccessful] = useState(false)
    const [activeDriverDetails, setActiveDriverDetails] = useState(undefined)
    const [phoneNumber, setPhoneNumber] = useState(31)
    const [variant, setVariant] = useState('warning')
    const [callOutMsg, setCallOutMsg] = useState<any>(undefined)

    const maskRef = useRef<any>()
    const driverRef = useRef<any>()

    const currentSubcoId = useSelector(getCurrentSubcoId)
    const subcoByIds = useSelector(getSubcosById)

    const onPhoneNumberChange = useCallback(
      (maskedValue, value) => setPhoneNumber(value),
      [setPhoneNumber],
    )

    const handleOnMount = useCallback((ref) => {
      if (ref) maskRef.current = ref.current
    }, [])

    const handleReset = useCallback(
      (isDriverFound: boolean, resetValues?: boolean) => {
        if (maskRef.current) {
          setIsDriverNotFound(isDriverFound)
          setCanBeSubmitted(false)
          setCallOutMsg(undefined)
          setActiveDriverDetails(undefined)
          if (resetValues) {
            maskRef.current.typedValue = '31'
          }
        }
      },
      [maskRef],
    )

    const handleClose = useCallback(() => {
      onClose()
      handleReset(false, true)
    }, [handleReset, onClose])

    const handleSearchDriver = useCallback(async () => {
      const response = await searchDriverByNumber(
        '+'.concat(phoneNumber.toString()),
      )
      if (get(response, 'isError')) {
        return handleReset(true)
      }
      setIsDriverNotFound(false)
      driverRef.current = { phoneNumber, name: get(response, 'name') }
      setActiveDriverDetails(response)

      if (get(response, 'subcoId') === currentSubcoId) {
        setCallOutMsg('USERS.NOTIF_DRIVER_CANNOT_BE_MOVED')
        setVariant('danger')
        return setCanBeSubmitted(false)
      }

      switch (get(response, 'availability')) {
        case DRIVER_AVAILABILITY.AVAILABLE_ASSIGNED:
          setCallOutMsg('USERS.AVAILABLE_ASSIGNED')
          setVariant('warning')
          return setCanBeSubmitted(true)
        case DRIVER_AVAILABILITY.NOT_AVAILABLE_ASSIGNED:
          setCallOutMsg('USERS.NOT_AVAILABLE_ASSIGNED')
          setVariant('danger')
          return setCanBeSubmitted(false)
        default:
          return setCanBeSubmitted(true)
      }
    }, [phoneNumber, currentSubcoId, handleReset])

    const handleSubmit = useCallback(async () => {
      const response = await moveDriverToDiffRegion({
        subcoId: currentSubcoId,
        driverId: get(activeDriverDetails, 'id') as unknown as number,
      })

      if (!get(response, 'isError')) {
        handleClose()
        setIsImportSuccessful(true)
        setActiveDriverDetails(undefined)
      }
    }, [activeDriverDetails, currentSubcoId, handleClose])

    return (
      <>
        <ModalBox visible={visible as boolean} onClose={handleClose}>
          <div className={styles.driverImportModalContents}>
            <div className={styles.header}>
              {t('USERS.IMPORT_DRIVER_MODAL_TITLE')}
            </div>
            <div className={styles.inputContainer}>
              <div>{t('USERS.DRIVER_PHONE_NUMBER')}</div>
              <div>
                <div>
                  <MaskedInput
                    onMount={handleOnMount}
                    isError={isDriverNotFound}
                    typedValue="31"
                    mask="(+00) 00 000 0000[0]"
                    placeholder="0"
                    onChange={onPhoneNumberChange}
                  />
                  {isDriverNotFound && (
                    <div className={styles.inputErrorLabel}>
                      <Icon name="danger" />
                      {t('USERS.NOTIF_CANNOT_FIND_DRIVER')}
                    </div>
                  )}
                </div>
                <Button
                  onClick={handleSearchDriver}
                  variant="success"
                  disabled={phoneNumber.toString().length < 11}
                >
                  <Icon name="search" />
                </Button>
              </div>
            </div>
            {activeDriverDetails && (
              <div className={styles.driverDetails}>
                <div>{get(driverRef, 'current.name')}</div>
                <div>
                  <Icon name="phone" />
                  {`+${get(driverRef, 'current.phoneNumber')}`}
                </div>
                <div>
                  <Icon name="house" />
                  {t('USERS.CURRENT_REGION')}{' '}
                  <b>{[get(activeDriverDetails, 'subcoName')]}</b>
                </div>
              </div>
            )}

            {callOutMsg && (
              <Callout variant={variant} className={styles.callout}>
                {t(callOutMsg, {
                  region:
                    subcoByIds[
                      get(activeDriverDetails, 'subcontractorId') as any
                    ],
                })}
              </Callout>
            )}
            <div className={styles.actionsContainer}>
              <Button
                variant="success"
                disabled={!canBeSubmitted}
                onClick={handleSubmit}
                onSubmit={handleSubmit}
              >
                {t('USERS.BUTTON_IMPORT_DRIVER')}
              </Button>
              <Button variant="link" onClick={handleClose}>
                {t('USERS.BUTTON_CANCEL')}
              </Button>
            </div>
          </div>
        </ModalBox>
        {isImportSuccessful && (
          <Toast
            headerText={t('USERS.TOAST_DRIVER_IMPORT_SUCCESS')}
            subText={t('USERS.TOAST_DRIVER_IMPORT_SUCCESS_SUBTEXT', {
              name: get(driverRef, 'current.name'),
            })}
          />
        )}
      </>
    )
  },
)

export default DriverImportModal
