import React from 'react'

import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles'
import Box from '@material-ui/core/Box'
import InputBase, { InputBaseProps } from '@material-ui/core/InputBase'

import clsx from 'clsx'
import useStyles from './useStyles'

export interface InputProps
  extends Omit<InputBaseProps, 'color' | 'size' | 'classes'>,
    WithStyles<typeof useStyles> {
  // normal: normal input, prepend: input with bordered start icon, default = normal
  variant?: 'normal' | 'prepend'

  // input colors, default = default
  color?: 'default' | 'success' | 'warning' | 'error'

  // medium: normal input size, large: large input intended for mobile devices, default = medium,
  size?: 'medium' | 'large'
}

const Input: React.FC<InputProps> = (
  {
    classes,
    variant = 'normal',
    color = 'default',
    size = 'medium',
    error,
    startAdornment,
    ...others
  },
  ref,
) => {
  const startIcon = React.useMemo(() => {
    if (startAdornment !== undefined) {
      if (variant === 'prepend') {
        return (
          <Box className={classes.prependIconContainer}>{startAdornment}</Box>
        )
      }
      return startAdornment
    }
    return null
  }, [classes.prependIconContainer, startAdornment, variant])

  return (
    <InputBase
      {...others}
      classes={{
        root: clsx(
          classes.root,
          classes[size],
          classes[color],
          classes[variant],
        ),
        disabled: classes.disabled,
        error: classes.error,
        adornedStart: classes.inputAdornedStart,
        adornedEnd: classes.inputAdornedEnd,
        focused: classes.focused,
        input: classes.input,
        multiline: classes.multiline,
      }}
      error={error}
      startAdornment={startIcon}
    />
  )
}

export default React.memo(withStyles(useStyles)(Input))
