import { useRef, useEffect, useMemo, useCallback } from 'react'

export const useInterval = (
  callback: (...args: any[]) => void,
  delay = 120000,
) => {
  const interval = useRef<any>()

  const start = useCallback(() => {
    interval.current = setInterval(callback, delay)
  }, [callback, delay])
  const stop = () => {
    if (interval.current) {
      clearInterval(interval.current)
    }
  }

  useEffect(() => {
    return () => stop()
  }, [])

  return useMemo(() => [start, stop], [start])
}
