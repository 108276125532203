import createStyles from '@material-ui/core/styles/createStyles'
import { Theme } from '@material-ui/core/styles'

const styles = ({ palette, spacing }: Theme) =>
  createStyles({
    root: {
      width: '100%',
      height: spacing(4),
      margin: 0,
      color: 'inherit',
      overflow: 'hidden',
      display: 'flex',
      alignItems: 'center',
    },

    // colors
    default: {
      color: palette.trunkrs.primary.indigo.base,
    },
    grayscale: {
      color: palette.trunkrs.grayscale.theBeast,
    },
    success: {
      color: palette.trunkrs.primary.green.darker,
    },
    warning: {
      color: palette.trunkrs.intent.orange.darkest,
    },
    danger: {
      color: palette.trunkrs.intent.red.darker,
    },
    icon: {
      height: 16,
      width: 16,
      marginRight: 4,
    },
  })

export default styles
