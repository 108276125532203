/* eslint-disable import/prefer-default-export */

export const validatePhoneNumber = (value: string): boolean => {
  if (process.env.NODE_ENV === 'development') {
    return !(value && value.match(/\+(316|639|62)\d{8,9}$/))
  }
  return !(value && value.match(/\+316\d{8}$/))
}

export const transformPhoneNumber = (value: string): string => {
  // return nothing if no value
  if (!value) return value

  // only allows + and 0-9 inputs
  const currentValue = value.replace(/[^\\+\d]/g, '')
  const cvLength = currentValue.length

  // returns: "x", "xx", "xxx"
  if (cvLength < 4) return currentValue

  // returns: "(xxx)", "(xxx) x", "(xxx) xx", "(xxx) xxx",
  if (cvLength < 7)
    return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`

  // returns: "(xxx) xxx-", (xxx) xxx-x", "(xxx) xxx-xx", "(xxx) xxx-xxx", "(xxx) xxx-xxx"
  if (cvLength < 10)
    return `(${currentValue.slice(0, 3)}) ${currentValue.slice(
      3,
      6,
    )}-${currentValue.slice(6, 9)}`

  // returns: "(xxx) xxx-xxx-x", "(xxx) xxx-xxx-xx", "(xxx) xxx-xxx-xxx", "(xxx) xxx-xxx-xxxx"
  return `(${currentValue.slice(0, 3)}) ${currentValue.slice(
    3,
    6,
  )}-${currentValue.slice(6, 9)}-${currentValue.slice(9, 13)}`
}
