import React, { useCallback, useEffect, useRef, useState } from 'react'
import clsx from 'clsx'
import get from 'lodash/get'

import styles from './styles.module.scss'
import Icon from '../../Icon'

export interface MiniCalloutProps extends React.HTMLAttributes<any> {
  variant:
    | 'default'
    | 'primary'
    | 'info'
    | 'success'
    | 'danger'
    | 'warning'
    | string
  visible?: boolean
}

const MiniCallout: React.FC<MiniCalloutProps> = ({
  children,
  className,
  variant = 'default',
  visible,
  ...props
}) => {
  const icon = get(
    {
      info: 'info-circle',
      success: 'success',
      danger: 'danger',
      warning: 'warning',
    },
    variant,
    variant,
  )

  return (
    <div
      className={clsx(
        styles.callout,
        get(styles, variant),
        className,
        !visible && styles.fadeOut,
      )}
      {...props}
    >
      <Icon name={icon} />
      {children}
    </div>
  )
}

export default React.memo(MiniCallout)
