/* eslint-disable import/no-cycle */

import React from 'react'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'

import { useDispatch, useSelector } from 'react-redux'
import {
  BANNER_TYPES,
  getBusyDay,
  getCentralSortingStatus,
} from 'actions/warehouse'
import { reloadShipments } from 'actions/shipments'
import * as fromCombined from 'selectors/combined'
import * as fromUi from 'selectors/ui'
import * as fromWarehouse from 'selectors/warehouse'

import { useInterval } from 'hooks'

import Callout from 'common/components/base/.raw/Callout'

import useStyles from './useStyles'
import { getNetworkType } from '../../../../../selectors/networkType'
import { NETWORK_TYPE } from '../../../../models/typings/TourOverview'

const TourPlanningNotificationTour = () => {
  const { t } = useTranslation()
  const classes = useStyles()
  const dispatch = useDispatch()

  const networkType = useSelector(getNetworkType)
  const [busyDayLogicDone, setBusyDayLogicDone] = React.useState<boolean>(false)
  const [centralSortingLogicDone, setCentralSortingLogicDone] =
    React.useState<boolean>(false)

  const isBusyDay = useSelector(fromWarehouse.getIsBusyDay)
  const isCentralSortingDone = useSelector(
    fromWarehouse.getIsCentralSortingDone,
  )

  const allToursAreDoneAtOptimizedTime = useSelector(
    fromCombined.allToursAreDoneAtOptimizedTime,
  )
  const hasOptimizationError = useSelector(fromUi.getHasOptimizationError)

  const whatBannerShouldDisplayState: BANNER_TYPES = useSelector(
    (state: any) => state.warehouse.whatBannerShouldDisplay,
  )

  const [startBusyDayPolling, stopBusyDayPolling] = useInterval(
    getBusyDay(),
    10800000,
  )
  const [startCentralSortingPolling, stopCentralSortingPolling] = useInterval(
    getCentralSortingStatus(),
    300000,
  )

  React.useEffect(() => {
    // run once
    dispatch(getBusyDay())

    startBusyDayPolling()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    if (isBusyDay && !busyDayLogicDone) {
      stopBusyDayPolling()

      // run once then poll
      dispatch(getCentralSortingStatus())
      startCentralSortingPolling()
      setBusyDayLogicDone(true)
    }

    if (isCentralSortingDone && !centralSortingLogicDone) {
      stopCentralSortingPolling()
      // get the latest shipment status
      dispatch(reloadShipments())
      setCentralSortingLogicDone(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isBusyDay,
    isCentralSortingDone,
    busyDayLogicDone,
    centralSortingLogicDone,
  ])

  return (
    <>
      {whatBannerShouldDisplayState === BANNER_TYPES.BUSY_DAY && (
        <Callout className={classes.callout} variant="primary">
          {t('GLOBAL_NOTIFICATIONS.BUSY_PEAK_DAYS')}
        </Callout>
      )}
      {whatBannerShouldDisplayState === BANNER_TYPES.CENTRAL_SORTING && (
        <Callout
          className={clsx(classes.callout, classes.center)}
          variant="danger"
        >
          {t('GLOBAL_NOTIFICATIONS.CENTRAL_SORTING_IS_DONE')}
        </Callout>
      )}
      {!allToursAreDoneAtOptimizedTime && (
        <Callout className={classes.callout} variant="warning">
          {t('LITE_NOTIFICATION_MESSAGE_NOT_DONE_BEFORE_TIME', {
            timestamp: networkType === NETWORK_TYPE.DAY ? '16:00' : '22:00',
          })}
        </Callout>
      )}
      {hasOptimizationError && (
        <Callout className={classes.callout} variant="warning">
          {t('LITE_NOTIFICATION_MESSAGE_OPTIMIZATION_FAILURE')}
        </Callout>
      )}
    </>
  )
}

export default React.memo(TourPlanningNotificationTour)
