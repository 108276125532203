import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box } from '@material-ui/core'
import clsx from 'clsx'

import { ShipmentTour } from 'common/models/typings/Tour'

import Typography from 'common/components/base/Typography'

import useStyles from './useStyles'

export interface FuelTypeProps {
  vehicleType: ShipmentTour['vehicleType']
  disabled: boolean
}

const VehicleBadge: React.FC<FuelTypeProps> = ({ vehicleType, disabled }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <Box
      className={clsx(classes.badge, classes[vehicleType], {
        [classes.disabled]: disabled,
      })}
    >
      <Typography
        component="span"
        variant="tableHeader"
        color="indigo"
        transform="capitalize"
      >
        {t(`${vehicleType.toUpperCase()}`)}
      </Typography>
    </Box>
  )
}

export default VehicleBadge
