import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import useStyles from './useStyles'
import Typography from '../../../common/components/base/Typography'
import NetworkTypeCard from '../../../common/components/base/NetworkTypeCard'
import RocketLaunch from '../../../common/components/animations/RocketLaunch'
import { NETWORK_TYPE } from '../../../common/models/typings/TourOverview'
import { selectNetworkType } from '../../../actions/networkType'
import { useTranslation } from 'react-i18next'

export interface ChooseNetworkTypeProps {
  handleStartPlanning: () => void
}

export const ChooseNetworkType: React.FC<ChooseNetworkTypeProps> = ({
  handleStartPlanning,
}) => {
  const classes = useStyles()
  const [t] = useTranslation()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [speed, setSpeed] = React.useState(0)

  const handleSelectNetworkType = React.useCallback(
    (e: React.MouseEvent<HTMLElement>, networkType: NETWORK_TYPE) => {
      dispatch(selectNetworkType(networkType))
      setSpeed(0.75)
      setTimeout(() => handleStartPlanning(), 1250)
    },
    [],
  )

  return (
    <div className={classes.root}>
      <div className={classes.rocket}>
        <RocketLaunch width={300} height={400} speed={speed} />
      </div>
      <div className={classes.text}>
        <Typography variant="h3">
          {t('EMPTY_SCREENS.WELCOME_WIZARD.HEADER')}
        </Typography>
        <Typography className={classes.subtitle}>
          {t('EMPTY_SCREENS.WELCOME_WIZARD.DESCRIPTION_1')}
          <span>{t('EMPTY_SCREENS.WELCOME_WIZARD.DESCRIPTION_2')}</span>
        </Typography>
      </div>
      <div className={classes.cards}>
        <NetworkTypeCard
          networkType={NETWORK_TYPE.DAY}
          onClick={(e) => handleSelectNetworkType(e, NETWORK_TYPE.DAY)}
        />
        <NetworkTypeCard
          networkType={NETWORK_TYPE.NIGHT}
          onClick={(e) => handleSelectNetworkType(e, NETWORK_TYPE.NIGHT)}
        />
      </div>
    </div>
  )
}

export default ChooseNetworkType
