import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const styles = ({ spacing }: Theme) =>
  createStyles({
    lastCell: {
      borderBottom: 0,
    },
  })

export default makeStyles(styles)
