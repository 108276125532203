import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box } from '@material-ui/core'

import { useDispatch, useSelector } from 'react-redux'
// eslint-disable-next-line import/no-cycle
import * as tourOverviewActions from 'actions/tourOverview'
import * as fromTourOverview from 'selectors/tourOverview'

import { TourOverview } from 'common/models/typings/TourOverview'

import Typography from 'common/components/base/Typography'
import NoActiveTour from 'common/components/features/EmptyState/NoActiveTour'
import MonitoringLegends from 'common/components/features/Monitoring/Legends'
import TourLegends from 'common/components/features/Monitoring/TourLegends'

const MonitoringPage = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [isAlreadyBootstrap, setIsAlreadyBootstrap] =
    React.useState<boolean>(false)

  const { bootstrapping } = useSelector((state: any) => state.ui)
  const tourOverview: TourOverview[] = useSelector(
    fromTourOverview.getTourOverviews,
  )

  React.useEffect(() => {
    if (!bootstrapping && !isAlreadyBootstrap) {
      dispatch(tourOverviewActions.fetchTourOverviews())
      setIsAlreadyBootstrap(true)
    }
  }, [dispatch, bootstrapping, isAlreadyBootstrap])

  const tourLegendCards = React.useMemo(
    () =>
      tourOverview.map((overview, index) => (
        <React.Fragment key={overview.tour.id}>
          <TourLegends
            tour={overview.tour}
            collations={overview.collations}
            driver={overview.driver}
          />
          {tourOverview.length !== index + 1 && <Box height={16} />}
        </React.Fragment>
      )),
    [tourOverview],
  )

  if (tourOverview.length < 1) {
    return <NoActiveTour />
  }

  return (
    <>
      <MonitoringLegends />
      <Box height={32} />
      {tourLegendCards}
    </>
  )
}

export default MonitoringPage
