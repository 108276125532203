import React from 'react'
import { useTranslation } from 'react-i18next'

import Button from 'common/components/base/Button'
import Icon from 'common/components/base/Icon'

import styles from './styles.module.scss'
import Lottie from '../../Lottie'
import animationData from '../../../animations/animationFiles/shipments-empty-state.json'

export interface ShipmentEmptyProps {
  onReloadShipments: () => void
}

const ShipmentEmpty: React.FC<ShipmentEmptyProps> = ({ onReloadShipments }) => {
  const { t } = useTranslation()

  return (
    <div className={styles.container}>
      <Lottie
        width={400}
        styles={{
          padding: 0,
        }}
        preserveAspectRatio="xMidYMid slice"
        animationData={animationData}
        isAutoPlay={false}
        isLoop
      />
      <div>
        <span>{t('SHIPMENTS_OVERVIEW.EMPTY_TITLE')}</span>
        <div>{t('SHIPMENTS_OVERVIEW.EMPTY_DESC')}</div>
      </div>
      <Button
        color="green"
        onClick={onReloadShipments}
        startIcon={<Icon name="retry" />}
      >
        {t('SHIPMENTS_OVERVIEW.RELOAD_BUTTON')}
      </Button>
    </div>
  )
}

export default React.memo(ShipmentEmpty)
