import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const styles = ({ spacing, palette }: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    legends: {
      display: 'flex',
      alignItems: 'center',
      marginRight: spacing(2),
    },
    badge: {
      height: 10,
      width: 10,
      borderRadius: 5,
      marginRight: spacing(1),
    },
    notScanned: {
      backgroundColor: palette.trunkrs.grayscale.theBeast,
    },
    inTransit: {
      backgroundColor: palette.trunkrs.intent.yellow.base,
    },
    delivered: {
      backgroundColor: palette.trunkrs.primary.green.base,
    },
    deliveredToNeighbour: {
      backgroundColor: palette.common.white,
      boxShadow: `0 0 0 2px ${palette.trunkrs.primary.green.base}`,
    },
    notDelivered: {
      backgroundColor: palette.trunkrs.intent.red.base,
    },
  })

export default makeStyles(styles)
