import React, { Children } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

const RoutePublic: React.FC<any> = ({ children }) => {
  const { isAuthenticated } = useSelector((state: any) => state.ui)
  const navigate = useNavigate()

  React.useEffect(() => {
    if (isAuthenticated) navigate('/')
  }, [isAuthenticated, navigate])

  return children
}

export default RoutePublic
