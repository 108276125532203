import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  CircularProgress,
  ButtonBase,
  InputBaseComponentProps,
  Box,
} from '@material-ui/core'
import get from 'lodash/get'

import Typography from 'common/components/base/Typography'
import Input from 'common/components/base/forms/Input'
import Icon from 'common/components/base/Icon'
import Button from 'common/components/base/Button'
import InputHelper from 'common/components/base/forms/InputHelper'

import useStyles from './useStyles'

export interface OtpFormProps {
  isSending?: boolean
  isBtnDisabled?: boolean
  error?: boolean
  phone: string
  value: string
  onChange: (value: string) => void
  onSend: () => Promise<void>
  onResend: () => void | Promise<void>
}

const inputProps: InputBaseComponentProps = {
  maxLength: 1,
  style: { textAlign: 'center', fontSize: 32, fontWeight: 'bold' },
}

const DIGIT_COUNT = 6
const digitFields = Array(DIGIT_COUNT).fill(null)

const OtfForm: React.FC<OtpFormProps> = ({
  isSending,
  isBtnDisabled,
  error,
  phone,
  value,
  onChange,
  onSend,
  onResend,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const { current: fieldRefs } = React.useRef(Array(DIGIT_COUNT))

  React.useEffect(() => {
    if (value.length === 6) {
      onSend().then(() => {
        if (typeof fieldRefs[0].focus === 'function') {
          fieldRefs[0].focus()
        }
      })
    }
  }, [fieldRefs, onSend, value.length])

  const mergeWithChange = (
    digits: string,
    newDigit: string,
    changeIndex: number,
  ) =>
    digitFields.reduce(
      (string, _, iterIndex) =>
        string +
        (iterIndex === changeIndex ? newDigit : get(digits, iterIndex) || ''),
      '',
    )

  const handleChangeHop = React.useCallback(
    (currentIndex: number, forward: boolean) => {
      if (forward && currentIndex === fieldRefs.length - 1) {
        return
      }
      if (!forward && currentIndex === 0) {
        return
      }

      fieldRefs[currentIndex + (forward ? 1 : -1)].focus()
    },
    [fieldRefs],
  )

  const handleFocuBackWhenNoValue = React.useCallback(
    (
      event: React.KeyboardEvent<HTMLDivElement | HTMLTextAreaElement>,
      inputValue: string,
      index: number,
    ) => {
      if (event.key === 'Backspace') {
        if (inputValue === '') {
          handleChangeHop(index, false)
        }
      }
    },
    [handleChangeHop],
  )

  return (
    <div className={classes.root}>
      <Typography
        variant="text"
        color="theBeast"
        align="center"
        className={classes.sub}
      >
        {t('SENT_CODE_TO', { number: phone })}
      </Typography>
      <Box marginBottom={4}>
        <div className={classes.inputsContainer}>
          {digitFields.map((_, index) => (
            <Input
              fullWidth
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              autoFocus={index === 0}
              // eslint-disable-next-line no-return-assign
              inputRef={(ref: any) => (fieldRefs[index] = ref)}
              inputProps={inputProps}
              className={classes.input}
              value={value.slice(index, index + 1)}
              onChange={({ target: { value: digit } }) => {
                onChange(mergeWithChange(value, digit, index))
                handleChangeHop(index, !!digit)
              }}
              onKeyUp={(event) =>
                handleFocuBackWhenNoValue(
                  event,
                  value.slice(index, index + 1),
                  index,
                )
              }
              error={error}
              disabled={isSending}
            />
          ))}
        </div>
        <Box height={32}>
          {error && (
            <InputHelper
              icon="danger"
              variant="danger"
              text={t('WRONG_VERIFICATION_CODE')}
            />
          )}
        </Box>
      </Box>

      <Button
        fullWidth
        size="large"
        variant="default"
        color="green"
        className={classes.button}
        disabled={isBtnDisabled}
        onClick={onSend}
        endIcon={isSending && <CircularProgress size={20} thickness={3} />}
      >
        {t('VERIFY_AND_LOGIN')}
      </Button>
      <ButtonBase onClick={isSending ? undefined : onResend}>
        <Typography
          color="grey"
          variant="packageText"
          className={classes.resend}
        >
          {t('RESENT_CODE')}
        </Typography>
      </ButtonBase>
    </div>
  )
}

export default React.memo(OtfForm)
