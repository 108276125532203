import React from 'react'
import { ClickAwayListener, Paper } from '@material-ui/core'

import { ShipmentTour } from 'common/models/typings/Tour'

import SelectFuelType from './SelectFuelType'

interface CustomPaperProps
  extends Omit<
    React.PropsWithChildren<React.HTMLAttributes<HTMLElement>>,
    'id'
  > {
  id: ShipmentTour['id']
  vehicleType: ShipmentTour['vehicleType']
  disabled: boolean
  onChangeVehicleType: (name: ShipmentTour['vehicleType']) => void
  onClickAway: () => void
}

const CustomPaper: React.FC<CustomPaperProps> = ({
  children,
  id,
  vehicleType,
  disabled,
  onChangeVehicleType,
  onClickAway,
  ...other
}) => {
  const handleClickAway = React.useCallback(
    (event: React.MouseEvent<Document, MouseEvent>) => {
      const { tagName, id: inputId, childNodes } = event.target as HTMLElement
      let haveChildOfAutocomplete = false
      let childId = ''
      childNodes.forEach((child) => {
        if (haveChildOfAutocomplete) return

        if ((child as HTMLElement)?.tagName?.toUpperCase() === 'INPUT') {
          haveChildOfAutocomplete = true
          childId = (child as HTMLElement).id
        }
      })

      if (
        (haveChildOfAutocomplete && childId === `autocomplete-${id}`) ||
        (tagName.toUpperCase() === 'INPUT' && inputId === `autocomplete-${id}`)
      )
        return
      onClickAway()
    },
    [id, onClickAway],
  )

  return (
    <Paper {...other}>
      <ClickAwayListener onClickAway={handleClickAway}>
        <div key="click-away-wrapper">
          <SelectFuelType
            vehicleType={vehicleType}
            locked={disabled}
            onChangeVehicleType={onChangeVehicleType}
          />
          {children}
        </div>
      </ClickAwayListener>
    </Paper>
  )
}

export default CustomPaper
