import createStyles from '@material-ui/core/styles/createStyles'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { Theme } from '@material-ui/core/styles'

const styles = ({ palette, spacing }: Theme) =>
  createStyles({
    root: {
      width: '100%',
      height: '100%',
      maxWidth: spacing(50),
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    illustrationCont: {
      width: spacing(43),
      height: spacing(25),
      marginBottom: spacing(4),
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    illustrationIcon: {
      width: '100%',
      height: '100%',
    },
    headerCont: {
      paddingBottom: spacing(2),
    },
    descriptionCont: {
      marginBottom: spacing(4),
    },
    actionCont: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '& button:first-child': {
        marginRight: spacing(1),
      },
    },
    btn: {
      color: palette.trunkrs.primary.indigo.base,
      minWidth: spacing(12.25),
      height: spacing(5),
    },
    secondaryBtn: {
      backgroundColor: 'transparent !important',
    },
  })

export default makeStyles(styles)
