/* eslint-disable import/no-cycle */

import moment from 'moment'
import get from 'lodash/get'
import * as Sentry from '@sentry/browser'

import { getCurrentSubcoId } from 'selectors/profile'
import { store } from 'App'
import trunkrs from 'common/utils/Sdk'
import { setLoading } from '../ui'
import * as fromCombined from '../../selectors/combined'

import * as types from '../../constants/ActionTypes'

export enum BANNER_TYPES {
  BUSY_DAY = 'BUSY_DAY',
  CENTRAL_SORTING = 'CENTRAL_SORTING',
  NONE = 'NONE',
}

export const resetWarehouseState =
  () =>
  (
    dispatch: (arg0: {
      type: string
      payload:
        | BANNER_TYPES
        | { centralSorting: null; isCentralSortingDone: boolean }
        | { busyDay: null; isBusyDayToday: boolean }
    }) => void,
    getState: any,
  ): void => {
    // reset banner
    dispatch({
      type: types.WAREHOUSE_SET_WHAT_BANNER_SHOULD_DISPLAY,
      payload: BANNER_TYPES.NONE,
    })
    // reset central sorting
    dispatch({
      type: types.WAREHOUSE_GET_CENTRAL_SORTING,
      payload: {
        centralSorting: null,
        isCentralSortingDone: false,
      },
    })
    // reset busy day
    dispatch({
      type: types.WAREHOUSE_GET_BUSY_DAY,
      payload: {
        busyDay: null,
        isBusyDayToday: false,
      },
    })
  }

export const whatBannerShouldDisplay =
  () =>
  (
    dispatch: (arg0: { type: string; payload: BANNER_TYPES }) => void,
    getState: () => any,
  ): void => {
    const state = getState()
    const { isBusyDay, isCentralSortingDone } = state.warehouse
    const isToursNotSorted =
      fromCombined.getShipmentToursIfIncludesNotSorted(state)
    const areToursPlanned = fromCombined.areToursPlanned(state)
    let showCentralSortingBanner = false
    let dontShowBanners = false

    if (isCentralSortingDone) {
      // check tours have not sorted state (SHIPMENT_SORTED) and Tours are not planned yet
      if (isToursNotSorted && !areToursPlanned) {
        showCentralSortingBanner = true
      } else {
        dontShowBanners = true
      }
    } else {
      showCentralSortingBanner = false
    }

    if (isBusyDay && !dontShowBanners) {
      if (showCentralSortingBanner) {
        dispatch({
          type: types.WAREHOUSE_SET_WHAT_BANNER_SHOULD_DISPLAY,
          payload: BANNER_TYPES.CENTRAL_SORTING,
        })
      } else {
        dispatch({
          type: types.WAREHOUSE_SET_WHAT_BANNER_SHOULD_DISPLAY,
          payload: BANNER_TYPES.BUSY_DAY,
        })
      }
    } else {
      dispatch({
        type: types.WAREHOUSE_SET_WHAT_BANNER_SHOULD_DISPLAY,
        payload: BANNER_TYPES.NONE,
      })
    }
  }

export const getCentralSortingStatus = () => async (): Promise<void> => {
  try {
    const state = store.getState()
    const subcoId = getCurrentSubcoId(state as never)
    const {
      result: { warehouseProcessCompletedAt },
    } = await trunkrs.Subco().getCentralSortingStatus(subcoId)
    const isCentralSortingDone = warehouseProcessCompletedAt
      ? moment(warehouseProcessCompletedAt).isSame(moment(), 'day')
      : false

    store.dispatch({
      type: types.WAREHOUSE_GET_CENTRAL_SORTING,
      payload: {
        centralSorting: warehouseProcessCompletedAt,
        isCentralSortingDone,
      },
    })
    store.dispatch(whatBannerShouldDisplay())
  } catch (error) {
    Sentry.captureException(error)
  }
}

export const getBusyDay = () => async (): Promise<void> => {
  try {
    const { date: busyDay } = await trunkrs.Warehouse().getWarehouseBusyDay()
    const isBusyDayToday = busyDay
      ? moment(busyDay).isSame(moment(), 'day')
      : false

    store.dispatch({
      type: types.WAREHOUSE_GET_BUSY_DAY,
      payload: {
        busyDay,
        isBusyDayToday,
      },
    })
    store.dispatch(whatBannerShouldDisplay())
  } catch (error) {
    Sentry.captureException(error)
  }
}

export const setBusyDay = async (busyDay?: Date): Promise<void> => {
  try {
    store.dispatch(setLoading(true))
    const formattedDate = moment(busyDay).format('YYYY-MM-DD')
    await trunkrs
      .Warehouse()
      .putWarehouseBusyDay(
        busyDay ? moment(busyDay).format('YYYY-MM-DD') : undefined,
      )

    const isBusyDayToday = moment(busyDay).isSame(moment(), 'day')

    store.dispatch({
      type: types.WAREHOUSE_SET_BUSY_DAY,
      payload: {
        busyDay: formattedDate,
        isBusyDayToday,
      },
    })
  } catch (error) {
    Sentry.captureException(error)
  } finally {
    store.dispatch(setLoading(false))
  }
}

export const deleteBusyDay = async (): Promise<void> => {
  try {
    store.dispatch(setLoading(true))
    await trunkrs.Warehouse().deleteWarehouseBusyDay()

    store.dispatch({
      type: types.WAREHOUSE_DELETE_BUSY_DAY,
      payload: null,
    })
  } catch (error) {
    Sentry.captureException(error)
  } finally {
    store.dispatch(setLoading(false))
  }
}

export const getNetworkStatus = async (
  date: string,
  networkType: 'DAY' | 'NIGHT',
): Promise<void> => {
  try {
    store.dispatch(setLoading(true))
    const networkStatus = await trunkrs
      .Shipment()
      .getCapacitySlotStatus(date, networkType)

    store.dispatch({
      type: types.WAREHOUSE_GET_NETWORK_STATUS,
      payload: get(networkStatus, 'isClosed', false),
    })
  } catch (error) {
    Sentry.captureException(error)
  } finally {
    store.dispatch(setLoading(false))
  }
}
