/* eslint-disable import/no-cycle */

import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box, IconButton, Popover, PopoverProps, Fade } from '@material-ui/core'
import { CirclePicker } from 'react-color'

import { useDispatch, useSelector } from 'react-redux'
import {
  optimizeTour,
  reverseTour,
  saveTourColor,
  setHiddenTourIds,
  setSelectedTourId,
} from 'actions/tours'
import { colours, contrastColor } from 'common/utils/Color'

import { ShipmentTour } from 'common/models/typings/Tour'

import Icon from 'common/components/base/Icon'
import Typography from 'common/components/base/Typography'
import Button from 'common/components/base/Button'

import useStyles from './useStyles'
import { areToursLocked } from 'selectors/combined'
import { Tooltip } from '../../../base/.raw'

const anchorOrigin: PopoverProps['anchorOrigin'] = {
  vertical: 'bottom',
  horizontal: 'right',
}

const transformOrigin: PopoverProps['transformOrigin'] = {
  vertical: 'top',
  horizontal: 'right',
}

export interface ActionsProps {
  id: ShipmentTour['id']
  color: ShipmentTour['color']
  hasBermudaStop: ShipmentTour['hasBermudaStop']
}

/**
 *
 * @param {string | number} id Shipment tour id
 * @param {string} color Shipment tour color
 * @param {boolean} hasBermudaStop Shipment tour if has bermuda stop
 * @returns
 */
const Actions: React.FC<ActionsProps> = ({ id, color, hasBermudaStop }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const classes = useStyles()

  const locked = useSelector(areToursLocked)
  const selectedTourId: ShipmentTour['id'] = useSelector(
    (state: any) => state.tours.selectedTourId,
  )
  const hiddenTourIds: ShipmentTour['id'][] = useSelector(
    (state: any) => state.tours.hiddenTourIds,
  )

  const [colorPickerRef, setColorPickerRef] =
    React.useState<HTMLElement | null>(null)

  const isOptimizeLocked = React.useMemo(
    () => locked || hasBermudaStop,
    [locked, hasBermudaStop],
  )

  const isTourSelected = React.useMemo(
    // eslint-disable-next-line eqeqeq
    () => id == selectedTourId,
    [id, selectedTourId],
  )

  const isTourHidden = React.useMemo(
    // eslint-disable-next-line eqeqeq
    () => hiddenTourIds.findIndex((hdt) => hdt == id) !== -1,
    [hiddenTourIds, id],
  )

  const isShowTourLocked = React.useMemo(
    // eslint-disable-next-line eqeqeq
    () => locked || id == selectedTourId,
    [locked, id, selectedTourId],
  )

  const handleOptimizeTour = () => {
    dispatch(optimizeTour(id))
  }

  const handleReverseTour = () => {
    dispatch(reverseTour(id))
  }

  const handleToggleColorPicker = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    setColorPickerRef((prev) => (prev === null ? event.currentTarget : null))
  }

  const handleHideTour = () => {
    dispatch(setHiddenTourIds(id))
  }

  const handleChangeColor = (selectedColor: string) => {
    if (color !== selectedColor) {
      dispatch(saveTourColor(id, selectedColor))
    }
  }

  const handleShowTour = () => {
    dispatch(setSelectedTourId(id))
  }

  return (
    <Box className={classes.root}>
      <Tooltip text={t('OPTIMISE_TOUR')} placement="bottom">
        <Box component="span">
          <IconButton
            className={classes.button}
            disabled={isOptimizeLocked}
            onClick={handleOptimizeTour}
          >
            <Icon name="retry" className={classes.icon} />
          </IconButton>
        </Box>
      </Tooltip>

      <Tooltip text={t('REVERSE_TOUR')} placement="bottom">
        <Box component="span">
          <IconButton
            className={classes.button}
            disabled={locked}
            onClick={handleReverseTour}
          >
            <Icon name="swap" className={classes.icon} />
          </IconButton>
        </Box>
      </Tooltip>

      <Tooltip text={t('COLOR_TOUR')} placement="bottom">
        <Box component="span">
          <IconButton
            className={classes.button}
            disabled={locked}
            onClick={handleToggleColorPicker}
            style={{ backgroundColor: color }}
          >
            <Icon
              name="palette"
              fill={contrastColor(color)}
              className={classes.icon}
            />
          </IconButton>
        </Box>
      </Tooltip>
      <Popover
        open={Boolean(colorPickerRef)}
        anchorEl={colorPickerRef}
        onClose={handleToggleColorPicker}
        classes={{ paper: classes.colorPickerContainer }}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
      >
        <Box marginBottom={1}>
          <Typography>{t('CHOOSE_TOUR_COLOUR')}</Typography>
        </Box>
        <CirclePicker
          width="100%"
          circleSpacing={12}
          circleSize={32}
          colors={colours}
          onChangeComplete={(clr) => handleChangeColor(clr.hex)}
          color={color}
        />
      </Popover>
      <Tooltip
        text={t(!isTourHidden ? 'HIDE_TOUR_TOOLTIP' : 'UNHIDE_TOUR_TOOLTIP')}
        placement="bottom"
      >
        <Box component="span">
          <IconButton
            className={classes.button}
            disabled={isShowTourLocked}
            onClick={handleHideTour}
          >
            {!isTourHidden ? <Icon name="view" /> : <Icon name="hide" />}
          </IconButton>
        </Box>
      </Tooltip>
      <Button
        color="white"
        className={classes.buttonShowTour}
        onClick={handleShowTour}
      >
        <Typography noWrap variant="button">
          {isTourSelected ? t('HIDE_TOUR') : t('SHOW_FULL_TOUR')}
        </Typography>
      </Button>
    </Box>
  )
}

export default Actions
