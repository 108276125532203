/* eslint-disable import/prefer-default-export */

import { ReactComponent as PlusIcon } from 'common/assets/images/icon-library/plus-icon.svg'
// import { ReactComponent as SwapIcon } from 'common/assets/images/icon-library/swap-icon.svg'
import { ReactComponent as EditIcon } from 'common/assets/images/icon-library/edit-icon.svg'
import { ReactComponent as TrashIcon } from 'common/assets/images/icon-library/trash-icon.svg'
import { ReactComponent as CloseIcon } from 'common/assets/images/icon-library/close-icon.svg'
import { ReactComponent as CheckCircleIcon } from 'common/assets/images/icon-library/check-circle-icon.svg'
// import { ReactComponent as InfoCircleIcon } from 'common/assets/images/icon-library/info-circle-icon.svg'
// import { ReactComponent as WarningIcon } from 'common/assets/images/icon-library/warning-icon.svg'
// import { ReactComponent as DangerIcon } from 'common/assets/images/icon-library/danger-icon.svg'
import { ReactComponent as SearchIcon } from 'common/assets/images/icon-library/search-icon.svg'
// import { ReactComponent as PhoneIcon } from 'common/assets/images/icon-library/phone-icon.svg'
import { ReactComponent as HouseIcon } from 'common/assets/images/icon-library/house-icon.svg'
import { ReactComponent as ChevronDownIcon } from 'common/assets/images/icon-library/chevron-down.svg'
import { ReactComponent as RetryIcon } from 'common/assets/images/icon-library/retry-icon.svg'
import { ReactComponent as ElipseIcon } from 'common/assets/images/icon-library/elipse.svg'
import { ReactComponent as ElipseFilledIcon } from 'common/assets/images/icon-library/elipse-filled.svg'
import { ReactComponent as TruckIcon } from 'common/assets/images/icon-library/truck.svg'
import { ReactComponent as CheckIcon } from 'common/assets/images/icon-library/check.svg'
import { ReactComponent as PhoneIcon } from 'common/assets/images/icon-library/phone.svg'
import { ReactComponent as PackageIcon } from 'common/assets/images/icon-library/package.svg'
import { ReactComponent as OrganisationIcon } from 'common/assets/images/icon-library/organisation.svg'
import { ReactComponent as HubInventoryIcon } from 'common/assets/images/icon-library/hub-inventory.svg'
import { ReactComponent as DangerIcon } from 'common/assets/images/icon-library/danger.svg'
import { ReactComponent as WarningIcon } from 'common/assets/images/icon-library/warning.svg'
import { ReactComponent as SuccessIcon } from 'common/assets/images/icon-library/success.svg'
import { ReactComponent as InfoCircleIcon } from 'common/assets/images/icon-library/info-circle.svg'
import { ReactComponent as ArrowRightIcon } from 'common/assets/images/icon-library/arrow-right.svg'
import { ReactComponent as SubtractIcon } from 'common/assets/images/icon-library/subtract.svg'
import { ReactComponent as SwapIcon } from 'common/assets/images/icon-library/swap-icon.svg'
import { ReactComponent as PaletteIcon } from 'common/assets/images/icon-library/palette.svg'
import { ReactComponent as ViewIcon } from 'common/assets/images/icon-library/view.svg'
import { ReactComponent as HideIcon } from 'common/assets/images/icon-library/hide.svg'
import { ReactComponent as CaretDownIcon } from 'common/assets/images/icon-library/caret-down.svg'
import { ReactComponent as UserIcon } from 'common/assets/images/icon-library/user.svg'
import { ReactComponent as LogoutIcon } from 'common/assets/images/icon-library/logout.svg'
import { ReactComponent as InfoIcon } from 'common/assets/images/icon-library/info.svg'
import { ReactComponent as NoScanIcon } from 'common/assets/images/icon-library/no-scan.svg'
import { ReactComponent as EditUserIcon } from 'common/assets/images/icon-library/edit-user.svg'
import { ReactComponent as PrintIcon } from 'common/assets/images/icon-library/print.svg'
import { ReactComponent as ExternalLinkIcon } from 'common/assets/images/icon-library/external-link.svg'
import { ReactComponent as ArrowLeftIcon } from 'common/assets/images/icon-library/arrow-left.svg'
import { ReactComponent as TableIcon } from 'common/assets/images/icon-library/table.svg'
import { ReactComponent as CaretUpIcon } from 'common/assets/images/icon-library/caret_up.svg'
import { ReactComponent as GroupIcon } from 'common/assets/images/icon-library/group.svg'
import { ReactComponent as UngroupIcon } from 'common/assets/images/icon-library/ungroup.svg'

export const IconLibrary = {
  plus: PlusIcon,
  edit: EditIcon,
  trash: TrashIcon,
  swap: SwapIcon,
  close: CloseIcon,
  'check-circle': CheckCircleIcon,
  'info-circle': InfoCircleIcon,
  warning: WarningIcon,
  danger: DangerIcon,
  success: SuccessIcon,
  search: SearchIcon,
  phone: PhoneIcon,
  house: HouseIcon,
  'chevron-down': ChevronDownIcon,
  retry: RetryIcon,
  elipse: ElipseIcon,
  'elipse-filled': ElipseFilledIcon,
  truck: TruckIcon,
  check: CheckIcon,
  package: PackageIcon,
  organisation: OrganisationIcon,
  'hub-inventory': HubInventoryIcon,
  'arrow-right': ArrowRightIcon,
  subtract: SubtractIcon,
  palette: PaletteIcon,
  view: ViewIcon,
  hide: HideIcon,
  'caret-down': CaretDownIcon,
  user: UserIcon,
  logout: LogoutIcon,
  info: InfoIcon,
  'no-scan': NoScanIcon,
  'edit-user': EditUserIcon,
  print: PrintIcon,
  'external-link': ExternalLinkIcon,
  'arrow-left': ArrowLeftIcon,
  table: TableIcon,
  'caret-up': CaretUpIcon,
  group: GroupIcon,
  ungroup: UngroupIcon,
}
