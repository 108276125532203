import * as React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'

import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import IconButton from '@material-ui/core/IconButton'

import TourTable from '../TourTable'

import Typography from '../../../base/Typography'
import Icon from '../../../base/Icon'

import classes from './TourDialog.module.scss'

const TourDialog = ({ tour, onClose }) => {
  const { t } = useTranslation()

  return (
    <Dialog
      open
      className={classes.dialog}
      classes={{ paper: classes.paper }}
      PaperProps={{ elevation: 1 }}
    >
      <div className={classes.title}>
        <Typography variant="headerRegular">
          {t('COLLECTION_TOUR_TITLE')}
        </Typography>

        <IconButton className={classes.closeBtn} onClick={onClose}>
          <Icon name="close" />
        </IconButton>
      </div>

      <DialogContent
        className={clsx(classes.content, !tour.remark && classes.noRemark)}
      >
        {tour.remark && (
          <p className={classes.remarkTitle}>
            <Typography component="span">{t('TOUR_REMARK')}</Typography>
            {tour.remark}
          </p>
        )}

        <div className={classes.tableContainer}>
          <TourTable stops={tour.stops} />
        </div>
      </DialogContent>
    </Dialog>
  )
}

TourDialog.propTypes = {
  tour: PropTypes.object.isRequired,
  onClose: PropTypes.func,
}

TourDialog.defaultProps = {
  onClose: () => null,
}

export default TourDialog
