import uuid from 'uuid'
import map from 'lodash/map'
import flatMap from 'lodash/flatMap'
import last from 'lodash/last'
import get from 'lodash/get'
import every from 'lodash/every'

import { captureException } from '@sentry/browser'

import getColor from '../../utils/getColor'
import * as types from '../../constants/ActionTypes'
import { TOUR_STATES } from '../../constants/tours'

import * as shipmentActions from '../shipments'

import * as fromProfile from '../../selectors/profile'
import * as fromTours from '../../selectors/tour'
import * as fromCollations from '../../selectors/collations'
import * as fromCombined from '../../selectors/combined'
import * as fromShipments from '../../selectors/shipments'

import { arrayify, resolveIndex } from '../../utils/dataSets'
import { translate } from '../../utils'

import * as helpers from './helpers'
import { setLoading } from '../ui'
import trunkrs from 'common/utils/Sdk'
import { normalizeTours } from '../../schema'
import * as Sentry from '@sentry/browser'
import { isBermudaStop } from 'actions/tours/helpers'

export function setEditMode(tourId) {
  return { type: types.SET_EDIT_MODE, payload: tourId }
}

export function setTours(rawEstimations, total) {
  const tours = rawEstimations.map((collations, tourIndex) => {
    const nextCollations = collations.map(helpers.mapEstimationToCollation)
    const hasBermudaStop = nextCollations.find(isBermudaStop)

    return {
      id: uuid(),
      color: getColor(tourIndex),
      number: tourIndex + 1,
      driverId: null,
      state: 'TOUR_CREATED',
      collations: nextCollations,
      vehicleType: 'fossil',
      hasBermudaStop: get(hasBermudaStop, 'isBermudaStop', false),
      ...get(total, tourIndex),
    }
  })

  const nextTours = helpers.mapProductivityToTours(
    tours,
    fromTours.getTourProductivity,
  )

  return {
    type: types.SET_TOURS,
    payload: normalizeTours(nextTours),
  }
}

export function setShipmentTours(shipments) {
  const shipmentTours = helpers.mapShipmentsToTours(shipments)
  const nextTours = helpers.mapProductivityToTours(
    shipmentTours,
    fromTours.getTourProductivity,
  )

  return {
    type: types.SET_TOURS,
    payload: normalizeTours(nextTours),
  }
}

export function updateTours(...tours) {
  const nextTours = helpers.mapProductivityToTours(
    tours,
    fromTours.getTourProductivity,
  )
  return {
    type: types.UPDATE_TOURS,
    payload: normalizeTours(nextTours),
  }
}

export function updateTour(tour) {
  return {
    type: types.UPDATE_TOUR,
    payload: { tour },
  }
}

export function setRegionProductivityGoal(productivity) {
  return {
    type: types.SET_REGION_PRODUCTIVITY_GOAL,
    payload: { productivity },
  }
}

export const getProductivityGoal = () => async (dispatch, getState) => {
  const state = getState()
  const sdkSubCo = fromProfile.getSdkSubco(state)
  const numberOfShipments = fromShipments.getNumberOfShipments(state)
  if (sdkSubCo) {
    const region = sdkSubCo.getTag
    const productivityGoal = await trunkrs
      .Tour()
      .getProductivityGoalForRegion(numberOfShipments, region)
    dispatch(
      setRegionProductivityGoal(get(productivityGoal, 'productivity', 0)),
    )
  }
}

export const setDriverAction = (tourId, driverId) => ({
  type: types.SET_DRIVER,
  payload: {
    tourId,
    driverId,
  },
})

export const setDriver = (tourId, driverId) => async (dispatch, getState) => {
  try {
    dispatch(setDriverAction(tourId, driverId))
  } catch (error) {}
}

export function setVehicleTypeAction(tourId, vehicleType) {
  return { type: types.SET_VEHICLE_TYPE, payload: { tourId, vehicleType } }
}

export const setTourPageState = (timeUserOnPage) => {
  return { type: types.SET_IS_PAGERELOADED, payload: { timeUserOnPage } }
}

export const setVehicleType =
  (tourId, vehicleType) => async (dispatch, getState) => {
    try {
      dispatch(setLoading(true))
      if (typeof tourId !== 'string') {
        await trunkrs.Tour().setTourVehicleType(tourId, vehicleType)
      }
      dispatch(setVehicleTypeAction(tourId, vehicleType))
    } catch (error) {
    } finally {
      dispatch(setLoading(false))
    }
  }

export function toggleToursWorking(...tourIds) {
  return { type: types.TOGGLE_TOURS_WORKING, payload: tourIds }
}

const lockDrivers = async (tours, subcoId) => {
  const driversAreLocked = every(
    tours.map((tour) => tour.state === 'TOUR_DRIVER_LOCKED'),
  )
  if (!driversAreLocked) {
    await trunkrs.Tour().setAllTourDriversLocked(subcoId)
  }
}

export const lockTours = (networkType) => async (dispatch, getState) => {
  try {
    dispatch(setLoading(true, translate('LOCK_TOURS_MSG_WAIT')))
    const state = getState()
    const subcoId = fromProfile.getCurrentSubcoId(state)
    await trunkrs.Tour().makePermanent(subcoId, networkType)
  } catch (error) {
    console.error(error)
    captureException(error)
  } finally {
    dispatch(setLoading(false))
    dispatch(shipmentActions.reloadShipments())
  }
}

export const moveSingle =
  (sourceId, targetId, collationIds) => async (dispatch, getState) => {
    dispatch(setLoading(true, translate('ESTIMATING')))

    const collationIdArray = arrayify(collationIds)
    const state = getState()
    const { [sourceId]: source, [targetId]: target } = fromTours.getData(state)
    const subco = fromProfile.getSdkSubco(state)
    const departureTime = fromProfile.getDepartureTime(state)
    const collationData = fromCollations.getData(state)

    const [sourceCollations, targetCollations] = helpers.moveCollations(
      source,
      target,
      collationIdArray,
    )

    const {
      estimations: [sourceEstimations, targetEstimations],
      total,
    } = await helpers.estimateCollations(
      collationData,
      subco,
      departureTime,
      resolveIndex(sourceCollations, collationData),
      resolveIndex(targetCollations, collationData),
    )

    const newSourceCollations = map(
      sourceEstimations,
      helpers.mapEstimationToCollation,
    )
    const newTargetCollations = map(
      targetEstimations,
      helpers.mapEstimationToCollation,
    )

    dispatch(
      updateTours(
        {
          id: sourceId,
          collations: newSourceCollations,
          hasBermudaStop: !!newSourceCollations.find(isBermudaStop),
          ...get(total, 0),
        },
        {
          id: targetId,
          collations: newTargetCollations,
          hasBermudaStop: !!newTargetCollations.find(isBermudaStop),
          ...get(total, 1),
        },
      ),
    )

    dispatch(setLoading(false))
  }

export const saveTourColor = (id, color) => async (dispatch, getState) => {
  const targetTour = fromTours.getRawTour(id)(getState())
  if (targetTour) {
    const nextTour = { ...targetTour, color }
    dispatch(updateTour(nextTour))
  }
  const tourInstance = await trunkrs.Tour().fetch(id)
  if (tourInstance) {
    tourInstance.setColor = color
    await tourInstance.saveTour(id)
  }
}

export const moveMultiple =
  (targetId, collationIds) => async (dispatch, getState) => {
    dispatch(setLoading(true, translate('ESTIMATING')))

    const state = getState()
    const subco = fromProfile.getSdkSubco(state)
    const departureTime = fromProfile.getDepartureTime(state)
    const tours = fromTours.getRawTours(state)
    const collationData = fromCollations.getData(state)

    const updatedTours = helpers.moveFromUnknownOrigin(
      tours,
      collationData,
      targetId,
      collationIds,
    )

    const { tourIds, estimations, total } = await helpers.estimateCollations(
      collationData,
      subco,
      departureTime,
      ...map(updatedTours, (tour) => ({
        tourId: tour.id,
        collations: resolveIndex(tour.collations, collationData),
      })),
    )

    const newCollations = estimations.map((tour, index) => {
      const collations = estimations[index].map(
        helpers.mapEstimationToCollation,
      )
      return {
        id: get(tourIds, index),
        ...get(total, index),
        collations,
        hasBermudaStop: !!collations.find(isBermudaStop),
      }
    })

    dispatch(updateTours(...newCollations))
    dispatch(setLoading(false))
  }

export const reverseTour = (tourId) => async (dispatch, getState) => {
  dispatch(setLoading(true, translate('ESTIMATING')))

  const state = getState()
  const subco = fromProfile.getSdkSubco(state)
  const departureTime = fromProfile.getDepartureTime(state)
  const { [tourId]: tour } = fromTours.getData(state)
  const collationData = fromCollations.getData(state)
  const collations = [...tour.collations].reverse()
  const {
    estimations: [est],
    total,
  } = await helpers.estimateCollations(
    collationData,
    subco,
    departureTime,
    resolveIndex(collations, collationData),
  )

  const newCollations = map(est, helpers.mapEstimationToCollation)
  dispatch(
    updateTours({
      id: tourId,
      collations: newCollations,
      hasBermudaStop: !!newCollations.find(isBermudaStop),
      ...total,
    }),
  )
  dispatch(setLoading(false))
}

export const optimizeTour = (tourId) => async (dispatch, getState) => {
  dispatch(setLoading(true, translate('OPTIMIZING')))

  const state = getState()
  const subco = fromProfile.getSdkSubco(state)
  const departureTime = fromProfile.getDepartureTime(state)
  const {
    [tourId]: { collations },
  } = fromTours.getData(state)
  const collationData = fromCollations.getData(state)
  const {
    estimations: [est],
    total,
  } = await helpers.optimizeCollations(
    collationData,
    subco,
    departureTime,
    resolveIndex(collations, collationData),
  )
  dispatch(
    updateTours({
      id: tourId,
      collations: map(est, helpers.mapEstimationToCollation),
      ...get(total, 0),
    }),
  )
  dispatch(setLoading(false))
}

export const changeCollationPosition =
  (tourId, from, to) => async (dispatch, getState) => {
    dispatch(setLoading(true, translate('ESTIMATING')))

    const state = getState()
    const subco = fromProfile.getSdkSubco(state)
    const departureTime = fromProfile.getDepartureTime(state)
    const {
      [tourId]: { collations },
    } = fromTours.getData(state)
    const collationData = fromCollations.getData(state)

    const newCollations = collations.filter((id) =>
      helpers.isValidCollation(collationData[id]),
    )
    const [collation] = newCollations.splice(from, 1)
    newCollations.splice(to, 0, collation)

    const {
      estimations: [est],
      total,
    } = await helpers.estimateCollations(
      collationData,
      subco,
      departureTime,
      resolveIndex(newCollations, collationData),
    )

    const changedPosCollations = map(est, helpers.mapEstimationToCollation)
    dispatch(
      updateTours({
        id: tourId,
        collations: changedPosCollations,
        hasBermudaStop: !!changedPosCollations.find(isBermudaStop),
        ...total,
      }),
    )
    dispatch(setLoading(false))
  }

/**
 * Re-order collation sequence
 * @param {string | number} tourId
 * @param {Collation} newCollations re-ordered collations
 * @param {Collation} oldCollations original collations
 * @returns void
 */
export const reOrderCollations =
  (tourId, newCollations, oldCollations) => async (dispatch, getState) => {
    dispatch(setLoading(true))
    try {
      const subco = fromProfile.getSdkSubco(state)
      const departureTime = fromProfile.getDepartureTime(state)

      const {
        estimations: [est],
        total,
      } = await helpers.estimateCollations(
        collationData,
        subco,
        departureTime,
        resolveIndex(newCollations, oldCollations),
      )

      const collations = map(est, helpers.mapEstimationToCollation)
      dispatch(
        updateTours({
          id: tourId,
          collations,
          hasBermudaStop: !!collations.find(isBermudaStop),
          ...total,
        }),
      )
      dispatch(setLoading(false))
    } catch (error) {
      dispatch(
        updateTours({
          id: tourId,
          collations: oldCollations,
        }),
      )
    } finally {
      dispatch(setLoading(false))
    }
  }

export const saveTours =
  (lock = false, networkType = 'NIGHT') =>
  async (dispatch, getState) => {
    dispatch(setLoading(true, translate('SAVING')))

    const state = getState()
    const { id: subcoId } = fromProfile.getSubco(state)
    const tours = fromCombined.getTours(state)
    const savableTours = tours.map((tour) => ({
      driverId: tour.driverId,
      color: tour.color,
      collations: flatMap(tour.collations, helpers.mapCollationToSavable),
      vehicleType: tour.vehicleType,
      willLock: lock,
      totalDistance: tour.totalDistance,
      networkType,
    }))

    const payload = {
      networkType,
      tours: savableTours,
    }

    try {
      await trunkrs.Tour().saveTours(subcoId, payload)
      if (lock) {
        dispatch({
          type: types.SET_IS_TOURS_PLANNING_LOCKED,
          payload: {
            isPlanningLocked: true,
          },
        })
      }
    } catch (error) {
      if (error.response && error.response.data) {
        const res = error.response
        if (
          res.status === 422 &&
          res.data.message === TOUR_STATES.TOUR_PLANNED
        ) {
          return dispatch(setLoading(true, translate('UNKOWN_ERROR_MSG')))
        }
      }
    }

    dispatch(shipmentActions.reloadShipments())
  }

export const fetchToursStatus = () => async (dispatch, getState) => {
  try {
    const state = getState()
    const subcoId = get(fromProfile.getSubco(state), 'id')
    if (!subcoId) {
      return
    }
    const isPlanningLocked = await trunkrs
      .Tour()
      .isActiveToursPlanningLocked(subcoId)
    dispatch({
      type: types.SET_IS_TOURS_PLANNING_LOCKED,
      payload: {
        isPlanningLocked,
      },
    })
  } catch (error) {
    Sentry.captureException(error)
    dispatch(setLoading(true, translate('UNKOWN_ERROR_MSG')))
  }
}

export const saveDriverToTour =
  (tourId, driverId, vehicleType) => async (dispatch) => {
    dispatch(setLoading(true, translate('SAVING')))
    const tourInstance = await trunkrs.Tour().fetch(tourId)
    const driverInstance = await trunkrs.Driver().fetch(driverId)
    if (tourInstance && driverInstance) {
      tourInstance.setDriver = driverInstance
      tourInstance.setDriverId = driverInstance.getId

      if (vehicleType) {
        tourInstance.setVehicleType = vehicleType
      }
      await tourInstance.saveTour(tourId).catch((err) => {
        Sentry.captureException(err)
      })
    }
    if (vehicleType) {
      dispatch(setVehicleType(tourId, vehicleType))
    }

    dispatch(setDriver(tourId, driverId))
    dispatch(setLoading(false))
  }

export const addTour = (collationIds) => async (dispatch, getState) => {
  dispatch(setLoading(true, translate('ESTIMATING')))

  const state = getState()
  const subco = fromProfile.getSdkSubco(state)
  const departureTime = fromProfile.getDepartureTime(state)
  const tours = fromTours.getRawTours(state)
  const collationData = fromCollations.getData(state)
  const lastTourNumber = last(tours).number

  const newTour = {
    id: uuid(),
    color: getColor(lastTourNumber),
    number: lastTourNumber + 1,
    driverId: null,
    state: 'TOUR_CREATED',
  }

  const updatedTours = helpers.moveFromUnknownOrigin(
    tours,
    collationData,
    newTour,
    collationIds,
  )
  const { estimations, total } = await helpers.optimizeCollations(
    collationData,
    subco,
    departureTime,
    ...map(updatedTours, (tour) =>
      resolveIndex(tour.collations, collationData),
    ),
  )

  const actualUpdates = updatedTours.map((tour, index) => {
    const collations = estimations[index].map(helpers.mapEstimationToCollation)
    return {
      ...tour,
      ...total[index],
      collations,
      hasBermudaStop: !!collations.find(isBermudaStop),
    }
  })

  dispatch(updateTours(...actualUpdates))
  dispatch(setLoading(false))
}

export const fetchTourSortingStatus = () => async (dispatch, getState) => {
  try {
    const state = getState()
    const sdkSubco = fromProfile.getSdkSubco(state)
    const isWarehouseProcessDone = await sdkSubco.isWarehouseProcessDone()
    dispatch({
      type: types.SET_ARE_TOURS_SORTED,
      payload: {
        areToursSorted: isWarehouseProcessDone,
      },
    })
  } catch (error) {
    Sentry.captureException(error)
  }
}

export const saveDriversAction = () => {
  return {
    type: types.SAVE_DRIVER,
  }
}

export const saveDrivers = () => async (dispatch, getState) => {
  try {
    dispatch(saveDriversAction())
  } catch (error) {}
}

export const printShippingDocument = async (tourId) => {
  try {
    return await trunkrs.Tour().printShippingDocument(tourId)
  } catch (error) {
    Sentry.captureException(error)
    return null
  }
}

/**
 * Set selected tour id in Tours redux state
 * @param {string | number | null} tourId
 * @returns void
 */
export const setSelectedTourId = (tourId) => (dispatch, getState) => {
  const state = getState()
  const { selectedTourId } = state.tours
  const tourIdString = String(tourId)
  let payload = selectedTourId !== tourIdString ? tourIdString : null
  if (!tourId) payload = null

  dispatch({
    type: types.SET_SELECTED_TOUR_ID,
    payload,
  })
}

/**
 * Set hidden tour id in Tours redux state
 * @param {string | number} tourId
 * @param {boolean} clearHiddenTours
 * @returns void
 */
export const setHiddenTourIds =
  (tourId, clearHiddenTours = false) =>
  (dispatch, getState) => {
    const state = getState()
    const { hiddenTourIds } = state.tours
    const tourIdString = String(tourId)
    let payload = hiddenTourIds.includes(tourIdString)
      ? hiddenTourIds.filter((htid) => htid !== tourIdString)
      : [...hiddenTourIds, tourIdString]

    if (clearHiddenTours) payload = []

    dispatch({
      type: types.SET_HIDDEN_TOUR_IDS,
      payload,
    })
  }

// /**
//  * Set hidden tour id in Tours redux state
//  * @param {NETWORK_TYPE} networkType
//  * @returns void
//  */
// export const setDayNetwork = (networkType) => (dispatch) => {
//   dispatch({
//     type: types.SET_NETWORK_TYPE,
//     networkType,
//   })
// }
