import React from 'react'

import Fade from '@material-ui/core/Fade'
import FormLabel from '@material-ui/core/FormLabel'
import FormControl from '@material-ui/core/FormControl'
import Chip from '@material-ui/core/Chip'

import { Intent } from 'common/models/typings/Intent'
import Input, { InputProps } from '.'
import InputHelper, { HelperVariant } from '../InputHelper'

import useStyles from './useStylesInputForm'

export interface InputFormProps extends Omit<InputProps, 'classes'> {
  /**
   * Label for InputForm
   * default value: none
   */
  label?: string
  labelChip?: string

  autoComplete?: string
  helperIcon?: Intent
  helperVariant?: HelperVariant
  helperText?: string
}

const InputForm: React.FC<InputFormProps> = React.forwardRef(
  (
    {
      label,
      labelChip,
      helperIcon,
      helperVariant,
      helperText,
      error,
      fullWidth,
      autoComplete,
      ...others
    },
    ref,
  ) => {
    const classes = useStyles()

    return (
      <FormControl fullWidth={fullWidth}>
        {label && (
          <FormLabel className={classes.label}>
            {label}
            {labelChip && (
              <Chip className={classes.labelChip} label={labelChip} />
            )}
          </FormLabel>
        )}
        <Input
          {...others}
          inputProps={{ autoComplete }}
          error={error}
          ref={ref}
        />
        <Fade in={!!helperText}>
          <InputHelper
            icon={helperIcon}
            variant={helperVariant}
            text={helperText}
          />
        </Fade>
      </FormControl>
    )
  },
)

export default React.memo(InputForm)
