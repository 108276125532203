/* eslint-disable no-useless-escape */

import * as Yup from 'yup'
import i18n from 'i18next'

const createFirstUserFormSchema = () =>
  Yup.object().shape({
    phoneNumber: Yup.string()
      .matches(
        /(\(\+\d{1,3}\)\s?)?((\(\d{3}\)\s?)|(\d{3})(\s|-?))(\d{3}(\s|-?))(\d{3,4})(\s?(([E|e]xt[:|.|]?)|x|X)(\s?\d+))?/,
        i18n.t('INVALID_PHONE_NUMBER'),
      )
      .required(i18n.t('PHONE_NUMBER_IS_REQUIRED')),
  })

export default createFirstUserFormSchema
