/* eslint-disable react/function-component-definition */

import clsx from 'clsx'
import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faExclamationCircle,
  faCheckCircle,
} from '@fortawesome/free-solid-svg-icons'
import { COLORS } from 'constants/COLORS'

import styles from './Alert.module.scss'

export enum ALERT_TYPES {
  WARNING = 'WARNING',
  SUCCESS = 'SUCCESS',
}

interface IAlertProps {
  type: ALERT_TYPES
  children: React.ReactNode | React.ReactNode[]
  actions?: React.ReactNode | React.ReactNode[]
  className?: string
}

const getIcon = (type: ALERT_TYPES) => {
  switch (type) {
    case ALERT_TYPES.SUCCESS:
      return faCheckCircle
    default:
      return faExclamationCircle
  }
}

const getColor = (type: ALERT_TYPES) => {
  switch (type) {
    case ALERT_TYPES.SUCCESS:
      return COLORS.GREEN
    default:
      return COLORS.ORANGE
  }
}

export const Alert: React.FunctionComponent<IAlertProps> = ({
  type,
  children,
  actions,
  className,
}) => {
  const [icon, setIcon] = useState(faExclamationCircle)
  const [color, setColor] = useState(COLORS.ORANGE)

  useEffect(() => {
    setIcon(getIcon(type))
    setColor(getColor(type))
  }, [type])

  return (
    <div
      className={clsx(
        styles.alertContainer,
        className,
        styles[type.toLowerCase()],
      )}
    >
      <FontAwesomeIcon
        icon={icon}
        size="2x"
        className={styles.icon}
        color={color}
      />
      <div className={styles.badgeContent}>{children}</div>
      {actions && <div className={styles.actions}>{actions}</div>}
    </div>
  )
}

export default Alert
