import createStyles from '@material-ui/core/styles/createStyles'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { Theme } from '@material-ui/core/styles'

const styles = ({ spacing, palette }: Theme) =>
  createStyles({
    root: {
      width: 424,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
    },
    title: {
      marginBottom: spacing(1),
    },
    sub: {
      marginBottom: spacing(4),
    },
    inputsContainer: {
      width: '100%',
      display: 'grid',
      gridTemplateColumns: 'repeat(6, 64px)',
      gridTemplateRows: 'repeat(1, 64px)',
      columnGap: spacing(1),
    },
    input: {
      height: 64,
      textAlign: 'center',
    },
    button: {
      marginBottom: spacing(4),
    },
    resend: {
      cursor: 'pointer',
      userSelect: 'none',
    },
  })

export default makeStyles(styles)
