import React, { useCallback } from 'react'

import { Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import { FUEL_TYPES } from 'constants/FuelTypes'
import { FuelName, FuelType } from 'common/models/typings/FuelType'
import ToggleButton from 'common/components/base/.raw/ToggleButton'
import { Driver } from 'common/models/typings/Driver'

import useStyles from './useStyles'

export interface FuelToggleProps {
  selectedVehicle: FuelName
  onSelect: (driver: Driver, vehicle: FuelName) => void
  selectedDriver?: Driver
  driver: Driver
}

export const FuelToggle: React.FC<FuelToggleProps> = ({
  onSelect,
  selectedVehicle,
  selectedDriver,
  driver,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const handleClick = useCallback(
    (vehicle: FuelName) => {
      onSelect(driver, vehicle)
    },
    [onSelect],
  )

  return (
    <div className={classes.fuelTypeSelection}>
      {(FUEL_TYPES as FuelType[]).map((fuelType) => (
        <Grid key={fuelType.name} item>
          <ToggleButton
            key={fuelType.name}
            label={t(fuelType.langKey)}
            icon={fuelType.icon}
            color={
              selectedDriver &&
              selectedDriver.id === driver.id &&
              selectedVehicle === fuelType.name
                ? fuelType.color
                : 'white'
            }
            onClick={(e) => {
              e.stopPropagation()
              handleClick(fuelType.name)
            }}
            active
            disabled={false}
          />
        </Grid>
      ))}
    </div>
  )
}

export default FuelToggle
