import React from 'react'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'

import Icon from 'common/components/base/Icon'

import { Intent } from 'common/models/typings/Intent'

import styles from './useStyles'

const useStyles = makeStyles(styles)

export interface InputHelperIconProps {
  icon?: Intent
}

export const InputHelperIcon: React.FC<InputHelperIconProps> = ({ icon }) => {
  const classes = useStyles()

  return React.useMemo(() => {
    switch (icon) {
      case 'info-circle':
        return (
          <Icon
            name="info-circle"
            className={classes.icon}
            fill="currentColor"
          />
        )
      case 'success':
        return (
          <Icon name="success" className={classes.icon} fill="currentColor" />
        )
      case 'warning':
        return (
          <Icon name="warning" className={classes.icon} fill="currentColor" />
        )
      case 'danger':
        return (
          <Icon name="danger" className={classes.icon} fill="currentColor" />
        )

      default:
        return <Box component="span" /> // return empty span
    }
  }, [classes.icon, icon])
}

export default InputHelperIcon
