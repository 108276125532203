/* eslint-disable import/no-cycle */

import React, { memo, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getHubInventory } from 'selectors/hubLogs'

import isEmpty from 'lodash/isEmpty'
import HubInventoryList from './components/HubInventoryList'
import EmptyLogs from './components/EmptyLogs'
import styles from './styles.module.scss'
import { ACTION_TYPES, getHubInventoryList } from '../../actions/hubLogs'
import { getCurrentSubcoId } from '../../selectors/profile'
import { getDrivers } from '../../selectors/driver'
import { useTranslation } from 'react-i18next'

function HubInventory() {
  const dispatch = useDispatch()

  const { t } = useTranslation()

  const currentSubcoId = useSelector(getCurrentSubcoId)
  const drivers = useSelector(getDrivers)
  const hubInventory = useSelector(getHubInventory)

  useEffect(() => {
    if (currentSubcoId && !isEmpty(drivers)) {
      dispatch(getHubInventoryList(currentSubcoId, drivers))
    }
    dispatch({ type: ACTION_TYPES.SET_HUB_INVENTORY, payload: [] })
    dispatch({ type: ACTION_TYPES.SET_RETURNED_SHIPMENTS, payload: [] })
  }, [dispatch, currentSubcoId, drivers])

  return (
    <>
      <div className={styles.headerContainer}>
        <h1 className={styles.header}>{t('HUB_INVENTORY.TITLE')}</h1>
      </div>
      {isEmpty(hubInventory) ? <EmptyLogs /> : <HubInventoryList />}
    </>
  )
}

export default memo(HubInventory)
