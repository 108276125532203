import moment from 'moment'
import minBy from 'lodash/minBy'
import maxBy from 'lodash/maxBy'
import { Collation } from 'common/models/typings/Collation'

export const getTotalDuration = (
  collations: Array<Collation>,
  unitOfTime: 'm' | 'h' = 'm',
) => {
  const start = minBy(collations, 'position')
  const end = maxBy(collations, 'position')

  if (start && end) {
    return moment(end.estimation).diff(moment(start.estimation), unitOfTime)
  }

  return 0
}
