/* eslint-disable import/no-cycle */

import React from 'react'
import { useTranslation } from 'react-i18next'

import { Box, Fade } from '@material-ui/core'
import isEmpty from 'lodash/isEmpty'
import filter from 'lodash/filter'

import { useSelector } from 'react-redux'
import * as fromCombined from 'selectors/combined'

import { Tour } from 'common/models/typings/Tour'

import Callout from 'common/components/base/.raw/Callout'
import Typography from 'common/components/base/Typography'

const TourMapNotifications = () => {
  const { t } = useTranslation()
  const tours: Tour[] = useSelector(fromCombined.getShipmentTours)

  const [text, setText] = React.useState<string>('')
  const [showNotification, setShowNotification] = React.useState<boolean>(false)

  React.useEffect(() => {
    const tour = tours.filter((tr) => tr.hasBermudaStop)
    if (!isEmpty(tour)) {
      const toursHasBermuda = tours
        .filter((tr) => tr.hasBermudaStop)
        .map((tr) => tr.number)
      const string =
        toursHasBermuda.length >= 3
          ? toursHasBermuda.join(', ')
          : toursHasBermuda.join(',')
      const { length } = string

      if (length >= 7) {
        setText(
          string
            .substring(0, length - 3)
            .concat(' and ', string.substring(length - 1, length)),
        )
      } else if (length >= 3) {
        setText(string.replace(',', ' and '))
      } else {
        setText(string)
      }
      setShowNotification(true)
    } else {
      setShowNotification(false)
    }
  }, [tours])

  if (showNotification) {
    return (
      <Fade in={showNotification}>
        <Box position="absolute" top={16} left={16} width={581} zIndex={1}>
          <Callout icon="danger">
            <Typography>{t('BERMUDA_TEXT_NOTIFICATION', { text })}</Typography>
            <Typography>{t('BERMUDA_TEXT_MORE_INFO')}</Typography>
          </Callout>
        </Box>
      </Fade>
    )
  }

  return null
}

export default TourMapNotifications
