/* eslint-disable no-case-declarations */

import { normalize, schema } from 'normalizr'
import {
  ADD_GREEN_REGIONS,
} from '../constants/ActionTypes'
import get from "lodash/get";

export const greenRegionsSchema = new schema.Entity('greenRegions', {}, { idAttribute: 'id' })

const normalizeData = (data) => {
  const {
    result: keys,
    entities: { greenRegions: normalizedData },
  } = normalize(data, [greenRegionsSchema])

  return [keys || [], normalizedData || {}]
}

const initialState = {}

export default function greenRegions(state = initialState, action) {
  switch (action.type) {

    case ADD_GREEN_REGIONS:
      const [, data] = normalizeData(action.payload)

      return {
        ...state,
        ...data,
      }

    default:
      return state
  }
}

export const selectGreenRegions = (state) => state
