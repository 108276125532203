import * as React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import { useField } from 'formik'

import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Collapse from '@material-ui/core/Collapse'

import Button from '.././../../base/Button'
import Typography from '.././../../base/Typography'
import Icon from 'common/components/base/Icon'

import classes from './TourCard.module.scss'

const TourCard = ({
  disabled,
  name,
  tourNumber,
  drivers,
  remark,
  onOpenTour,
}) => {
  const { t } = useTranslation()
  const [{ value, ...field }] = useField({ name })

  return (
    <div className={clsx(classes.container, value && classes.driverSet)}>
      <div className={classes.header}>
        {t('COLLECTION_TOUR', { number: tourNumber })}
        <Button className={classes.tourDetailsBtn} onClick={onOpenTour}>
          {t('STOPS')}
        </Button>
      </div>

      {remark && (
        <div className={classes.remarkContainer}>
          <Typography component="p" className={classes.remarkTitle}>
            {t('TOUR_REMARK')}
          </Typography>
          <p>{remark}</p>
        </div>
      )}

      <div className={classes.selectContainer}>
        <Select
          displayEmpty
          disabled={disabled}
          className={classes.select}
          value={value || ''}
          {...field}
        >
          <MenuItem disabled value="">
            {t('COLLECTION_DRIVER_PLACEHOLDER')}
          </MenuItem>
          {React.useMemo(
            () =>
              drivers.map((driver) => (
                <MenuItem
                  key={driver.id}
                  value={driver.id}
                  disabled={driver.inUse}
                >
                  {driver.name}
                </MenuItem>
              )),
            [drivers],
          )}
        </Select>
      </div>

      <Collapse in={!value}>
        <div className={classes.noDriverFooter}>
          <Icon name="info-circle" className={classes.icon} />
          <Typography>{t('NO_DRIVER_ASSIGNED')}</Typography>
        </div>
      </Collapse>
    </div>
  )
}

TourCard.propTypes = {
  disabled: PropTypes.bool,
  name: PropTypes.string.isRequired,
  tourNumber: PropTypes.number,
  drivers: PropTypes.array,
  remark: PropTypes.string,
  onOpenTour: PropTypes.func,
}

TourCard.defaultProps = {
  disabled: false,
  tourNumber: 0,
  driverId: null,
  drivers: [],
  remark: null,
  onOpenTour: () => null,
}

export default TourCard
