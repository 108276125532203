import React from 'react'
import FormHelperText from '@material-ui/core/FormHelperText'
import Box from '@material-ui/core/Box'
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles'
import clsx from 'clsx'

import { Intent } from 'common/models/typings/Intent'

import FormInputHelperIcon from './InputHelperIcon'
import Typography from '../../Typography'

import useStyles from './useStyles'

export type HelperVariant =
  | 'default'
  | 'grayscale'
  | 'success'
  | 'warning'
  | 'danger'

export interface InputHelperProps extends WithStyles<typeof useStyles> {
  /**
   * Icon for FormInputHelper
   * default value: none
   */
  icon?: Intent

  /**
   * Color for the FormInputHelper w/ the Icon
   * default value: default
   */
  variant?: HelperVariant

  /**
   * Content for the FormInputHelper
   * default value: ' ' to maintain the height even no text was provided
   */
  text?: string

  className?: string
}

export const FormInputHelper: React.FC<InputHelperProps> = React.forwardRef<
  HTMLDivElement,
  InputHelperProps
>(({ classes, icon, variant = 'default', text = ' ', className }, ref) => {
  return (
    <div ref={ref} className={clsx(classes.root, classes[variant], className)}>
      {icon && <FormInputHelperIcon icon={icon} />}
      <Typography variant="packageText" color="inherit">
        {text}
      </Typography>
    </div>
  )
})

export default React.memo(withStyles(useStyles)(FormInputHelper))
