/* eslint-disable import/no-cycle, no-nested-ternary */

import React, { memo, useCallback, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'
import { toggleDriver } from 'actions/profile'
import { FILTERS, FORM_ACTION, IUserProfile } from './@types'

import {
  Avatar,
  Button,
  Callout,
  Chip,
  Icon,
  MaskedInput,
  ModalBox,
  Switch,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
  TableRowHeader,
  Tooltip,
  Toast,
} from 'common/components/base/.raw'

import { NoResults } from 'common/components/base/.raw/noResults/noResults'

import * as fromProfile from 'selectors/profile'
import * as fromTours from 'selectors/tour'

import DriverIcon from 'common/assets/images/driver-icon.png'
import styles from './styles.module.scss'
import Modal from './components/Modal'

import DriverImportModal from './components/DriverImportModal'

function UsersList() {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [isDriverImportModalVisible, setIsDriverImportModalVisible] =
    useState(false)
  const [isSuccessNotificationVisible, setSuccessNotificationVisible] =
    useState(false)
  const [currentFilter, setCurrentFilter] = useState<FILTERS>(
    FILTERS.ALL_EMPLOYEES,
  )
  const [selectedUser, setSelectedUser] = useState<IUserProfile>()
  const [formAction, setFormAction] = useState<FORM_ACTION>(FORM_ACTION.CREATE)
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const categorizedData = {
    [FILTERS.ALL_EMPLOYEES]: useSelector(fromProfile.getDriverProfiles),
    [FILTERS.PLANNER]: useSelector(fromProfile.getPlanners),
    [FILTERS.INACTIVE]: useSelector(fromProfile.getInactiveEmployees),
    [FILTERS.DELETED]: useSelector(fromProfile.getDeletedEmployees),
  } as { [key: string]: Array<IUserProfile> }

  const filteredUsers = categorizedData[currentFilter].sort((a, b) =>
    a.name.localeCompare(b.name),
  )

  const openModal = useCallback(() => setIsModalVisible(true), [])
  const closeModal = useCallback(() => setIsModalVisible(false), [])

  const openDriverImportModal = useCallback(
    () => setIsDriverImportModalVisible(true),
    [],
  )
  const closeDriverImportModal = useCallback(
    () => setIsDriverImportModalVisible(false),
    [],
  )

  const handleNotificationExit = useCallback(
    () => setSuccessNotificationVisible(false),
    [],
  )

  const handleUserCreationSuccess = useCallback(() => {
    closeModal()
    setSuccessNotificationVisible(true)
  }, [closeModal])

  const handleUserCreation = useCallback(() => {
    setSelectedUser(undefined)
    setFormAction(FORM_ACTION.CREATE)
    openModal()
  }, [openModal])

  const handleUserEdit = useCallback(
    (user: IUserProfile) => async () => {
      setSelectedUser(user)
      setFormAction(FORM_ACTION.EDIT)
      openModal()
    },
    [openModal],
  )

  const handleUserArchive = useCallback(
    (user: IUserProfile) => () => {
      setSelectedUser(user)
      setFormAction(FORM_ACTION.DELETE)
      openModal()
    },
    [openModal],
  )

  const toggleEmployee = useCallback(
    (driverId: number) => (active: boolean) => {
      dispatch(toggleDriver(driverId, active))
    },
    [dispatch],
  )

  const filterToAllEmployees = useCallback(
    () => setCurrentFilter(FILTERS.ALL_EMPLOYEES),
    [],
  )
  const filterToPlanner = useCallback(
    () => setCurrentFilter(FILTERS.PLANNER),
    [],
  )
  const filterToInactive = useCallback(
    () => setCurrentFilter(FILTERS.INACTIVE),
    [],
  )
  const filterToDeleted = useCallback(
    () => setCurrentFilter(FILTERS.DELETED),
    [],
  )

  const isFilteredToDeleted = currentFilter === FILTERS.DELETED

  return (
    <div>
      <div className={styles['top-button-container']}>
        <Button variant="success" onClick={openDriverImportModal}>
          <Icon name="swap" />
          {t('USERS.BUTTON_IMPORT_DRIVER')}
        </Button>
        <Button onClick={handleUserCreation}>
          <Icon name="plus" />
          {t('USERS.ADD_USER_BUTTON')}
        </Button>
      </div>
      <Modal
        visible={isModalVisible}
        mode={formAction}
        user={selectedUser}
        onClose={closeModal}
        onSucceed={handleUserCreationSuccess}
        deleteFilter={isFilteredToDeleted}
      />
      {isSuccessNotificationVisible && (
        <Toast
          headerText={t('USERS.ADD_USER_SUCCESS_MSG')}
          subText={t(
            formAction === FORM_ACTION.DELETE
              ? t('USERS.DELETE_USER_SUCCESS_MSG_SUBTEXT').replace(
                  '{{EMP_NAME}}',
                  selectedUser!.name,
                )
              : formAction === FORM_ACTION.CREATE
              ? t('USERS.ADD_USER_SUCCESS_MSG_SUBTEXT')
              : t('USERS.EDIT_USER_SUCCESS_MSG_SUBTEXT'),
          )}
          onExit={handleNotificationExit}
        />
      )}
      <h1 className={styles.header}>{t('USERS.USER_LIST_HEADER')}</h1>
      <div className={styles['tab-container']}>
        <Tab
          active={currentFilter === FILTERS.ALL_EMPLOYEES}
          onClick={filterToAllEmployees}
        >
          {t('USERS.USER_LIST_TAB_ALL_EMPLOYEES')}
        </Tab>
        <Tab
          active={currentFilter === FILTERS.PLANNER}
          onClick={filterToPlanner}
        >
          {t('USERS.USER_LIST_TAB_PLANNER')}
        </Tab>
        <Tab
          active={currentFilter === FILTERS.INACTIVE}
          onClick={filterToInactive}
        >
          {t('USERS.USER_LIST_TAB_INACTIVE')}
        </Tab>
        <Tab
          active={currentFilter === FILTERS.DELETED}
          onClick={filterToDeleted}
        >
          {t('USERS.USER_LIST_TAB_DELETED')}
        </Tab>
      </div>
      <Table className={styles['custom-table-style']}>
        <TableRowHeader className="table-header-row">
          <TableHeader>{t('USERS.USER_LIST_TABLE_EMPLOYEE_NAME')}</TableHeader>
          <TableHeader>{t('USERS.USER_LIST_TABLE_ROLE')}</TableHeader>
          <TableHeader className={clsx(isFilteredToDeleted && styles.hidden)}>
            {t('USER_LIST_TABLE_PHONE_NUMBER')}
          </TableHeader>
          <TableHeader>{t('USERS.USER_LIST_TABLE_STATUS')}</TableHeader>
          <TableHeader>
            {t('USERS.USER_LIST_TABLE_MANAGE_EMPLOYEE')}
          </TableHeader>
        </TableRowHeader>
        <TableBody>
          {useMemo(
            () =>
              filteredUsers.map((user: IUserProfile) => {
                const { id, name, phoneNumber, active, isPlanner } = user
                return (
                  <TableRow
                    key={id}
                    className={clsx(isFilteredToDeleted && styles.disabled)}
                  >
                    <TableCell className={clsx(styles['avatar-container'])}>
                      <Avatar>{name}</Avatar>
                      {name}
                    </TableCell>
                    <TableCell>
                      <Chip>
                        {t(
                          isFilteredToDeleted
                            ? 'USERS.USER_LIST_TABLE_ROLE_DELETED'
                            : isPlanner
                            ? 'USERS.USER_LIST_TABLE_ROLE_PLANNER'
                            : 'USERS.USER_LIST_TABLE_ROLE_DRIVER',
                        )}
                      </Chip>
                    </TableCell>
                    <TableCell
                      className={clsx(isFilteredToDeleted && styles.hidden)}
                    >
                      {phoneNumber}
                    </TableCell>
                    <TableCell className={clsx(styles['switch-container'])}>
                      <Switch onToggle={toggleEmployee(id)} active={active} />
                      <span>
                        {t(
                          active
                            ? 'USERS.USER_LIST_TABLE_ACTIVE'
                            : 'USERS.USER_LIST_TABLE_INACTIVE',
                        )}
                      </span>
                    </TableCell>
                    <TableCell className={styles['menu-tooltip']}>
                      <Tooltip
                        text={t('USERS.EDIT_INFORMATION_TOOLTIP')}
                        placement="bottom"
                        eventTriggers={['hover']}
                      >
                        <Icon name="edit" onClick={handleUserEdit(user)} />
                      </Tooltip>
                      <Tooltip
                        text={t('USERS.DELETE_TOOLTIP')}
                        placement="bottom"
                        eventTriggers={['hover']}
                      >
                        <Icon name="trash" onClick={handleUserArchive(user)} />
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                )
              }),
            [
              filteredUsers,
              handleUserArchive,
              handleUserEdit,
              isFilteredToDeleted,
              t,
              toggleEmployee,
            ],
          )}
          {!filteredUsers.length && (
            <NoResults
              icon={DriverIcon}
              title={t('USERS.NO_USERS_FOUND')}
              content={t('USERS.NO_USERS_FOUND_MSG')}
              button={null}
            />
          )}
        </TableBody>
      </Table>
      <DriverImportModal
        visible={isDriverImportModalVisible}
        onClose={closeDriverImportModal}
      />
    </div>
  )
}

export default memo(UsersList)
