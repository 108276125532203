import React from 'react'
import clsx from 'clsx'
import get from 'lodash/get'

import styles from './styles.module.scss'

type ChipTypes = 'default' | 'primary' | 'success' | 'danger' | 'warning'

export interface ChipProps extends React.HTMLAttributes<any> {
  variant?: ChipTypes
  className?: string
}

const Chip: React.FC<ChipProps> = ({
  children,
  variant = 'default',
  className,
}) => {
  return (
    <div className={clsx(styles.chip, get(styles, variant))}>{children}</div>
  )
}

export default React.memo(Chip)
