/* eslint-disable import/no-cycle */

import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Box,
} from '@material-ui/core'

import { useSelector } from 'react-redux'
import * as fromCombined from 'selectors/combined'
import * as fromDrivers from 'selectors/driver'

import { Tour } from 'common/models/typings/Tour'
import { Driver } from 'common/models/typings/Driver'

import Icon from 'common/components/base/Icon'
import Typography from 'common/components/base/Typography'
import Button from 'common/components/base/Button'

import ToursTable from './Content/ToursTable'
import TourTable from './Content/TourTable'

export interface TourPlanningDialogOverviewProps {
  open: boolean
  onClose: () => void
}

const TourPlanningDialogOverview: React.FC<TourPlanningDialogOverviewProps> = ({
  open,
  onClose,
}) => {
  const { t } = useTranslation()
  const tours: Tour[] = useSelector(fromCombined.getShipmentTours)
  const drivers: Driver[] = useSelector((state) =>
    fromDrivers.getDrivers(state as never),
  )
  const [tourId, setTourId] = React.useState<string | number | null>(null)

  const tour = React.useMemo(
    () => tours.find((tr) => tr.id === tourId),
    [tourId, tours],
  )

  const handleSetTourId = React.useCallback((id: string | number | null) => {
    setTourId(id)
  }, [])

  return (
    <Dialog
      disableEscapeKeyDown
      fullWidth
      maxWidth="md"
      scroll="body"
      open={open}
      PaperProps={{ elevation: 1 }}
    >
      <Box position="absolute" top={16} right={16}>
        <IconButton onClick={onClose}>
          <Icon name="close" />
        </IconButton>
      </Box>
      <DialogTitle disableTypography>
        <Typography variant="h3" transform="capitalize">
          {tourId ? `${t('TOUR')} ${tour?.number}` : t('OVERVIEW')}
        </Typography>
        {!tourId && (
          <Typography variant="text" color="theBeast">
            {t('TOUR_COUNT', { count: tours.length })}
          </Typography>
        )}
      </DialogTitle>
      <DialogContent>
        {tourId && (
          <Box marginBottom={3}>
            <Button
              color="white"
              startIcon={<Icon name="arrow-left" />}
              onClick={() => handleSetTourId(null)}
            >
              {t('RETURNS_SCAN.ACKNOWLEDGEMENT.BACK')}
            </Button>
          </Box>
        )}
        {tourId ? (
          <TourTable collations={tour?.collations || []} />
        ) : (
          <ToursTable
            tours={tours}
            drivers={drivers}
            onSelect={handleSetTourId}
          />
        )}
      </DialogContent>
    </Dialog>
  )
}

export default TourPlanningDialogOverview
