import React, { memo } from 'react'
import clsx from 'clsx'

import styles from './styles.module.scss'

const Tab = memo(
  ({
    children,
    className,
    active,
    ...props
  }: React.HTMLAttributes<any> & { active: boolean }) => (
    <div className={styles.tabContainer}>
      <div
        className={clsx(styles.tab, active && styles.active, className)}
        {...props}
      >
        {children}
      </div>
      <div className={clsx(styles.barIndicator, active && styles.active)} />
    </div>
  ),
)

export default Tab
