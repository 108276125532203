import { normalize } from 'normalizr'
import { freezerManagementSchema } from 'schema/schemas'
import { IFreezerLog } from './@types'

export const normalizePayload = (payload: IFreezerLog[]) => {
  const {
    result: identifiers,
    entities: { freezerManagement: data },
  } = normalize(payload, [freezerManagementSchema])

  return {
    identifiers,
    data,
  }
}
