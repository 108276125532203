import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const styles = ({ spacing }: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'space-between',

      '& > *': {
        '&:first-child': {
          marginLeft: 0,
        },

        '&:last-child': {
          marginRight: 0,
        },

        margin: '0 3px',
      },
    },
    noResultsContainer: {
      width: '100%',
      height: 'calc(100vh - 151px)',
    },
  })

export default makeStyles(styles)
