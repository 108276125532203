import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from '@material-ui/core'
import clsx from 'clsx'
import moment from 'moment'
import get from 'lodash/get'

import { Tour } from 'common/models/typings/Tour'
import { Driver } from 'common/models/typings/Driver'

import { getLastStopTime } from 'utils'

import Typography from 'common/components/base/Typography'
import Button from 'common/components/base/Button'

import useStyles from './useStyles'

export interface TourPlanningDialogOverviewToursTableProps {
  tours: Tour[]
  drivers: Driver[]
  onSelect: (tourId: string | number) => void
}

const TourPlanningDialogOverviewToursTable: React.FC<
  TourPlanningDialogOverviewToursTableProps
> = ({ tours, drivers, onSelect }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const handleGetDriverName = React.useCallback(
    (driverId: number) => {
      const driverDetails = drivers.find((drvr) => drvr.id === driverId)

      if (driverDetails) {
        return <Typography variant="button">{driverDetails.name}</Typography>
      }

      return (
        <Typography variant="button" color="grey">
          Not yet assigned
        </Typography>
      )
    },
    [drivers],
  )

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>{t('LITE_TOURS_TABLE_TOUR')}</TableCell>
          <TableCell>{t('LITE_TOURS_TABLE_DEPARTURE')}</TableCell>
          <TableCell>{t('LITE_TOURS_TABLE_LAST_STOP')}</TableCell>
          <TableCell>{t('LITE_TOURS_TABLE_DRIVER')}</TableCell>
          <TableCell>{t('LITE_TOURS_TABLE_VEHICLE_TYPE')}</TableCell>
          <TableCell>{t('TOTAL_DISTANCE')}</TableCell>
          <TableCell />
        </TableRow>
      </TableHead>
      <TableBody>
        {React.useMemo(
          () =>
            tours.map((tour, index) => {
              const totalDistanceInKM = (tour.totalDistance / 1000).toFixed(1)
              return (
                <TableRow key={tour.id}>
                  <TableCell
                    className={clsx({
                      [classes.lastCell]: index + 1 === tours.length,
                    })}
                  >
                    {tour.number}
                  </TableCell>
                  <TableCell
                    className={clsx({
                      [classes.lastCell]: index + 1 === tours.length,
                    })}
                  >
                    <Typography variant="button">
                      {moment(get(tour.collations, '[0].estimation')).format(
                        'HH:mm',
                      )}
                    </Typography>
                  </TableCell>
                  <TableCell
                    className={clsx({
                      [classes.lastCell]: index + 1 === tours.length,
                    })}
                  >
                    <Typography variant="button">
                      {moment(getLastStopTime(tour.collations)).format('HH:mm')}
                    </Typography>
                  </TableCell>
                  <TableCell
                    className={clsx({
                      [classes.lastCell]: index + 1 === tours.length,
                    })}
                  >
                    <Typography variant="button">
                      {handleGetDriverName(tour.driverId)}
                    </Typography>
                  </TableCell>
                  <TableCell
                    className={clsx({
                      [classes.lastCell]: index + 1 === tours.length,
                    })}
                  >
                    <Typography variant="button">{tour.vehicleType}</Typography>
                  </TableCell>
                  <TableCell
                    className={clsx({
                      [classes.lastCell]: index + 1 === tours.length,
                    })}
                  >
                    <Typography variant="button">{`${totalDistanceInKM} km`}</Typography>
                  </TableCell>
                  <TableCell
                    className={clsx({
                      [classes.lastCell]: index + 1 === tours.length,
                    })}
                  >
                    <Button
                      variant="flat"
                      color="white"
                      onClick={() => onSelect(tour.id)}
                    >
                      {t('SHOW_FULL_TOUR')}
                    </Button>
                  </TableCell>
                </TableRow>
              )
            }),
          [classes.lastCell, handleGetDriverName, onSelect, t, tours],
        )}
      </TableBody>
    </Table>
  )
}

export default TourPlanningDialogOverviewToursTable
