import React from 'react'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Box,
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import Typography from 'common/components/base/Typography'
import Button from 'common/components/base/Button'
import Icon from 'common/components/base/Icon'

import useStyles from './useStyles'

export interface inventoryScanDialogWarning {
  open: boolean
  onClose: () => void
  onProceed: () => void
  title: string
  content: string
  backButton: any
  proceedButton: any
}

const InventoryScanDialogWarning: React.FC<inventoryScanDialogWarning> = ({
  open,
  onClose,
  onProceed,
  title,
  content,
  backButton,
  proceedButton,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <Dialog
      disableEscapeKeyDown
      fullWidth
      maxWidth="sm"
      PaperProps={{ elevation: 1 }}
      classes={{ paper: classes.root }}
      open={open}
      onClose={onClose}
    >
      <Box position="absolute" top={16} right={16}>
        <IconButton onClick={onClose}>
          <Icon name="close" />
        </IconButton>
      </Box>
      <DialogTitle disableTypography>
        <Typography variant="h3" transform="capitalize">
          {title}?
        </Typography>
      </DialogTitle>
      <DialogContent>{content}</DialogContent>
      <Box height={24} />
      <DialogActions>
        <Button color="green" onClick={onProceed}>
          {proceedButton}
        </Button>
        <Button variant="flat" color="white" onClick={onClose}>
          {backButton}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default React.memo(InventoryScanDialogWarning)
