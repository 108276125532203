import React, { memo, ReactNode, useRef, useState } from 'react'
import get from 'lodash/get'
import clsx from 'clsx'
import PerfectScrollBar from 'react-perfect-scrollbar'

import styles from './styles.module.scss'

export interface ICartProps {
  title: string
  placeholder?: string | ReactNode
  className?: string
  children?: Array<ReactNode>
}

function Cart({ title, className, placeholder, children }: ICartProps) {
  const contents = React.Children.toArray(children).filter(
    (child) => get(child, 'type.displayName') !== 'CartFooter',
  )
  const footerContent = React.Children.toArray(children).filter(
    (child) => get(child, 'type.displayName') === 'CartFooter',
  )[0]

  const count = get(contents, 'length', 0)
  const badgeText = `0${count}`.substr(
    count.toString().length + 1 === 2 ? 0 : 1,
    count.toString().length + 1,
  )

  return (
    <div className={clsx(styles.cartContainer, className)}>
      <div className={styles.header}>
        {title} {!!count && <span className={styles.badge}>{badgeText}</span>}
      </div>
      <div className={clsx(styles.content, !count && styles.empty)}>
        {contents}
        {!count && <div className={styles.placeholder}>{placeholder}</div>}
      </div>
      {footerContent && footerContent}
    </div>
  )
}

export default memo(Cart)
