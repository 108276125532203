/* eslint-disable import/no-cycle */

import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core'
import moment from 'moment'

import { useSelector } from 'react-redux'
import * as profileActions from 'actions/tours'
import * as fromCombined from 'selectors/combined'
import * as fromProfile from 'selectors/profile'

import { Tour } from 'common/models/typings/Tour'
import { Driver } from 'common/models/typings/Driver'

import { mergePDFBinaryFiles, downloadPDF } from 'common/utils/Pdf'

import Icon from 'common/components/base/Icon'
import Typography from 'common/components/base/Typography'
import Button from 'common/components/base/Button'
import Checkbox from 'common/components/base/forms/Checkbox'

import useStyles from './useStyles'

interface ToursWithDriver extends Tour {
  driver: Driver
}

export interface HandoverDialogPrintProps {
  open: boolean
  onClose: () => void
}

const HandoverDialogPrint: React.FC<HandoverDialogPrintProps> = ({
  open,
  onClose,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const tours: ToursWithDriver[] = useSelector(fromCombined.getToursWithDriver)
  const region = useSelector(fromProfile.getSubcoRegion)

  const [loading, setLoading] = React.useState<boolean>(false)
  const [selectedIds, setSelectedIds] = React.useState<(string | number)[]>([])

  const handleToggleAll = (isChecked: boolean) =>
    setSelectedIds(isChecked ? tours.map(({ id }: Tour) => id) : [])

  const handleTourSelection = React.useCallback(
    (tourId: string | number) => {
      const index = selectedIds.findIndex((id) => id === tourId)

      if (index === -1) {
        setSelectedIds((prev) => [...prev, tourId])
      } else {
        setSelectedIds((prev) => [
          ...prev.slice(0, index),
          ...prev.slice(index + 1),
        ])
      }
    },
    [selectedIds],
  )

  const handleExport = React.useCallback(async () => {
    setLoading(true)
    const binaryFiles = await Promise.all(
      selectedIds.map(profileActions.printShippingDocument),
    )
    const pdfBytes = await mergePDFBinaryFiles(binaryFiles)
    const filename = `SHIPPING_TOUR_${region}_${moment().format(
      'YYYYMMDDHHmmss',
    )}.pdf`
    downloadPDF(pdfBytes, filename)
    setLoading(false)
  }, [region, selectedIds])

  return (
    <Dialog
      disableEscapeKeyDown
      fullWidth
      maxWidth="sm"
      open={open}
      PaperProps={{ elevation: 1 }}
    >
      <Box position="absolute" top={16} right={16}>
        <IconButton onClick={onClose}>
          <Icon name="close" />
        </IconButton>
      </Box>
      <DialogTitle disableTypography>
        <Typography variant="h3">{t('SELECT_TOUR_TO_PRINT')}</Typography>
        <Typography variant="packageText" color="theBeast">
          {t('SELECT_TOUR_TO_PRINT_SUB_DESCRIPTION')}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Box>
          <FormControlLabel
            control={
              <Checkbox
                indeterminate={
                  selectedIds.length > 0 && selectedIds.length < tours.length
                }
                checked={
                  tours.length > 0 && selectedIds.length === tours.length
                }
                onChange={(event) => handleToggleAll(event.target.checked)}
              />
            }
            label={
              selectedIds.length > 0
                ? `${selectedIds.length} ${t('SELECTED_PRINT')}`
                : t('SELECT_PRINT_ALL')
            }
          />
        </Box>
        <Box className={classes.scrollable}>
          <List>
            {React.useMemo(() => {
              return tours.map((tour) => (
                <ListItem
                  dense
                  button
                  key={tour.id}
                  onClick={() => handleTourSelection(tour.id)}
                >
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      checked={selectedIds.includes(tour.id)}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={`${t('TOUR')} ${tour.id}: ${tour.driver.name}`}
                  />
                </ListItem>
              ))
            }, [handleTourSelection, selectedIds, t, tours])}
          </List>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          startIcon={
            loading ? <CircularProgress size="24px" /> : <Icon name="print" />
          }
          onClick={handleExport}
        >
          {t(loading ? 'PRINT_EXPORT_LOADING' : 'PRINT_EXPORT')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default HandoverDialogPrint
