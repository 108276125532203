import round from 'lodash/round'
import { getTotalDuration as computeTotalDuration } from '../utils'
import { createSelector } from 'reselect'

import { resolveIndex } from '../utils/dataSets'
import { createFeatureSelector } from './core'

import * as fromTours from '../reducers/tour'

const selectFeature = createFeatureSelector('tours')

export const getIdentifiers = createSelector(
  selectFeature,
  fromTours.selectIdentifiers,
)
export const getData = createSelector(selectFeature, fromTours.selectData)
export const getEditTourId = createSelector(
  selectFeature,
  fromTours.selectEditTourId,
)

export const hasTours = createSelector(selectFeature, fromTours.selectHasTours)

export const getRawTours = createSelector(getIdentifiers, getData, resolveIndex)

export const getRawTour = (id) =>
  createSelector(getRawTours, (tours) => tours.find((tour) => tour.id === id))

export const getAssignedDriverIds = createSelector(getRawTours, (tours) =>
  tours.map((tour) => tour.driverId),
)

export const getTotalDuration = createSelector(
  (tour) => tour.collations,
  (collations) => computeTotalDuration(collations),
)

export const getPositiveCollations = createSelector(
  (tour) => tour.collations,
  (collations) =>
    collations.filter((collation) =>
      collation.shipmentIds.some((shipmentId) => shipmentId > -1),
    ),
)

export const getTourProductivity = createSelector(
  getTotalDuration,
  getPositiveCollations,
  (totalDuration, positiveCollations) =>
    positiveCollations.length &&
    round(positiveCollations.length / (totalDuration / 60), 2),
)

export const getProductivityGoal = createSelector(
  selectFeature,
  fromTours.selectProductivityGoal,
)

export const getIsPlanningLocked = createSelector(
  selectFeature,
  fromTours.selectIsPlanningLocked,
)

export const getAreToursSorted = createSelector(
  selectFeature,
  fromTours.selectAreToursSorted,
)

export const getTourPageState = createSelector(
  selectFeature,
  fromTours.selectTourPageState,
)
