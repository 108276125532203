import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const styles = ({ spacing, shape, palette }: Theme) =>
  createStyles({
    root: {
      position: 'relative',
      padding: spacing(2),
    },
    header: {
      padding: spacing(0, 0, 1),
    },
    titleContainer: {
      display: 'flex',
      alignItems: 'center',
      borderRadius: shape.radius4,
      padding: spacing(0.5, 1),
    },
    title: {
      fontWeight: 'bold',
      marginRight: spacing(3),
    },
    badge: {
      height: 16,
      width: 24,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: palette.common.white,
      borderRadius: 16,
    },
    content: {
      padding: '0px !important',
    },
    parcelsContainer: {
      height: 168,
      width: '100%',
      overflowY: 'auto',
    },
    list: {
      paddingBottom: 0,
    },
    listItemText: {
      fontSize: 12,
    },
    changeDriverBtn: {
      padding: spacing(1),
      minWidth: 32,
      height: 32,
      boxShadow: 'unset',
    },
    callout: {
      minWidth: '100%',
    },
  })

export default makeStyles(styles)
