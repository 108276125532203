/* eslint-disable import/no-cycle */

import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  Paper,
  Box,
  Popper,
  Grow,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
} from '@material-ui/core'
import clsx from 'clsx'
import moment from 'moment'
import get from 'lodash/get'

import { useDispatch, useSelector } from 'react-redux'
import { getSelectedCollations } from 'selectors/ui'
import { getShipmentTours } from 'selectors/combined'
import { clearSelected } from 'actions/ui'
import { addTour, moveMultiple } from 'actions/tours'
import { isValidCollation } from 'actions/tours/helpers'
import { getLastStopTime, isPastDeadLine } from 'utils'
import { contrastColor } from 'common/utils/Color'

import { ShipmentTour } from 'common/models/typings/Tour'

import Button from 'common/components/base/Button'
import Typography from 'common/components/base/Typography'

import useStyles from './useStyles'
import { NETWORK_TYPE } from '../../../../models/typings/TourOverview'
import * as fromNetworkType from '../../../../../selectors/networkType'

const TourMapActions: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const classes = useStyles()

  const tours: ShipmentTour[] = useSelector(getShipmentTours)
  const selectedMarkers = useSelector(getSelectedCollations)
  const networkType: NETWORK_TYPE = useSelector(fromNetworkType.getNetworkType)

  const [anchor, setAnchor] = React.useState<HTMLButtonElement | null>(null)

  const handleToggleAnchor = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchor(anchor ? null : event.currentTarget)
    },
    [anchor],
  )

  const getStartTime = React.useCallback(
    (collations) => moment(get(collations, '[0].estimation')).format('HH:mm'),
    [],
  )

  const getEndTime = React.useCallback((collations) => {
    return {
      endTime: moment(getLastStopTime(collations)).format('HH:mm'),
      pastDeadline: isPastDeadLine(collations, networkType),
    }
  }, [])

  const handleUnSelect = () => {
    dispatch(clearSelected())
  }

  const handleAddTour = () => {
    dispatch(addTour(selectedMarkers))
    dispatch(clearSelected())
  }

  const handleMoveCollations = React.useCallback(
    (id: ShipmentTour['id']) => {
      dispatch(moveMultiple(id, selectedMarkers))
      dispatch(clearSelected())
    },
    [dispatch, selectedMarkers],
  )

  const listItems = React.useMemo(
    () =>
      tours.map((tr, index) => (
        <ListItem
          key={tr.id}
          disableGutters
          button
          className={classes.listItem}
          onClick={() => handleMoveCollations(tr.id)}
        >
          <Box
            className={clsx(classes.listItemMarker, {
              [classes.borderTopLeft]: index === 0,
            })}
            style={{ background: tr.color, color: contrastColor(tr.color) }}
          >
            {tr.number}
          </Box>
          <Box width="32px" />
          <ListItemText
            primary={
              <>
                <Typography variant="packageText" color="indigo">
                  {getStartTime(tr.collations)}
                  {' - '}
                </Typography>
                <Typography variant="packageText" color="indigo">
                  {getEndTime(tr.collations).endTime}
                </Typography>
              </>
            }
            style={{ marginLeft: 32 }}
          />
          <ListItemSecondaryAction>
            <Typography variant="packageText" color="indigo">
              {tr.collations
                .filter(isValidCollation as any)
                .reduce((a, b) => a + b.shipmentIds.length || a, 0)}
            </Typography>
          </ListItemSecondaryAction>
        </ListItem>
      )),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [handleMoveCollations, tours],
  )

  const open = Boolean(anchor)

  if (selectedMarkers.length < 1) return null

  return (
    <Paper className={classes.paper}>
      <Box className={classes.container}>
        <Box className={classes.indicator}>
          <Typography>{selectedMarkers.length}</Typography>
        </Box>
        <Button
          color="action"
          onClick={handleToggleAnchor}
          className={classes.button}
        >
          {t('MOVE_TO_DIFFERENT_TOUR')}
        </Button>
        <Button
          color="danger"
          onClick={handleUnSelect}
          className={classes.button}
        >
          {t('DE_SELECT_ALL')}
        </Button>
      </Box>
      <Popper
        open={open}
        anchorEl={anchor}
        placement="top"
        transition
        className={classes.popper}
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps} timeout={350}>
            <Paper className={classes.popperPaper}>
              <Box className={classes.listContainer}>
                <List disablePadding>{listItems}</List>
              </Box>
              <Button
                variant="flat"
                color="white"
                fullWidth
                onClick={handleAddTour}
              >
                {t('ADD_TO_NEW_TOUR')}
              </Button>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Paper>
  )
}

export default React.memo(TourMapActions)
