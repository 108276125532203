import React from 'react'
import { useTranslation } from 'react-i18next'
import { Card, CardContent, Box, CircularProgress } from '@material-ui/core'
import { Formik, Form } from 'formik'

import InputFormik from 'common/components/base/forms/Input/InputFormik'
import Button from 'common/components/base/Button'
import Typography from 'common/components/base/Typography'

import editProfileSchema from './schema'

import useStyles from './useStyles'

export interface EditProfileFormFields {
  street: string
  postalCode: string
  city: string
  latitude: string
  longitude: string
}

export interface EditProfileFormProps {
  initialValues: EditProfileFormFields
  onSubmit: (values: EditProfileFormFields) => void
}

const EditProfileForm: React.FC<EditProfileFormProps> = ({
  initialValues,
  onSubmit,
}) => {
  const { t } = useTranslation()
  const schema = editProfileSchema()
  const classes = useStyles()

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={schema}
    >
      {({ isSubmitting, isValid }) => (
        <Form style={{ width: '100%' }}>
          <Card>
            <CardContent>
              <InputFormik fullWidth name="street" label={t('STREET')} />
              <Box height={16} />
              <Box className={classes.container}>
                <Box className={classes.items}>
                  <InputFormik
                    fullWidth
                    name="postalCode"
                    label={t('POSTALCODE')}
                  />
                </Box>
                <Box className={classes.items}>
                  <InputFormik fullWidth name="city" label={t('CITY')} />
                </Box>
              </Box>
              <Box height={16} />
              <Box className={classes.container}>
                <Box className={classes.items}>
                  <InputFormik fullWidth name="latitude" label={t('LAT')} />
                </Box>
                <Box className={classes.items}>
                  <InputFormik fullWidth name="longitude" label={t('LNG')} />
                </Box>
              </Box>
            </CardContent>
          </Card>
          <Box height={16} />
          <Box display="flex" justifyContent="flex-end">
            <Button
              type="submit"
              color="green"
              disabled={!isValid || isSubmitting}
              startIcon={isSubmitting && <CircularProgress size="18" />}
            >
              <Typography color="white" transform="capitalize">
                {t(isSubmitting ? 'SAVING_USER_BUTTON' : 'SAVE')}
              </Typography>
            </Button>
          </Box>
        </Form>
      )}
    </Formik>
  )
}

export default EditProfileForm
