import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const styles = ({ spacing }: Theme) =>
  createStyles({
    root: {
      position: 'relative',
    },
  })

export default makeStyles(styles)
