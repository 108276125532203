import * as React from 'react'

import styles from './NoResults.module.scss'

export const NoResults = ({ icon, title, content, button, children = null }) => (<div>
  <div className={styles.noResultsContainer}>
    <div className="no-results-image">
      <img src={icon} />
    </div>
    <h1 className={styles.noResultsHeader}>{title}</h1>
    { content && <p className={styles.noResultsContent}>{content}</p> }
    {button}
    {children}
  </div>
</div>)
