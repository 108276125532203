/* eslint-disable import/no-cycle */

import { BANNER_TYPES } from 'actions/warehouse'
import * as types from 'constants/ActionTypes'

interface IAction {
  type: any
  payload: any
}

interface WarehouseState {
  /** Date format YYYY-MM-DD */
  busyDay: string | null
  /** is busyDay today */
  isBusyDay: boolean
  /** Date format YYYY-MM-DD timestamp */
  centralSorting: string | null
  /** is central sorting done today */
  isCentralSortingDone: boolean
  whatBannerShouldDisplay: BANNER_TYPES
  currentNetworkReady: boolean
}

const initialState: WarehouseState = {
  busyDay: null,
  isBusyDay: false,
  centralSorting: null,
  isCentralSortingDone: false,
  whatBannerShouldDisplay: BANNER_TYPES.NONE,
  currentNetworkReady: false,
}

export default function warehouse(state = initialState, action: IAction) {
  switch (action.type) {
    case types.WAREHOUSE_GET_BUSY_DAY:
      return {
        ...state,
        busyDay: action.payload.busyDay,
        isBusyDay: action.payload.isBusyDayToday,
      }
    case types.WAREHOUSE_SET_BUSY_DAY:
      return {
        ...state,
        busyDay: action.payload.busyDay,
        isBusyDay: action.payload.isBusyDayToday,
      }
    case types.WAREHOUSE_DELETE_BUSY_DAY:
      return {
        ...state,
        busyDay: action.payload,
      }
    case types.WAREHOUSE_GET_CENTRAL_SORTING:
      return {
        ...state,
        centralSorting: action.payload.centralSorting,
        isCentralSortingDone: action.payload.isCentralSortingDone,
      }
    case types.WAREHOUSE_SET_WHAT_BANNER_SHOULD_DISPLAY:
      return {
        ...state,
        whatBannerShouldDisplay: action.payload,
      }
    case types.WAREHOUSE_GET_NETWORK_STATUS:
      return {
        ...state,
        currentNetworkReady: action.payload,
      }
    default:
      return state
  }
}
