import { darken } from '@material-ui/core/styles/colorManipulator'
import { Theme } from '@material-ui/core/styles'
import createStyles from '@material-ui/core/styles/createStyles'

const styles = ({ spacing, palette, shadows, shape, breakpoints }: Theme) =>
  createStyles({
    button: {
      borderRadius: shape.radius4,
      padding: spacing(1, 2),
      boxShadow: shadows[1],
      color: palette.trunkrs.primary.indigo.base,
      whiteSpace: 'pre',
      height: spacing(5),
      fontSize: 14,
      lineHeight: '24px',

      [breakpoints.down('sm')]: {
        '&:not($mobileFull)': {
          height: spacing(3),
          paddingLeft: spacing(2),
          paddingRight: spacing(2),
        },
      },

      '&$indigo': {
        color: palette.common.white,
        backgroundColor: palette.trunkrs.primary.indigo.base,

        '&:hover:not($disabled)': {
          backgroundColor: palette.trunkrs.primary.indigo.darkest,
        },
      },

      '&$green': {
        color: palette.trunkrs.primary.indigo.base,
        backgroundColor: palette.trunkrs.primary.green.base,

        '&:hover:not($disabled)': {
          backgroundColor: palette.trunkrs.primary.green.darkest,
        },
      },

      '&$action': {
        backgroundColor: palette.trunkrs.intent.blue.base,

        '&:hover:not($disabled)': {
          backgroundColor: palette.trunkrs.intent.blue.darkest,
        },
      },

      '&$danger': {
        backgroundColor: palette.trunkrs.intent.red.base,

        '&:hover:not($disabled)': {
          backgroundColor: palette.trunkrs.intent.red.darkest,
        },
      },

      '&$warning': {
        backgroundColor: palette.trunkrs.intent.yellow.base,

        '&:hover:not($disabled)': {
          backgroundColor: palette.trunkrs.intent.yellow.darkest,
        },
      },

      '&$grey': {
        backgroundColor: palette.trunkrs.grayscale.base,

        '&:hover:not($disabled)': {
          backgroundColor: palette.trunkrs.grayscale.darker,
        },
      },

      '&$white': {
        backgroundColor: palette.common.white,
        color: palette.trunkrs.primary.indigo.base,

        '&:hover:not($disabled)': {
          backgroundColor: darken(palette.common.white, 0.06),
        },
      },

      '&$background': {
        backgroundColor: palette.trunkrs.background,
        color: palette.trunkrs.primary.indigo.base,

        '&:hover:not($disabled)': {
          backgroundColor: darken(palette.trunkrs.background, 0.06),
        },
      },

      '&$small': {
        lineHeight: `1.42`,
        padding: `${spacing(2)}px ${spacing(3)}px`,
      },
    },

    rounded: {
      borderRadius: spacing(6),
    },

    flat: {
      boxShadow: 'none !important',
    },

    disabled: {
      '&$indigo': {
        backgroundColor: palette.trunkrs.grayscale.purgatory,
        color: palette.trunkrs.grayscale.base,
      },

      '&$green': {
        backgroundColor: palette.trunkrs.grayscale.purgatory,
        color: palette.trunkrs.grayscale.base,
      },

      '&$action': {
        backgroundColor: palette.trunkrs.grayscale.purgatory,
        color: palette.trunkrs.grayscale.base,
      },

      '&$danger': {
        backgroundColor: palette.trunkrs.grayscale.purgatory,
        color: palette.trunkrs.grayscale.base,
      },

      '&$warning': {
        backgroundColor: palette.trunkrs.grayscale.purgatory,
        color: palette.trunkrs.grayscale.base,
      },

      '&$grey': {
        backgroundColor: palette.trunkrs.grayscale.purgatory,
        color: palette.trunkrs.grayscale.base,
      },

      '&$theBeast': {
        backgroundColor: palette.trunkrs.grayscale.purgatory,
        color: palette.trunkrs.grayscale.base,
      },

      '&$white': {
        backgroundColor: palette.trunkrs.grayscale.purgatory,
        color: palette.trunkrs.grayscale.base,
      },
    },

    indigo: {},
    green: {},
    action: {},
    danger: {},
    warning: {},
    grey: {},
    lightGrey: {},
    theBeast: {},
    white: {},
    background: {},

    default: {},

    large: {
      height: 56,
      fontSize: 16,
      lineHeight: '28px',
    },
    medium: {},
    small: {},

    mobileFull: {},
  })

export default styles
