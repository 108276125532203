import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const styles = ({ spacing, shape, shadows, typography }: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: 200,
      height: 160,
      borderRadius: shape.radius8,
      cursor: 'pointer',
      margin: spacing(2, 2),
      boxShadow: shadows[1],
      '&:hover': {
        boxShadow: shadows[2],
      },
      padding: '32px 20px',
    },
    logo: {
      fontSize: 48,
    },
    text: {
      fontFamily: typography.clanPro.bold,
      fontSize: 16,
      position: 'relative',
    },
  })

export default makeStyles(styles)
