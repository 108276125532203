import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'
import { clanPro } from 'common/theme/mui/typography'

const styles = ({ spacing, palette }: Theme) =>
  createStyles({
    badge: {
      // position: 'absolute',
      // top: 8,
      // right: 30,
      border: '1px solid rgba(0, 0, 0, 0.12)',
      borderRadius: 12,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: spacing(0, 1.75),
      marginRight: 5,
      whiteSpace: 'nowrap',
    },
    fossil: {
      backgroundColor: palette.trunkrs.intent.orange.base,
    },
    electric: {
      backgroundColor: palette.trunkrs.primary.green.base,
    },
    disabled: {
      opacity: 0.4,
      cursor: 'default',
    },
  })

export default makeStyles(styles)
