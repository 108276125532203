/* eslint-disable import/no-cycle */

import React from 'react'
import { Dialog, Box, IconButton } from '@material-ui/core'

import { Tour } from 'common/models/typings/Tour'
import Icon from 'common/components/base/Icon'

import { FuelName } from 'common/models/typings/FuelType'
import WarningMessage from './WarningMessage'
import DriverSelect from './DriverSelect'
import { Driver } from 'common/models/typings/Driver'

export interface HandoverDialogChangeDriverProps {
  open: boolean
  tour: Tour
  onChangeDriver: (driverId: number | null, vehicle: FuelName) => void
  onSave: () => void
  onClose: (tour?: Tour) => void
}

const HandoverDialogChangeDriver: React.FC<HandoverDialogChangeDriverProps> = ({
  open,
  tour,
  onChangeDriver,
  onSave,
  onClose,
}) => {
  const [isSelecting, setIsSelecting] = React.useState(false)
  const [originalTour, setOriginalTour] = React.useState<Tour | null>(null)

  React.useEffect(() => {
    if (tour) {
      setOriginalTour(tour)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleStartSelection = React.useCallback(() => setIsSelecting(true), [])

  const handleStopSelection = React.useCallback(() => setIsSelecting(false), [])

  const handleConfirmChange = React.useCallback(
    (driver: Driver, vehicle: FuelName) => {
      onChangeDriver(driver.id, vehicle)
      setIsSelecting(false)
    },
    [onChangeDriver],
  )

  const handleSave = React.useCallback(() => {
    onSave()
  }, [onSave])

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      scroll="body"
      PaperProps={{ elevation: 1 }}
      open={open}
      style={{ overflowY: 'visible' }}
    >
      <Box position="absolute" top="16px" right="16px">
        <IconButton onClick={() => onClose()}>
          <Icon name="close" />
        </IconButton>
      </Box>

      {isSelecting ? (
        <DriverSelect
          tour={tour}
          onConfirmChange={handleConfirmChange}
          onGoBack={handleStopSelection}
        />
      ) : (
        <WarningMessage
          tour={tour}
          originalTour={originalTour}
          onClose={onClose}
          onStartSelect={handleStartSelection}
          onSave={handleSave}
        />
      )}
    </Dialog>
  )
}

export default HandoverDialogChangeDriver
