/* eslint-disable no-case-declarations */

import { normalize, schema } from 'normalizr'
import {
  ADD_DRIVER,
  CLEAR_DRIVERS,
  ACTIVATE_DRIVER,
  DEACTIVATE_DRIVER,
  ADD_DRIVERS,
  CHANGE_SUBCO,
} from '../constants/ActionTypes'

const driverSchema = new schema.Entity('drivers', {}, { idAttribute: 'id' })

const normalizeData = (data) => {
  const {
    result: keys,
    entities: { drivers: normalizedData },
  } = normalize(data, [driverSchema])

  return [keys || [], normalizedData || {}]
}

const initialState = {}

export default function drivers(state = initialState, action) {
  switch (action.type) {
    case ADD_DRIVER:
      return {
        ...state,
        [action.payload.Id]: {
          Id: action.payload.Id,
          Name: action.payload.Name,
          instance: action.payload.Instance,
        },
      }

    case ADD_DRIVERS:
      const [, data] = normalizeData(action.payload)

      return {
        ...state,
        ...data,
      }

    case ACTIVATE_DRIVER:
      return {
        ...state,
        [action.payload.Id]: {
          ...state[action.payload.Id],
          active: true,
        },
      }

    case DEACTIVATE_DRIVER:
      return {
        ...state,
        [action.payload.Id]: {
          ...state[action.payload.Id],
          active: false,
        },
      }

    case CHANGE_SUBCO:
    case CLEAR_DRIVERS:
      return initialState

    default:
      return state
  }
}
