import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const styles = ({ spacing, palette }: Theme) =>
  createStyles({
    root: {
      padding: spacing(2, 2, 1),
    },
    toolbar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: spacing(2),
    },
    avatar: {
      marginBottom: spacing(4),
    },
    itemText: {
      margin: 0,
    },
    avatarNo: {
      color: palette.trunkrs.primary.green.darker,
      '&:hover': {
        color: palette.trunkrs.primary.green.darkest,
      },
    },
    tourLegends: {
      display: 'flex',
      alignItems: 'center',
    },
    tourLegendsClickable: {
      cursor: 'pointer',
    },
    collapsibleRoot: {
      width: '92%',
    },
    collapsibleInner: {
      overflowX: 'auto',
    },
    legends: {
      flexGrow: 1,
      display: 'grid',
      columnGap: 60,
      rowGap: 24,
      gridTemplateColumns: 'repeat(15, auto)',
      padding: '12px 24px',
      backgroundColor: palette.trunkrs.background,
      borderRadius: 4,
      width: '100%',
    },
    stop: {
      height: 24,
      width: 24,
      borderRadius: 12,
      backgroundColor: palette.trunkrs.grayscale.lighter,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontFamily: 'ClanPro-Bold',
      fontSize: 10,
    },
    badge: {
      height: 8,
      width: 8,
      minWidth: 8,
      padding: 0,
      borderRadius: 4,
    },
    notScanned: {
      backgroundColor: palette.trunkrs.grayscale.theBeast,
    },
    inTransit: {
      backgroundColor: palette.trunkrs.intent.yellow.base,
    },
    delivered: {
      backgroundColor: palette.trunkrs.primary.green.base,
    },
    deliveredToNeighbour: {
      backgroundColor: palette.common.white,
      boxShadow: `0 0 0 2px ${palette.trunkrs.primary.green.base}`,
    },
    notDelivered: {
      backgroundColor: palette.trunkrs.intent.red.base,
    },
    timeText: {
      fontSize: 14,
      lineHeight: '24px',
    },
  })

export default makeStyles(styles)
