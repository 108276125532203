import get from 'lodash/get'
import * as Sentry from '@sentry/browser'

import * as ActionTypes from '../constants/ActionTypes'
import * as fromIntervalTasks from '../selectors/intervalTasks'
import trunkrs from 'common/utils/Sdk'

export const queueTask = (
  id,
  task,
  args,
  timeoutDuration = 45000,
  maxRetries = 3,
) => ({
  type: ActionTypes.QUEUE_TASK,
  payload: {
    id,
    task,
    args,
    timeoutDuration,
    lastAttemptAt: 0,
    isRunning: false,
    tries: 0,
    maxRetries,
  },
})

const execute = async(dispatch, task, target) => {
  try {
    dispatch({ type: ActionTypes.START_TASK, payload: { id: task.id } })

    const result = await target.apply(null, task.args)
    dispatch({ type: ActionTypes.COMPLETE_TASK, payload: { id: task.id, result } })
  } catch (error) {
    const isMarkedForRemoval = get(error, 'response.status') === 422
      || task.tries + 1 === task.maxRetries

    if (!isMarkedForRemoval) {
      dispatch({
        type: ActionTypes.FAIL_TASK,
        payload: {
          id: task.id,
          error,
          tries: (task.tries || 0) + 1,
          maxRetries: task.maxRetries || 3,
        },
      })
    } else {
      dispatch({ type: ActionTypes.COMPLETE_TASK, payload: { id: task.id } })
    }
  }
}

export const startTicker = () => (dispatch, getState) => {
  const worker = () => {
    const tasks = fromIntervalTasks.getTasks(getState())

    const now = Number(new Date())
    const restartableTasks = tasks.filter(task =>
      (now - task.lastAttemptAt) > task.timeoutDuration)

    restartableTasks.forEach((task) => {

      const trace = task.task.split('.')
      let layer = trunkrs

      trace.forEach((pathPart, index) => {
        const paramsStartIndex = pathPart.lastIndexOf('(')

        const nextEvalPart = paramsStartIndex !== -1
          ? pathPart.substring(0, paramsStartIndex)
          : pathPart

        const nextEvalTarget = get(layer, nextEvalPart)

        if (index === trace.length - 1) {
          execute(dispatch, task, nextEvalTarget)
            .catch(Sentry.captureException)
        } else {
          layer = typeof nextEvalTarget === 'function'
            ? nextEvalTarget.call()
            : nextEvalTarget
        }
      })
    })
  }

  const isTickerActive = fromIntervalTasks.getIsTickerStarted(getState())
  if (!isTickerActive) {
    setInterval(worker, 1000)
    dispatch({ type: ActionTypes.TASK_TICKER_STARTED })
  }
}
