/* eslint-disable import/no-cycle */

import React from 'react'
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'

import { isDevelopment } from 'constants/index'

// layouts
import LayoutDashboard from 'common/components/layouts/Dashboard'

// pages
import LoginPage from 'pages/login'

import ShipmentsPage from 'pages/shipments'
import InventoryScanPage from 'pages/planningWizard/inventoryScan'
import InventoryScanPageNew from 'pages/planningWizard/inventoryScanNew'
import HubInventoryPage from 'pages/hubInventory'
import EmployeesPage from 'pages/employees'
import TourPlanningPage from 'pages/planningWizard/tourPlanning'
import HandoverPage from 'pages/planningWizard/handover'
import MonitoringPage from 'pages/planningWizard/monitoring'
import ProfilePage from 'pages/profile'
import ProfileEditPage from 'pages/profile/edit'
import CollectionsPage from 'pages/collections'
import ChooseNetworkType from 'pages/planningWizard/chooseNetworkType'

import LayoutPlanningWizard from 'common/components/layouts/PlanningWizard'
import RoutePublic from './Public'

import TaskTickerManager from 'common/components/features/TaskTickerStarter'

const Router: React.FC = () => (
  <>
    <BrowserRouter>
      <Routes>
        <Route
          path="/login"
          element={
            <RoutePublic>
              <LoginPage />
            </RoutePublic>
          }
        />
        <Route path="" element={<LayoutDashboard />}>
          <Route index element={<ShipmentsPage />} />
          <Route path="planning" element={<LayoutPlanningWizard />}>
            {/* <Route path="inventory-scan" element={<InventoryScanPage />} /> */}
            <Route path="inventory-scan" element={<InventoryScanPageNew />} />
            <Route path="tour-planning" element={<TourPlanningPage />} />
            <Route path="handover" element={<HandoverPage />} />
            <Route path="monitoring" element={<MonitoringPage />} />
          </Route>
          <Route path="hub-inventory" element={<HubInventoryPage />} />
          <Route path="drivers" element={<EmployeesPage />} />
          <Route path="profile" element={<ProfilePage />} />
          <Route path="profile/edit" element={<ProfileEditPage />} />
          {isDevelopment && (
            <Route path="collections" element={<CollectionsPage />} />
          )}
        </Route>
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </BrowserRouter>
    <TaskTickerManager />
  </>
)

export default Router
