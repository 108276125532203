import React from 'react'

import Lottie from '../base/Lottie'

import animationData from './animationFiles/evening-monitoring-empty-state.json'
import styles from './styles.module.scss'

const EmptyMonitoringNightAni = () => (
  <div className={styles.roundedStroke}>
    <Lottie
      preserveAspectRatio="xMidYMid slice"
      animationData={animationData}
      isAutoPlay={false}
      isLoop
    />
  </div>
)
export default EmptyMonitoringNightAni
