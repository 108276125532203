import { createSelector } from 'reselect'

import { createFeatureSelector } from './core'

export const selectFeature = createFeatureSelector('warehouse')

export const getIsBusyDay = createSelector(
  selectFeature,
  (state) => state.isBusyDay,
)

export const getCentralSorting = createSelector(
  selectFeature,
  (state) => state.centralSorting,
)

export const getIsCentralSortingDone = createSelector(
  selectFeature,
  (state) => state.isCentralSortingDone,
)

export const getWhatBannerShouldDisplay = createSelector(
  selectFeature,
  (state) => state.whatBannerShouldDisplay,
)

export const getCurrentNetworkReady = createSelector(
  selectFeature,
  (state) => state.currentNetworkReady,
)
