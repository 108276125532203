import { createSelector } from 'reselect'

import { createFeatureSelector } from './core'
import * as fromIntervalTasks from '../reducers/intervalTasks'

const selectFeature = createFeatureSelector('intervalTasks')

export const getIsTickerStarted = createSelector(
  selectFeature,
  fromIntervalTasks.selectIsTickerActive,
)

export const getIdentifiers = createSelector(
  selectFeature,
  fromIntervalTasks.selectIdentifiers,
)

export const getTaskData = createSelector(
  selectFeature,
  fromIntervalTasks.selectTasks,
)

export const getTasks = createSelector(
  getTaskData,
  getIdentifiers,
  (data, identifiers) => identifiers.map(id => data[id]),
)
