import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const styles = ({ spacing, palette }: Theme) =>
  createStyles({
    paper: {
      padding: spacing(2),
      minWidth: 375,
      position: 'absolute',
      left: 25,
      bottom: '10%',
    },
    container: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: spacing(2),
    },
    indicator: {
      height: 30,
      width: 30,
      minWidth: 30,
      backgroundColor: palette.trunkrs.primary.green.base,
      borderRadius: 15,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    button: {
      color: palette.common.white,
    },
    popper: {
      top: `-${spacing(3)}px !important`,
    },
    popperPaper: {
      minWidth: 200,
      padding: 0,
    },
    listContainer: {
      maxHeight: 200,
      overflowY: 'auto',
    },
    listItem: {
      position: 'relative',
      minHeight: 40,
      display: 'flex',
    },
    listItemMarker: {
      position: 'absolute',
      height: '100%',
      width: 24,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    borderTopLeft: {
      borderTopLeftRadius: spacing(1),
    },
  })

export default makeStyles(styles)
