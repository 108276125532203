import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const styles = ({ palette }: Theme) =>
  createStyles({
    dialogContent: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      paddingTop: 0,
    },
    link: {
      fontWeight: 'bold',
      textDecoration: 'underline',
      color: palette.trunkrs.secondary.violet.base,
    },
  })

export default makeStyles(styles)
