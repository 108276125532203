import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const styles = ({ spacing, palette }: Theme) =>
  createStyles({
    paper: {
      minHeight: 128,
      position: 'relative',
      padding: spacing(2),
      marginBottom: spacing(2),
    },
    borderDanger: {
      boxShadow: `0 0 0 2px ${palette.trunkrs.intent.red.darker}`,
    },
    tourLabelContainer: {
      position: 'absolute',
      top: spacing(2),
      left: spacing(2),
    },
    labelContainer: {
      display: 'flex',
      gap: 8,
    },
    perLabelContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      flex: '1 1 0',
      overflow: 'hidden',
      textOverflow: 'ellipsis',

      '& > *': {
        width: '100%',
      },
    },
    label: {
      fontWeight: 'bold',
    },
    productivity: {
      marginTop: spacing(1),
    },
    labelRed: {
      fontWeight: 'bold',
      color: palette.trunkrs.intent.red.darker,
    },
    colorIndicator: {
      height: 12,
      width: 12,
      borderRadius: 6,
      marginRight: spacing(1),
    },
    listItemMarker: {
      position: 'absolute',
      height: '100%',
      width: 24,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    tourCountLabel: {
      display: 'flex',
      flexDirection: 'column',
      marginBottom: '16px',
    },
    tourLabelRow: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
  })

export default makeStyles(styles)
