import { createFeatureSelector } from './core'
import { createSelector } from 'reselect'
import * as fromTourOverviews from '../reducers/tourOverview'

const selectFeature = createFeatureSelector('tourOverviews')

export const getIdentifiers = createSelector(
  selectFeature,
  fromTourOverviews.getIdentifiers,
)

export const getData = createSelector(
  selectFeature,
  fromTourOverviews.getData,
)

export const hasFetched = createSelector(
  selectFeature,
  fromTourOverviews.hasFetched,
)

export const isLoading = createSelector(
  selectFeature,
  fromTourOverviews.isLoading,
)

export const getTourOverviews = createSelector(
  getIdentifiers,
  getData,
  (ids, data) => ids.map(id => data[id]),
)
