import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box, LinearProgress } from '@material-ui/core'
import moment from 'moment'

import { useInterval } from 'hooks'

import Typography from 'common/components/base/Typography'

import useStyles from './useStyles'

const CreateToursDialogCreatingTours = () => {
  const { t } = useTranslation()
  const classes = useStyles()

  const [time, setTime] = React.useState(moment().format('HH:mm:ss'))

  const updateTime = React.useCallback(
    () => setTime(moment().format('HH:mm:ss')),
    [],
  )

  const [startPolling, stopPolling] = useInterval(updateTime, 2500)

  React.useEffect(() => {
    startPolling()

    return () => stopPolling()
  }, [startPolling, stopPolling])

  return (
    <Box>
      <LinearProgress
        classes={{
          root: classes.root,
          bar: classes.bar,
          colorPrimary: classes.primary,
        }}
      />
      <Box height={16} />
      <Box>
        <Typography variant="textBold">{t('LAST_UPDATE')}</Typography>
        <Typography className={classes.fontSizeTwelve}>
          {time.toString()}
        </Typography>
      </Box>
    </Box>
  )
}

export default React.memo(CreateToursDialogCreatingTours)
