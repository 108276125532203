/* eslint-disable import/no-cycle */

import React, { HtmlHTMLAttributes } from 'react'
import { useTranslation } from 'react-i18next'
import { PopperProps } from '@material-ui/core'
import Autocomplete, {
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  AutocompleteCloseReason,
  AutocompleteRenderInputParams,
} from '@material-ui/lab/Autocomplete'

import { useDispatch, useSelector } from 'react-redux'
import { setVehicleType, setDriver } from 'actions/tours'
import { areToursLocked, getShipmentTours } from 'selectors/combined'
import { getDrivers } from 'selectors/driver'
import { ShipmentTour } from 'common/models/typings/Tour'
import CustomInput from './CustomInput'
import CustomPopper from './CustomPopper'

import { Driver } from 'common/models/typings/Driver'

import CustomPaper from './CustomPaper'

import useStyles from './useStyles'

interface AutoCompleteProps {
  id: ShipmentTour['id']
  value: number
  vehicleType: ShipmentTour['vehicleType']
  isWorking?: boolean
  disabled?: boolean
  onChangeDriver?: (driverId: number | null) => void
  onChangeVehicleType?: (type: string) => void
  isGreenRegion: boolean
}

const AutoComplete: React.FC<AutoCompleteProps> = ({
  id,
  value,
  vehicleType = 'fossil',
  isWorking,
  disabled,
  isGreenRegion = false,
  onChangeDriver,
  onChangeVehicleType,
}) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const classes = useStyles()

  const tours: ShipmentTour[] = useSelector(getShipmentTours)
  const locked: boolean = useSelector(areToursLocked)
  const drivers: Driver[] = useSelector(getDrivers)
  const [open, setOpen] = React.useState<boolean>(false)

  const options = React.useMemo(
    () => drivers.sort((a, b) => -b.name.localeCompare(a.name)),
    [drivers],
  )

  const selectedDriverIds = React.useMemo(
    () => tours.filter((tour) => tour.id !== id).map((ftr) => ftr.driverId),
    [tours, id],
  )

  const inputValue = React.useMemo(
    () => options.find((opt) => opt.id === value) || null,
    [options, value],
  )

  const isChangingDisabled: boolean = React.useMemo(() => {
    if (isWorking !== undefined) return isWorking || locked
    if (disabled !== undefined) return disabled
    return locked
  }, [disabled, isWorking, locked])

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const handleChange = (
    event: React.ChangeEvent<unknown>,
    current: Driver | null,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<Driver | undefined>,
  ) => {
    if (reason === 'clear' || reason === 'select-option') {
      if (typeof onChangeDriver === 'function') {
        onChangeDriver(current?.id || null)
      } else {
        dispatch(setDriver(id, current?.id || null))
      }
      handleClose()
    }
  }

  const handleLossFocus = (
    event: React.ChangeEvent<unknown>,
    reason: AutocompleteCloseReason,
  ) => {
    if (reason === 'escape') {
      handleClose()
    }
  }

  const getOptionDisabled = (param: Driver) =>
    selectedDriverIds.includes(param.id)

  const groupBy = (param: Driver) => param.name.charAt(0).toUpperCase()

  const getOptionLabel = (param: Driver) => param.name

  const handleChangeVehicleType = React.useCallback(
    (name: string) => {
      if (typeof onChangeVehicleType === 'function') {
        onChangeVehicleType(name)
      } else {
        dispatch(setVehicleType(id, name))
      }
    },
    [onChangeVehicleType, id, dispatch],
  )

  React.useEffect(() => {
    if (isGreenRegion && vehicleType !== 'electric') {
      handleChangeVehicleType('electric')
    }
  }, [isGreenRegion, id, vehicleType])

  const renderCustomInput = (props: AutocompleteRenderInputParams) => {
    return (
      <CustomInput
        {...props}
        disabled={isChangingDisabled}
        vehicleType={vehicleType}
      />
    )
  }

  const renderCustomPaper = (
    props: React.PropsWithChildren<HtmlHTMLAttributes<HTMLElement>>,
  ) => (
    <CustomPaper
      {...props}
      id={id}
      vehicleType={vehicleType}
      disabled={isChangingDisabled || isGreenRegion}
      onChangeVehicleType={handleChangeVehicleType}
      onClickAway={handleClose}
    />
  )

  const renderCustomPopper = (props: PopperProps) => {
    return <CustomPopper {...props} onClickAway={handleClose} />
  }

  return (
    <Autocomplete
      fullWidth
      openOnFocus
      id={`autocomplete-${id}`}
      disabled={isChangingDisabled}
      open={open}
      onOpen={handleOpen}
      onClose={handleLossFocus}
      value={inputValue}
      options={options}
      getOptionDisabled={getOptionDisabled}
      groupBy={groupBy}
      getOptionLabel={getOptionLabel}
      onChange={handleChange}
      renderInput={renderCustomInput}
      PaperComponent={renderCustomPaper}
      classes={{
        inputRoot: classes.inputRoot,
        endAdornment: classes.endAdornment,
        popper: classes.popper,
        paper: classes.paper,
      }}
    />
  )
}

export default AutoComplete
