import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const styles = ({ spacing, palette }: Theme) =>
  createStyles({
    paper: {
      padding: spacing(0),

      '& input[type="number"]': {
        '-moz-appearance': 'textfield !important',
        '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
          '-webkit-appearance': 'none',
          margin: 0,
        },
      },
    },
    dialogTitle: {
      padding: spacing(4, 4, 0, 4),
    },
    dialogContent: {
      padding: spacing(5, 4),
    },
    dialogActions: {
      padding: spacing(0, 4, 2),
    },
    button: {
      textTransform: 'capitalize',
    },
  })

export default makeStyles(styles)
