import React, { SVGProps } from 'react'
import clsx from 'clsx'

import { IconLibrary } from './library'

import styles from './styles.module.scss'

interface IconProps extends SVGProps<SVGSVGElement> {
  name: keyof typeof IconLibrary
  className?: string
  disabled?: boolean
}

const Icon: React.FC<IconProps> = ({ name, className, disabled, ...props }) => {
  const IconComponent = IconLibrary[name]
  return (
    <IconComponent
      className={clsx(
        className,
        styles.icon,
        disabled && styles.disabled,
        props.onClick && styles.clickable,
      )}
      {...props}
    />
  )
}

Icon.displayName = 'Icon'

export default React.memo(Icon)
