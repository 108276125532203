import { createSelector } from 'reselect'

import { createFeatureSelector } from './core'
import * as fromCollations from '../reducers/collations'

const selectFeature = createFeatureSelector('collations')

export const getIdentifiers = createSelector(
  selectFeature,
  fromCollations.selectIdentifiers,
)
export const getData = createSelector(
  selectFeature,
  fromCollations.selectData,
)

export const hasCollations = createSelector(
  selectFeature,
  fromCollations.selectHasCollations,
)
