/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import React from 'react'
import clsx from 'clsx'

import get from 'lodash/get'
import defaultTo from 'lodash/defaultTo'

import ClickAway from '../../utils/ClickAway'
import Icon from '../../Icon'

import styles from './styles.module.scss'

interface DropdownMenuProps {
  data: { [key: string]: React.ReactNode }
  onChange: (value: any) => void
  value?: any
  className?: string
}

const DropdownMenu: React.FC<DropdownMenuProps> = ({
  data,
  value,
  className,
  onChange,
}) => {
  const [isDropdownVisible, setIsDropdownVisible] =
    React.useState<boolean>(false)

  const selectedNode = React.useMemo(
    () => get(data, `${value}.props.children`),
    [data, value],
  )

  const handleShowDropdown = React.useCallback(
    () => setIsDropdownVisible(true),
    [],
  )
  const handleHideDropdown = React.useCallback(
    () => setIsDropdownVisible(false),
    [],
  )

  const handleChange = React.useCallback(
    (keyValue) => () => {
      handleHideDropdown()
      onChange(keyValue)
    },
    [handleHideDropdown, onChange],
  )

  return (
    <div className={clsx(className, styles.container)}>
      <div
        onClick={handleShowDropdown}
        className={clsx(styles.label, isDropdownVisible && styles.active)}
      >
        <div>{selectedNode}</div>
        <Icon name="caret-down" />
      </div>
      <ClickAway callback={handleHideDropdown}>
        <ul
          className={clsx(
            styles.dropdownContainer,
            isDropdownVisible && styles.visible,
          )}
        >
          {React.useMemo(
            () =>
              Object.keys(data).map((keyValue, i) => {
                const node = get(data, keyValue)
                return (
                  node &&
                  React.cloneElement(node as any, {
                    ...get(node, 'props'),
                    key: `dropdown_menu_${keyValue}_${i}`,
                    className: clsx(
                      get(node, 'props.className'),
                      // eslint-disable-next-line eqeqeq
                      value == keyValue && styles.selected,
                    ),
                    onClick: handleChange(keyValue),
                  })
                )
              }),
            [data, value, handleChange],
          )}
        </ul>
      </ClickAway>
    </div>
  )
}

export default React.memo(DropdownMenu)
