import map from 'lodash/map'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import keys from 'lodash/keys'
import { useCallback, useMemo, useRef } from 'react'
import { useSelector } from 'react-redux'
import { getCurrentLocale } from 'selectors/profile'
import { CURRENT_LOCALE_KEY } from '../constants'

export const languages = {
  de: import('common/i18n/resources/de.json'),
  en: import('common/i18n/resources/en.json'),
  nl: import('common/i18n/resources/nl.json'),
  fr: import('common/i18n/resources/en.json'), // set to fr.json if the translations were completed
} as { [key: string]: any }

export const getLocale = () => {
  const locale = localStorage.getItem(CURRENT_LOCALE_KEY) || languages.nl
  return keys(languages).includes(locale) && locale
}

export const getTranslations = () => {
  const locale = getLocale()
  return isEmpty(locale) ? languages.nl : languages[locale!]
}

export const translate = (keyOrKeys: string | string[]) => {
  try {
    const translations = getTranslations()

    return Array.isArray(keyOrKeys)
      ? map(keyOrKeys, (key) => translations[key] || key)
      : translations[keyOrKeys] || keyOrKeys
  } catch (e) {
    console.error(e)
    return keyOrKeys
  }
}

export const useTranslate = (ns?: string | string[]) => {
  const translations = useRef({})

  useSelector(getCurrentLocale) /* subscribe to locale changes */

  if (typeof ns === 'object') {
    translations.current = (ns as Array<string>).reduce((value, namespace) => {
      const i18nKeys = get(getTranslations(), namespace)
      const dictionary = Object.keys(i18nKeys).reduce(
        (acc, key) => ({
          ...acc,
          [`${namespace}:${key}`]: i18nKeys[key],
        }),
        {},
      )

      return {
        ...value,
        ...dictionary,
      }
    }, {}) as any
  } else if (typeof ns === 'string') {
    translations.current = get(getTranslations(), ns)
  } else {
    translations.current = getTranslations()
  }

  const interpolate = (str: any, obj: { [x: string]: any }) => {
    if (!str) return str
    const objKeys = Object.keys(obj)
    const newStr = objKeys.reduce((acc, key) => {
      const regex = new RegExp(`{{${key}}}`, 'gi')
      /* eslint-disable no-param-reassign */
      acc = acc.replace(regex, obj[key])
      return acc
    }, str)
    return newStr
  }

  const t = useCallback(
    (key: string, interpolation?: { [key: string]: string }) => {
      const str = get(translations.current, key)
      return interpolation ? interpolate(str, interpolation) : str
    },
    [translations],
  )

  return useMemo(() => ({ t }), [t])
}
