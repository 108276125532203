/* eslint-disable import/prefer-default-export, no-bitwise */

export const contrastColor = (color: string) => {
  const c = color.substr(1)
  const rgb = parseInt(c, 16)
  const r = (rgb >> 16) & 0xff
  const g = (rgb >> 8) & 0xff
  const b = (rgb >> 0) & 0xff
  const luma = 0.2126 * r + 0.7152 * g + 0.0722 * b
  if (luma > 200) {
    return '#201047'
  }
  return '#ffffff'
}

export const colours = [
  '#4D4D4D',
  '#999999',
  '#FFFFFF',
  '#F44E3B',
  '#FE9200',
  '#FCDC00',
  '#DBDF00',
  '#A4DD00',
  '#68CCCA',
  '#73D8FF',
  '#AEA1FF',
  '#FDA1FF',
  '#333333',
  '#808080',
  '#cccccc',
  '#D33115',
  '#E27300',
  '#FCC400',
  '#B0BC00',
  '#68BC00',
  '#16A5A5',
  '#009CE0',
  '#7B64FF',
  '#FA28FF',
  '#000000',
  '#666666',
  '#B3B3B3',
  '#9F0500',
  '#C45100',
  '#FB9E00',
  '#808900',
  '#194D33',
  '#0C797D',
  '#0062B1',
  '#653294',
  '#AB149E',
  '#FF7F50',
  '#40E0D0',
  '#FF4F79',
  '#4B0082',
  '#00FF7F',
  '#8B4513',
  '#FF69B4',
  '#1E90FF',
  '#FFD700',
  '#2E8B57',
]

export const getColor = (index: number) =>
  index
    ? colours[index % colours.length]
    : colours[Math.floor(Math.random() * colours.length) % colours.length]
