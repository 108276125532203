import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const styles = ({ spacing }: Theme) =>
  createStyles({
    root: {},
    container: {
      display: 'flex',
      gap: 16,
    },
    items: {
      flex: '1 0 0',
    },
  })

export default makeStyles(styles)
