/* eslint-disable import/no-cycle */

import * as Sentry from '@sentry/browser'
import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'
import { store } from 'App'
import trunkrs from 'common/utils/Sdk'

import * as actionTypes from '../constants/ActionTypes'
import * as shipmentActions from './shipments'
import * as fromProfile from '../selectors/profile'
import * as UIActions from '../actions/ui'
import * as warehouseActions from '../actions/warehouse'

import { setLoading } from './ui'
import { CURRENT_LOCALE_KEY } from '../constants'
import { toggleUserActive } from './user'
import * as driversActions from './drivers'

export const login = (payload) => async (dispatch) => {
  dispatch({
    type: actionTypes.LOGIN,
    payload,
  })

  const userSub = await trunkrs.Auth().getUserSub()
  const {
    subcos: [subcoZero],
  } = payload

  Sentry.setUser({
    id: userSub,
    username: subcoZero.name,
  })
  Sentry.setTags({
    'subco-id': subcoZero.id,
    'subco-region': subcoZero.tag,
  })
}

export const changeSubco = (payload) => async (dispatch, getState) => {
  dispatch(setLoading(true))
  dispatch({ type: actionTypes.CHANGE_SUBCO, payload })

  const subco = fromProfile.getSdkSubco(getState())
  const subcoProfile = fromProfile.getSubco(getState())
  const rawDrivers = await Promise.all(await subco.getDrivers)

  const drivers = rawDrivers.map((driver) => ({
    id: driver.id,
    name: driver.name,
    email: driver.getEmailAddress,
    phoneNumber: driver.getPhoneNumber,
    active: driver.active,
    picture: driver.picture,
    isPlanner: get(driver, 'isPlanner', false),
    isArchived: get(driver, 'isArchived', false),
    Instance: driver,
  }))
  const location = await subco.getLocation

  dispatch({ type: actionTypes.CHANGE_DRIVERS, payload: drivers })
  dispatch({ type: actionTypes.ADD_DRIVERS, payload: drivers })
  dispatch({ type: actionTypes.CHANGE_LOCATION, payload: location })

  dispatch(shipmentActions.reloadShipments())
  dispatch(warehouseActions.getBusyDay())
  dispatch(warehouseActions.getCentralSortingStatus())

  Sentry.setTags({
    'subco-id': subcoProfile.id,
    'subco-region': subcoProfile.tag,
    'used-switch': true,
  })
}

export function logoff() {
  localStorage.removeItem(CURRENT_LOCALE_KEY)
  return { type: actionTypes.LOGOFF }
}

export function setSubco(payload) {
  return { type: actionTypes.SET_SUBCO, payload }
}

export function disableMarkTourConfirmation() {
  return { type: actionTypes.DISABLE_MARK_TOUR_CONFIRMATION }
}

export function removeDriver(payload) {
  return { type: actionTypes.REMOVE_DRIVER, payload }
}

export const transferDriver = (payload) => async (dispatch) => {
  try {
    const { driverId, subCoId } = payload
    const result = await trunkrs.Driver().transferDriver(driverId, subCoId)
    if (result) {
      dispatch(removeDriver(driverId))
    }
    return result
  } catch (error) {
    Sentry.captureException(error)
  }
}

export const fetchInvoiceSubco = () => (dispatch) =>
  trunkrs
    .Subco()
    .fetchInvoiceSubco()
    .then((invoiceSubcos) =>
      dispatch({ type: actionTypes.SET_INVOICE_SUBCO, payload: invoiceSubcos }),
    )
    .catch(Sentry.captureException)

const isSubcoAction = () => ({ type: actionTypes.ROLE_IS_SUBCO })
const isNotSubcoAction = () => ({ type: actionTypes.ROLE_IS_NOT_SUBCO })
const isPlannerAction = () => ({ type: actionTypes.ROLE_IS_PLANNER })
const isNotPlannerAction = () => ({ type: actionTypes.ROLE_IS_NOT_PLANNER })

const setIsSubco = async (dispatch) => {
  const isSubco = await trunkrs.Auth().isSubco()
  if (isSubco) {
    dispatch(isSubcoAction())
  } else {
    dispatch(isNotSubcoAction())
  }
}

const setIsPlanner = async (dispatch) => {
  const isPlanner = await trunkrs.Auth().isPlanner()
  if (isPlanner) {
    dispatch(isPlannerAction())
  } else {
    dispatch(isNotPlannerAction())
  }
}

export const setRoles = () => async (dispatch) => {
  await setIsSubco(dispatch)
  await setIsPlanner(dispatch)
}

export const fetchLocale = () => async (dispatch) => {
  const locale = localStorage.getItem(CURRENT_LOCALE_KEY)
  if (isEmpty(locale)) {
    await trunkrs
      .UserPreferences()
      .fetch('locale')
      .then(({ value: locale }) => {
        localStorage.setItem(CURRENT_LOCALE_KEY, locale)
        dispatch({ type: actionTypes.SET_CURRENT_LOCALE, payload: locale })
      })
      .catch(Sentry.captureException)
  } else {
    await dispatch({ type: actionTypes.SET_CURRENT_LOCALE, payload: locale })
  }
}

export const setLocale = (locale) => (dispatch) => {
  dispatch(setLoading(true))
  trunkrs
    .UserPreferences()
    .save('locale', locale)
    .then(() => {
      localStorage.setItem(CURRENT_LOCALE_KEY, locale)
      dispatch({ type: actionTypes.SET_CURRENT_LOCALE, payload: locale })
    })
    .catch(Sentry.captureException)
    .finally(() => dispatch(setLoading(false)))
}

export const reloadDrivers = (newDriver) => async (dispatch) => {
  const driver = {
    Instance: newDriver,
    active: true,
    email: newDriver.getEmailAddress,
    id: newDriver.getId,
    name: newDriver.getName,
    phoneNumber: newDriver.getPhoneNumber,
  }

  dispatch({ type: actionTypes.UPDATE_DRIVERS, payload: driver })
}

export const getDriverLocale = (driverId) => async (dispatch) => {
  dispatch(UIActions.setLoading(true))
  const locale = await trunkrs
    .UserPreferences()
    .getDriverPreference(driverId, { key: 'locale' })
  dispatch({
    type: actionTypes.SET_USER_LOCALE,
    payload: { id: driverId, locale: locale.value },
  })
  dispatch(UIActions.setLoading(false))
  return locale
}

export const toggleDriver = (driverId, active) => async (dispatch) => {
  dispatch(toggleUserActive(driverId, active))
  const Driver = trunkrs.Driver()
  const driver = new Driver(driverId)
  if (active) {
    driver.activate()
    store.dispatch(driversActions.activateDriver({ Id: driverId }))
  } else {
    driver.deactivate()
    store.dispatch(driversActions.deactivateDriver({ Id: driverId }))
  }
}

export const setArchiveState = (user, isArchived) => async (dispatch) => {
  await trunkrs.Driver().editDriverInfo(user.id, { willArchive: isArchived })
  dispatch({
    type: actionTypes.SET_USER_ARCHIVED,
    payload: { id: user.id, isArchived },
  })
}
