import React, { ElementType } from 'react'
import clsx from 'clsx'

import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles'
import MuiTypography, {
  TypographyProps as MuiTypographyProps,
} from '@material-ui/core/Typography'
import { Colors } from 'common/models/typings/Colors'
import { Component } from 'common/models/typings/Component'

import { VariantMapper, VariantProps } from './helper'
import styles from './styles'

export interface TypographyProps extends WithStyles<typeof styles> {
  children?: React.ReactNode
  className?: string
  variant?: VariantProps
  align?: MuiTypographyProps['align']
  color?: Colors | 'inherit'
  transform?: 'capitalize' | 'uppercase' | 'lowercase' | 'none'
  component?: Component
  noWrap?: MuiTypographyProps['noWrap']
  display?: MuiTypographyProps['display']
  gutterBottom?: MuiTypographyProps['gutterBottom']
  onMouseEnter?: MuiTypographyProps['onMouseEnter']
  onMouseOut?: MuiTypographyProps['onMouseOut']
}

export const Typography: React.FC<TypographyProps> = ({
  classes,
  transform,
  component,
  variant = 'text',
  children,
  className,
  align,
  color,
  noWrap,
  display,
  gutterBottom,
}) => (
  <MuiTypography
    color="inherit"
    component={component as ElementType}
    variant={VariantMapper[variant]}
    align={align}
    className={clsx(
      classes[variant],
      color && classes[color],
      transform && classes[transform],
      className,
    )}
    noWrap={noWrap}
    display={display}
    gutterBottom={gutterBottom}
  >
    {children}
  </MuiTypography>
)

export default withStyles(styles)(Typography)
