import React from 'react'
import {useTranslation} from 'react-i18next'
import clsx from 'clsx'

import { COLORS } from 'constants/COLORS'

import styles from './toggle-button.module.css'

export interface ToggleButtonProps {
  color: 'green' | 'orange' | 'white'
  icon: {}
  active: boolean
  disabled: boolean
  label: string
  onClick: (e: React.MouseEvent<HTMLElement>) => void
}

const ToggleButton: React.FC<ToggleButtonProps> = ({ active, color, icon, label, disabled, onClick }) => {
  const {t} = useTranslation()
  const getActiveColorClass = (color: string) => {
    switch (color) {
      case 'orange':
        return styles.toggleButtonOrange
      case 'green':
        return styles.toggleButtonGreen
      case 'white':
          return styles.toggleButtonWhite
      default:
        return styles.toggleButtonGreen
    }
  }

  const getIconColor = (color: any) => {
    switch (color) {
      case 'orange':
        return COLORS.ORANGE
      case 'green':
        return COLORS.GREEN
      default:
        return COLORS.GRAY_FADED
    }
  }

  return (
    <div
      className={clsx(
        styles.toggleButton,
        active && getActiveColorClass(color),
        disabled && styles.disabled,
      )}
      onClick={disabled ? undefined : onClick}
    >
      {t(label)}
    </div>
  )
}

export default ToggleButton
