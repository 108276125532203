import React from 'react'

export default React.memo(
  ({
    children,
    callback,
  }: React.HTMLAttributes<HTMLDivElement> & { callback: () => void }) => {
    const container = React.useRef<HTMLDivElement | null>(null)

    React.useEffect(() => {
      const handleClickAway = (ev: MouseEvent) => {
        if (
          container.current &&
          !container.current.contains(ev.target as any)
        ) {
          if (typeof callback === 'function') {
            callback()
          }
        }
      }
      document.addEventListener('mousedown', handleClickAway)
      return () => document.removeEventListener('mousedown', handleClickAway)
    }, [callback, container])

    return <div ref={container}>{children}</div>
  },
)
