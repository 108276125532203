import React from 'react'
import { useTranslation } from 'react-i18next'
import { DialogContent, DialogActions, Box } from '@material-ui/core'

import Typography from 'common/components/base/Typography'
import Button from 'common/components/base/Button'

import useStyles from './useStyles'

export interface CreateToursDialogFailedProps {
  isOptimizationError?: boolean
  onReOptimize: () => void
  onClose: () => void
}

const CreateToursDialogFailed: React.FC<CreateToursDialogFailedProps> = ({
  isOptimizationError,
  onReOptimize,
  onClose,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <>
      <DialogContent>
        {isOptimizationError ? (
          <>
            <Typography>{t('OPTIMIZATION_RESULT_ERROR_DESC_1')}</Typography>
            <Typography>{t('OPTIMIZATION_RESULT_ERROR_DESC_2')}</Typography>
          </>
        ) : (
          <>
            <Typography>{t('OPTIMIZATION_RESULT_ERROR_DESC_3')}</Typography>
            <Typography>
              {t('OPTIMIZATION_RESULT_ERROR_DESC_4')}{' '}
              <a href="tel:+31850601410" className={classes.link}>
                {t('OPTIMIZATION_RESULT_ERROR_SUPPORT_TEL')}
              </a>
              .
            </Typography>
          </>
        )}
      </DialogContent>
      {isOptimizationError && (
        <DialogActions>
          <Button color="white" onClick={onReOptimize}>
            {t('OPTIMIZATION_RESULT_BUTTON_RUN_AGAIN')}
          </Button>
          <Button color="danger" onClick={onClose}>
            {t('CANCEL')}
          </Button>
        </DialogActions>
      )}
    </>
  )
}

export default React.memo(CreateToursDialogFailed)
