import { withStyles, Theme, createStyles } from '@material-ui/core/styles'
import { Spacing } from '@material-ui/core/styles/createSpacing'
import { Palette } from '@material-ui/core/styles/createPalette'

import Checkbox from '@material-ui/core/Checkbox'

import colors from 'common/theme/mui/colors'

/* For the unit tests */
const defaultSpacing: Spacing = (() => 0) as unknown as Spacing

export default withStyles(
  ({ palette = colors as Palette, spacing = defaultSpacing }: Theme) =>
    createStyles({
      root: {
        '&:hover': {
          backgroundColor: 'transparent !important',
        },
        '& svg': {
          width: spacing(3),
          height: spacing(3),
        },
      },
      checked: {
        '& svg': {
          color: palette.trunkrs.secondary.violet.base,
        },
      },
    }),
)(Checkbox)
