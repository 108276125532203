import ShipmentState from './ShipmentState'

export interface TourOverViewCollation {
  id: string
  eta: string
  state: ShipmentState
  shipment_id: string
}

export enum NETWORK_TYPE {
  DAY = 'DAY',
  NIGHT = 'NIGHT',
}

export interface TourOverview {
  tour: {
    id: string
    startTime: string
    endTime: string
    color?: string
    totalDistance?: number
  }
  collations: TourOverViewCollation[]
  driver: {
    name: string
    phoneNumber: string
    latitude: string
    latestPosition: string
    speed: string
  }
}
