import React from 'react'
import { useTranslation } from 'react-i18next'
import { Grid } from '@material-ui/core'
import clsx from 'clsx'
import { faBolt, faFire } from '@fortawesome/free-solid-svg-icons'

import { FuelType } from 'common/models/typings/FuelType'
import { ShipmentTour } from 'common/models/typings/Tour'

import ToggleButton from 'common/components/base/.raw/ToggleButton'
import Typography from 'common/components/base/Typography'

import styles from './styles.module.scss'
import { FUEL_TYPES } from 'constants/FuelTypes'

export interface SelectFuelTypeProps {
  className?: string
  vehicleType: ShipmentTour['vehicleType']
  locked: boolean
  onChangeVehicleType: (name: ShipmentTour['vehicleType']) => void
}

const SelectFuelType: React.FC<SelectFuelTypeProps> = ({
  className,
  vehicleType = 'fossil',
  locked,
  onChangeVehicleType,
}) => {
  const { t } = useTranslation()

  return (
    <Grid
      container
      direction="row"
      className={clsx(styles['vehicle-type-container'], className)}
    >
      <Typography>{t('VEHICLE_TYPE')}</Typography>
      <div>
        {React.useMemo(
          () =>
            (FUEL_TYPES as FuelType[]).map((fuelType, index) => (
              <Grid key={fuelType.name} className={styles[`grid${index}`]} item>
                <ToggleButton
                  disabled={locked}
                  key={fuelType.name}
                  label={t(fuelType.langKey)}
                  icon={fuelType.icon}
                  color={fuelType.color}
                  active={vehicleType === fuelType.name}
                  onClick={() => onChangeVehicleType(fuelType.name)}
                />
              </Grid>
            )),
          [locked, t, vehicleType, onChangeVehicleType],
        )}
      </div>
    </Grid>
  )
}

export default SelectFuelType
