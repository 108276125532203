import React from 'react'

import Lottie from '../base/Lottie'

import animationData from './animationFiles/no-created-tours.json'

const NoToursAni = () => (
  <Lottie
    preserveAspectRatio="xMidYMid slice"
    animationData={animationData}
    isAutoPlay={false}
    isLoop
  />
)
export default NoToursAni
