import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const styles = ({ spacing, palette }: Theme) =>
  createStyles({
    input: {
      height: 56,
      width: 79,
      margin: spacing(0, 1),
    },
    textInput: {
      textAlign: 'center',
      fontSize: 16,
      color: palette.trunkrs.grayscale.theBeast,
    },
    button: {
      height: 56,
      width: 56,
    },
    optimizationLabel: {
      lineHeight: '20px',
    },
  })

export default makeStyles(styles)
