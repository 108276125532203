import React from 'react'

import Lottie from '../base/Lottie'

import animationData from './animationFiles/central-sort-not-done.json'

const NetworkNotReadyAni = () => (
  <Lottie
    preserveAspectRatio="xMidYMid slice"
    animationData={animationData}
    isAutoPlay={false}
    isLoop={false}
  />
)
export default NetworkNotReadyAni
