import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box, FormControlLabel } from '@material-ui/core'

import Button from 'common/components/base/Button'
import Icon from 'common/components/base/Icon'
import Input from 'common/components/base/forms/Input'
import Checkbox from 'common/components/base/forms/Checkbox'
import Typography from 'common/components/base/Typography'

import useStyles from './useStyles'

export interface CreateToursDialogDrivers {
  shipmentCount: number
  value: number
  usePreviousOptimization: boolean
  newRoutificVersion: boolean
  onChange: (value: number) => void
  onIncrement: () => void
  onDecrement: () => void
  onToggleUsePrevious: (checked: boolean) => void
  onToggleRoutific: (checked: boolean) => void
}

const CreateToursDialogDrivers: React.FC<CreateToursDialogDrivers> = ({
  shipmentCount,
  value,
  usePreviousOptimization,
  newRoutificVersion,
  onChange,
  onIncrement,
  onDecrement,
  onToggleUsePrevious,
  onToggleRoutific,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <>
      <Box display="flex" alignItems="center" justifyContent="center" mb={5}>
        <Button color="white" onClick={onDecrement} className={classes.button}>
          <Icon name="subtract" />
        </Button>
        <Box>
          <Input
            value={value}
            onChange={(ev) => onChange(parseInt(ev.target.value))}
            type="number"
            className={classes.input}
            classes={{ input: classes.textInput }}
          />
        </Box>
        <Button color="white" onClick={onIncrement} className={classes.button}>
          <Icon name="plus" />
        </Button>
      </Box>
      <FormControlLabel
        control={
          <Checkbox
            checked={usePreviousOptimization}
            onChange={(event) => onToggleUsePrevious(event.target.checked)}
          />
        }
        label={
          <Typography variant="textBold" className={classes.optimizationLabel}>
            {t('USE_PREVIOUS_OPTIMIZATION')}
          </Typography>
        }
      />
      {shipmentCount > 500 && (
        <FormControlLabel
          control={
            <Checkbox
              checked={newRoutificVersion}
              onChange={(event) => onToggleRoutific(event.target.checked)}
            />
          }
          label={
            <Typography
              variant="textBold"
              className={classes.optimizationLabel}
            >
              {t('USE_ROUTIFIC_V2')}
            </Typography>
          }
        />
      )}
    </>
  )
}

export default React.memo(CreateToursDialogDrivers)
