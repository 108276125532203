import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const styles = ({ spacing, palette }: Theme) =>
  createStyles({
    fuelTypeSelection: {
      display: 'flex',
      flexDirection: 'row',
      gap: spacing(1),
    },
  })

export default makeStyles(styles)
