import React from 'react'

import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon'

const Snowflake: React.FC<Exclude<SvgIconProps, 'viewBox'>> = React.forwardRef(
  (props, ref) => (
    <SvgIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      ref={ref}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.99993 12L6.66248 13.9269L2.9645 12.936L2.44686 14.8679L4.21299 15.3411L1.97363 16.634L2.97363 18.366L5.21299 17.0731L4.73976 18.8393L6.67161 19.3569L7.66248 15.6589L11 13.732V17.5858L8.2929 20.2929L9.70711 21.7071L11 20.4142V23H13V20.4142L14.2929 21.7071L15.7071 20.2929L13 17.5858V13.7321L16.3374 15.6589L17.3282 19.3569L19.2601 18.8393L18.7869 17.0731L21.0262 18.366L22.0262 16.634L19.7869 15.3411L21.553 14.8678L21.0354 12.936L17.3374 13.9269L13.9999 12L17.3373 10.0731L21.0353 11.064L21.5529 9.13216L19.7868 8.65892L22.0262 7.36603L21.0262 5.63398L18.7868 6.92687L19.2601 5.16074L17.3282 4.64311L16.3373 8.34108L13 10.2679V6.41421L15.7071 3.70711L14.2929 2.29289L13 3.58579V1H11V3.58579L9.70711 2.29289L8.2929 3.70711L11 6.41421V10.268L7.66252 8.34108L6.67165 4.64311L4.7398 5.16074L5.21303 6.92687L2.97368 5.63398L1.97368 7.36603L4.21303 8.65892L2.44691 9.13215L2.96455 11.064L6.66252 10.0731L9.99993 12Z"
        fill="#220C4A"
      />
    </SvgIcon>
  ),
)

export default Snowflake
