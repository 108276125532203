import { createSelector } from 'reselect'
import { createFeatureSelector } from './core'
import * as fromGreenRegions from "../reducers/greenRegions";

export const selectFeature = createFeatureSelector('greenRegions')

export const getGreenRegions = createSelector(
  selectFeature,
  fromGreenRegions.selectGreenRegions,
)
