import React from 'react'
import { useTranslation } from 'react-i18next'

import { ReactComponent as NoTours } from 'common/assets/images/empty/no-tours.svg'
import EmptyState from '..'

import useStyles from '../useStyles'
import { NETWORK_TYPE } from '../../../../models/typings/TourOverview'
import { useSelector } from 'react-redux'
import * as fromNetworkType from '../../../../../selectors/networkType'
import { capitalize } from '../../../../../utils/capitalize'

import NoToursAni from '../../../animations/NoTours'

export interface NoShipmentsFoundProps {
  onPrimaryAction: () => void
}

const NoShipmentsFound: React.FC<NoShipmentsFoundProps> = ({
  onPrimaryAction,
}) => {
  const classes = useStyles()
  const networkType: NETWORK_TYPE = useSelector(fromNetworkType.getNetworkType)
  const { t } = useTranslation()

  return (
    <EmptyState
      illustration={<NoToursAni />}
      heading={t('SHIPMENTS_OVERVIEW.NO_TOURS_YET')}
      description={t('CREATE_AND_EDIT_TOURS_BY_NETWORK', {
        networkType:
          networkType === NETWORK_TYPE.DAY
            ? t(`NETWORK_TYPE.${NETWORK_TYPE.DAY}`).toLowerCase()
            : t(`NETWORK_TYPE.${NETWORK_TYPE.NIGHT}`).toLowerCase(),
      })}
      primaryText={t('CREATE_TOURS_BY_NETWORK', {
        networkType:
          networkType === NETWORK_TYPE.DAY
            ? capitalize(t(`NETWORK_TYPE.${NETWORK_TYPE.DAY}`))
            : capitalize(t(`NETWORK_TYPE.${NETWORK_TYPE.NIGHT}`)),
      })}
      onPrimaryAction={onPrimaryAction}
    />
  )
}

export default NoShipmentsFound
