/* eslint-disable import/no-cycle */

import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import * as fromCombined from 'selectors/combined'
import * as fromNetworkType from 'selectors/networkType'
import { reloadShipments } from 'actions/shipments'

import { AllShipmentsFilter } from 'common/models/typings/AllShipmentsFilter'
import { Shipment } from 'common/models/typings/Shipment'

import ShipmentsPercentage from 'common/components/features/ShipmentsPercentage'
import ShipmentsTab from 'common/components/features/ShipmentsTab'
import NetworkTypeHeader from '../../common/components/features/NetworkTypeHeader'

import styles from './styles.module.scss'
import { NETWORK_TYPE } from '../../common/models/typings/TourOverview'

const ShipmentsPage: React.FC = () => {
  const dispatch = useDispatch()

  const [currentFilter, setCurrentFilter] =
    React.useState<AllShipmentsFilter>('all')

  const shipmentsSortedAtRegionalHub = useSelector(
    fromCombined.getShipmentsSortedAtRegionalHub,
  )
  const shipmentsNotSortedAtRegionalHub = useSelector(
    fromCombined.getShipmentsNotSortedAtRegionalHub,
  )
  const shipmentsAcceptedByDrivers = useSelector(
    fromCombined.getShipmentsAcceptedByDrivers,
  )
  const shipmentsNotAcceptedByDrivers = useSelector(
    fromCombined.getShipmentsNotAcceptedByDrivers,
  )
  const shipmentsWithEndState = useSelector(
    fromCombined.getShipmentsWithEndState,
  )
  const shipmentsWithoutEndState = useSelector(
    fromCombined.getShipmentsWithoutEndState,
  )
  const shipmentsDeliveredToNeighbour = useSelector(
    fromCombined.getShipmentsDeliveredToNeighbour,
  )
  const shipmentsNotDelivered = useSelector(
    fromCombined.getShipmentsNotDelivered,
  )

  const networkType: NETWORK_TYPE = useSelector(fromNetworkType.getNetworkType)

  const handleReloadShipments = React.useCallback(() => {
    dispatch(reloadShipments())
  }, [dispatch])

  const handleFilterAllShipments = React.useCallback(
    () => setCurrentFilter('all'),
    [],
  )
  const handleFilterCentralSortedShipments = React.useCallback(
    () => setCurrentFilter('centralSorted'),
    [],
  )
  const handleFilterCancelledShipments = React.useCallback(
    () => setCurrentFilter('cancelled'),
    [],
  )

  const data = {
    all: useSelector(fromCombined.getOverviewShipments),
    centralSorted: useSelector(fromCombined.getShipmentsCentralSorted),
    cancelled: useSelector(fromCombined.getShipmentsCancelled),
  } as { [key: string]: Array<Shipment> }

  return (
    <>
      <div className={styles.networkHeader}>
        <NetworkTypeHeader networkType={networkType} usage="Shipments" />
      </div>
      <ShipmentsPercentage
        sortedAtRegionalHub={shipmentsSortedAtRegionalHub}
        notSortedAtRegionalHub={shipmentsNotSortedAtRegionalHub}
        acceptebByDrivers={shipmentsAcceptedByDrivers}
        notAcceptedByDrivers={shipmentsNotAcceptedByDrivers}
        withEndState={shipmentsWithEndState}
        withoutEndState={shipmentsWithoutEndState}
        deliveredToNeighbour={shipmentsDeliveredToNeighbour}
        notDelivered={shipmentsNotDelivered}
      />
      <ShipmentsTab
        filteredData={data[currentFilter]}
        currentFilter={currentFilter}
        onFilterAllShipments={handleFilterAllShipments}
        onFilterCentralSorted={handleFilterCentralSortedShipments}
        onFilterCancelled={handleFilterCancelledShipments}
        onReloadShipments={handleReloadShipments}
      />
    </>
  )
}

export default ShipmentsPage
