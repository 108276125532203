/* eslint-disable no-param-reassign */

import { normalize } from 'normalizr'
import { todoList } from '../../schema/schemas'

export const normalizeTodoList = (listData) => {
  const {
    result: identifiers,
    entities: { todoList: data },
  } = normalize(listData, [todoList])

  return [identifiers || [], data || {}]
}

export const createScanLookup = (...shipmentArrays) =>
  shipmentArrays.reduce((lookup, currentArray) => {
    currentArray
      .filter((shipment) => !shipment.todoStatus)
      .forEach((shipment) => {
        lookup[shipment.trunkrsNr] = shipment
        lookup[shipment.barcode] = shipment
      })

    return lookup
  }, {})
