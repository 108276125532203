/* eslint-disable @typescript-eslint/no-shadow */

import React, { memo, useCallback } from 'react'
import TooltipTrigger from 'react-popper-tooltip'
import { ChildrenArg, TooltipArg } from 'react-popper-tooltip/dist/types'
import clsx from 'clsx'

import styles from './styles.module.scss'

interface ITooltipProps extends React.HTMLAttributes<HTMLElement> {
  placement?: 'top' | 'right' | 'bottom' | 'left'
  text?: string | React.ReactNode
  eventTriggers?: string[]
}

function Tooltip({
  text,
  placement = 'right',
  eventTriggers = ['click', 'hover'],
  children,
  className,
}: ITooltipProps) {
  const tooltip = useCallback(
    ({
      arrowRef,
      tooltipRef,
      getArrowProps,
      getTooltipProps,
      placement,
    }: TooltipArg) => (
      <div
        {...getTooltipProps({
          ref: tooltipRef,
          className: clsx(styles.tooltipContainer, className),
        })}
      >
        <div
          {...getArrowProps({
            ref: arrowRef,
            className: clsx(styles.tooltipArrow, 'text'),
            'data-placement': placement,
          })}
        />
        {text}
      </div>
    ),
    [text, className],
  )

  const trigger = useCallback(
    ({ getTriggerProps, triggerRef }: ChildrenArg) => (
      <span
        {...getTriggerProps({
          ref: triggerRef,
          className: styles['tooltip-trigger'],
        })}
      >
        {children}
      </span>
    ),
    [children],
  )

  return (
    <TooltipTrigger
      placement={placement}
      trigger={eventTriggers as any}
      tooltip={tooltip}
    >
      {trigger}
    </TooltipTrigger>
  )
}

export default memo(Tooltip)
