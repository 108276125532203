import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const styles = ({ spacing, palette, shape }: Theme) =>
  createStyles({
    root: {
      height: spacing(1),
      borderRadius: shape.radius16,
    },
    primary: {
      backgroundColor: palette.trunkrs.grayscale.lighter,
    },
    bar: {
      backgroundColor: palette.trunkrs.secondary.violet.base,
      borderRadius: shape.radius16,
    },
    fontSizeTwelve: {
      fontSize: 12,
    },
  })

export default makeStyles(styles)
