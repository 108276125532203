import moment from 'moment'
import minBy from 'lodash/minBy'
import maxBy from 'lodash/maxBy'
import get from 'lodash/get'
import { Collation } from 'common/models/typings/Collation'
import { getTotalDuration } from 'utils/getTotalDuration'
import { defaultHandlingTime } from '../estimated-time-calc'

export const getEndTime = (
  collations: Array<Collation>,
  unitOfTime: 'm' | 'h' = 'm',
) => {
  const start = minBy(collations, 'position')
  if (start) {
    const totalDuration = getTotalDuration(collations)
    return moment(start.estimation).add(totalDuration, unitOfTime)
  }
  return undefined
}

export const getLastStopTime = (collations: Array<Collation>) => {
  const end = maxBy(collations, 'position')
  if (end) {
    const durationValue = get(end, 'durationValue', 0)
    return moment(end.estimation).subtract(
      durationValue + defaultHandlingTime,
      's',
    )
  }
  return undefined
}

export const parseTime = (timeString: string, timeFormat: string) =>
  moment(timeString, timeFormat)

export const isAfter = (time: string, format: string, after: string) =>
  parseTime(time, format).isAfter(parseTime(after, format))
