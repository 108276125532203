export const calculateTotalHoursOfTour = (
  startTime: string,
  endTime: string,
) => {
  const startTimeDate = new Date(`1970-01-01T${startTime}:00Z`)
  const endTimeDate = new Date(`1970-01-01T${endTime}:00Z`)

  const durationInMilliseconds = endTimeDate.getTime() - startTimeDate.getTime()
  return durationInMilliseconds / (1000 * 60 * 60)
}
