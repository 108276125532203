/* eslint-disable no-nested-ternary */

import * as actionTypes from '../../constants/ActionTypes'
import { createScanLookup, normalizeTodoList } from './helpers'

const initialState = {
  isLoading: false,
  hasFetched: false,
  notDelivered: {
    identifiers: [],
    data: {},
  },
  noEndState: {
    identifiers: [],
    data: {},
  },
  returnShipments: {
    identifiers: [],
    data: {},
  },
  scanLookup: {},
}

const updateShipment = (state, type, shipmentId, updated) => ({
  ...state,
  [type]: {
    ...state[type],
    data: {
      ...state[type].data,
      [shipmentId]: updated,
    },
  },
})

function todoListReducer(state = initialState, { type, payload }) {
  switch (type) {
    case actionTypes.LOAD_TODO: {
      return { ...state, isLoading: true }
    }

    case actionTypes.LOAD_TODO_SUCCESS: {
      const [notDeliveredIds, notDeliveredData] = normalizeTodoList(
        payload.notDelivered,
      )
      const [noEndStateIds, noEndStateData] = normalizeTodoList(
        payload.noEndStates,
      )
      const [returnShipmentIds, returnShipmentsData] = normalizeTodoList(
        payload.returns,
      )
      const scanLookup = createScanLookup(payload.notDelivered)

      return {
        ...state,
        isLoading: false,
        hasFetched: true,
        notDelivered: {
          identifiers: notDeliveredIds,
          data: notDeliveredData,
        },
        noEndState: {
          identifiers: noEndStateIds,
          data: noEndStateData,
        },
        returnShipments: {
          identifiers: returnShipmentIds,
          data: returnShipmentsData,
        },
        scanLookup,
      }
    }

    case actionTypes.SET_SHIPMENT_LOADING: {
      const { shipmentId, loading: isLoading } = payload
      const {
        noEndState: {
          data: { [shipmentId]: noEndStateShipment },
        },
        notDelivered: {
          data: { [shipmentId]: notDeliveredShipment },
        },
        returnShipments: {
          data: { [shipmentId]: returns },
        },
      } = state
      const shipment = noEndStateShipment || notDeliveredShipment || returns

      return updateShipment(
        state,
        noEndStateShipment
          ? 'noEndState'
          : notDeliveredShipment
          ? 'notDelivered'
          : 'returnShipments',
        shipmentId,
        { ...shipment, isLoading },
      )
    }

    case actionTypes.MARK_SHIPMENT_PROCESSED: {
      const { shipmentId, choice } = payload
      const {
        noEndState: {
          data: { [shipmentId]: noEndStateShipment },
        },
        notDelivered: {
          data: { [shipmentId]: notDeliveredShipment },
        },
        returnShipments: {
          data: { [shipmentId]: returns },
        },
        scanLookup: oldScanLookup,
      } = state
      const shipment = noEndStateShipment || notDeliveredShipment || returns
      let scanLookup = oldScanLookup

      if (notDeliveredShipment) {
        scanLookup = { ...scanLookup }
        delete scanLookup[shipment.trunkrsNr]
        delete scanLookup[shipment.barcode]
      }

      return updateShipment(
        state,
        noEndStateShipment
          ? 'noEndState'
          : notDeliveredShipment
          ? 'notDelivered'
          : 'returnShipments',
        shipmentId,
        {
          ...shipment,
          isLoading: false,
          todoStatus: choice,
        },
      )
    }

    case actionTypes.CHANGE_SUBCO: {
      return initialState
    }

    default:
      return state
  }
}

export const selectHasFetched = (state) => state.hasFetched
export const selectIsLoading = (state) => state.isLoading
export const selectNotDelivered = (state) => state.notDelivered
export const selectNoEndState = (state) => state.noEndState
export const selectScanLookup = (state) => state.scanLookup
export const selectReturnShipments = (state) => state.returnShipments

export default todoListReducer
