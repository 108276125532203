import { SET_NETWORK_TYPE } from '../constants/ActionTypes'
import get from 'lodash/get'
import { NETWORK_TYPE } from '../common/models/typings/TourOverview'

const initialState = {
  type: NETWORK_TYPE.NIGHT,
}

export default function networkType(state = initialState, action) {
  switch (action.type) {
    case SET_NETWORK_TYPE:
      return { type: action.payload }
    default:
      return state
  }
}

export const selectNetworkType = (state) => get(state, 'type')
