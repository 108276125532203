import * as React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import TableContainer from '@material-ui/core/TableContainer'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import Paper from '@material-ui/core/Paper'

import StopPosition from './StopPosition'
import classes from './TourTable.module.scss'

const TourTable = ({ stops }) => {
  const { t } = useTranslation()

  return (
    <TableContainer
      component={Paper}
      elevation={0}
      className={classes.container}
    >
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="left" width={96}>
              {t('ORDER_STOPS')}
            </TableCell>
            <TableCell align="left" width={96}>
              {t('CLIENT_TITLE')}
            </TableCell>
            <TableCell align="left" width={180}>
              {t('ADDRESS')}
            </TableCell>
            <TableCell align="left" width={96}>
              {t('POSTAL')}
            </TableCell>
            <TableCell align="left" width={96}>
              {t('CITY')}
            </TableCell>
            <TableCell align="left" width={96}>
              {t('PICKUP_TIME')}
            </TableCell>
            <TableCell align="left">{t('STOP_REMARK')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {React.useMemo(
            () =>
              stops.map((stop, index) => (
                <TableRow key={stop.id}>
                  <TableCell align="left" width={96}>
                    <StopPosition
                      position={index}
                      first={index === 0}
                      last={index === stops.length - 1}
                    />
                  </TableCell>
                  <TableCell align="left" width={96}>
                    {stop.name}
                  </TableCell>
                  <TableCell align="left" width={180}>
                    {stop.addressLine}
                  </TableCell>
                  <TableCell align="left" width={96}>
                    {stop.postal}
                  </TableCell>
                  <TableCell align="left" width={96}>
                    {stop.city}
                  </TableCell>
                  <TableCell align="left" width={96}>
                    {stop.time}
                  </TableCell>
                  <TableCell align="left" width={96}>
                    {stop.remark}
                  </TableCell>
                </TableRow>
              )),
            [stops],
          )}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

TourTable.propTypes = {
  stops: PropTypes.array,
}

TourTable.defaultProps = {
  stop: [],
}

export default TourTable
