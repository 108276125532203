import React from 'react'
import LottieEx, { LottieOptions } from 'lottie-react-web'
import { CSSProperties } from '@material-ui/styles'

export interface LottieProps {
  preserveAspectRatio?: string
  width?: number
  height?: number
  isAutoPlay?: boolean
  isLoop?: boolean
  isPaused?: boolean
  styles?: CSSProperties
  animationData: unknown
  speed?: number
  segments?: number[]
}

const Lottie: React.FC<LottieProps> = ({
  animationData,
  preserveAspectRatio,
  styles,
  isLoop,
  isPaused,
  isAutoPlay,
  speed,
  segments,
  ...props
}) => {
  const options = React.useMemo<LottieOptions>(
    () => ({
      animationData,
      rendererSettings: preserveAspectRatio
        ? { preserveAspectRatio }
        : undefined,
      autoplay: isAutoPlay,
      loop: isLoop,
    }),
    [animationData, isAutoPlay, isLoop, preserveAspectRatio],
  )

  return (
    <LottieEx
      options={options}
      {...props}
      style={styles}
      speed={speed}
      segments={segments}
      isPaused={isPaused}
    />
  )
}

export default Lottie
