import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const styles = ({ spacing }: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      height: '100%',
      flexDirection: 'column',
    },
    text: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
    subtitle: {
      textAlign: 'center',
      width: '80%',
      margin: spacing(2, 0),

      '& > span:last-of-type': {
        display: 'block',
        marginTop: 24,
      },
    },
    rocket: {
      marginTop: -200,
    },
    cards: {
      display: 'flex',
    },
  })

export default makeStyles(styles)
