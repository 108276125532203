import React from 'react'

import { useDispatch } from 'react-redux'
import { startTicker } from 'actions/intervalTasks'

const TaskTickerManager = () => {
  const dispatch = useDispatch()
  React.useEffect(() => dispatch(startTicker()), [dispatch])
  return null
}

export default TaskTickerManager
