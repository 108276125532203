import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const styles = ({ spacing, palette }: Theme) =>
  createStyles({
    '@global': {
      '.MuiAutocomplete-option[data-focus="true"]': {
        background: palette.trunkrs.secondary.violet.base,
        color: palette.common.white,
      },
    },
    root: {},
    inputRoot: {
      paddingRight: `${spacing(2)}px !important`,
      marginBottom: 0,
      '& input::placeholder': {
        fontSize: 10,
      },
    },
    inputFocused: {
      borderBottomRightRadius: 0,
      borderBottomLeftRadius: 0,
    },
    endAdornment: {
      // top: 'calc(50% - 12px)',
      // right: spacing(1),
    },
    popper: {
      top: '-2px !important',
    },
    paper: {
      marginLeft: 1,
      paddingTop: 0,
      padding: spacing(0.5),
      boxShadow: `0 0 0 2px ${palette.trunkrs.secondary.violet.base}`,
      borderTopRightRadius: 0,
      borderTopLeftRadius: 0,
      width: '100%',
    },
  })

export default makeStyles(styles)
