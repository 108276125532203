import React from 'react'
import PropTypes from 'prop-types'
import * as Sentry from '@sentry/browser'

import ErrorPage from './ErrorPage'
import { translate } from '../../../../utils/translate'

class ErrorBoundary extends React.PureComponent {
  static getDerivedStateFromError(error) {
    return {
      hasError: true,
      lastError: error,
    }
  }

  state = {
    hasError: false,
    lastError: null,
    lastReBootTime: 0,
  }

  componentDidCatch(error) {
    const { lastError, lastReBootTime } = this.state

    const msSinceLast = Number(new Date()) - lastReBootTime
    const isRebootCrash =
      msSinceLast < 1000 && error.message === lastError.message

    if (isRebootCrash) {
      Sentry.captureEvent({
        event_id: 'reboot_impossible',
        message: 'Not possible to reboot application after a crash.',
      })

      // Application must be truly rebooted
      window.location.reload()

      // Already logged this exception
      return
    }

    Sentry.captureException(error)
    Sentry.showReportDialog({
      eventId: '@SentrySdk.LastEventId',
      title: translate('FEEDBACK_TITLE'),
      subtitle: translate('FEEDBACK_SUBTITLE'),
      subtitle2: translate('FEEDBACK_SUBTITLE_2'),
      labelName: translate('FEEDBACK_LABEL_NAME'),
      labelEmail: translate('FEEDBACK_LABEL_EMAIL'),
      labelComments: translate('FEEDBACK_LABEL_COMMENTS'),
      labelClose: translate('FEEDBACK_CLOSE_BTN'),
      labelSubmit: translate('FEEDBACK_SUBMIT_BTN'),
      successMessage: translate('FEEDBACK_SUCCESS'),
    })
  }

  handleReboot = () => {
    this.setState({
      hasError: false,
      lastRebootTime: Number(new Date()),
    })
  }

  render() {
    const {
      props: { children },
      state: { hasError, lastReBootTime },
    } = this

    if (hasError) {
      return <ErrorPage onReboot={this.handleReboot} />
    }

    return <React.Fragment key={lastReBootTime}>{children}</React.Fragment>
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
}

export default ErrorBoundary
