import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from '@material-ui/core'
import clsx from 'clsx'
import moment from 'moment'

import { isValidCollation } from 'actions/tours/helpers'

import { Collation } from 'common/models/typings/Collation'

import Typography from 'common/components/base/Typography'

import useStyles from './useStyles'

export interface TourPlanningDialogOverviewTourTableProps {
  collations: Collation[]
}

const TourPlanningDialogOverviewTourTable: React.FC<
  TourPlanningDialogOverviewTourTableProps
> = ({ collations }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const filteredCollations = React.useMemo(
    () => collations.filter(isValidCollation as any),
    [collations],
  )

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>{t('LITE_TOUR_STOP_TABLE_STOP')}</TableCell>
          <TableCell>{t('LITE_TOUR_STOP_TABLE_STREET')}</TableCell>
          <TableCell>{t('LITE_TOUR_STOP_TABLE_POSTAL_CODE')}</TableCell>
          <TableCell>{t('LITE_TOUR_STOP_TABLE_CITY')}</TableCell>
          <TableCell>{t('LITE_TOUR_STOP_TABLE_COLLI_COUNT')}</TableCell>
          <TableCell>{t('LITE_TOUR_STOP_TABLE_ETA')}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {React.useMemo(() => {
          return filteredCollations.map((collation, index) => (
            <TableRow key={collation.id}>
              <TableCell
                className={clsx({
                  [classes.lastCell]: index + 1 === filteredCollations.length,
                })}
              >
                <Typography variant="button">{index}</Typography>
              </TableCell>
              <TableCell
                className={clsx({
                  [classes.lastCell]: index + 1 === filteredCollations.length,
                })}
              >
                <Typography variant="button">
                  {collation.recipient.location.address}
                </Typography>
              </TableCell>
              <TableCell
                className={clsx({
                  [classes.lastCell]: index + 1 === filteredCollations.length,
                })}
              >
                <Typography variant="button">
                  {collation.recipient.location.postalCode}
                </Typography>
              </TableCell>
              <TableCell
                className={clsx({
                  [classes.lastCell]: index + 1 === filteredCollations.length,
                })}
              >
                <Typography variant="button">
                  {collation.recipient.location.city}
                </Typography>
              </TableCell>
              <TableCell
                className={clsx({
                  [classes.lastCell]: index + 1 === filteredCollations.length,
                })}
              >
                <Typography variant="button">
                  {collation.isMultiColli ? 1 : 0}
                </Typography>
              </TableCell>
              <TableCell
                className={clsx({
                  [classes.lastCell]: index + 1 === filteredCollations.length,
                })}
              >
                <Typography variant="button">
                  {moment(collation.estimation).format('HH:mm')}
                </Typography>
              </TableCell>
            </TableRow>
          ))
        }, [classes.lastCell, filteredCollations])}
      </TableBody>
    </Table>
  )
}

export default TourPlanningDialogOverviewTourTable
