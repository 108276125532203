import React from 'react'

import Lottie from '../base/Lottie'

import animationData from './animationFiles/rocket-launch.json'

interface RocketLaunchProps {
  width: number
  height: number
  speed?: number
}

const RocketLaunch: React.FC<RocketLaunchProps> = ({
  width,
  height,
  speed,
}) => (
  <Lottie
    preserveAspectRatio="xMidYMid slice"
    animationData={animationData}
    width={width}
    height={height}
    isAutoPlay={false}
    isLoop={false}
    speed={speed}
  />
)
export default RocketLaunch
