import React, { useCallback } from 'react'
import {
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import DriverVehicleField from '../DriverVehicleField'
import Typography from 'common/components/base/Typography'
import Button from 'common/components/base/Button'
import { FUEL_TYPES } from 'constants/FuelTypes'
import { FuelType } from 'common/models/typings/FuelType'

import { Tour } from 'common/models/typings/Tour'
import { Driver } from 'common/models/typings/Driver'
import isEqual from 'lodash/isEqual'

import * as fromDrivers from 'selectors/driver'
import { useSelector } from 'react-redux'
import useStyles from './useStyles'

export interface WarningMessageProps {
  tour: Tour
  originalTour: Tour | null
  onClose: (tour?: Tour) => void
  onStartSelect: () => void
  onSave: () => void
}

export const WarningMessage: React.FC<WarningMessageProps> = ({
  tour,
  originalTour,
  onClose,
  onStartSelect,
  onSave,
}) => {
  const drivers: Driver[] = useSelector(fromDrivers.getDrivers)
  const { t } = useTranslation()

  const classes = useStyles()

  const currentDriver = drivers.find((driver) => driver.id === tour.driverId)

  return (
    <>
      <DialogTitle disableTypography>
        <Typography variant="h3" color="indigo">
          {t('HANDOVER_CHANGE_DRIVER_FOR_CART', { cart: tour.number })}
        </Typography>
        <Typography variant="text" color="theBeast">
          {t('HANDOVER_CURRENT_DRIVER', {
            driver: currentDriver ? currentDriver.name : '',
          })}
        </Typography>
      </DialogTitle>
      <DialogContent style={{ overflowY: 'visible' }}>
        <Typography variant="button">{t('CAUTION_CHANGE_DRIVER')}</Typography>
        <Box height={24} />
        <Box position="relative" height={47} maxHeight={47} width="100%">
          <DriverVehicleField
            onStartSelect={onStartSelect}
            value={{
              driverName: currentDriver?.name,
              vehicleType: (FUEL_TYPES as FuelType[]).find(
                (fuelType) => fuelType.name === tour.vehicleType,
              ),
            }}
          />
        </Box>
      </DialogContent>
      <DialogActions className={classes.actionsContainer}>
        <Button
          disabled={isEqual(tour, originalTour) || tour.driverId === -1}
          onClick={onSave}
        >
          {t('CHANGE_DRIVER')}
        </Button>
        <Button variant="flat" color="white" onClick={() => onClose()}>
          {t('GO_BACK')}
        </Button>
      </DialogActions>
    </>
  )
}

export default WarningMessage
