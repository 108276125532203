import React from 'react'
import { useTranslation } from 'react-i18next'
import { AutocompleteRenderInputParams } from '@material-ui/lab/Autocomplete'

import { ShipmentTour } from 'common/models/typings/Tour'

import Input from 'common/components/base/forms/Input'
import VehicleBadge from './VehicleBadge'

import useStyles from './useStyles'

export interface CustomInputProps extends AutocompleteRenderInputParams {
  disabled: boolean
  vehicleType: ShipmentTour['vehicleType']
}

const CustomInput: React.FC<CustomInputProps> = ({
  disabled,
  vehicleType,
  ...others
}) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const { size, InputProps, InputLabelProps, inputProps, ...params } = others
  const { ref, className } = InputProps

  return (
    <div style={{ position: 'relative' }} ref={ref}>
      <Input
        {...params}
        fullWidth
        size="medium"
        placeholder={`${t('SELECT_DRIVER')}...`}
        inputRef={ref}
        className={className}
        classes={{
          root: classes.inputRoot,
          focused: classes.inputFocused,
        }}
        endAdornment={
          <VehicleBadge disabled={disabled} vehicleType={vehicleType} />
        }
        inputProps={{ ...inputProps, tabIndex: -1 }}
      />
    </div>
  )
}

export default CustomInput
