/* eslint-disable import/no-cycle */

import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box } from '@material-ui/core'
import { AuditLog } from 'Trunkrs-SDK/dist/models'
import get from 'lodash/get'
import map from 'lodash/map'

import { useDispatch, useSelector } from 'react-redux'
import * as shipmentsAction from 'actions/shipments'
import * as tourActions from 'actions/tours'
import * as UIActions from 'actions/ui'
import * as fromShipments from 'selectors/shipments'
import * as fromProfile from 'selectors/profile'
import * as fromTour from 'selectors/tour'
import * as fromCombined from 'selectors/combined'

import { Tour } from 'common/models/typings/Tour'
import { Driver } from 'common/models/typings/Driver'

import trunkrs, { responseHandler } from 'common/utils/Sdk'

import Typography from 'common/components/base/Typography'
import ScanInput from 'common/components/base/forms/ScanInput'
import Button from 'common/components/base/Button'
import Icon from 'common/components/base/Icon'
// eslint-disable-next-line import/no-cycle
import HandoverLocked from 'common/components/features/Handover/Locked'
import HandoverCart from 'common/components/features/Handover/Cart'

import HandoverDialogResult from 'common/components/features/Handover/Dialog/Result'
import HandoverDialogPrint from 'common/components/features/Handover/Dialog/Print'
import HandoverDialogChangeDriver from 'common/components/features/Handover/Dialog/ChangeDriver'
import * as fromNetworkType from 'selectors/networkType'
import useStyles from './useStyles'
import { FuelName, FuelType } from 'common/models/typings/FuelType'

const HandoverPage = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const scanInputRef = React.useRef<HTMLInputElement>()
  const shipments = useSelector(fromShipments.getData)
  const barcodes = useSelector(fromShipments.getHandoverShipments)
  const subcoCurrentRegion = useSelector(fromProfile.getSubcoRegion)
  const subcoId = useSelector(fromProfile.getCurrentSubcoId)
  const tours: Tour[] = useSelector(fromCombined.getShipmentToursHandovered)
  const drivers: Record<string, Driver> = useSelector(
    (state: any) => state.drivers,
  )
  const toursPlanned = useSelector(fromCombined.areToursPlanned)

  const [value, setValue] = React.useState<string>('')
  const [lastShipment, setLastShipment] = React.useState<any>(null)
  const [isResultOpen, setIsResultOpen] = React.useState<boolean>(false)
  const [isError, setIsError] = React.useState<boolean>(false)
  const [isPrintOpen, setIsPrintOpen] = React.useState<boolean>(false)

  const [activeTour, setActiveTour] = React.useState<Tour | null>(null)
  const [isChangeDriver, setIsChangeDriver] = React.useState<boolean>(false)
  const networkType = useSelector(fromNetworkType.getNetworkType)

  const addMissorted = React.useCallback(async () => {
    dispatch(UIActions.setLoading(true))
    const [error, shipmentInstance] = await responseHandler(
      trunkrs.Shipment().fetchByBarcode(value),
    )

    if (error) {
      AuditLog.logShipmentByBarcode(value)
    }

    if (shipmentInstance) {
      shipmentInstance.isMissorted(subcoCurrentRegion, subcoId)
    }

    dispatch(UIActions.setLoading(false))
  }, [dispatch, subcoCurrentRegion, subcoId, value])

  const handleToggleDialog = React.useCallback((open?: boolean) => {
    if (open !== undefined) {
      setIsResultOpen(open)
    } else {
      setIsResultOpen((prev) => !prev)
    }
  }, [])

  const handleLockTours = React.useCallback(() => {
    dispatch(tourActions.lockTours(networkType))
  }, [dispatch, networkType])

  const handleHandover = React.useCallback(
    (shipmentId: number) => {
      dispatch(shipmentsAction.handOverShipment(shipmentId))
    },
    [dispatch],
  )

  const handleScan = React.useCallback(
    async (event: React.KeyboardEvent<HTMLDivElement>) => {
      if (event.key === 'Enter' && value) {
        // close dialog if open
        handleToggleDialog(false)

        event.preventDefault()
        const shipmentId = barcodes[value]

        if (!shipmentId) {
          await addMissorted()
          setIsError(true)
        } else {
          const shipment = shipments[shipmentId]
          const tour = tours.find((tr) => tr.id === shipment.tour.id)

          if (!tour) {
            setIsError(true)
          } else {
            const driver = drivers[tour.driverId]
            const position = get(shipments, `${shipmentId}.tour.position`)
            setIsError(false)
            setLastShipment({
              ...shipment,
              position,
              tour,
              driver,
            })
            handleHandover(shipmentId)
          }
        }
        setValue('')
        handleToggleDialog()
      }
    },
    [
      addMissorted,
      barcodes,
      drivers,
      handleHandover,
      handleToggleDialog,
      shipments,
      tours,
      value,
    ],
  )

  const handleToggleChangeDriver = React.useCallback(
    (tour?: Tour) => {
      if (isChangeDriver) {
        setActiveTour(null)
      } else if (tour) {
        setActiveTour(tour)
      }
      setIsChangeDriver((prev) => !prev)
    },
    [isChangeDriver],
  )

  const handleTogglePrinting = React.useCallback(() => {
    setIsPrintOpen((prev) => !prev)
  }, [])

  const handleChangeDriver = React.useCallback(
    (driverId: number | null, vehicleType: FuelName) => {
      setActiveTour((prev) =>
        prev !== null
          ? { ...prev, driverId: driverId || -1, vehicleType }
          : null,
      )
    },
    [],
  )

  const handleSave = React.useCallback(async () => {
    if (activeTour) {
      await dispatch(
        tourActions.saveDriverToTour(
          activeTour.id,
          activeTour.driverId,
          activeTour.vehicleType,
        ),
      )
      handleToggleChangeDriver()
    }
  }, [activeTour, dispatch, handleToggleChangeDriver])

  const carts = React.useMemo(
    () =>
      map(
        tours,
        (tour, index) =>
          tour.driverId &&
          drivers && (
            <HandoverCart
              key={tour.id}
              index={Number(index)}
              tour={tour}
              driver={drivers[tour.driverId]}
              onHandover={handleHandover}
              onToggleChangeDriver={() => handleToggleChangeDriver(tour)}
            />
          ),
      ),
    [drivers, handleHandover, handleToggleChangeDriver, tours],
  )

  if (!toursPlanned) {
    return <HandoverLocked onLockTours={handleLockTours} />
  }

  return (
    <>
      <Box>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box>
            <Typography variant="h4" transform="none">
              {t('READY_HANDOVER')}
            </Typography>
            <Typography color="theBeast"> {t('START_SCANNING')}</Typography>
          </Box>
          <Button
            color="white"
            className={classes.button}
            startIcon={<Icon name="print" fill="currentColor" />}
            onClick={handleTogglePrinting}
          >
            {t('EXPORT')}
          </Button>
        </Box>
        <Box height={32} />
        <ScanInput
          fullWidth
          size="large"
          placeholder={t('BARCODE')}
          value={value}
          onChange={(ev) => setValue(ev.target.value)}
          onKeyPress={handleScan}
          inputRef={scanInputRef}
        />
        <Box height={24} />
        <Box className={classes.cartContainer}>{carts}</Box>
      </Box>
      <HandoverDialogResult
        shipment={lastShipment}
        error={isError}
        open={isResultOpen}
        onClose={handleToggleDialog}
        TransitionProps={{ onEntered: () => scanInputRef.current?.focus() }}
      />
      <HandoverDialogPrint open={isPrintOpen} onClose={handleTogglePrinting} />
      {activeTour && (
        <HandoverDialogChangeDriver
          open={isChangeDriver}
          tour={activeTour}
          onChangeDriver={handleChangeDriver}
          onSave={handleSave}
          onClose={handleToggleChangeDriver}
        />
      )}
    </>
  )
}

export default HandoverPage
