import React from 'react'

import Input, { InputProps } from 'common/components/base/forms/Input'

import { ReactComponent as BarcodeIcon } from 'common/assets/images/barcode-icon.svg'

export type ScanInputProps = Omit<InputProps, 'classes'>

const ScanInput: React.FC<ScanInputProps> = (props) => {
  return <Input autoFocus startAdornment={<BarcodeIcon />} {...props} />
}
export default React.memo(ScanInput)
