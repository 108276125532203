import React from 'react'
import { useTranslation } from 'react-i18next'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faThumbsDown } from '@fortawesome/free-solid-svg-icons'

import Button from 'common/components/base/Button'

import styles from './ErrorPage.module.scss'

export interface ErrorPageProps {
  onReboot: () => void
}

const ErrorPage: React.FC<ErrorPageProps> = ({ onReboot }) => {
  const { t } = useTranslation()

  return (
    <div className={styles.container}>
      <div className={styles.box}>
        <FontAwesomeIcon icon={faThumbsDown} className={styles.icon} />
        <h1 className={styles.title}>{t('ERROR_PAGE_TITLE')}</h1>
        <p className={styles.message}>{t('ERROR_PAGE_MESSAGE')}</p>

        <Button color="green" onClick={onReboot}>
          {t('RELOAD_APP')}
        </Button>
      </div>
    </div>
  )
}

export default ErrorPage
