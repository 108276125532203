import get from 'lodash/get'
import { createSelector } from 'reselect'

import { createFeatureSelector } from './core'
import * as fromUI from '../reducers/ui'
import moment from 'moment'
import { getSubco } from './profile'
import { getNetworkType } from './networkType'
import { NETWORK_TYPE } from "../common/models/typings/TourOverview";

const selectFeature = createFeatureSelector('ui')

export const isLoading = createSelector(
  selectFeature,
  fromUI.selectIsLoading,
)
export const getLoadMessage = createSelector(
  selectFeature,
  fromUI.selectLoadMessage,
)
export const getAppVersion = createSelector(
  selectFeature,
  fromUI.selectAppVersion,
)
export const getSelectedCollations = createSelector(
  selectFeature,
  fromUI.selectSelectedCollations,
)
export const isOptimizeModalShown = createSelector(
  selectFeature,
  fromUI.selectOptimizeModalShown,
)


export const getVrpOptions = createSelector(
  [selectFeature, getSubco, getNetworkType],
  (ui, subco, networkType) => ({
    trafficSpeed: `SPEED_${get(ui, 'traffic', 'normal').toUpperCase()}`,
    minimumVisitsPerVehicle: 1,
    minimumAmountOfVehicles: false,
    maxAmountOfVehicles: Number(get(ui, 'number_of_drivers', 5)),
    subcoStartTime: get(subco, networkType === NETWORK_TYPE.DAY ? 'defaultDayStartTime' : 'defaultStartTime', new moment()),
    usePreviousOptimization: get(ui, 'use_previous_optimization', false),
    version: get(ui, 'new_routific_version') ? 2 : 1,
  }),
)

export const getSidebarCollapsed = createSelector(
  selectFeature,
  fromUI.selectSidebarCollapsed,
)

export const getHasOptimizationError = createSelector(
  selectFeature,
  fromUI.selectHasOptimzationError,
)

export const getMode = createSelector(
  selectFeature,
  fromUI.selectPowerMode,
)

export const getOptimizeResult = createSelector(
  selectFeature,
  fromUI.selectOptimizeResult,
)

export const getIsAuthenticated = createSelector(
  selectFeature,
  fromUI.selectIsAuthenticated
)

export const getIsShipmentsFetching = createSelector(
  selectFeature,
  fromUI.selectIsShipmentsFetching
)
