import Input from 'common/components/base/forms/Input'
import ArrowRight from '@material-ui/icons/ArrowRight'
import React from 'react'

import useStyles from './useStyles'
import { FuelType } from 'common/models/typings/FuelType'
import { useTranslation } from 'react-i18next'
import ToggleButton from 'common/components/base/.raw/ToggleButton'

interface SelectedDriverAndVehicle {
  driverName?: string
  vehicleType?: FuelType
}

export interface DriverVehicleFieldProps {
  value?: SelectedDriverAndVehicle
  onStartSelect: () => void
}

export const DriverVehicleField: React.FC<DriverVehicleFieldProps> = ({
  value,
  onStartSelect,
}) => {
  const { t } = useTranslation()
  const classes = useStyles({ badgeColor: value?.vehicleType?.color })

  return (
    <Input
      onClick={onStartSelect}
      value={value?.driverName}
      placeholder="Select driver and vehicle type..."
      classes={{
        root: classes.root,
        input: classes.input,
      }}
      endAdornment={
        value?.vehicleType ? (
          <div className={classes.adornment}>
            {/* <div className={classes.badge}>
              <Typography variant="text" className={classes.label}>
                {t(`${value.vehicleType.name.toUpperCase()}`)}
              </Typography>
            </div> */}
            <ToggleButton
              key={value.vehicleType.name}
              label={t(value.vehicleType.langKey)}
              icon={value.vehicleType.icon}
              color={value.vehicleType.color}
              active
              disabled={false}
              onClick={onStartSelect}
            />
            <ArrowRight />
          </div>
        ) : (
          <ArrowRight />
        )
      }
    />
  )
}

export default DriverVehicleField
