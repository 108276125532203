import createStyles from '@material-ui/core/styles/createStyles'
import { Theme } from '@material-ui/core/styles'

const styles = ({ palette, shape, spacing, transitions }: Theme) => {
  const placeholder = {
    color: palette.trunkrs.grayscale.theBeast,
  }

  return createStyles({
    root: {
      '--box-shadow-color': palette.trunkrs.grayscale.base,
      '--box-shadow-size': '1px',
      boxShadow: '0 0 0 var(--box-shadow-size) var(--box-shadow-color)',
      borderRadius: shape.borderRadius,
      backgroundColor: palette.common.white,
      padding: spacing(0, 2),
      transition: transitions.create(
        ['background-color', 'color', 'border-color', 'box-shadow'],
        {
          duration: transitions.duration.standard,
          easing: transitions.easing.easeOut,
        },
      ),
      '&:hover:not($disabled)': {
        '--box-shadow-color': palette.trunkrs.secondary.lightViolet.lightest,
        '--box-shadow-size': '2px',
      },
      '&$focused:not($disabled)': {
        '--box-shadow-color': palette.trunkrs.secondary.violet.base,
        '--box-shadow-size': '2px',
      },
      marginBottom: spacing(1),
      '& input::placeholder': placeholder,
      '& input::-webkit-input-placeholder': placeholder,
      '& input::-moz-placeholder': placeholder, // Firefox 19+
      '& input:-ms-input-placeholder': placeholder, // IE 11
      '& input::-ms-input-placeholder': placeholder, // Edge
      '& input': {
        lineHeight: spacing(2),
      },
    },
    input: {
      padding: 0,
    },
    focused: {},

    // disabled
    disabled: {
      color: palette.trunkrs.grayscale.base,
      backgroundColor: palette.trunkrs.grayscale.purgatory,
      '& svg': {
        color: palette.trunkrs.grayscale.darker,
      },
    },

    // input adornment
    inputAdornedStart: {
      color: palette.trunkrs.grayscale.theBeast,
      '& svg:first-child': {
        fontSize: 24,
        marginRight: spacing(1),
      },
    },

    inputAdornedEnd: {
      color: palette.trunkrs.grayscale.theBeast,
      '& svg:not(:first-child)': {
        fontSize: 24,
        marginLeft: spacing(1),
      },
    },

    // colors
    default: {},
    success: {
      '--box-shadow-color': palette.trunkrs.primary.green.base,
      '--box-shadow-size': '2px',
    },
    warning: {
      '--box-shadow-color': palette.trunkrs.intent.orange.base,
      '--box-shadow-size': '2px',
    },
    error: {
      '--box-shadow-color': palette.trunkrs.intent.red.base,
      '--box-shadow-size': '2px',
    },

    // sizes
    medium: {
      height: spacing(5),
    },
    large: {
      height: spacing(6.75),
    },

    // input variants
    normal: {},
    prepend: {
      paddingLeft: 0,
    },
    prependIconContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
      width: spacing(7),
      fontSize: 24,
      marginRight: spacing(1),
      borderRight: `solid 1px ${palette.trunkrs.grayscale.base}`,
      backgroundColor: palette.trunkrs.grayscale.purgatory,
      borderTopLeftRadius: shape.borderRadius,
      borderBottomLeftRadius: shape.borderRadius,
      color: palette.trunkrs.primary.indigo.base,
      '& > svg': {
        marginRight: '0px !important',
      },
    },
    multiline: {
      padding: spacing(1),
      height: 'unset',
    },
  })
}

export default styles
