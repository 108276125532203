import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  Card,
  Box,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Collapse,
  Tooltip,
  Badge,
} from '@material-ui/core'
import clsx from 'clsx'
import moment from 'moment'

import { TourOverview } from 'common/models/typings/TourOverview'
import ShipmentState from 'common/models/typings/ShipmentState'

import { getInitial } from 'common/utils/String'
import { contrastColor } from 'common/utils/Color'

import Typography from 'common/components/base/Typography'
import Button from 'common/components/base/Button'
import Icon from 'common/components/base/Icon'

import useStyles from './useStyles'

const TourLegends: React.FC<TourOverview> = ({ tour, collations, driver }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const [isCollapsed, setIsCollapsed] = React.useState<boolean>(false)

  const getClassForShipment = React.useCallback(
    (state: ShipmentState) => {
      switch (state) {
        case ShipmentState.DATA_PROCESSED:
          return classes.notScanned
        case ShipmentState.SHIPMENT_SORTED:
          return classes.notScanned
        case ShipmentState.SHIPMENT_ACCEPTED_BY_DRIVER:
          return classes.inTransit
        case ShipmentState.SHIPMENT_DELIVERED:
          return classes.delivered
        case ShipmentState.SHIPMENT_DELIVERED_TO_NEIGHBOR:
          return classes.deliveredToNeighbour
        case ShipmentState.SHIPMENT_NOT_DELIVERED:
          return classes.notDelivered
        case ShipmentState.EXCEPTION_SHIPMENT_INVESTIGATE:
          return classes.notDelivered
        case ShipmentState.RETURN_SHIPMENT_TO_SENDER:
          return classes.notDelivered
        default:
          return ''
      }
    },
    [
      classes.delivered,
      classes.deliveredToNeighbour,
      classes.inTransit,
      classes.notDelivered,
      classes.notScanned,
    ],
  )

  const handleShowMore = React.useCallback(() => {
    setIsCollapsed((prev) => !prev)
  }, [])

  const totalDistanceInKM = ((tour.totalDistance || 0) / 1000).toFixed(1)

  return (
    <Card className={classes.root}>
      <Box className={classes.toolbar}>
        <Typography variant="h4" transform="capitalize">
          {t('TOUR')} {tour.id}
        </Typography>
        <Box display="flex" alignItems="center">
          <Box>
            <Typography align="right">
              {t('TOTAL_DISTANCE')}: {totalDistanceInKM} km
            </Typography>
          </Box>
          <Box paddingX={2}>
            <Typography align="right">
              {t('SPEED')}: {driver.speed} km/h
            </Typography>
          </Box>
          {!!driver.latitude && (
            <Button
              color="white"
              variant="flat"
              target="_blank"
              rel="noopener noreferrer"
              href={driver.latestPosition}
              startIcon={<Icon name="external-link" />}
            >
              Show on maps
            </Button>
          )}
          <Box />
        </Box>
      </Box>
      <Box className={classes.avatar}>
        <List disablePadding>
          <ListItem disableGutters style={{ padding: 0 }}>
            <ListItemAvatar>
              <Avatar
                style={{
                  fontSize: '14px',
                  backgroundColor: tour.color,
                  color: contrastColor(tour.color || '#000000'),
                }}
              >
                {getInitial(driver.name)}
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={driver.name}
              secondary={
                <a href={`tel:${driver.phoneNumber}`}>{driver.phoneNumber}</a>
              }
              classes={{ root: classes.itemText, secondary: classes.avatarNo }}
            />
          </ListItem>
        </List>
      </Box>
      <Box className={classes.tourLegends}>
        <Box paddingRight={2}>
          <Typography variant="textBold" className={classes.timeText}>
            {tour.startTime}
          </Typography>
        </Box>

        <Collapse
          in={isCollapsed}
          collapsedSize={48}
          classes={{
            root: classes.collapsibleRoot,
            wrapperInner: classes.collapsibleInner,
          }}
        >
          <Box className={classes.legends}>
            {React.useMemo(
              () =>
                collations.map((collation, index) => (
                  <Tooltip
                    key={collation.id}
                    title={`ETA: ${moment(collation.eta).format('HH:mm')}`}
                    placement="bottom"
                    style={{ width: 24 }}
                  >
                    <Badge
                      overlap="circular"
                      badgeContent=" "
                      classes={{
                        badge: clsx(
                          classes.badge,
                          getClassForShipment(collation.state),
                        ),
                      }}
                    >
                      <Box
                        className={clsx(classes.stop)}
                        key={collation.shipment_id}
                      >
                        {index + 1}
                      </Box>
                    </Badge>
                  </Tooltip>
                )),
              [classes.badge, classes.stop, collations, getClassForShipment],
            )}
          </Box>
        </Collapse>
        <Box paddingLeft={2}>
          <Typography variant="textBold" className={classes.timeText}>
            {tour.endTime}
          </Typography>
        </Box>
      </Box>
      {collations.length > 15 ? (
        <>
          <Box height={8} />
          <Box display="flex" alignItems="center" justifyContent="center">
            <Button
              variant="flat"
              color="white"
              startIcon={
                <Icon
                  name={isCollapsed ? 'caret-up' : 'caret-down'}
                  fill="currentColor"
                />
              }
              onClick={handleShowMore}
            >
              {t(isCollapsed ? 'SEE_LESS_STOPS' : 'SEE_MORE_STOPS')}
            </Button>
          </Box>
        </>
      ) : (
        <Box height={24} />
      )}
    </Card>
  )
}

export default TourLegends
