import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const styles = ({ spacing }: Theme) =>
  createStyles({
    root: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      gap: '32px',
    },
    cards: {
      display: 'flex',
      flexDirection: 'row',
      gap: '24px',
    },
    card: {
      maxWidth: '564px',
      width: '100%',
      padding: spacing(2),
    },
    headerContainer: {
      display: 'flex',
      alignItems: 'center',
      padding: spacing(2, 0),
    },
    iconText: {
      fontSize: 32,
      lineHeight: '48px',
      marginRight: 16,
    },
    sub: {
      fontSize: 12,
    },
    lockButton: {
      width: 'fit-content',
    },
  })

export default makeStyles(styles)
