import moment from 'moment'
import { Collation } from 'common/models/typings/Collation'
import { getLastStopTime } from './getEndTime'
import { NETWORK_TYPE } from '../common/models/typings/TourOverview'

export const isPastDeadLine = (
  collations: Array<Collation>,
  networkType: NETWORK_TYPE,
) => {
  const calculateEndTime = getLastStopTime(collations)
  return (
    calculateEndTime &&
    calculateEndTime.isAfter(
      moment({ h: networkType === NETWORK_TYPE.DAY ? 16 : 22 }),
    )
  )
}
