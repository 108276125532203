import * as React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import Typography from 'common/components/base/Typography'

const StopPosition = ({ position, first, last }) => {
  const { t } = useTranslation()

  if (first) {
    return (
      <Typography component="b" transform="capitalize">
        {t('START')}
      </Typography>
    )
  }
  if (last) {
    return (
      <Typography component="b" transform="capitalize">
        {t('END')}
      </Typography>
    )
  }

  return <p>{position}</p>
}

StopPosition.propTypes = {
  position: PropTypes.number,
  first: PropTypes.bool,
  last: PropTypes.bool,
}

StopPosition.defaultProps = {
  position: 0,
  first: false,
  last: false,
}

export default StopPosition
