/* eslint-disable no-shadow, @typescript-eslint/no-shadow */
import { createSelector } from 'reselect'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'

import { HubInventory } from 'common/models/typings/HubInventory'

import { createFeatureSelector } from './core'

/* eslint-disable import/no-cycle */
import { IHubInventory, IReturnedShipments, ITYPES } from '../reducers/hublogs'

export const selectFeature = createFeatureSelector('hubLogs')

export const getReturnedShipments = createSelector(
  selectFeature,
  (state) => state.returnedShipments,
)

export const getHubInventory = createSelector(
  selectFeature,
  (state) => state.inventory as Array<HubInventory>,
)

export const selectIsBatchOutDone = createSelector(
  selectFeature,
  (state) => state.isBatchHubOutDone,
)

export const getShipmentsAtRegionalHub = createSelector(
  getHubInventory,
  (inventory: Array<HubInventory>) =>
    inventory.filter((shipment) => !shipment.type),
)

export const getHubInventoryScanList = createSelector(
  getHubInventory,
  (inventory: Array<HubInventory>) =>
    inventory.reduce((acc, shipment) => {
      Object.assign(acc, {
        [shipment.barcode.trim()]: shipment,
        [shipment.trunkrsNr.trim()]: shipment,
      })
      return acc
    }, {} as any),
)

export const isShipmentReturnedExistOnInventory = createSelector(
  getHubInventory,
  getReturnedShipments,
  (
    inventory: Array<HubInventory>,
    returnedShipments: Array<IReturnedShipments>,
  ) => {
    const shipmentsShippedBack = returnedShipments.filter(
      (shipment) => shipment.type === ITYPES.BACK_TO_TRUNKRS,
    )
    if (isEmpty(shipmentsShippedBack)) {
      return true
    }
    return !!inventory.find(
      (s) =>
        s.shipmentId === get(shipmentsShippedBack, '[0].shipmentId', undefined),
    )
  },
)

export const getShipmentsToKeepOnHub = createSelector(
  getHubInventory as any,
  getReturnedShipments,
  getHubInventoryScanList,
  isShipmentReturnedExistOnInventory,
  (
    inventory: Array<HubInventory>,
    returnedShipments: Array<IReturnedShipments>,
    getHubInventoryScanList,
    isShipmentReturnedExistOnInventory,
  ) => {
    if (isShipmentReturnedExistOnInventory) {
      return inventory.filter(
        (shipment) => shipment.type === ITYPES.KEEP_IN_REGION,
      )
    }
    return returnedShipments.filter(
      (shipment) =>
        shipment.type === ITYPES.KEEP_IN_REGION &&
        !getHubInventoryScanList[shipment.shipmentId],
    ) as HubInventory[]
  },
)

export const getShipmentsBackToTrunkrs = createSelector(
  getHubInventory as any,
  getReturnedShipments,
  getHubInventoryScanList,
  isShipmentReturnedExistOnInventory,
  (
    inventory: Array<HubInventory>,
    returnedShipments: Array<IReturnedShipments>,
    getHubInventoryScanList,
    isShipmentReturnedExistOnInventory,
  ) => {
    if (isShipmentReturnedExistOnInventory) {
      return inventory.filter(
        (shipment) => shipment.type === ITYPES.BACK_TO_TRUNKRS,
      )
    }
    return returnedShipments.filter(
      (shipment) =>
        shipment.type === ITYPES.BACK_TO_TRUNKRS &&
        !getHubInventoryScanList[shipment.shipmentId],
    ) as HubInventory[]
  },
)

export const getShipmentsUnscannable = createSelector(
  getHubInventory as any,
  getReturnedShipments,
  getHubInventoryScanList,
  isShipmentReturnedExistOnInventory,
  (
    inventory: Array<HubInventory>,
    returnedShipments: Array<IReturnedShipments>,
    getHubInventoryScanList,
    isShipmentReturnedExistOnInventory,
  ) => {
    if (isShipmentReturnedExistOnInventory) {
      return inventory.filter(
        (shipment) => shipment.type === ITYPES.UNSCANNABLE,
      )
    }
    return returnedShipments.filter(
      (shipment) =>
        shipment.type === ITYPES.UNSCANNABLE &&
        !getHubInventoryScanList[shipment.shipmentId],
    ) as HubInventory[]
  },
)
