import React from 'react'
import { useField } from 'formik'

import InputForm, { InputFormProps } from './InputForm'

const InputFormik: React.FC<InputFormProps> = ({
  label,
  name,
  helperText,
  helperVariant,
  ...props
}) => {
  const [field, meta] = useField(name || `input-${label}`)

  return (
    <InputForm
      {...field}
      {...props}
      label={label}
      name={name}
      error={!!(meta.touched && meta.error)}
      helperText={helperText || (meta.touched && meta.error) || ''}
      helperVariant={meta.error ? 'danger' : 'default'}
      helperIcon={meta.error && meta.touched ? 'danger' : undefined}
    />
  )
}

export default InputFormik
