import React from 'react'
import {
  Dialog,
  DialogProps,
  CircularProgress,
  DialogContent,
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import TrunkrsIcon from 'common/assets/images/trunkrs-loader-icon.svg'

import useStyles from './useStyles'

export interface DialogLoaderProps {
  open?: boolean
  message?: string
  maxWidth?: DialogProps['maxWidth']
}

const DialogLoader: React.FC<DialogLoaderProps> = ({
  open = false,
  message,
  maxWidth = 'xs',
}) => {
  const { t } = useTranslation()
  const classes = useStyles()
  return (
    <Dialog
      disableEscapeKeyDown
      fullWidth
      classes={{
        paper: classes.paper,
        scrollPaper: classes.scrollPaper,
        paperScrollBody: classes.paperScrollBody,
      }}
      maxWidth={maxWidth}
      open={Boolean(open)}
      PaperProps={{ elevation: 1 }}
    >
      <DialogContent className={classes.content}>
        <img src={TrunkrsIcon} alt="Trunkrs" className={classes.image} />
        <span className={classes.spinner}>
          <CircularProgress
            size={18}
            thickness={3}
            style={{ marginBottom: 3, marginRight: 12 }}
          />{' '}
          {message || t('LOADING')}
        </span>
      </DialogContent>
    </Dialog>
  )
}

export default React.memo(DialogLoader)
