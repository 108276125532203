import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const styles = ({ spacing, palette, shape }: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      gap: spacing(1),
    },
    button: {
      height: 32,
      width: 34,
      minWidth: 32,
      backgroundColor: palette.trunkrs.grayscale.lighter,
      padding: spacing(0.75),
    },
    buttonShowTour: {
      padding: spacing(1),
    },
    icon: {
      height: 24,
      width: 24,
    },
    colorPickerContainer: {
      width: 375,
      borderRadius: shape.borderRadius,
      padding: spacing(2),
    },
  })

export default makeStyles(styles)
