import React from 'react'
import { MarkerClusterer } from '@react-google-maps/api'
import { isValidCollation } from 'actions/tours/helpers'
import { getSelectedCollations } from 'selectors/ui'
import { useSelector } from 'react-redux'
import { Tour } from 'common/models/typings/Tour'
import GoogleMarker from './GoogleMarker'
import { COLORS } from '../../../../constants'

export interface MarkerProps {
  tour: Tour
  mapBounds?: google.maps.LatLngBounds | null
  isSingleTour?: boolean
  showAsCluster?: boolean
}

export const Marker: React.FC<MarkerProps> = ({
  tour,
  isSingleTour,
  mapBounds,
  showAsCluster = true,
}) => {
  const selectedMarkers = useSelector(getSelectedCollations)
  const stops = React.useMemo(
    () => tour.collations.filter(isValidCollation as any),
    [tour],
  )

  const isAnyStopSelected = React.useMemo(
    () => stops.some((stop) => selectedMarkers.includes(stop.id)),
    [selectedMarkers, stops],
  )

  const filteredStops = React.useMemo(() => {
    if (!mapBounds) return stops
    return stops.filter((stop) => {
      const { latitude, longitude } = stop.recipient.location
      return mapBounds.contains(new google.maps.LatLng(latitude, longitude))
    })
  }, [stops, mapBounds])

  const clusterOptions = {
    imagePath:
      'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m',
    maxZoom: 12,
    minimumClusterSize: 2,
    styles: [
      {
        textColor: 'white',
        url: `data:image/svg+xml;base64,${window.btoa(`
       <svg width="120" height="120" viewBox="0 0 120 120" xmlns="http://www.w3.org/2000/svg">
         <g clip-path="url(#clip0_5_2)">
           <path d="M60 96C79.8823 96 96 79.8823 96 60C96 40.1177 79.8823 24 60 24C40.1177 24 24 40.1177 24 60C24 79.8823 40.1177 96 60 96Z" fill="${
             isAnyStopSelected ? COLORS.GREEN_1 : tour.color
           }"/>
           <path d="M60 102C83.196 102 102 83.196 102 60C102 36.804 83.196 18 60 18C36.804 18 18 36.804 18 60C18 83.196 36.804 102 60 102Z" fill="${
             isAnyStopSelected ? COLORS.GREEN_1 : tour.color
           }" fill-opacity="0.8"/>
           <path d="M60 108C86.5097 108 108 86.5097 108 60C108 33.4903 86.5097 12 60 12C33.4903 12 12 33.4903 12 60C12 86.5097 33.4903 108 60 108Z" fill="${
             isAnyStopSelected ? COLORS.GREEN_1 : tour.color
           }" fill-opacity="0.5"/>
           <path d="M60 114C89.8234 114 114 89.8234 114 60C114 30.1766 89.8234 6 60 6C30.1766 6 6 30.1766 6 60C6 89.8234 30.1766 114 60 114Z" fill="${
             isAnyStopSelected ? COLORS.GREEN_1 : tour.color
           }" fill-opacity="0.2"/>
           <path d="M60 120C93.1371 120 120 93.1371 120 60C120 26.8629 93.1371 0 60 0C26.8629 0 0 26.8629 0 60C0 93.1371 26.8629 120 60 120Z" fill="${
             isAnyStopSelected ? COLORS.GREEN_1 : tour.color
           }" fill-opacity="0.2"/>
         </g>
         <defs>
           <clipPath id="clip0_5_2">
             <rect width="120" height="120" fill="white"/>
           </clipPath>
         </defs>
       </svg>
     `)}`,
        height: 60,
        width: 60,
      },
    ],
  }

  const markers = stops.map((stop) => (
    <GoogleMarker
      key={stop.id}
      tour={tour}
      tourColor={tour.color}
      stop={stop}
      isSelected={selectedMarkers.includes(stop.id)}
      isSingleTour={isSingleTour}
    />
  ))

  if (!showAsCluster) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{markers}</>
  }

  return (
    <MarkerClusterer options={clusterOptions}>
      {(clusterer) => (
        <>
          {filteredStops.map((stop) => (
            <GoogleMarker
              key={stop.id}
              tour={tour}
              tourColor={tour.color}
              stop={stop}
              isSelected={selectedMarkers.includes(stop.id)}
              isSingleTour={isSingleTour}
              clusterer={clusterer}
            />
          ))}
        </>
      )}
    </MarkerClusterer>
  )
}

export default React.memo(Marker)
