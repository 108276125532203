/* eslint-disable import/no-cycle */

import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Box, Card, Grow, ButtonBase } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import { useSelector, useDispatch } from 'react-redux'
import * as warehouseAction from 'actions/warehouse'
import * as fromCombined from 'selectors/combined'
import * as fromWarehouse from 'selectors/warehouse'
import * as fromTours from 'selectors/tour'

import Typography from 'common/components/base/Typography'
import Button from 'common/components/base/Button'
import Icon from 'common/components/base/Icon'
import Callout from 'common/components/base/.raw/Callout'

import useStyles from './useStyles'

export interface HandoverLockedProps {
  onLockTours: () => void
}

const HandoverLocked: React.FC<HandoverLockedProps> = ({ onLockTours }) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const { t } = useTranslation()
  const navigate = useNavigate()

  const tours = useSelector(fromTours.getData)
  const toursSaved: boolean = useSelector(fromCombined.allToursHaveIds)
  const driversAssigned: boolean = useSelector(fromCombined.allToursHaveDrivers)
  const isBusyDay: boolean = useSelector(fromWarehouse.getIsBusyDay)
  const shipmentToursNotSorted = useSelector(
    fromCombined.getShipmentToursIfIncludesNotSorted,
  )

  React.useEffect(() => {
    dispatch(warehouseAction.getBusyDay())
  }, [dispatch])

  const toursIsNotSortedYet = React.useMemo(
    () => isBusyDay && shipmentToursNotSorted,
    [isBusyDay, shipmentToursNotSorted],
  )

  const disabled =
    !toursSaved ||
    !(driversAssigned && tours.isSaved === undefined) ||
    toursIsNotSortedYet

  return (
    <>
      <Box>
        <Typography variant="h4">{t('LOCK_TITLE')}</Typography>
        <Typography color="theBeast">{t('LOCK_DESCRIPTION')}</Typography>
      </Box>
      {toursIsNotSortedYet && (
        <Grow timeout={450}>
          <>
            <Box height={24} />
            <Callout variant="danger">
              {t('GLOBAL_NOTIFICATIONS.HANDOVER_CENTRAL_SORTING_DONE')}
            </Callout>
          </>
        </Grow>
      )}
      <Box height={24} />
      <Box className={classes.root}>
        <Box className={classes.cards}>
          <Card className={classes.card}>
            <Box>
              <Typography
                variant="tableHeader"
                color={toursSaved ? 'green' : 'danger'}
              >
                {toursSaved ? 'Done' : 'Not yet done'}
              </Typography>
              <Box className={classes.headerContainer}>
                <span className={classes.iconText}>📋</span>
                <Box>
                  <Typography className={classes.sub} color="theBeast">
                    {t('LOCK_PLANNING_OWNER')}
                  </Typography>
                  <Typography variant="h4" transform="none">
                    {t('PLANNING_TIMELINE.TOUR_PLANNING')}
                  </Typography>
                </Box>
              </Box>
              <Box>
                <Typography color="theBeast">
                  {t('HANDOVER_TOUR_PLANNING_CARD_DESCRIPTION')}
                </Typography>
              </Box>
            </Box>
          </Card>

          <Card className={classes.card}>
            <Box>
              <Typography
                variant="tableHeader"
                color={driversAssigned ? 'green' : 'danger'}
              >
                {driversAssigned ? 'Done' : 'Not yet done'}
              </Typography>
              <Box className={classes.headerContainer}>
                <span className={classes.iconText}>🚚</span>
                <Box>
                  <Typography className={classes.sub} color="theBeast">
                    {t('LOCK_DRIVERS_OWNER')}
                  </Typography>
                  <Typography variant="h4" transform="none">
                    {t('LOCK_DRIVERS_TITLE')}
                  </Typography>
                </Box>
              </Box>
              <Box mb={!driversAssigned ? 3 : 0}>
                <Typography color="theBeast">
                  {t('HANDOVER_ASSIGN_DRIVERS_CARD_DESCRIPTION')}
                </Typography>
              </Box>
              {!driversAssigned && (
                <Box display="flex" justifyContent="flex-end">
                  <ButtonBase
                    onClick={() => navigate('/planning/tour-planning')}
                  >
                    <Typography variant="button">
                      {t('LOCK_DRIVER_CTA')}
                    </Typography>
                  </ButtonBase>
                </Box>
              )}
            </Box>
          </Card>
        </Box>

        <Button
          color="green"
          startIcon={<Icon name="check" fill="currentColor" />}
          disabled={disabled}
          onClick={onLockTours}
          className={classes.lockButton}
        >
          {t('LOCK_BUTTON')}
        </Button>
      </Box>
    </>
  )
}

export default HandoverLocked
