import React from 'react'
import { useTranslation } from 'react-i18next'

import Card from '@material-ui/core/Card'
import useStyles from './useStyles'
import { NETWORK_TYPE } from '../../../models/typings/TourOverview'
import { Icon } from '../.raw'

export interface NetworkTypeCardProps {
  networkType: NETWORK_TYPE
  onClick: React.MouseEventHandler<HTMLElement>
}

export const NetworkTypeCard: React.FC<NetworkTypeCardProps> = ({
  networkType,
  onClick,
}) => {
  const classes = useStyles()
  const [t] = useTranslation()

  return (
    <Card className={classes.root} onClick={onClick}>
      <Icon name={networkType === NETWORK_TYPE.DAY ? 'sun' : 'moon'} />
      <div className={classes.text}>
        {t(`NETWORK_TYPE.${networkType}_PLANNING`)}
      </div>
    </Card>
  )
}

export default NetworkTypeCard
