import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Card, Box } from '@material-ui/core'
import get from 'lodash/get'

import { useSelector } from 'react-redux'
import * as fromProfile from 'selectors/profile'

import Typography from 'common/components/base/Typography'
import EditProfileForm, {
  EditProfileFormFields,
} from 'common/components/features/Forms/EditProfile'

import useStyles from './useStyles'

const ProfileEditPage = () => {
  const { t } = useTranslation()
  const classes = useStyles()
  const navigate = useNavigate()

  const subco = useSelector(fromProfile.getSubco)
  const location = get(subco, 'location')
  const street = get(subco, 'location.address', '')
  const postalCode = get(subco, 'location.postalCode', '')
  const city = get(subco, 'location.city', '')
  const lat = get(subco, 'location.latitude', '')
  const lng = get(subco, 'location.longitude', '')
  const country = get(subco, 'location.country', '')

  const handleSubmit = React.useCallback(
    async (values: EditProfileFormFields) => {
      try {
        location.setAddress = values.street
        location.setPostalCode = values.postalCode
        location.setCity = values.city
        location.latitude = values.latitude
        location.longitude = values.longitude
        await location.save()
        navigate('/profile')
      } catch (error) {
        console.warn(error)
      }
    },
    [location, navigate],
  )

  return (
    <>
      <Typography variant="header" transform="capitalize">
        {t('EDIT_PROFILE')}
      </Typography>
      <Box height={32} />
      <Box width={650}>
        <EditProfileForm
          initialValues={{
            street,
            postalCode,
            city,
            latitude: lat,
            longitude: lng,
          }}
          onSubmit={handleSubmit}
        />
      </Box>
    </>
  )
}

export default ProfileEditPage
