import { denormalize, normalize } from 'normalizr'
import keys from 'lodash/keys'

import { arrayify } from '../utils/dataSets'

import { tourSchema, shipmentSchema } from './schemas'

export const normalizeTours = (data) => {
  const {
    result: tourKeys,
    entities: {
      tour: tourData,
      collation: collationData,
    },
  } = normalize(arrayify(data), [tourSchema])

  return {
    tours: [
      tourKeys || [],
      tourData || {},
    ],
    collations: [
      keys(collationData),
      collationData || {},
    ],
  }
}

export const denormalizeTours = (idOrIds, tour, collation) => {
  const { tour: tourData } = denormalize(
    { tour: arrayify(idOrIds) },
    { tour: [tourSchema] },
    { tour, collation },
  )

  return tourData || []
}

export const normalizeShipments = (data) => {
  const {
    result: identifiers,
    entities: { shipment: shipmentData },
  } = normalize(arrayify(data), [shipmentSchema])

  return [
    identifiers || [],
    shipmentData || {},
  ]
}
