/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import React, { memo } from 'react'
import clsx from 'clsx'
import styles from './styles.module.scss'
import Icon from '../Icon'

export interface ISimpleModalBoxProps extends React.HTMLAttributes<any> {
  visible: boolean
  onClose: () => void
}

function SimpleModalBox({
  visible,
  onClose,
  children,
  ...props
}: ISimpleModalBoxProps) {
  return (
    <div className={clsx('modal-box', { hidden: !visible })} {...props}>
      <div className={clsx('modal-box-content', styles.modalBody)}>
        <span className="close-button" onClick={onClose}>
          <Icon name="close" />
        </span>
        <div className="content">{children}</div>
      </div>
    </div>
  )
}

export default memo(SimpleModalBox)
