/* eslint-disable no-await-in-loop, no-restricted-syntax, no-return-await, import/prefer-default-export */
import { PDFDocument } from 'pdf-lib'

export const mergePDFBinaryFiles = async (files: ArrayBuffer[]) => {
  const pdfDoc = await PDFDocument.create()

  for (const file of files) {
    if (file) {
      const doc = await PDFDocument.load(file)
      const pages = await pdfDoc.copyPages(doc, doc.getPageIndices())
      pages.forEach((page) => pdfDoc.addPage(page))
    }
  }

  return await pdfDoc.save()
}

export const downloadPDF = (pdfBytes: Uint8Array, filename: string) => {
  const blob = new Blob([pdfBytes], { type: 'application/pdf' })
  const link = document.createElement('a')
  link.href = window.URL.createObjectURL(blob)
  link.download = filename
  link.click()
}
