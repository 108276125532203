import { faBolt, faFire } from '@fortawesome/free-solid-svg-icons'

const FUEL_TYPE_FOSSIL = 'fossil'
const FUEL_TYPE_ELECTRIC = 'electric'

export const FUEL_TYPES = [
  {
    name: FUEL_TYPE_FOSSIL,
    icon: faFire,
    langKey: FUEL_TYPE_FOSSIL.toUpperCase(),
    color: 'orange',
  },
  {
    name: FUEL_TYPE_ELECTRIC,
    icon: faBolt,
    langKey: FUEL_TYPE_ELECTRIC.toUpperCase(),
    color: 'green',
  },
]
