import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const styles = ({ spacing, palette }: Theme) =>
  createStyles({
    selectionRoot: {
      height: spacing(38),
      overflowY: 'scroll',
      paddingRight: spacing(1),
    },
    groupLabel: {
      paddingLeft: spacing(1),
      paddingRight: spacing(1),
      color: palette.trunkrs.grayscale.darker,
      minHeight: '36px',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    selectItem: {
      padding: 0,
      paddingLeft: spacing(1),
      paddingRight: spacing(1),
      width: '100%',
      height: spacing(5),
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      boxShadow: 'none',

      '&:hover:not($active)': {
        backgroundColor: palette.trunkrs.grayscale.purgatory,
      },
    },
    disabled: {
      '& p': {
        color: palette.trunkrs.grayscale.lighter,
      },
    },
    active: {
      backgroundColor: `${palette.trunkrs.secondary.violet.base} !important`,

      '& p': {
        color: 'white',
      },
    },
    actionsContainer: {
      marginTop: spacing(4),
    },
  })

export default makeStyles(styles)
