import get from 'lodash/get'
import * as moment from 'moment'

export const defaultHandlingTime = 120
export const getStart = (collations, shipments, subco = null) => {
  if (!collations || collations.length === 0) {
    return new Date()
  }
  const firstId = collations[0]
  const firstShipment = shipments[firstId]
  const startTimeWindow = firstShipment.Timeslot.Open
  const handlingTime = get(subco, 'handlingTime', defaultHandlingTime) * 1000
  let startTime = new Date(new Date().toISOString().substr(0, 10)).setHours(startTimeWindow) - (firstShipment.Duration * 1000) - handlingTime
  if (subco && get(subco, 'startTime')) {
    startTime = moment(get(subco, 'startTime')).set({ year: moment().year(), month: moment().month(), date: moment().date() }).millisecond() - (firstShipment.Duration * 1000) - handlingTime
  }
  return new Date(startTime)
}

export const getTimeShipment = (collations, id, shipments, subco) => {
  if (collations && collations.length > 0) {
    let duration = 0
    const handlingTime = get(subco, 'handlingTime', defaultHandlingTime)
    for (let i = 0; i < collations.length; i += 1) {
      const idInRoute = collations[i]
      if (shipments[idInRoute]) {
        duration += shipments[idInRoute].Duration + handlingTime
      }
      if (idInRoute === id) {
        break
      }
    }
    return new Date(getStart(collations, shipments).getTime() + duration * 1000)
  }
  return new Date()
}

export const calculateDuration = (nextLocation, currentLocation) => {
  if(nextLocation !== null) {
    if((nextLocation.Recipient.Lat === currentLocation.Recipient.Lat) && (nextLocation.Recipient.Lng === currentLocation.Recipient.Lng)) {
      return 0
    }
    return 3

  }
  return 0

}
