import shadows from '@material-ui/core/styles/shadows'
import Switch from '@material-ui/core/Switch'
import { NoEncryption } from '@material-ui/icons'
import { withStyles } from '@material-ui/styles'
import { url } from 'inspector'
import { transparent } from 'material-ui/styles/colors'
import moon from '../../../assets/images/icon-library/moon.svg'
import sun from '../../../assets/images/icon-library/sun.svg'

const AntSwitch = withStyles((theme) => ({
  root: {
    width: 58,
    height: 32,
    padding: 0,
    display: 'flex',
    borderRadius: 32 / 2,
    '&:active': {
      outline: '2px solid #8000FF',
    },
    margin: '0 15px 0 15px',
  },
  switchBase: {
    padding: 0,
    color: transparent,
    backgroundImage: `url(${moon})`,
    backgroundRepeat: 'no-repeat',
    transform: 'translateX(26px)',
    position: 'absolute',
    top: 2,
    left: 2,
    backgroundPosition: 'center',
    '&$checked': {
      color: transparent,
      transform: 'translateX(0px)',
      '& + $track': {
        opacity: 1,
        backgroundColor: theme.palette.trunkrs.intent.yellow.darkest,
      },
    },
    '&:hover': {
      boxShadow: shadows[2],
    },
  },
  thumb: {
    width: 28,
    height: 28,
    boxShadow: 'none',
  },
  track: {
    borderRadius: 32 / 2,
    opacity: 1,
    backgroundColor: theme.palette.trunkrs.secondary.lightViolet.lightest,
  },
  checked: {
    backgroundImage: `url(${sun})`,
    backgroundRepeat: 'no-repeat',
  },
}))(Switch)

export default AntSwitch
