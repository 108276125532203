/* eslint-disable import/no-cycle */

import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { UseFormMethods } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'

import Alert, { ALERT_TYPES } from 'common/components/base/.raw/Alert/Alert'

import Grid from '@material-ui/core/Grid/Grid'
import MenuItem from '@material-ui/core/MenuItem/MenuItem'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import pick from 'lodash/pick'
import clsx from 'clsx'
import Collapse from '@material-ui/core/Collapse'
import isEqual from 'lodash/isEqual'
import styles from './styles.module.scss'
import validationSchema, { defaultValues } from './schema'
import languages, { EditModel, UserModel } from './resource'
import { FORM_ACTION, IUserProfile } from '../../@types'
import { getDriverLocale } from 'actions/profile'
import {
  createUser,
  editUser,
  ICreateUserParams,
  reloadUsers,
  updateDriverSuccess,
} from 'actions/user'
import { getCurrentSubcoId } from 'selectors/profile'

import {
  SimpleForm,
  ModalBox,
  SimpleSwitch,
  SimpleTextbox,
  SimpleDropdownMenu,
  Button,
} from 'common/components/base/.raw'

export interface IAddDriverModalProps {
  visible: boolean
  onSucceed: () => void
  onClose: () => void
  user?: IUserProfile
  deleteFilter?: any
}

function AddUserModal({ onSucceed, user, ...props }: IAddDriverModalProps) {
  const mode = user ? FORM_ACTION.EDIT : FORM_ACTION.CREATE
  const [loading, setLoading] = useState(false)
  const [isPhoneNumberChanged, setIsPhoneNumberChanged] = useState(false)
  const [showAlert, setShowAlert] = useState(false)
  const [disabled, setDisabled] = useState(false)
  const [errorMsg, setErrorMsg] = useState('')

  const currentSubcoId = useSelector(getCurrentSubcoId)
  const dispatch = useDispatch()

  const { t } = useTranslation()

  const schema = () =>
    Yup.object().shape({
      name: Yup.string().required(t('USERS.ADD_USER_NAME_REQUIRED')),
      isPlanner: Yup.bool().default(false).notRequired(),
      userPreferences: Yup.object().shape({
        locale: Yup.string().required(t('USERS.ADD_USER_LANGUAGE_REQUIRED')),
      }),
      phoneNumber: Yup.string().required(t('USERS.ADD_USER_PHONE_REQUIRED')),
    })

  const formMethods = useRef<
    UseFormMethods<ICreateUserParams> & {
      formRef?: React.Ref<HTMLFormElement>
    }
  >()

  const handleOnLoad = useCallback(
    (methods: UseFormMethods<ICreateUserParams>) => {
      formMethods.current = methods
    },
    [],
  )

  const resetState = useCallback(() => {
    setLoading(false)
    setIsPhoneNumberChanged(false)
    setDisabled(false)
    setShowAlert(false)
    setErrorMsg('')
  }, [])

  const resetForm = useCallback((values = defaultValues) => {
    if (formMethods.current) {
      const { reset } = formMethods.current
      reset(values)
    }
    setErrorMsg('')
  }, [])

  const handleSubmit = useCallback(
    // eslint-disable-next-line consistent-return
    async (values: ICreateUserParams) => {
      let params: ICreateUserParams = {
        ...values,
        subcontractorId: currentSubcoId,
      }

      if (mode === FORM_ACTION.EDIT && !isPhoneNumberChanged) {
        params = pick(params, EditModel) as ICreateUserParams
      }

      setLoading(true)
      const response = await (mode === FORM_ACTION.CREATE
        ? createUser(params)
        : editUser(user!.id, params))
      setLoading(false)

      if (get(response, 'isError')) {
        return setErrorMsg(get(response, 'message'))
      }

      if (mode === FORM_ACTION.CREATE) {
        dispatch(
          reloadUsers({
            ...response,
            isPlanner: values.isPlanner,
          }),
        )
      } else {
        dispatch(
          updateDriverSuccess({
            ...params,
            id: user!.id,
            active: user!.active,
          }),
        )
      }
      onSucceed()
    },
    [currentSubcoId, mode, isPhoneNumberChanged, user, onSucceed, dispatch],
  )

  useEffect(() => {
    resetState()
    if (user) {
      const { id } = user
      let locale = get(user, 'userPreferences.locale')
      setDisabled(true)
      if (!locale) {
        ;(async () => {
          const payload = await dispatch(getDriverLocale(id))
          locale = get(payload, 'value', 'nl')
          // eslint-disable-next-line no-param-reassign
          user.userPreferences = { locale }
          resetForm(user)
        })()
      } else {
        resetForm(user)
      }
    } else {
      setDisabled(false)
      resetForm(defaultValues)
    }
  }, [user, props.visible, resetState, dispatch, resetForm])

  const onFocus = useCallback(() => {
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const defaultValues = pick(formMethods.current?.getValues(), EditModel)
    setShowAlert(true)
    resetForm(defaultValues)
  }, [resetForm])

  const onBlur = useCallback(() => {
    const formValues = formMethods.current?.getValues()
    setShowAlert(false)
    resetForm({ ...formValues, phoneNumber: user?.phoneNumber })
  }, [resetForm, user?.phoneNumber])

  const onChange = useCallback(() => {
    const formValues = formMethods.current?.getValues()
    if (isEqual(pick(user, UserModel), formValues)) {
      setDisabled(true)
    } else {
      setDisabled(false)
      if (
        formValues &&
        !!formValues.phoneNumber &&
        !isEqual(formValues.phoneNumber, user?.phoneNumber)
      ) {
        setIsPhoneNumberChanged(true)
      }
    }
  }, [user])

  const { deleteFilter, ...others } = props

  return (
    <ModalBox {...others}>
      <SimpleForm<ICreateUserParams>
        onSubmit={handleSubmit}
        onLoad={handleOnLoad}
        onClick={mode === FORM_ACTION.EDIT ? onChange : undefined}
        onChange={mode === FORM_ACTION.EDIT ? onChange : undefined}
        resolver={yupResolver(schema())}
        defaultValues={defaultValues}
      >
        <div className={styles.wrapper}>
          <div className={styles['header-container']}>
            <span className={styles.header}>
              {t(
                mode === FORM_ACTION.CREATE
                  ? 'ADD_USER_TITLE'
                  : 'EDIT_USER_TITLE',
              )}
            </span>
          </div>
          {!isEmpty(errorMsg) && <div className={styles.error}>{errorMsg}</div>}
          <div className={styles['main-content']}>
            <Grid container>
              <Grid item xs={12}>
                <label htmlFor="full-name">{t('ADD_USER_FULL_NAME')}</label>
                <SimpleTextbox
                  id="full-name"
                  name="name"
                  placeholder={t('ADD_USER_FULL_NAME_PLACEHOLDER')}
                />
              </Grid>
              <Grid item xs={12}>
                <div className={styles['planner-permission-container']}>
                  <div>
                    <span>{t('PLANNER_PERMISSION')}</span>
                    <span>{t('PLANNER_PERMISSION_SUBTEXT')}</span>
                  </div>
                  <SimpleSwitch name="isPlanner" />
                </div>
              </Grid>
              <Grid item xs={12} className={styles['dropdown-menu-container']}>
                <label htmlFor="preferred-language">
                  {t('ADD_USER_PREFERRED_LANGUAGE')}
                </label>
                <SimpleDropdownMenu
                  id="preferred-language"
                  name="userPreferences.locale"
                >
                  <MenuItem value="" hidden>
                    <span className={styles.placeholder}>
                      {t('ADD_USER_PREFERRED_LANGUAGE_PLACEHOLDER')}
                    </span>
                  </MenuItem>
                  {useMemo(
                    () =>
                      languages.map(({ text, value }) => (
                        <MenuItem key={value} value={value}>
                          {text}
                        </MenuItem>
                      )),
                    [],
                  )}
                </SimpleDropdownMenu>
              </Grid>
              <Grid item xs={12}>
                <label htmlFor="phone-number">
                  {t('ADD_USER_PREFERRED_PHONE_NUMBER')}
                </label>
                <SimpleTextbox
                  onBlur={
                    mode === FORM_ACTION.EDIT && !isPhoneNumberChanged
                      ? onBlur
                      : undefined
                  }
                  onFocus={
                    mode === FORM_ACTION.EDIT && !isPhoneNumberChanged
                      ? onFocus
                      : undefined
                  }
                  id="phone-number"
                  name="phoneNumber"
                  autoComplete="off"
                  placeholder={t('ADD_USER_PREFERRED_PHONE_NUMBER_PLACEHOLDER')}
                />
              </Grid>
              {mode === FORM_ACTION.EDIT && (
                <Collapse in={showAlert} className={styles.fade}>
                  <Alert
                    type={ALERT_TYPES.WARNING}
                    className={styles.alertContainer}
                  >
                    <div className={styles.title}>{t('WARNING')}</div>
                    <div className={styles.message}>
                      {t('EDIT_PHONE_NUMBER_WARNING')}
                    </div>
                  </Alert>
                </Collapse>
              )}
            </Grid>
          </div>
          <div className={styles.footer}>
            <Button className={styles['cancel-button']} onClick={props.onClose}>
              {t('ADD_USER_CANCEL_BUTTON')}
            </Button>
            <Button
              type="submit"
              disabled={disabled}
              className={clsx(
                styles['submit-button'],
                loading && styles['submit-loading'],
              )}
            >
              {loading && <span className={styles.loading} />}
              {t(
                // eslint-disable-next-line no-nested-ternary
                mode === FORM_ACTION.CREATE
                  ? loading
                    ? 'ADD_USER_CREATING_BUTTON'
                    : 'ADD_USER_CREATE_BUTTON'
                  : loading
                  ? 'SAVING_USER_BUTTON'
                  : 'SAVE_USER_BUTTON',
              )}
            </Button>
          </div>
        </div>
      </SimpleForm>
    </ModalBox>
  )
}

export default memo(AddUserModal)
