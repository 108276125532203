/* eslint-disable import/no-cycle */
/* eslint-disable import/no-import-module-exports */

import { createStore, compose, applyMiddleware } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import { createFilter } from 'redux-persist-transform-filter'

import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import thunk from 'redux-thunk'

import rootReducer from '../reducers'
import { environment } from '../constants'

const intervalTasksFilter = createFilter('intervalTasks', [
  'identifiers',
  'tasks',
])

const subcoFilter = createFilter('profile', [
  'selectedSubco',
  'subcoInstance',
  'subcos'
])

const persistConfig = {
  key: 'Trunkrs Planner',
  storage,
  transforms: [intervalTasksFilter, subcoFilter],
  whitelist: ['intervalTasks', 'isAuthenticated', 'networkType', 'profile', 'tours'],
}

export default function configureStore(initialState) {
  const composeWithDevToolsExtension =
    environment !== 'production' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  const composeEnhancers =
    typeof composeWithDevToolsExtension === 'function'
      ? composeWithDevToolsExtension
      : compose

  const composedMiddleware = composeEnhancers(applyMiddleware(thunk))

  const persistedReducer = persistReducer(persistConfig, rootReducer)

  const store = createStore(persistedReducer, initialState, composedMiddleware)

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('../reducers', () => {
      const nextReducer = import('../reducers')
      store.replaceReducer(nextReducer)
    })
  }

  const persistor = persistStore(store)
  return { store, persistor }
}
