import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box } from '@material-ui/core'

import get from 'lodash/get'

import { contrastColor } from 'common/utils/Color'

import Icon from 'common/components/base/Icon'
import Typography from 'common/components/base/Typography'

import useStyles from './useStyles'

export interface HandoverDialogSuccessProps {
  shipment: any
}

const HandoverDialogSuccess: React.FC<HandoverDialogSuccessProps> = ({
  shipment,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const recipient =
    get(shipment, 'originalRecipient') || get(shipment, 'recipient')

  const newRecipient = get(shipment, 'recipient')

  const color = React.useMemo(
    () =>
      contrastColor(shipment.tour.color) === '#ffffff' ? 'white' : 'indigo',
    [shipment.tour.color],
  )

  return (
    <Box className={classes.content}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        style={{ backgroundColor: shipment.tour.color }}
      >
        <Typography variant="header" color={color}>
          {t('CART')}
        </Typography>
        <Box height={40} />
        <Typography className={classes.tourNr} color={color}>
          {get(shipment, 'tour.number')}
        </Typography>
        <Box height={40} />
        <Box>
          <Typography variant="header" color={color}>
            Tour: {get(shipment, 'tour.id')}
          </Typography>
          <Typography variant="header" color={color}>
            Position: {get(shipment, 'position')}
          </Typography>
        </Box>
      </Box>
      <Box>
        <Box padding={3} height="100%">
          <Typography variant="textBold">{t('TOUR')}</Typography>
          <Typography>{get(shipment, 'tour.id')}</Typography>
          <Box height={16} />

          <Typography variant="textBold">{t('DRIVER')}</Typography>
          <Typography>{get(shipment, 'driver.name')}</Typography>
          <Box height={16} />

          <Typography variant="textBold">{t('ADDRESS')}</Typography>
          <Typography>{get(recipient, 'name')}</Typography>
          <Typography>T. {get(recipient, 'phoneNumber')}</Typography>
          <Typography>{get(recipient, 'location.address')}</Typography>
          <Typography>{get(recipient, 'location.postalCode')}</Typography>
          <Box height={16} />

          {get(shipment, 'originalRecipient') && get(shipment, 'recipient') && (
            <Box>
              <Box display="flex" alignItems="center" marginBottom={2}>
                <Box marginRight={1} height={24}>
                  <Icon name="danger" />
                </Box>
                <Typography variant="textBold" transform="none">
                  {t('SHIPMENT_ADDRESS_CHANGED')}
                </Typography>
              </Box>
              <Box>
                <Typography variant="textBold">{t('NEW_ADDRESS')}</Typography>
                <Typography>{get(newRecipient, 'name')}</Typography>
                <Typography>T. {get(newRecipient, 'phoneNumber')}</Typography>
                <Typography>{get(newRecipient, 'location.address')}</Typography>
                <Typography>
                  {get(newRecipient, 'location.postalCode')}
                </Typography>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  )
}

export default HandoverDialogSuccess
