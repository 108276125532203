import * as types from '../constants/ActionTypes'
import { ADD_DRIVERS } from '../constants/ActionTypes'

export function add(payload) {
  return { type: types.ADD_DRIVER, payload }
}

export function addMultiple(payload) {
  return { type: ADD_DRIVERS, payload }
}

export function addNew(payload) {
  return { type: types.NEW_DRIVER, payload }
}

export function clear() {
  return { type: types.CLEAR_DRIVERS }
}

export function activateDriver(payload) {
  return { type: types.ACTIVATE_DRIVER, payload }
}

export function deactivateDriver(payload) {
  return { type: types.DEACTIVATE_DRIVER, payload }
}
