import React from 'react'
import clsx from 'clsx'
import get from 'lodash/get'

import styles from './styles.module.scss'

export type PercentageCardTypes =
  | 'sorted'
  | 'accepted'
  | 'endState'
  | 'neighbourNonDelivery'
  | null

export interface IPercentageCardProps {
  caption: string
  details: string
  percentage: number
  variant: PercentageCardTypes
}

function PercentageCard({
  caption,
  details,
  percentage,
  variant,
}: IPercentageCardProps) {
  return (
    <div className={styles.container}>
      <div className={styles.header}>{caption}</div>
      <div className={styles.subHeader}>{details}</div>
      <div
        className={clsx(styles.percentageBar, get(styles, variant as never))}
      >
        {!Number.isNaN(percentage) && (
          <div style={{ width: `${percentage * 100}%` }}>
            <span>{`${Math.round(percentage * 100)}%`}</span>
          </div>
        )}
      </div>
    </div>
  )
}

export default React.memo(PercentageCard)
