import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const styles = ({ spacing }: Theme) =>
  createStyles({
    content: {
      height: 276,
      display: 'flex',
    },
    items: {
      flex: '1 1 0',
    },
  })

export default makeStyles(styles)
