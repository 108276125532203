import * as Yup from 'yup'
import i18n from 'i18next'

const editProfileFormSchema = () =>
  Yup.object().shape({
    street: Yup.string().required(i18n.t('THIS_FIELD_IS_REQUIRED')),
    postalCode: Yup.string().required(i18n.t('THIS_FIELD_IS_REQUIRED')),
    city: Yup.string().required(i18n.t('THIS_FIELD_IS_REQUIRED')),
    latitude: Yup.string().required(i18n.t('THIS_FIELD_IS_REQUIRED')),
    longitude: Yup.string().required(i18n.t('THIS_FIELD_IS_REQUIRED')),
  })

export default editProfileFormSchema
