import React from 'react'
import { Polyline } from '@react-google-maps/api'
import { decode } from 'google-polyline'

import { Collation } from 'common/models/typings/Collation'
import tour from 'reducers/tour'

export interface DirectionsProps {
  color: string
  collations: Collation[]
}

export const Directions: React.FC<DirectionsProps> = ({
  color,
  collations,
}) => {
  const mapToLatLngLiteral = (item: any) =>
    item && {
      lat: item[0],
      lng: item[1],
    }

  return (
    <>
      {React.useMemo(
        () =>
          collations.map(
            (collation) =>
              !!collation.polyline && (
                <Polyline
                  key={collation.id}
                  path={decode(collation.polyline).map(mapToLatLngLiteral)}
                  options={{
                    strokeColor: color,
                    strokeOpacity: 0.5,
                  }}
                />
              ),
          ),
        [collations, color],
      )}
    </>
  )
}

export default Directions
