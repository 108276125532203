import * as Sentry from '@sentry/browser'
import { TrunkrsSDK } from 'Trunkrs-SDK/dist'

/**
 * https://stackoverflow.com/a/47158850/7510775
 * Use for pausing api call with await
 *
 * @param milliseconds wait time
 * @returns a blank promise
 */
export const wait = (milliseconds: number) =>
  // eslint-disable-next-line no-promise-executor-return
  new Promise((resolve, reject) => setTimeout(resolve, milliseconds))

export const responseHandler = <TResponse>(
  promise: Promise<TResponse>,
): Promise<[Error | null, TResponse | null]> => {
  return promise
    .then<[null, TResponse | null]>((response) => [null, response])
    .catch((err: Error) => {
      Sentry.captureException(err)
      return [err, null]
    })
}

export const getNode = (): any => {
  switch (process.env.REACT_APP_ENV) {
    case 'development':
      return 'DEVELOPMENT'
    case 'qa':
      return 'QA'
    case 'production':
      return 'PRODUCTION'
    default:
      return 'DEVELOPMENT'
  }
}

const sdk = new TrunkrsSDK({
  cache: false,
  cacheInvalidateAfter: 15,
  maxRetryAttempts: 2,
  mode: getNode(),
  store: localStorage,
  enableBatching: true,
})

export default sdk
