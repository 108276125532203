import { schema } from 'normalizr'

// Shipments
export const shipmentSchema = new schema.Entity('shipment')

// Tours
export const collationSchema = new schema.Entity('collation')
export const tourSchema = new schema.Entity('tour', {
  collations: [collationSchema],
})

export const overviewTour = new schema.Entity('overviewTour')

// Todo List

export const todoList = new schema.Entity(
  'todoList',
  {},
  { idAttribute: 'shipmentId' },
)

// Collection Tours

export const collectionTourSchema = new schema.Entity('tour')
export const collectionTourDateSchema = new schema.Entity('tourDate', {
  tours: [collectionTourSchema],
}, { idAttribute: 'date' })

// Freezer Management
export const freezerManagementSchema = new schema.Entity(
  'freezerManagement',
  {},
  { idAttribute: 'shipmentId' },
)
