/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { memo, useCallback, useEffect, useRef, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import get from 'lodash/get'
import clsx from 'clsx'

import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes'
import {
  faCheckCircle,
  definition,
} from '@fortawesome/free-solid-svg-icons/faCheckCircle'

import styles from './styles.module.scss'
import Icon from '../Icon'

interface IToastProps extends React.DetailedHTMLProps<any, any> {
  icon?: React.ReactNode
  position?: 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right'
  headerText?: string
  subText?: string
  onExit?: () => void
}

function Toast({
  icon = <Icon name="check-circle" />,
  position = 'bottom-left',
  className,
  children,
  headerText,
  subText,
  onExit,
  open,
  ...props
}: IToastProps) {
  const [isVisible, setIsVisible] = useState(false)
  const timer = useRef<any>()

  const clearTimer = useCallback(() => {
    clearTimeout(timer.current)
  }, [])

  useEffect(() => {
    clearTimer()
    if (isVisible) {
      timer.current = setTimeout(() => {
        setIsVisible(false)
        if (typeof onExit === 'function') {
          onExit()
        }
      }, 5000)
    }
    return clearTimer
  }, [clearTimer, isVisible, onExit])

  useEffect(() => {
    setIsVisible(true)
  }, [])

  return isVisible && open ? (
    <div
      className={clsx(
        styles.notificationContainer,
        get(styles, position),
        className,
      )}
      {...props}
    >
      <div className={styles.icon}>{icon}</div>
      <div className={styles.messageContainer}>
        <span className={styles.closeButton} onClick={onExit}>
          <Icon name="close" />
        </span>
        {children ? children : (
          <>
            <span className={styles['header-text']}>{headerText}</span>
            <span className={styles['sub-text']}>{subText}</span>
          </>
        )}
      </div>
    </div>
  ) : null
}

export default memo(Toast)
