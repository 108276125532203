import React from 'react'
import { useTranslation } from 'react-i18next'

import EmptyState from '..'

import useStyles from '../useStyles'
import { Icon } from '../../../base/.raw'
import NetworkNotReadyAni from '../../../animations/NetworkNotReady'

const NetworkNotReady = () => {
  const classes = useStyles()
  const [t] = useTranslation()

  return (
    <EmptyState
      illustration={<NetworkNotReadyAni />}
      heading={t('EMPTY_SCREENS.NETWORK_NOT_READY.HEADER')}
      description={t('EMPTY_SCREENS.NETWORK_NOT_READY.DESCRIPTION')}
    />
  )
}

export default NetworkNotReady
