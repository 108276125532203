import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const styles = ({ spacing }: Theme) =>
  createStyles({
    actionsContainer: {
      marginTop: spacing(4),
    },
  })

export default makeStyles(styles)
