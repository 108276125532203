/* eslint-disable global-require */
/* eslint-disable import/no-cycle */

import React, { memo, useCallback } from 'react'
import Button from 'common/components/base/Button'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { getHubInventoryList } from 'actions/hubLogs'
import { getDrivers } from 'selectors/driver'
import { getCurrentSubcoId } from 'selectors/profile'
import isEmpty from 'lodash/isEmpty'
import Icon from 'common/components/base/.raw/Icon'

import styles from './styles.module.scss'

function EmptyLogs() {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const currentSubcoId = useSelector(getCurrentSubcoId)
  const drivers = useSelector(getDrivers)

  const handleReload = useCallback(() => {
    if (currentSubcoId && !isEmpty(drivers)) {
      dispatch(getHubInventoryList(currentSubcoId, drivers))
    }
  }, [currentSubcoId, drivers, dispatch])

  return (
    <div className={styles.container}>
      <Icon name="empty-result" />
      <div>
        <span>{t('HUB_INVENTORY.EMPTY_TITLE')}</span>
        <div>{t('HUB_INVENTORY.EMPTY_DESC')}</div>
      </div>
      <Button startIcon={<Icon name="retry" />} onClick={handleReload}>
        {t('HUB_INVENTORY.RELOAD_BUTTON')}
      </Button>
    </div>
  )
}

export default memo(EmptyLogs)
