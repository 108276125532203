import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const styles = ({ spacing }: Theme) =>
  createStyles({
    cartContainer: {
      display: 'grid',
      gridTemplateColumns: 'repeat(4, 1fr)',
      gridTemplateRows: 'repeat(auto, 326px)',
      gap: spacing(4),
    },
    button: {
      textTransform: 'capitalize',
    },
  })

export default makeStyles(styles)
