export const colours = [
  '#FF5351',
  '#FF0000',
  '#9D0000',
  '#CC7A55',
  '#AC4900',
  '#12D2FC',
  '#5977FF',
  '#303F9F',
  '#4BF1FF',
  '#1485CC',
  '#FFB333',
  '#FF8F5E',
  '#FFEA00',
  '#E39D00',
  '#FFCE00',
  '#29A389',
  '#00FFB1',
  '#00AD49',
  '#30929F',
  '#006704',
  '#201047',
  '#6900A0',
  '#E83F8A',
  '#FF55E6',
  '#484848',
  '#939393',
  '#DDDDDD',
]

export const color = (index) => index
  ? colours[index % colours.length]
  : colours[Math.floor((Math.random() * colours.length)) % colours.length]

export default color
