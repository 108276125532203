import React from 'react'
import { useTranslation } from 'react-i18next'
import { DialogContent, DialogActions, Box } from '@material-ui/core'

import Typography from 'common/components/base/Typography'
import Button from 'common/components/base/Button'
import Icon from 'common/components/base/Icon'
import Callout from 'common/components/base/.raw/Callout'

import useStyles from './useStyles'

export interface CreateToursDialogSuccessProps {
  elapsedTime?: string
  driverCount?: string
  overflowCount?: string
  onReOptimize: () => void
  onClose: () => void
}

const CreateToursDialogSuccess: React.FC<CreateToursDialogSuccessProps> = ({
  elapsedTime,
  driverCount,
  overflowCount,
  onReOptimize,
  onClose,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <>
      <DialogContent
        className={classes.dialogContent}
        style={{ display: 'block' }}
      >
        <div>
          <Typography>
            {t('OPTIMIZATION_RESULT_SUCCESS_DESC_1')} <b>{elapsedTime}</b>
          </Typography>
          <Typography>
            {t('OPTIMIZATION_RESULT_SUCCESS_DESC_2')} <b>{driverCount}</b>
          </Typography>
          {overflowCount && (
            <Typography>
              {t('OPTIMIZATION_RESULT_SUCCESS_DESC_3')} <b>{overflowCount}</b>
            </Typography>
          )}
        </div>
        {overflowCount && (
          <>
            <Box height={16} />
            <Callout variant="warning">
              {t('OPTIMIZATION_RESULT_OVERFLOW_DESC')}
            </Callout>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          color="green"
          onClick={onClose}
          startIcon={<Icon name="check" fill="currentColor" />}
        >
          {t('OPTIMIZATION_RESULT_BUTTON_ACCEPT')}
        </Button>
        <Button variant="flat" color="white" onClick={onReOptimize}>
          {t('OPTIMIZATION_RESULT_BUTTON_RE_OPTIMIZE')}
        </Button>
      </DialogActions>
    </>
  )
}

export default React.memo(CreateToursDialogSuccess)
