import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const styles = ({ spacing }: Theme) =>
  createStyles({
    card: {
      width: 872,
    },
    actions: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
  })

export default makeStyles(styles)
