import React from 'react'
import clsx from 'clsx'

import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles'
import MUIButton, {
  ButtonProps as MUIButtonProps,
} from '@material-ui/core/Button'

import { Colors as ButtonColor } from '../../../models/typings/Colors'

import styles from './styles'

type ButtonVariant = 'default' | 'rounded' | 'flat'
type ButtonSize = 'medium' | 'small' | 'large'

export interface ButtonProps extends WithStyles<typeof styles> {
  type?: 'submit' | 'reset' | 'button'
  href?: string
  rel?: string
  className?: string
  size?: ButtonSize
  color?: ButtonColor
  variant?: ButtonVariant
  children?: React.ReactNode
  disabled?: boolean
  startIcon?: React.ReactNode
  endIcon?: React.ReactNode
  onClick?: MUIButtonProps['onClick']
  fullWidth?: MUIButtonProps['fullWidth']
  target?: string
}

export const Button: React.FC<ButtonProps> = React.forwardRef<
  HTMLButtonElement,
  ButtonProps
>(
  (
    {
      classes,
      className,
      size = 'medium',
      color = 'green',
      variant = 'default',
      disabled,
      startIcon,
      endIcon,
      onClick,
      children,
      fullWidth,
      type = 'button',
      ...props
    },
    ref,
  ) => {
    const buttonClasses = React.useMemo<MUIButtonProps['classes']>(
      () => ({
        disabled: classes.disabled,
      }),
      [classes.disabled],
    )

    return (
      <MUIButton
        {...props}
        ref={ref}
        type={type}
        variant="contained"
        color="default"
        className={clsx(
          classes.button,
          classes[variant],
          classes[color],
          classes[size],
          className,
        )}
        classes={buttonClasses}
        disabled={disabled}
        onClick={onClick}
        startIcon={startIcon}
        endIcon={endIcon}
        fullWidth={fullWidth}
        size={size}
      >
        {children}
      </MUIButton>
    )
  },
)

export default withStyles(styles)(Button)
