/* eslint-disable import/no-cycle */
/* eslint-disable no-param-reassign */

import { SHIPMENT_STATES_ID } from 'Trunkrs-SDK/dist/typings/types'
import defaultTo from 'lodash/defaultTo'
import { ACTION_TYPES } from 'actions/hubLogs'
import setShipmentNextStep from 'utils/setShipmentNextStep'

export enum ITYPES {
  BACK_TO_TRUNKRS = 'BACK_TO_TRUNKRS',
  KEEP_IN_REGION = 'KEEP_IN_REGION',
  ERROR = 'ERROR',
  UNSCANNABLE = 'UNSCANNABLE',
}

export interface IHubInventory {
  trunkrsNr: string
  address: string
  houseNumber: string
  postalCode: string
  city: string
  country: string
  lastDriver: number
  driverName: string
  shipmentState: string
  intendedDeliveryDate: Date
  shipmentId: number
  inSince: Date
  barcode: string
  type?: ITYPES
}

export interface IReturnedShipments {
  shipmentId: number
  address: string
  postalCode: string
  type: ITYPES
}

interface IAction {
  type: ACTION_TYPES
  payload: any
}

const initialState: {
  inventory: Array<IHubInventory>
  returnedShipments: Array<IReturnedShipments>
  isBatchHubOutDone: boolean
} = {
  inventory: [],
  returnedShipments: [],
  isBatchHubOutDone: false,
}

const massSetHubInventoryTypeUnscannable = (state: any, action: any) => {
  const { inventory } = state
  const {
    shipments,
  }: {
    shipments: {
      nextStep: ITYPES
      shipmentId: number
      stateId: number | undefined
    }[]
  } = action.payload

  const newInventory = inventory.reduce((acc: any, inv: IHubInventory) => {
    const shipment = shipments.find(
      ({ shipmentId }) => shipmentId === inv.shipmentId,
    )

    if (shipment !== undefined) {
      if (shipment.stateId) {
        inv.shipmentState =
          Object.keys(SHIPMENT_STATES_ID)[
            Object.values(SHIPMENT_STATES_ID).indexOf(shipment.stateId)
          ]
      }

      inv.type = shipment.nextStep
    }

    return [...acc, inv]
  }, [])
  return newInventory
}

export default function hubLogs(state = initialState, action: IAction) {
  switch (action.type) {
    case ACTION_TYPES.SET_HUB_INVENTORY:
      return {
        ...state,
        isBatchHubOutDone: false,
        inventory: defaultTo(action.payload, []),
      }
    case ACTION_TYPES.SET_RETURNED_SHIPMENTS:
      return {
        ...state,
        returnedShipments: defaultTo(action.payload, []),
      }
    case ACTION_TYPES.SET_SHIPMENT_NEXT_STEP:
      return {
        ...state,
        inventory: setShipmentNextStep(state, action),
      }
    case ACTION_TYPES.SET_BATCH_HUB_OUT_DONE:
      return {
        ...state,
        isBatchHubOutDone: true,
      }
    case ACTION_TYPES.MASS_SET_HUB_INVENTORY_TYPE_UNSCANNABLE:
      return {
        ...state,
        inventory: massSetHubInventoryTypeUnscannable(state, action),
      }
    default:
      return state
  }
}
