import React from 'react'
import {
  GoogleMap,
  GoogleMapProps,
  useJsApiLoader,
} from '@react-google-maps/api'

import { mapContainerStyles, mapStyles } from './mapStyles'

export interface MapProps extends GoogleMapProps {
  children?: React.ReactNode
}

const Map: React.FC<MapProps> = ({
  children,
  center: propCenter,
  zoom = 8,
  options = {
    zoomControl: true,
    mapTypeControl: false,
    scaleControl: false,
    streetViewControl: false,
    rotateControl: false,
    fullscreenControl: true,
    scrollwheel: true,
    styles: mapStyles,
    disableDoubleClickZoom: true,
  },
  ...others
}) => {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: 'AIzaSyBzWD9a4l8NYzXz7eakuWJpLNBmRnxYEc4',
  })

  const [center, setCenter] = React.useState<
    google.maps.LatLng | google.maps.LatLngLiteral
  >({
    lat: 51.990276,
    lng: 5.103033,
  })

  React.useEffect(() => {
    if (propCenter !== undefined) {
      setCenter(propCenter)
    }
  }, [propCenter])

  return isLoaded ? (
    <GoogleMap
      id="google-map"
      mapContainerStyle={mapContainerStyles}
      center={center}
      zoom={zoom}
      options={options}
      {...others}
    >
      {/* Child components, such as markers, info windows, etc. */}
      {children}
    </GoogleMap>
  ) : (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <></>
  )
}

export default Map
