import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

interface Props {
  badgeColor?: 'green' | 'orange'
}

const styles = makeStyles<Theme, Props>(({ spacing, palette, shape }) =>
  createStyles({
    root: {
      height: spacing(6),
      width: '100%',
      cursor: 'pointer',
      padding: spacing(1),
      paddingLeft: spacing(2),
    },
    input: {
      cursor: 'pointer',
    },
    adornment: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    label: {
      fontSize: '10px',
    },
    badge: {
      padding: '4px 8px',
      borderRadius: '50px',
      backgroundColor: ({ badgeColor }) => {
        switch (badgeColor) {
          case 'green':
            return palette.trunkrs.primary.green.base
          case 'orange':
            return palette.trunkrs.intent.orange.base
          default:
            return palette.background.paper
        }
      },
    },
  }),
)

export default styles
