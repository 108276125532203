import React from 'react'
import {
  Box,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  FormControlLabel,
  DialogActions,
  CircularProgress,
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import { useDispatch, useSelector } from 'react-redux'
import * as uiAction from 'actions/ui'
import * as fromShipments from 'selectors/shipments'

import Button from 'common/components/base/Button'
import Icon from 'common/components/base/Icon'
import Typography from 'common/components/base/Typography'

import Drivers from './Drivers'
import CreatingTours from './CreatingTours'

import useStyles from './useStyles'
import Loader from '../../base/Dialog/Loader'

export interface CreateToursDialogProps {
  open: boolean
  loading?: boolean
  onClose: () => void

  onOptimize: () => void
}

const CreateToursDialog: React.FC<CreateToursDialogProps> = ({
  open,
  loading,
  onClose,
  onOptimize,
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const classes = useStyles()

  const shipmentCount = useSelector(
    fromShipments.getNumberOfNonCancelledShipments,
  )

  const { optimizing } = useSelector((state: any) => state.ui)

  const [value, setValue] = React.useState(0)
  const [usePreviousOptimization, setUsePreviousOptimization] =
    React.useState(false)
  const [newRoutificVersion, setNewRoutificVersion] = React.useState(
    shipmentCount > 500,
  )

  React.useEffect(() => {
    setValue(Math.ceil(shipmentCount / 50))
  }, [shipmentCount, open])

  React.useEffect(() => {
    dispatch(uiAction.set({ number_of_drivers: value }))
  }, [dispatch, value])

  const handleChange = React.useCallback((data: number) => setValue(data), [])

  const handleIncrement = React.useCallback(
    () => setValue((prevValue) => prevValue + 1),
    [],
  )
  const handleDecrement = React.useCallback(() => {
    setValue((prevValue) => {
      if (prevValue <= 0) {
        return 0
      }
      return prevValue - 1
    })
  }, [])

  const handleToggleUsePrevious = React.useCallback(
    (checked) => {
      setUsePreviousOptimization(checked)
      dispatch(uiAction.set({ use_previous_optimization: checked }))
    },
    [dispatch],
  )

  const handleToggleRoutific = React.useCallback(
    (checked) => {
      setNewRoutificVersion(checked)
      dispatch(uiAction.set({ new_routific_version: checked }))
    },
    [dispatch],
  )

  const handleOptimize = React.useCallback(() => {
    if (value > 0) {
      dispatch(uiAction.set({ optimizeModal: false }))
      onOptimize()
    }
  }, [dispatch, onOptimize, value])

  return (
    <Dialog
      disableEscapeKeyDown
      fullWidth
      maxWidth="xs"
      open={open}
      PaperProps={{ elevation: 0 }}
      classes={{ paper: classes.paper }}
    >
      <Box position="absolute" top="16px" right="16px">
        <IconButton disabled={optimizing} onClick={onClose}>
          <Icon name="close" />
        </IconButton>
      </Box>
      <DialogTitle disableTypography className={classes.dialogTitle}>
        <Typography variant="h3">
          {t(optimizing ? 'CREATING_TOURS' : 'HOW_MANY_DRIVERS')}
        </Typography>
        {optimizing && (
          <Typography variant="button" color="theBeast">
            {t('DO_NOT_LEAVE_PAGE')}
          </Typography>
        )}
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        {!optimizing ? (
          <Drivers
            shipmentCount={shipmentCount}
            value={value}
            onChange={handleChange}
            usePreviousOptimization={usePreviousOptimization}
            newRoutificVersion={newRoutificVersion}
            onIncrement={handleIncrement}
            onDecrement={handleDecrement}
            onToggleUsePrevious={handleToggleUsePrevious}
            onToggleRoutific={handleToggleRoutific}
          />
        ) : (
          <CreatingTours />
        )}
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button
          color="green"
          startIcon={
            loading ? (
              <CircularProgress size={24} />
            ) : (
              <Icon name="truck" fill="currentColor" />
            )
          }
          disabled={optimizing || loading}
          onClick={handleOptimize}
        >
          {t('CREATE_TOURS')}
        </Button>
        {!optimizing && (
          <Button
            variant="flat"
            color="white"
            onClick={onClose}
            className={classes.button}
          >
            {t('CANCEL')}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}

export default React.memo(CreateToursDialog)
