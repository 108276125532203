import React from 'react'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import { ButtonBase, LinearProgress } from '@material-ui/core'

import Typography from '../../Typography'
import Icon from '../../Icon'

import useStyles from './useStyles'
import { NETWORK_TYPE } from '../../../../models/typings/TourOverview'
import { useSelector } from 'react-redux'
import * as fromWarehouse from '../../../../../selectors/warehouse'
import { capitalize } from '../../../../../utils/capitalize'

export interface Timeline {
  title?: string
  description?: string
  path?: string
  onClick?: () => void | Promise<unknown>
  disabled?: boolean
}

export interface TimelineHorizontalProps {
  /**
   * Always start at 0
   */
  current: number
  steps: Timeline[]
  variant?: 'success' | 'danger'
  className?: string
  networkType?: NETWORK_TYPE
}

const TimelineHorizontal: React.FC<TimelineHorizontalProps> = ({
  current,
  steps,
  variant = 'success',
  networkType,
  className,
}) => {
  const classes = useStyles()
  const currentNetworkReady = useSelector(fromWarehouse.getCurrentNetworkReady)

  const [t] = useTranslation()

  return (
    <div className={clsx(classes.root, className)}>
      {React.useMemo(
        () =>
          steps.map(({ title, description, onClick, disabled }, index) => {
            const inventoryDisabled =
              networkType === NETWORK_TYPE.DAY &&
              title === t('PLANNING_TIMELINE.INVENTORY_SCAN')
            const networkNotReady = !currentNetworkReady && index === 1

            const isPrevious = index < current
            const isCurrent = index === current
            const isNext = index > current
            const isLast = steps.length - 1 === index
            const progress = index > current ? 0 : 100

            return (
              <ButtonBase
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                className={clsx(classes.container, {
                  [classes.containerLast]: isLast,
                  [classes.disabled]: inventoryDisabled,
                })}
                onClick={!disabled ? onClick : undefined}
                disabled={inventoryDisabled}
              >
                <LinearProgress
                  variant="determinate"
                  value={progress}
                  classes={{
                    root: classes.progress,
                    bar: classes[
                      inventoryDisabled
                        ? 'disabledLinear'
                        : networkNotReady
                        ? 'danger'
                        : variant
                    ] as string,
                  }}
                />
                <div
                  className={clsx(
                    classes.titleContainer,
                    inventoryDisabled && classes.disabled,
                  )}
                >
                  {networkNotReady ? (
                    <Icon
                      fill="#FF3A37"
                      name="danger"
                      className={classes.icon}
                    />
                  ) : (
                    isCurrent && (
                      <Icon
                        fill="#2CE27E"
                        name="elipse-filled"
                        className={classes.icon}
                      />
                    )
                  )}
                  {isNext && !networkNotReady && (
                    <Icon name="elipse" className={classes.icon} />
                  )}
                  {inventoryDisabled && (
                    <Icon
                      name="warning"
                      fill="lightgrey"
                      className={classes.icon}
                    />
                  )}
                  {isPrevious && !inventoryDisabled && !networkNotReady && (
                    <Icon
                      fill="#2CE27E"
                      name="check-circle"
                      className={classes.icon}
                    />
                  )}
                  <div>
                    <Typography
                      variant="textBold"
                      className={clsx(
                        classes.title,
                        inventoryDisabled && classes.disabled,
                      )}
                    >
                      {index === 1
                        ? `${capitalize(
                            t(`NETWORK_TYPE.${networkType}_PLANNING`),
                          )}`
                        : title}
                    </Typography>
                    {description && (
                      <Typography
                        variant="tableHeader"
                        className={clsx(
                          classes.description,
                          inventoryDisabled && classes.disabled,
                        )}
                      >
                        {inventoryDisabled
                          ? t('NETWORK_TYPE.DISABLED')
                          : index === 1 && networkNotReady
                          ? t('PLANNING_TIMELINE.TOUR_PLANNING_DESC_2')
                          : description}
                      </Typography>
                    )}
                  </div>
                </div>
              </ButtonBase>
            )
          }),
        [classes, current, steps, variant, networkType, currentNetworkReady],
      )}
    </div>
  )
}

export default React.memo(TimelineHorizontal)
