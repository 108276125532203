import { ReasonCodeUnscannable } from 'Trunkrs-SDK/dist/typings/types'

import * as types from 'constants/ActionTypes'

interface ReasonCodeUnscannableState {
  reasonCodes: ReasonCodeUnscannable[]
  unScannableResult: 'success' | 'failed' | null
}

interface Action {
  type: any
  payload: any
}

const initialState: ReasonCodeUnscannableState = {
  reasonCodes: [],
  unScannableResult: null,
}

export default function reasonCodeUnscannable(
  state = initialState,
  action: Action,
) {
  switch (action.type) {
    case types.REASON_CODE_UNSCANNABLE_SET:
      return {
        ...state,
        reasonCodes: action.payload,
      }
    case types.REASON_CODE_UNSCANNABLE_FAILED:
      return {
        ...state,
        unScannableResult: 'failed',
      }
    case types.REASON_CODE_UNSCANNABLE_SUCCESS:
      return {
        ...state,
        unScannableResult: 'success',
      }
    case types.REASON_CODE_UNSCANNABLE_RESET:
      return {
        ...state,
        unScannableResult: null,
      }
    default:
      return state
  }
}
