import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box } from '@material-ui/core'
import clsx from 'clsx'

import { capitalize } from 'common/utils/String'

import Typography from 'common/components/base/Typography'

import useStyles from './useStyles'

const MonitoringLegends = () => {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <Box className={classes.root}>
      <Box className={classes.legends}>
        <Box className={clsx(classes.badge, classes.notScanned)} />
        <Typography>{capitalize(t('NOT_SCANNED_AT_HUB'))}</Typography>
      </Box>
      <Box className={classes.legends}>
        <Box className={clsx(classes.badge, classes.inTransit)} />
        <Typography>{capitalize(t('ON_ITS_WAY'))}</Typography>
      </Box>
      <Box className={classes.legends}>
        <Box className={clsx(classes.badge, classes.delivered)} />
        <Typography>{capitalize(t('DELIVERED'))}</Typography>
      </Box>
      <Box className={classes.legends}>
        <Box className={clsx(classes.badge, classes.deliveredToNeighbour)} />
        <Typography>{capitalize(t('DELIVERED_TO_NEIGHBOUR'))}</Typography>
      </Box>
      <Box className={classes.legends}>
        <Box className={clsx(classes.badge, classes.notDelivered)} />
        <Typography>{capitalize(t('NOT_DELIVERED'))}</Typography>
      </Box>
    </Box>
  )
}

export default MonitoringLegends
