import find from 'lodash/find'
import get from 'lodash/get'
import map from 'lodash/map'
import keys from 'lodash/keys'
import isFunction from 'lodash/isFunction'
import partialRight from 'lodash/partialRight'

export const leftFindJoin = (
  left, right, leftSelector, rightSelector, combiner,
) => {
  const selectLeftField = isFunction(leftSelector)
    ? leftSelector
    : partialRight(get, leftSelector)
  const selectRightField = isFunction(rightSelector)
    ? rightSelector
    : partialRight(get, rightSelector)

  return left.map(leftValue => {
    const leftField = selectLeftField(leftValue)
    const rightValue = find(right, value =>
      selectRightField(value) === leftField)

    return combiner(leftValue, rightValue)
  })
}

export const arrayify = (value) =>
  Array.isArray(value) ? value : [value]

export const resolveIndex = (index, dictionary) =>
  map(index, key => dictionary[key])

export const expandDictionary = dictionary =>
  resolveIndex(keys(dictionary), dictionary)

export const mergeIndexes = (...indexes) => {
  const [first, ...rest] = indexes
  return Array.from(new Set(first.concat(...rest)))
}
