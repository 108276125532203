/* eslint-disable no-unused-expressions */
/* eslint-disable @typescript-eslint/no-shadow */

import React, { memo, useEffect } from 'react'
import { useIMask } from 'react-imask'
import clsx from 'clsx'
import styles from './styles.module.scss'

const MaskedInput = memo(
  ({
    mask,
    isError,
    placeholder,
    value,
    onChange,
    typedValue,
    onMount,
    ...props
  }: React.DetailedHTMLProps<any, any> & {
    mask: string
    isError?: boolean
  }) => {
    const { ref, maskRef } = useIMask(
      { mask, placeholderChar: placeholder, lazy: false },
      {
        onAccept: (maskedValue, mask) =>
          onChange && onChange(maskedValue, mask.unmaskedValue),
      },
    )

    useEffect(() => {
      if (maskRef.current) {
        maskRef.current.typedValue = typedValue
        onMount && onMount(maskRef)
      }
      return () => onMount && onMount(undefined)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
      <input
        ref={ref}
        value={value}
        {...props}
        className={clsx(styles.maskedInput, isError && styles.error)}
      />
    )
  },
)

export default MaskedInput
