/* eslint-disable no-param-reassign, @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/no-shadow */
import { decode } from 'google-polyline'
import get from 'lodash/get'
import * as Sentry from '@sentry/browser'
import { isValidCollation } from 'actions/tours/helpers'

import { ShipmentTour } from 'common/models/typings/Tour'
import { Collation } from 'common/models/typings/Collation'
import tours from 'reducers/tour'
import { captureException } from '@sentry/browser'

interface TourModified extends Collation {
  tourId: number
}

export const googleRectangleOptions = {
  strokeColor: '#201047',
  strokeWeight: 1.5,
  fillColor: '#201047',
  fillOpacity: 0.1,
  clickable: false,
  bounds: undefined,
}

const mapToLatLngLiteral = (item: any) =>
  item && {
    lat: get(item, '[0]', null),
    lng: get(item, '[1]', null),
  }

export const decodePolyline = (lines: string) => {
  let polyline: google.maps.LatLngLiteral[] = []

  try {
    if (lines) {
      polyline = decode(lines).map(mapToLatLngLiteral)
    }
  } catch (err) {
    Sentry.captureException(err)
  }

  return polyline
}

export const getPositionsAndPolylines = (tours: ShipmentTour[]) => {
  const positionAndPolylines: {
    id: string
    position: google.maps.LatLngLiteral
    polyline: google.maps.LatLngLiteral[]
    strokeColor: string
    collationPos: number
    isMultiColli: boolean
    location: Collation['recipient']['location']
  }[] = [] // id is combination of tour id and collation id
  tours.forEach((tour) => {
    const { id, color, collations } = tour
    const filteredCollations = collations.filter(isValidCollation)
    filteredCollations.forEach((collation) => {
      const {
        id: collationId,
        recipient,
        polyline,
        isMultiColli,
        position,
      } = collation
      const { latitude, longitude } = recipient.location
      positionAndPolylines.push({
        id: `${id}|${collationId}`,
        position: { lat: latitude, lng: longitude },
        polyline: decodePolyline(polyline),
        strokeColor: color,
        collationPos: position,
        isMultiColli,
        location: recipient.location,
      })
    })
  })

  return positionAndPolylines
}

export const getValidMarkers = (tours: ShipmentTour[]) => {
  const markers = tours.reduce((acc: TourModified[], tour) => {
    const filtered = tour.collations
      .filter(isValidCollation)
      .map((collation: any) => ({ ...collation, tourId: tour.id }))
    acc = acc.concat(filtered)
    return acc
  }, [])
  return markers
}

export const getMarkerOptionUsingCollationId = (
  tours: ShipmentTour[],
  collationId: string,
) => {
  let tour: ShipmentTour | undefined
  let collation: Collation | undefined

  tours.forEach((tr) => {
    const index = tr.collations.findIndex((col) => col.id === collationId)
    if (index === -1) return

    tour = tr
    collation = tr.collations[index]
  })

  return {
    position: collation?.position,
    colour: tour?.color || '#cdcdcd',
    isMultiColli: collation?.isMultiColli,
  }
}
