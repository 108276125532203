import { createSelector } from 'reselect'
import filter from 'lodash/filter'
import values from 'lodash/values'
import orderBy from 'lodash/orderBy'
import { createFeatureSelector } from './core'
import { getAssignedDriverIds } from './tour'
import { getDrivers as driversFromProfile } from './profile'

export const selectFeature = createFeatureSelector('drivers')

export const getDrivers = createSelector(
  driversFromProfile,
  drivers => orderBy(filter(values(drivers), 'active'), [driver => driver.name.toLowerCase()]),
)

export const getAvailableDrivers = createSelector(
  [getDrivers, getAssignedDriverIds],
  (drivers, assignedDrivers) => drivers.filter(driver => !assignedDrivers.includes(driver.id)),
)
