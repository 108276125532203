import { normalize } from 'normalizr'

import { arrayify } from '../../utils/dataSets'
import { overviewTour } from '../../schema/schemas'

export const normalizeOverviewTours = (tours) => {
  const {
    result: overviewKeys,
    entities: { overviewTour: overviewData },
  } = normalize(arrayify(tours), [overviewTour])

  return [overviewKeys || [], overviewData || {}]
}
