import React from 'react'
import { useTranslation } from 'react-i18next'
import { Dialog, Box, IconButton, DialogProps } from '@material-ui/core'

import Icon from 'common/components/base/Icon'
import Success from './Sucess'
import Failed from './Failed'

import useStyles from './useStyles'

export interface HandoverDialogResultProps
  extends Omit<
    DialogProps,
    | 'fullWidth'
    | 'naxWidth'
    | 'PaperProps'
    | 'classes'
    | 'onClose'
    | 'disableBackdropClick'
    | 'disableEnforceFocus'
  > {
  shipment: any
  error: boolean
  onClose: (open?: boolean) => void
}

const HandoverDialogResult: React.FC<HandoverDialogResultProps> = ({
  shipment,
  error,
  open,
  onClose,
  ...others
}) => {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <Dialog
      disableEnforceFocus
      fullWidth
      maxWidth="md"
      open={open}
      PaperProps={{ elevation: 0 }}
      classes={{ paper: classes.paper }}
      onClose={() => onClose(false)}
      {...others}
    >
      <Box position="absolute" top={16} right={16}>
        <IconButton onClick={() => onClose(false)}>
          <Icon name="close" />
        </IconButton>
      </Box>
      {error ? <Failed /> : <Success shipment={shipment} />}
    </Dialog>
  )
}

export default HandoverDialogResult
