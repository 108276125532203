import React, { useCallback, useEffect, useRef } from 'react'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'

import Typography from 'common/components/base/Typography'
import useStyles from './useStyles'

import Lottie from '../../base/Lottie'
import day from '../../animations/animationFiles/day-network.json'
import { NETWORK_TYPE } from '../../../models/typings/TourOverview'
import { useDispatch } from 'react-redux'
// eslint-disable-next-line import/no-cycle
import { reloadShipments } from '../../../../actions/shipments'

import { selectNetworkType } from '../../../../actions/networkType'
import InventoryScanDialogWarning from '../InventoryScan/Dialog/Warning'
import AntSwitch from 'common/components/base/Switch'

import { Icon } from '../../base/.raw'
import { MiniCallout } from '../../base/.raw/.v2'
import { capitalize } from '../../../../utils/capitalize'
import { useLocation } from 'react-router-dom'

export const NetworkTypeHeader: React.FC<{
  networkType: NETWORK_TYPE
  usage: string
}> = ({ networkType, usage, children }) => {
  const classes = useStyles()
  const [t] = useTranslation()
  const dispatch = useDispatch()
  const location = useLocation()
  const [animationIsPaused, setAnimationIsPaused] = React.useState(true)
  const [networkWarningOpen, setNetworkWarningOpen] = React.useState(false)
  const [showNetworkSwitchCallout, setShowNetworkSwitchCallout] =
    React.useState(false)

  const isDay = networkType === NETWORK_TYPE.DAY
  const isNight = networkType === NETWORK_TYPE.NIGHT
  const timer = useRef<any>()

  const clearTimer = useCallback(() => {
    clearTimeout(timer.current)
  }, [timer.current])

  const handleProceed = React.useCallback(() => {
    setAnimationIsPaused(false)
    setTimeout(() => setAnimationIsPaused(true), 1800)
    if (isDay) dispatch(selectNetworkType(NETWORK_TYPE.NIGHT))
    if (isNight) dispatch(selectNetworkType(NETWORK_TYPE.DAY))
    setNetworkWarningOpen(false)

    if (location.pathname.includes('/planning')) {
      setShowNetworkSwitchCallout(true)
      clearTimer()
      timer.current = setTimeout(() => {
        setShowNetworkSwitchCallout(false)
      }, 5000)
    }
    dispatch(reloadShipments())
  }, [networkType, location])

  const handleClose = useCallback(() => {
    //warehouseAction.getNetworkStatus(moment().format('YYYY-MM-DD'), networkType)
    setNetworkWarningOpen(false)
  }, [networkType])

  return (
    <div className={clsx(classes.root, isDay && classes.day)}>
      <div className={classes.AntSwitch}>
        <AntSwitch
          onClick={() => setNetworkWarningOpen(true)}
          checked={isDay}
        />
        <InventoryScanDialogWarning
          open={networkWarningOpen}
          onProceed={handleProceed}
          onClose={handleClose}
          title={t('NETWORK_TYPE.WARNING_TITLE', {
            networkType: isDay
              ? t(`NETWORK_TYPE.${NETWORK_TYPE.NIGHT}`).toLowerCase()
              : t(`NETWORK_TYPE.${NETWORK_TYPE.DAY}`).toLowerCase(),
            usage: usage.toLowerCase(),
          })}
          content={t('NETWORK_TYPE.WARNING_CONTENT', {
            targetNetworkType: isDay
              ? capitalize(t(`NETWORK_TYPE.${NETWORK_TYPE.NIGHT}`))
              : capitalize(t(`NETWORK_TYPE.${NETWORK_TYPE.DAY}`)),
            networkType: isDay
              ? capitalize(t(`NETWORK_TYPE.${NETWORK_TYPE.DAY}`))
              : capitalize(t(`NETWORK_TYPE.${NETWORK_TYPE.NIGHT}`)),
            usage: usage.toLowerCase(),
          })}
          backButton={t('RETURNS_SCAN.ACKNOWLEDGEMENT.BACK')}
          proceedButton={
            <span className={classes.dialogButtonWrapper}>
              <Icon
                className={classes.dialogIcon}
                name={isDay ? 'moon' : 'sun'}
              />
              {t('NETWORK_TYPE.WARNING_BUTTON', {
                networkType: isDay
                  ? capitalize(t(`NETWORK_TYPE.${NETWORK_TYPE.NIGHT}`))
                  : capitalize(t(`NETWORK_TYPE.${NETWORK_TYPE.DAY}`)),
                usage: usage.toLowerCase(),
              })}
            </span>
          }
        />
        <Typography
          variant="headerRegular"
          transform="capitalize"
          className={classes.header}
        >
          {t('NETWORK_TYPE.HEADER', {
            networkType: isDay
              ? t(`NETWORK_TYPE.${NETWORK_TYPE.DAY}`).toLowerCase()
              : t(`NETWORK_TYPE.${NETWORK_TYPE.NIGHT}`).toLowerCase(),
            usage: usage.toLowerCase(),
          })}
        </Typography>
      </div>
      <div>
        <MiniCallout variant="info" visible={showNetworkSwitchCallout}>
          {t('NETWORK_TYPE.SWITCH_NETWORK_CALLOUT', {
            networkType: isDay
              ? t(`NETWORK_TYPE.${NETWORK_TYPE.DAY}`).toLowerCase()
              : t(`NETWORK_TYPE.${NETWORK_TYPE.NIGHT}`).toLowerCase(),
          })}
        </MiniCallout>
        {children}
      </div>
      <div className={classes.lottie}>
        <Lottie
          key={networkType}
          animationData={day}
          isAutoPlay={false}
          isLoop={false}
          isPaused={animationIsPaused}
          height={350}
          width={350}
          segments={isDay ? [0, 50] : [100, 200]}
        />
      </div>
    </div>
  )
}

export default NetworkTypeHeader
