import createStyles from '@material-ui/core/styles/createStyles'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { Theme } from '@material-ui/core/styles'

const styles = ({ spacing, palette }: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'stretch',
      alignItems: 'flex-start',
      minWidth: '80%',
      height: 'fit-content',
      '&$disabled': {
        color: 'grey !important',
      },
    },
    container: {
      flex: '1 1 0',
      width: 0,
      flexDirection: 'column',
      alignItems: 'flex-start',
      marginRight: 4,
    },
    containerLast: {
      marginRight: 'unset',
    },
    progress: {
      width: '100%',
      borderRadius: 100,
      marginBottom: 8,
    },
    success: {
      backgroundColor: palette.trunkrs.primary.green.base,
    },
    disabledLinear: {
      backgroundColor: palette.trunkrs.grayscale.lighter,
    },
    danger: {
      backgroundColor: 'var(--plannr-danger)',
    },
    titleContainer: {
      display: 'flex',
    },
    titleInner: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      '&$disabled': {
        color: palette.trunkrs.grayscale.lighter,
      },
    },
    title: {
      width: 'fit-content',
      '&$disabled': {
        color: palette.trunkrs.grayscale.lighter,
      },
    },
    icon: {
      width: 16,
      height: 16,
      marginRight: 8,
      marginTop: 2,
    },
    description: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      color: palette.trunkrs.grayscale.theBeast,
      lineHeight: '16px',
      width: '100%',
      fontWeight: 'normal',
      fontFamily: 'ClanPro-News, san-serif',
      // whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      textAlign: 'left',
    },
    badgeWrapper: {
      marginLeft: '18px',
    },
    disabled: {},
  })

export default makeStyles(styles)
