import React, { useCallback, useMemo, useState } from 'react'
import clsx from 'clsx'

import { DialogTitle, DialogContent, DialogActions } from '@material-ui/core'
import Typography from 'common/components/base/Typography'
import { Driver } from 'common/models/typings/Driver'
import { ShipmentTour, Tour } from 'common/models/typings/Tour'

import { FUEL_TYPES } from 'constants/FuelTypes'
import * as fromDrivers from 'selectors/driver'
import { useSelector } from 'react-redux'
import FuelToggle from './FuelToggle'
import { FuelName, FuelType } from 'common/models/typings/FuelType'
import Button from 'common/components/base/.raw/Button'
import ActionButton from 'common/components/base/Button'

import useStyles from './useStyles'
import { useTranslation } from 'react-i18next'
import { getShipmentTours } from 'selectors/combined'

export interface DriverSelectProps {
  tour: Tour
  onConfirmChange: (driver: Driver, vehicle: FuelName) => void
  onGoBack: () => void
}

interface DriverSelection {
  [x: string]: {
    drivers: Driver[]
  }
}

export const DriverSelect: React.FC<DriverSelectProps> = ({
  tour,
  onConfirmChange,
  onGoBack,
}) => {
  const { t } = useTranslation()
  const tours: ShipmentTour[] = useSelector(getShipmentTours)

  const drivers: Driver[] = useSelector(fromDrivers.getDrivers)

  const [selectedDriverAndVehicle, setSelectedDriverAndVehicle] = useState({
    driver: drivers.find((driver) => driver.id === tour.driverId),
    vehicle: tour.vehicleType,
  })

  const classes = useStyles()

  const groupedDrivers = useMemo(() => {
    const grouped = drivers.reduce<DriverSelection>((prev, curr) => {
      const group = curr.name.charAt(0).toUpperCase()
      const accumulated = prev

      if (!accumulated[group]) {
        accumulated[group] = { drivers: [curr] }
      } else {
        accumulated[group].drivers.push(curr)
      }

      return accumulated
    }, {})

    return grouped
  }, drivers)

  const handleSelect = useCallback((driver: Driver, vehicle: FuelName) => {
    setSelectedDriverAndVehicle({ driver, vehicle })
  }, [])

  const handleConfirm = useCallback(() => {
    const { driver, vehicle } = selectedDriverAndVehicle

    if (!driver || !vehicle) return

    onConfirmChange(driver, vehicle)
  }, [onConfirmChange, selectedDriverAndVehicle])

  const selectedDriverIds = React.useMemo(
    () =>
      tours.filter((value) => value.id !== tour.id).map((ftr) => ftr.driverId),
    [tours, tour],
  )

  const isChanged =
    (selectedDriverAndVehicle.driver &&
      selectedDriverAndVehicle.driver.id !== tour.driverId) ||
    selectedDriverAndVehicle.vehicle !== tour.vehicleType

  const getOptionDisabled = (param: Driver) =>
    selectedDriverIds.includes(param.id)

  return (
    <>
      <DialogTitle disableTypography>
        <Typography variant="h3" color="indigo">
          Select a new driver and vehicle
        </Typography>
      </DialogTitle>
      <DialogContent style={{ overflowY: 'visible' }}>
        <div className={classes.selectionRoot}>
          {Object.keys(groupedDrivers).map((group) => (
            <>
              <Typography variant="link" className={classes.groupLabel}>
                {group[0]}
              </Typography>

              {groupedDrivers[group].drivers.map((driver) => (
                <Button
                  disabled={getOptionDisabled(driver)}
                  onClick={() =>
                    handleSelect(driver, FUEL_TYPES[0].name as FuelName)
                  }
                  className={clsx(
                    classes.selectItem,
                    selectedDriverAndVehicle.driver &&
                      selectedDriverAndVehicle.driver.id === driver.id &&
                      classes.active,
                    getOptionDisabled(driver) && classes.disabled,
                  )}
                >
                  <Typography variant="link">{driver.name}</Typography>

                  <FuelToggle
                    selectedVehicle={selectedDriverAndVehicle.vehicle}
                    onSelect={handleSelect}
                    selectedDriver={selectedDriverAndVehicle.driver}
                    driver={driver}
                  />
                </Button>
              ))}
            </>
          ))}
        </div>
      </DialogContent>
      <DialogActions className={classes.actionsContainer}>
        <ActionButton disabled={!isChanged} onClick={handleConfirm}>
          {t('CONFIRM')}
        </ActionButton>
        <ActionButton variant="flat" color="white" onClick={onGoBack}>
          {t('GO_BACK')}
        </ActionButton>
      </DialogActions>
    </>
  )
}

export default DriverSelect
