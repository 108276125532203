/* eslint-disable import/no-cycle */

import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  List,
  ListItem,
  Fade,
  Box,
  Grid,
  Chip,
  ListItemText,
  ListItemSecondaryAction,
} from '@material-ui/core'
import clsx from 'clsx'
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from 'react-sortable-hoc'

import memoize from 'memoize-one'
import moment from 'moment'
import get from 'lodash/get'

import MoreVertIcon from '@material-ui/icons/MoreVert'
import { faArrowsAltV } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { useDispatch, useSelector } from 'react-redux'
import { getIsPlanningLocked } from 'selectors/tour'
import { areToursLocked, getShipmentTours } from 'selectors/combined'
import { changeCollationPosition, moveSingle } from 'actions/tours'

import { isValidCollation } from 'actions/tours/helpers'
import { getLastStopTime, isPastDeadLine } from 'utils'
import { contrastColor } from 'common/utils/Color'
import { ShipmentTour, Tour } from 'common/models/typings/Tour'
import { Collation } from 'common/models/typings/Collation'

import Typography from 'common/components/base/Typography'

import styles from './styles.module.scss'
import useStyles from '../useStyles'
import { NETWORK_TYPE } from '../../../../../models/typings/TourOverview'
import * as fromNetworkType from '../../../../../../selectors/networkType'

interface HandleProps {
  className?: string
}

interface SortableItemElementProps {
  id: ShipmentTour['id']
  locked: boolean
  collation: Collation
}

export interface SortableListProps {
  id: ShipmentTour['id']
  collations: Collation[]
}

const Handle = SortableHandle(({ className }: HandleProps) => (
  <div className={clsx(styles.dragHandle, className)}>
    <FontAwesomeIcon icon={faArrowsAltV} />
  </div>
))

const SortableItemElement = SortableElement(
  ({ id, collation, locked }: SortableItemElementProps) => {
    const { t } = useTranslation()
    const classes = useStyles()
    const dispatch = useDispatch()
    const {
      estimation,
      position,
      recipient: { location },
      isMultiColli,
      shipmentIds,
    } = collation

    const tours = useSelector(getShipmentTours)
    const networkType: NETWORK_TYPE = useSelector(
      fromNetworkType.getNetworkType,
    )

    const [active, setActive] = React.useState(false)
    const [open, setOpen] = React.useState(false)

    const getStartTime = React.useCallback(
      (collations: Collation[]) =>
        moment(get(collations, '[0].estimation')).format('HH:mm'),
      [],
    )

    const getEndDetails = React.useCallback((collations: Collation[]) => {
      return {
        endTime: moment(getLastStopTime(collations)).format('HH:mm'),
        pastDeadline: isPastDeadLine(collations, networkType),
      }
    }, [])

    const handleMoveTour = (
      tourId: ShipmentTour['id'],
      newTourId: ShipmentTour['id'],
      collationId: Collation['id'],
    ) => {
      dispatch(moveSingle(tourId, newTourId, collationId))
    }

    const getMenuItems = memoize((accu: ShipmentTour[], tourId) => {
      return accu.map((item) => {
        return (
          item.id !== tourId && (
            <ListItem
              button
              disableGutters
              key={item.id}
              onClick={() => handleMoveTour(tourId, item.id, collation.id)}
              disabled={item.isWorking}
              className={styles.itemRoot}
            >
              <Box
                className={classes.listItemMarker}
                style={{
                  background: item.color,
                  color: contrastColor(item.color),
                }}
              >
                {item.number}
              </Box>
              <ListItemText
                primary={
                  <>
                    <Typography variant="packageText" color="indigo">
                      {getStartTime(item.collations)}
                      {' - '}
                    </Typography>
                    <Typography variant="packageText" color="indigo">
                      {getEndDetails(item.collations).endTime}
                    </Typography>
                  </>
                }
                style={{ marginLeft: 32 }}
              />
              <ListItemSecondaryAction>
                <Typography variant="packageText" color="indigo">
                  {item.collations
                    .filter(isValidCollation as any)
                    .reduce((a, b) => a + b.shipmentIds.length || a, 0)}
                </Typography>
              </ListItemSecondaryAction>
            </ListItem>
          )
        )
      })
    })

    let menuItems
    if (!locked) {
      menuItems = getMenuItems(tours, id)
    }

    const mouseEnter = () => {
      setActive(true)
    }

    const mouseLeave = () => {
      setActive(false)
    }

    const handleClick = () => {
      setOpen(!open)
    }

    return (
      <Box
        className={clsx(
          styles.menu,
          collation.isBermudaStop && styles.bermudaIndicator,
        )}
      >
        <div onMouseEnter={mouseEnter} onMouseLeave={mouseLeave}>
          <Grid container>
            <Grid container item>
              <Grid item xs={10} lg={11}>
                {active ? (
                  <Handle
                    className={clsx(
                      { [styles.hover]: active },
                      collation.isBermudaStop && styles.dragHandleButton,
                    )}
                  />
                ) : (
                  <div
                    className={clsx(
                      styles.dragHandle,
                      collation.isBermudaStop && styles.dragHandleButton,
                    )}
                  >
                    {position}
                  </div>
                )}
                <div className={styles.customerInfo}>
                  <span className={styles.address}>{location.address}</span>
                  <span>
                    {location.postalCode} {location.city}
                  </span>
                </div>
              </Grid>
              <Grid item xs={2} lg={1} className={styles['pull-right']}>
                <span className={styles.estimation}>
                  {!!estimation && moment(estimation).format('HH:mm')}
                </span>
                <span className={styles['more-icon-container']}>
                  {!locked && tours.length >= 2 && (
                    <MoreVertIcon
                      className={styles.moreIcon}
                      onClick={handleClick}
                    />
                  )}
                </span>
              </Grid>
            </Grid>
            {isMultiColli && (
              <div className={styles.barPositioning}>
                <Chip
                  label={`${shipmentIds.length} ${t('AMOUNT_OF_PACKAGES')}`}
                />
              </div>
            )}
          </Grid>
        </div>
        <Fade style={{ display: open ? 'block' : 'none' }} in={open}>
          <List
            classes={{
              root: styles.listRoot,
            }}
          >
            <span className={styles.listTitle}>{t('MOVE_TOUR')}</span>
            <Box className={styles.boxScroll}>
              <List disablePadding className={styles.boxRoot}>
                {menuItems}
              </List>
            </Box>
          </List>
        </Fade>
      </Box>
    )
  },
)

const SortableList: React.FC<SortableListProps> = ({ id, collations }) => {
  const locked = useSelector(areToursLocked)
  const isPlanningLocked = useSelector(getIsPlanningLocked)

  const isTourLocked = React.useMemo(
    () => locked || isPlanningLocked,
    [locked, isPlanningLocked],
  )

  return (
    <>
      {collations.filter(isValidCollation as any).map((collation, index) => (
        <SortableItemElement
          key={collation.id}
          id={id}
          index={index}
          locked={isTourLocked}
          collation={collation}
        />
      ))}
    </>
  )
}

const SortableItem = SortableContainer(SortableList)

export default SortableItem
