/* eslint-disable import/no-cycle */

import React from 'react'

import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'

import Router from 'routes/Router'
import configureStore from 'store/configureStore'

import ThemeProvider from 'common/theme/mui/Provider'

import DialogLoader from 'common/components/base/Dialog/Loader'

import * as uiActions from 'actions/ui'

// initialize Trunkrs SDK
import trunkrs from 'common/utils/Sdk'

// initialize i18next
import 'common/i18n'

import 'common/theme/index.scss'

export const { store, persistor } = configureStore({})

const App = () => {
  const [isLoading, setIsLoading] = React.useState<boolean>(true)

  const isLoggedIn = React.useCallback(async () => {
    const loggedIn = await trunkrs.Auth().checkAccess()
    store.dispatch(uiActions.setIsAuthenticated(loggedIn))
    setIsLoading(false)
  }, [])

  React.useEffect(() => {
    isLoggedIn()
  }, [isLoggedIn])

  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <ThemeProvider>
          {isLoading ? <DialogLoader open /> : <Router />}
        </ThemeProvider>
      </PersistGate>
    </Provider>
  )
}

export default App
