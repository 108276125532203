import * as actionTypes from '../constants/ActionTypes'

const initialState = {
  hasFetched: false,
  isLoading: false,
  drivers: 0,
  shipments: 0,
  remaining: 0,
  hours: [],
  minutes: [],
  seconds: [],
}

export default function shipmentAndDriverEstimation(state = initialState, { type, payload }) {
  switch (type) {
  case actionTypes.SHIPMENT_AND_DRIVER_ESTIMATION_LOADING:
    return {
      ...state,
      isLoading: true,
      hasFetched: false,
    }
  case actionTypes.SHIPMENT_AND_DRIVER_ESTIMATION_SUCCESS:
    return {
      ...state,
      isLoading: false,
      hasFetched: true,
      drivers: payload.drivers,
      shipments: payload. shipments,
    }
  case actionTypes.SHIPMENT_AND_DRIVER_ESTIMATION_FAIL:
    return {
      ...state,
      isLoading: false,
      hasFetched: true,
    }
  case actionTypes.SHIPMENT_AND_DRIVER_ESTIMATION_COUNTDOWN:
    return {
      ...state,
      remaining: payload.remaining,
      hours: payload.hours,
      minutes: payload.minutes,
      seconds: payload.seconds,
    }
  default:
    return state
  }
}

export const hasFetched = state => state.hasFetched
export const isLoading = state => state.isLoading
export const shipments = state => state.shipments
export const drivers = state => state.drivers
export const remaining = state => state.remaining
export const hours = state => state.hours
export const minutes = state => state.minutes
export const seconds = state => state.seconds
