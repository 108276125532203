import React from 'react'
import { useTranslation } from 'react-i18next'
import get from 'lodash/get'

import { Shipment } from 'common/models/typings/Shipment'

import StatusBadge from 'common/components/base/.raw/StatusBadge'

import { isCancelled, isDataProcessed } from 'utils/state-checker'

export interface ShipmentStateBadgeProps {
  shipment: Shipment
}

const ShipmentStateBadge: React.FC<ShipmentStateBadgeProps> = ({
  shipment,
}) => {
  const { t } = useTranslation()

  const getBadgeType = React.useCallback(
    (state: Shipment['State'], hasTour: Shipment['HasTourAssigned']) => {
      if (isCancelled(state)) {
        return {
          label: t('SHIPMENTS_OVERVIEW.STATE_CANCELED'),
          variant: 'danger',
        }
      }
      if (isDataProcessed(state)) {
        return {
          label: t('SHIPMENTS_OVERVIEW.STATE_EXPECTED'),
          variant: 'dark',
        }
      }
      if (hasTour) {
        return {
          label: t('SHIPMENTS_OVERVIEW.STATE_HAS_TOUR'),
          variant: 'primaryPurple',
        }
      }
      return {
        label: t('SHIPMENTS_OVERVIEW.STATE_NO_TOUR'),
        variant: 'primary',
      }
    },
    [t],
  )

  const { label, variant } = getBadgeType(
    get(shipment, 'State'),
    get(shipment, 'HasTourAssigned', false),
  )

  return <StatusBadge variant={variant as any}>{label}</StatusBadge>
}

export default React.memo(ShipmentStateBadge)
