import createStyles from '@material-ui/core/styles/createStyles'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { Theme } from '@material-ui/core/styles'

const styles = ({ palette, spacing }: Theme) =>
  createStyles({
    label: {
      marginBottom: spacing(1),
      height: spacing(2),
      overflow: 'hidden',
      color: palette.trunkrs.primary.indigo.base,
      fontWeight: 'bold',
      lineHeight: '20px',

      display: 'flex',
      alignItems: 'center',
      textTransform: 'capitalize',
    },
    labelChip: {
      height: '100%',
      marginLeft: spacing(1),
      '& > span': {
        height: spacing(2),
        fontSize: 10,
        fontWeight: 475,
        color: palette.trunkrs.primary.indigo.base,
        borderRadius: spacing(1),
        display: 'flex',
        alignItems: 'center',
        paddingLeft: 4,
        paddingRight: 4,
      },
    },
    helperText: {},
  })

export default makeStyles(styles)
