import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const styles = ({ spacing }: Theme) =>
  createStyles({
    callout: {
      marginBottom: spacing(2),
    },
    center: {
      alignItems: 'center',
    },
  })

export default makeStyles(styles)
