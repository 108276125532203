/* eslint-disable import/no-cycle */

import React from 'react'
import { useTranslation } from 'react-i18next'

import PercentageCard from 'common/components/base/.raw/PercentageCard'

import styles from './styles.module.scss'

export interface ShipmentStateDashboardProps {
  sortedAtRegionalHub: any[]
  notSortedAtRegionalHub: any[]
  acceptebByDrivers: any[]
  notAcceptedByDrivers: any[]
  withEndState: any[]
  withoutEndState: any[]
  deliveredToNeighbour: any[]
  notDelivered: any[]
}

const ShipmentStateDashboard: React.FC<ShipmentStateDashboardProps> = ({
  sortedAtRegionalHub,
  notSortedAtRegionalHub,
  acceptebByDrivers,
  notAcceptedByDrivers,
  withEndState,
  withoutEndState,
  deliveredToNeighbour,
  notDelivered,
}) => {
  const { t } = useTranslation()

  return (
    <div className={styles.container}>
      <PercentageCard
        caption={t('SHIPMENTS_SORTED_AT_REGIONAL_HUB')}
        details={t('SHIPMENTS_OVERVIEW.OUT_OF_MAXCOUNT', {
          count: sortedAtRegionalHub.length,
          maxCount: sortedAtRegionalHub.length + notSortedAtRegionalHub.length,
        })}
        percentage={
          sortedAtRegionalHub.length /
          (sortedAtRegionalHub.length + notSortedAtRegionalHub.length)
        }
        variant="sorted"
      />
      <PercentageCard
        caption={t('SHIPMENTS_ACCEPTED_BY_DRIVERS')}
        details={t('SHIPMENTS_OVERVIEW.OUT_OF_MAXCOUNT', {
          count: acceptebByDrivers.length,
          maxCount: acceptebByDrivers.length + notAcceptedByDrivers.length,
        })}
        percentage={
          acceptebByDrivers.length /
          (acceptebByDrivers.length + notAcceptedByDrivers.length)
        }
        variant="accepted"
      />
      <PercentageCard
        caption={t('SHIPMENTS_WITH_END_STATE')}
        details={t('SHIPMENTS_OVERVIEW.OUT_OF_MAXCOUNT', {
          count: withEndState.length,
          maxCount: withEndState.length + withoutEndState.length,
        })}
        percentage={
          withEndState.length / (withEndState.length + withoutEndState.length)
        }
        variant="endState"
      />
      <PercentageCard
        caption={t('NEIGHBOUR_DELIVERY_VS_NON_DELIVERY')}
        details={`${deliveredToNeighbour.length} : ${notDelivered.length}`}
        percentage={
          deliveredToNeighbour.length /
          (deliveredToNeighbour.length + notDelivered.length)
        }
        variant="neighbourNonDelivery"
      />
    </div>
  )
}

export default React.memo(ShipmentStateDashboard)
