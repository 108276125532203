import { createSelector } from 'reselect'
import moment from 'moment'
import first from 'lodash/first'
import flatMap from 'lodash/flatMap'
import get from 'lodash/get'
import round from 'lodash/round'
import isEmpty from 'lodash/isEmpty'

import * as fromTours from '../tour'
import * as fromShipments from '../shipments'
import * as fromCollations from '../collations'
import * as fromDriver from '../driver'
import * as fromProfile from '../profile'
import * as fromHublogs from '../hubLogs'
import * as fromWarehouse from '../warehouse'

import {
  mapOverviewShipment,
  mapTourForOverview,
  mapToToursForOverview,
  mapToToursForHandover,
  mapDriverToTour,
} from './helpers'
import { denormalizeTours } from '../../schema'
import { getTotalDuration as computeTotalDuration } from '../../utils/getTotalDuration'
import { getTotalStops } from '../../utils/getTotalStops'
import { isNaNOrInfinity, getLastStopTime } from '../../utils'
import { SHIPMENT_STATES } from 'Trunkrs-SDK/dist/typings/types/Shipment'
import { lockedTourStates, plannedTourStates } from '../../constants/tours'
import { isValidCollation } from '../../actions/tours/helpers'
import { getNetworkType } from '../networkType'
import { NETWORK_TYPE } from '../../common/models/typings/TourOverview'

export const getOverviewShipments = createSelector(
  fromShipments.getShipments,
  fromTours.getData,
  (shipments, tourData) =>
    shipments
      .filter((shipment) => shipment.id > 0)
      .map((shipment) => mapOverviewShipment(shipment, tourData)),
)

export const getTours = createSelector(
  fromTours.getIdentifiers,
  fromTours.getData,
  fromCollations.getData,
  denormalizeTours,
)

export const getToursWithDriver = createSelector(
  getTours,
  fromDriver.selectFeature,
  (tours, drivers) => tours.map((tour) => mapDriverToTour(tour, drivers)),
)

export const getEditTour = createSelector(
  fromTours.getData,
  fromCollations.getData,
  fromTours.getEditTourId,
  fromShipments.getShipments,
  (tourData, collationData, id, shipments) =>
    id &&
    mapTourForOverview(
      shipments,
      first(denormalizeTours(id, tourData, collationData)),
    ),
)

export const areToursLocked = createSelector(
  getTours,
  (tours) =>
    !!tours.length &&
    tours.every((tour) => lockedTourStates.includes(tour.state)),
)

export const areToursPlanned = createSelector(
  getTours,
  (tours) =>
    !!tours.length &&
    tours.every((tour) => plannedTourStates.includes(tour.state)),
)

export const allToursHaveDrivers = createSelector(
  getTours,
  (tours) => !!tours.length && tours.every((tour) => !!tour.driverId),
)

export const allToursHaveIds = createSelector(
  getTours,
  (tours) =>
    !!tours.length && tours.every((tour) => typeof tour.id === 'number'),
)

export const getShipmentTours = createSelector(
  getTours,
  fromShipments.getShipments,
  mapToToursForOverview,
)

export const getValidTours = createSelector(getShipmentTours, (tours) =>
  tours.reduce((acc, tour) => {
    const filtered = tour.collations
      .filter(isValidCollation)
      .map((collation) => ({ ...collation, tourId: tour.id }))
    acc = acc.concat(filtered)
    return acc
  }, []),
)

export const getShipmentToursCount = createSelector(
  getShipmentTours,
  (tours) => tours.length,
)

export const allToursAreDoneAtOptimizedTime = createSelector(
  getShipmentTours,
  getNetworkType,
  (tours, networkType) =>
    !tours.find((tour) => {
      const timestamp = getLastStopTime(get(tour, 'collations'))
      return timestamp && timestamp.isAfter(moment({ h: networkType === NETWORK_TYPE.DAY ? 16 : 22 }))
    }),
)

export const getShipmentToursHandovered = createSelector(
  getTours,
  fromShipments.getShipments,
  mapToToursForHandover,
)

export const getShipmentIdsWithCollations = createSelector(
  fromCollations.getData,
  (collations) =>
    flatMap(collations, (collation) =>
      collation.shipmentIds.filter((id) => id >= 0),
    ),
)

export const getShipmentIdsWithCollationsNoCancel = createSelector(
  fromCollations.getData,
  (collations) =>
    flatMap(collations, (collation) =>
      collation.shipmentIds.filter((id) => id >= 0),
    ),
)

export const getRegionProductivity = createSelector(getTours, (tours) => {
  if (Array.isArray(tours)) {
    const totalDuration = tours.reduce(
      (totalDuration, tour) =>
        totalDuration + computeTotalDuration(get(tour, 'collations', [])),
      0,
    )
    const totalStops = tours.reduce(
      (totalStops, tour) =>
        totalStops + getTotalStops(get(tour, 'collations', [])),
      0,
    )
    const productivity = totalStops / (totalDuration / 60)
    return isNaNOrInfinity(productivity) ? 0 : round(productivity, 2)
  }
  return 0
})

export const getShipmentsCentralSorted = createSelector(
  fromShipments.getShipments,
  fromTours.getData,
  (shipments, tourData) =>
    shipments
      .filter(
        (shipment) =>
          shipment.id > 0 &&
          shipment.state.name !== SHIPMENT_STATES.DATA_RECEIVED &&
          shipment.state.name !== SHIPMENT_STATES.DATA_PROCESSED &&
          shipment.state.name !==
            SHIPMENT_STATES.EXCEPTION_SHIPMENT_CANCELLED_BY_SENDER &&
          shipment.state.name !==
            SHIPMENT_STATES.EXCEPTION_SHIPMENT_CANCELLED_BY_TRUNKRS &&
          shipment.state.name !==
            SHIPMENT_STATES.EXCEPTION_SHIPMENT_NOT_ARRIVED &&
          shipment.state.name !== SHIPMENT_STATES.EXCEPTION_SHIPMENT_MIS_SORTED,
      )
      .map((shipment) => mapOverviewShipment(shipment, tourData)),
)

export const getShipmentsSortedAtRegionalHub = createSelector(
  fromShipments.getShipments,
  fromTours.getData,
  (shipments, tourData) =>
    shipments
      .filter(
        (shipment) =>
          (shipment.state.name ===
            SHIPMENT_STATES.SHIPMENT_SORTED_AT_SUB_DEPOT ||
            shipment.state.name ===
              SHIPMENT_STATES.SHIPMENT_ACCEPTED_BY_DRIVER ||
            shipment.state.name === SHIPMENT_STATES.SHIPMENT_DELIVERED ||
            shipment.state.name ===
              SHIPMENT_STATES.SHIPMENT_DELIVERED_TO_NEIGHBOR ||
            shipment.state.name === SHIPMENT_STATES.SHIPMENT_NOT_DELIVERED) &&
          shipment.recipient.location.latitude &&
          shipment.recipient.location.latitude,
      )
      .map((shipment) => mapOverviewShipment(shipment, tourData)),
)

export const getShipmentsNotSortedAtRegionalHub = createSelector(
  fromShipments.getShipments,
  fromTours.getData,
  (shipments, tourData) =>
    shipments
      .filter(
        (shipment) =>
          shipment.state.name === SHIPMENT_STATES.SHIPMENT_SORTED &&
          // || shipment.state.name === SHIPMENT_STATES.DATA_RECEIVED
          // || shipment.state.name === SHIPMENT_STATES.DATA_PROCESSED
          shipment.recipient.location.latitude &&
          shipment.recipient.location.latitude,
      )
      .map((shipment) => mapOverviewShipment(shipment, tourData)),
)

export const getShipmentsAcceptedByDrivers = createSelector(
  fromShipments.getShipments,
  fromTours.getData,
  (shipments, tourData) =>
    shipments
      .filter(
        (shipment) =>
          shipment.state.name === SHIPMENT_STATES.SHIPMENT_ACCEPTED_BY_DRIVER &&
          shipment.recipient.location.latitude &&
          shipment.recipient.location.latitude,
      )
      .map((shipment) => mapOverviewShipment(shipment, tourData)),
)

export const getShipmentsNotAcceptedByDrivers = createSelector(
  fromShipments.getShipments,
  fromTours.getData,
  (shipments, tourData) =>
    shipments
      .filter(
        (shipment) =>
          shipment.state.name ===
            SHIPMENT_STATES.SHIPMENT_SORTED_AT_SUB_DEPOT &&
          shipment.recipient.location.latitude &&
          shipment.recipient.location.latitude,
      )
      .map((shipment) => mapOverviewShipment(shipment, tourData)),
)

export const getShipmentsWithEndState = createSelector(
  fromShipments.getShipments,
  fromTours.getData,
  (shipments, tourData) =>
    shipments
      .filter(
        (shipment) =>
          (shipment.state.name === SHIPMENT_STATES.SHIPMENT_DELIVERED ||
            shipment.state.name ===
              SHIPMENT_STATES.SHIPMENT_DELIVERED_TO_NEIGHBOR ||
            shipment.state.name === SHIPMENT_STATES.SHIPMENT_NOT_DELIVERED) &&
          shipment.recipient.location.latitude &&
          shipment.recipient.location.latitude,
      )
      .map((shipment) => mapOverviewShipment(shipment, tourData)),
)

export const getShipmentsWithoutEndState = createSelector(
  fromShipments.getShipments,
  fromTours.getData,
  (shipments, tourData) =>
    shipments
      .filter(
        (shipment) =>
          shipment.state.name === SHIPMENT_STATES.SHIPMENT_ACCEPTED_BY_DRIVER &&
          shipment.recipient.location.latitude &&
          shipment.recipient.location.latitude,
      )
      .map((shipment) => mapOverviewShipment(shipment, tourData)),
)

export const getShipmentsDeliveredToNeighbour = createSelector(
  fromShipments.getShipments,
  fromTours.getData,
  (shipments, tourData) =>
    shipments
      .filter(
        (shipment) =>
          shipment.state.name ===
            SHIPMENT_STATES.SHIPMENT_DELIVERED_TO_NEIGHBOR &&
          shipment.recipient.location.latitude &&
          shipment.recipient.location.latitude,
      )
      .map((shipment) => mapOverviewShipment(shipment, tourData)),
)

export const getShipmentsNotDelivered = createSelector(
  fromShipments.getShipments,
  fromTours.getData,
  (shipments, tourData) =>
    shipments
      .filter(
        (shipment) =>
          shipment.state.name === SHIPMENT_STATES.SHIPMENT_NOT_DELIVERED &&
          shipment.recipient.location.latitude &&
          shipment.recipient.location.latitude,
      )
      .map((shipment) => mapOverviewShipment(shipment, tourData)),
)

export const getShipmentsCancelled = createSelector(
  fromShipments.getShipments,
  fromTours.getData,
  (shipments, tourData) =>
    shipments
      .filter(
        (shipment) =>
          shipment.state.name ===
            SHIPMENT_STATES.EXCEPTION_SHIPMENT_CANCELLED_BY_SENDER ||
          (shipment.state.name ===
            SHIPMENT_STATES.EXCEPTION_SHIPMENT_CANCELLED_BY_TRUNKRS &&
            shipment.recipient.location.latitude &&
            shipment.recipient.location.latitude),
      )
      .map((shipment) => mapOverviewShipment(shipment, tourData)),
)

export const getCorrectShipments = createSelector(
  fromShipments.getShipments,
  fromTours.getData,
  (shipments, tourData) =>
    shipments
      .filter(
        (shipment) =>
          shipment.id > 0 &&
          shipment.recipient.location.latitude &&
          shipment.recipient.location.latitude,
      )
      .map((shipment) => mapOverviewShipment(shipment, tourData)),
)

export const getErrorShipments = createSelector(
  fromShipments.getShipments,
  fromTours.getData,
  (shipments, tourData) =>
    shipments
      .filter(
        (shipment) =>
          shipment.id > 0 &&
          (!shipment.recipient.location.latitude ||
            !shipment.recipient.location.latitude),
      )
      .map((shipment) => mapOverviewShipment(shipment, tourData)),
)

/**
 * Check all shipments in tours if SHIPMENT_SORTED
 * @returns boolean true if shipments contains not SHIPMENT_SORTED, and false if all shipments are SHIPMENT_SORTED
 */
export const getShipmentToursIfIncludesNotSorted = createSelector(
  getShipmentTours,
  (tour) => {
    const parcels = tour.reduce((acc, current) => {
      return acc.concat(
        current.collations.filter((col) =>
          col.shipmentIds.some((shipmentId) => shipmentId > 0),
        ),
      )
    }, [])
    return (
      parcels.filter(
        (tr) =>
          !isEmpty(tr.state) &&
          tr.state.name !== SHIPMENT_STATES.SHIPMENT_SORTED,
      ).length > 0
    )
  },
)

export const getIsBatchHubOutDone = createSelector(
  fromHublogs.selectIsBatchOutDone,
  fromProfile.getSubco,
  (isBatchHubOutDone, selectedSubo) => {
    if (isBatchHubOutDone) {
      return true
    }

    if (selectedSubo && selectedSubo.inventoryScanCompletedAt) {
      if (
        moment().isSame(moment(selectedSubo.inventoryScanCompletedAt), 'day')
      ) {
        return true
      }
      return false
    }
    return false
  },
)
