import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const styles = ({ spacing, palette }: Theme) =>
  createStyles({
    root: {
      padding: 0,
      width: '100%',
    },
    timeline: {
      zIndex: 'auto',
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      height: 100,
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: spacing(2, 4),
      margin: 'auto',
      background: palette.common.white,
      gap: spacing(2),
    },
    action: {
      gap: spacing(2),
      minWidth: '20%',
      display: 'flex',
      justifyContent: 'flex-end',
    },
    button: {
      height: 'auto',
    },
    buttonLabel: {
      whiteSpace: 'break-spaces',
      textAlign: 'initial',
    },
    toolbar: {
      display: 'flex',
      flexDirection: 'column',
      zIndex: 100,
      alignItems: 'center',
      margin: spacing(-5, -4, 4, -4),
    },
    network: {
      display: 'flex',
      height: 100,
      width: '100%',
      padding: spacing(4),
      flexDirection: 'row',
      background: '#48AFF0',
      alignItems: 'center',
      overflow: 'hidden',
    },
  })

export default makeStyles(styles)
