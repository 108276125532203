import * as types from '../constants/ActionTypes'

import * as fromUI from '../selectors/ui'

export function toggleSidebar() {
  return { type: types.TOGGLE_SIDEBAR }
}

export function setLoading(loading, loadMessage, isShipmentsFetching) {
  return { type: types.SET_LOADING, payload: { loading, loadMessage, isShipmentsFetching } }
}

export function resetLoading() {
  return { type: types.RESET_LOADING }
}

export function setOptimizing(payload) {
  return { type: types.SET_OPTIMIZING, payload }
}

export function set(payload) {
  return { type: types.SET_SETTING, payload }
}

export function toggleSelected(collationId) {
  return { type: types.TOGGLE_SELECTED_COLLATION, payload: collationId }
}

export function selectMultipleCollations(collations) {
  return { type: types.SELECT_MULTI_COLLATIONS, payload: collations }
}

export function clearSelected() {
  return { type: types.CLEAR_SELECTED_COLLATIONS }
}

export function setLoginErrorMessage(message) {
  return { type: types.SET_LOGIN_ERROR, payload: message }
}

export const toggleOptimizeModal = () => (dispatch, getState) => {
  const isShown = fromUI.isOptimizeModalShown(getState())

  dispatch(
    set({
      optimizeModal: !isShown,
      hasOptimizationError: false,
    }),
  )
}

export function setSidebarCollapsed(payload) {
  return { type: types.SET_SIDEBAR_COLLAPSED, payload }
}

export const setHasOptimizationError = (payload) => ({
  type: types.SET_HAS_OPTIMIZATION_ERROR,
  payload,
})

export const setPowerMode = (payload) => ({
  type: types.SET_POWER_MODE,
  payload,
})

export const setOptimizeResponse = (payload) => ({
  type: types.SET_OPTIMIZE_DONE,
  payload,
})

export const setIsAuthenticated = (isAuthenticated) => (dispatch, getState) => {
  dispatch({
    type: types.UI_SET_IS_AUTHENTICATED,
    payload: isAuthenticated,
  })
}

/**
 * Used for marking if the app bootstrapping. Calling all necessary data needed when the app is loaded
 * @param {boolean} isBootstrapping
 * @returns void
 */
export const setBootstrapping = (isBootstrapping) => (dispatch, getState) => {
  dispatch({
    type: types.BOOTSTRAP_APP,
    payload: isBootstrapping,
  })
}
