import uuid from 'uuid'

import groupBy from 'lodash/groupBy'
import map from 'lodash/map'
import orderBy from 'lodash/orderBy'
import filter from 'lodash/filter'
import get from 'lodash/get'

import trunkrs from '../../common/utils/Sdk'
import getColor from '../../utils/getColor'
import { expandDictionary } from '../../utils/dataSets'
import { concatBermudaStops, setBermudaStop } from '../shipments/helpers'
import defaultTo from 'lodash/defaultTo'
import { defaultHandlingTime } from '../../utils/estimated-time-calc'


export const mapEstimationToCollation = ({
  collation, arrival: estimation, polyline,
  duration, stop, isBermudaStop,
}, position) => ({
  id: get(collation, 'id', uuid()),
  shipmentIds: get(collation, 'shipmentIds', [stop.id]),
  isMultiColli: get(collation, 'isMultiColli', false),
  position,
  estimation,
  durationValue: get(duration, 'value'),
  polyline,
  isBermudaStop: defaultTo(isBermudaStop, false),
  coord: {
    lat: stop.latitude,
    lng: stop.longitude,
  },
})


export const moveCollations = (source, target, collationIds) => {
  const {
    [false]: sourceCollations,
    [true]: targetAdditions,
  } = groupBy(source.collations, collationId =>
    collationIds.includes(collationId))
  const targetCollations = target.collations.concat(targetAdditions)

  return [
    sourceCollations || [],
    targetCollations || [],
  ]
}

export const mapCollationToShipment = collation => ({
  id: collation.id,
  recipient: {
    location: {
      latitude: collation.coord.lat,
      longitude: collation.coord.lng,
    },
  },
})

export const isValidCollation = (data) => {
  if (!data || !data.shipmentIds) {
    return false
  }
  const { shipmentIds: [shipmentId] } = data
  return shipmentId > 0
}

export const isBermudaStop = (c) => !!get(c, 'isBermudaStop')

const combineWithCollation = collationsData => estimationSet => map(
  estimationSet,
  (estimation) => ({
    ...estimation,
    collation: get(collationsData, get(estimation, 'stop.id')),
  }),
)

export const estimateCollations = (collationData, subco, departureTime, ...collationSets) =>
  Promise.all(map(
    collationSets,
    collations => (async () => {
      const nextCollations = (Array.isArray(collations) ? collations : get(collations, 'collations', []))
      const validCollations = nextCollations.filter(isValidCollation)
      const estimations = validCollations.length > 0 ? await trunkrs.Tour().fetchEstimations(
        map(validCollations, mapCollationToShipment),
        subco,
        departureTime,
        defaultHandlingTime,
      ) : []
      return {
        tourId: get(collations, 'tourId'),
        estimations,
      }
    })(),
  )).then(response => {
    // Add bermuda stops to tour estimations
    const tourEstimations = setBermudaStop(defaultTo(response, []).map(response => response.estimations), false)
    return {
      tourIds: response.map(response => get(response, 'tourId')),
      estimations: map(
        tourEstimations.map(estimation => get(estimation, 'result')),
        combineWithCollation(collationData),
      ),
      total: tourEstimations.map(estimation => get(estimation, 'total')),
    }
  })

export const optimizeCollations = (collationData, subco, departureTime, ...collationSets) => Promise.all(
  collationSets.map(collations => {
    if (collations.filter(isValidCollation).length >= 1) {
      return trunkrs.Tour().optimizeGroupWithEstimations(
        map(collations.filter(isValidCollation), mapCollationToShipment),
        subco,
        departureTime,
        defaultHandlingTime,
      )
    }
  }),
).then(results => {
  // Add bermuda stops to tour estimations
  const { tourEstimations, total } = concatBermudaStops(results)
  return {
    estimations: map(
      tourEstimations,
      combineWithCollation(collationData),
    ),
    total,
  }
})


export const moveFromUnknownOrigin = (tours, collationData, targetOrId, collationIds) => {
  const updates = []
  const target = typeof targetOrId === 'object'
    ? targetOrId
    : { id: targetOrId }
  const targetId = target.id

  const targetUpdate = { collations: [], oldCollations: [] }

  tours.forEach(tour => {
    const removedCollations = []

    if (tour.id === targetId) {
      targetUpdate.oldCollations = tour.collations
      return
    }

    tour.collations.forEach(collationId => {
      const canSkipCollation = !isValidCollation(get(collationData, collationId))
        || !collationIds.includes(collationId)

      if (canSkipCollation) {
        return
      }

      targetUpdate.collations.push(collationId)
      removedCollations.push(collationId)
    })

    if (removedCollations.length) {
      updates.push({
        id: tour.id,
        collations: tour.collations
          .filter(collation => !removedCollations.includes(collation)),
      })
    }
  })

  updates.push({
    ...target,
    collations: targetUpdate.oldCollations.concat(targetUpdate.collations),
  })

  return updates
}

export const mapCollationToSavable = (collation, position) => map(
  collation.shipmentIds,
  shipmentId => ({
    position,
    shipmentId: shipmentId,
    polyline: collation.polyline,
    estimation: collation.estimation,
    durationValue: Math.round(collation.durationValue / 60),
  }),
)

const mapShipmentToCollation = (shipments) => {
  const [shipment] = shipments

  return ({
    id: uuid(),
    isMultiColli: shipments.length > 1,
    shipmentIds: map(shipments, 'id'),
    estimation: shipment.tour.estimation,
    durationValue: shipment.tour.durationValue,
    polyline: shipment.tour.polyline,
    position: shipment.tour.position,
    coord: {
      lat: get(shipment, 'recipient.location.latitude'),
      lng: get(shipment, 'recipient.location.longitude'),
    },
  })
}

const mapGroupingToTour = (shipments, tourIndex) => {
  const [{ tour }] = shipments

  const multiColliGroupings = groupBy(
    shipments,
    ({ recipient: { location }, id }) =>
      !!location.latitude && !!location.longitude ?
        Math.pow(location.latitude, 2) + Math.pow(location.longitude, 2) :
        id,
  )

  const collations = map(
    expandDictionary(multiColliGroupings),
    mapShipmentToCollation,
  )

  return {
    id: tour.id || uuid(),
    color: tour.color || getColor(tourIndex),
    number: tourIndex + 1,
    driverId: tour.driverId,
    state: tour.state,
    vehicleType: tour.vehicleType,
    collations: orderBy(collations, 'position'),
    totalDistance: get(tour, 'totalDistance', 0),
  }
}

export const mapShipmentsToTours = (shipments) => {
  const shipmentsWithTour = filter(shipments, 'tour.id')
  if (!shipmentsWithTour.length) return []

  const tourGrouping = groupBy(shipmentsWithTour, 'tour.id')

  return map(
    expandDictionary(tourGrouping),
    mapGroupingToTour,
  )
}

export const mapProductivityToTours = (tours, productivitySelector) => tours.map(tour => {
  const productivity = productivitySelector(tour)
  return {
    ...tour,
    productivity,
  }
})
