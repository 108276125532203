import * as yup from 'yup'
import i18n from 'i18next'

export const defaultValues: IUserFormSchema = {
  name: '',
  isPlanner: false,
  userPreferences: {
    locale: '',
  },
  phoneNumber: '',
}

export default yup.object().shape({
  name: yup.string().required(i18n.t('USERS.ADD_USER_NAME_REQUIRED')),
  isPlanner: yup.bool().default(false).notRequired(),
  userPreferences: yup.object().shape({
    locale: yup.string().required(i18n.t('USERS.ADD_USER_LANGUAGE_REQUIRED')),
  }),
  phoneNumber: yup.string().required(i18n.t('USERS.ADD_USER_PHONE_REQUIRED')),
})

export interface IUserFormSchema {
  name: string
  isPlanner: boolean
  userPreferences: {
    locale: string
  }
  phoneNumber: string
}
