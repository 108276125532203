import React from 'react'
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon'

const PATH_D =
  'M12 2C8.13 2 5 5.13 5 9c0 5 7 13 7 13s7-8 7-13c0-3.87-3.13-7-7-7z'

export interface ISvgMarkerProps extends SvgIconProps {
  markerNumber?: number
  markerColor?: string
}

const SvgMarker = React.memo(
  React.forwardRef<any, Exclude<ISvgMarkerProps, 'viewBox'>>((props, ref) => (
    <SvgIcon
      ref={ref}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      stroke="#220C4A"
      strokeWidth={1}
      {...props}
    >
      <path d={PATH_D} />
      <text
        x="12"
        y="10"
        textAnchor="middle"
        fill="white"
        fontSize="14px"
        fontWeight="bold"
        stroke="none"
        dy=".4em"
      >
        {props.markerNumber}
      </text>
    </SvgIcon>
  )),
) as React.FC<ISvgMarkerProps>

export default SvgMarker
