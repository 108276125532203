/* eslint-disable import/no-cycle */

import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@material-ui/core/Box'
import { useWithLongClick } from 'hooks/useWithLongClick'
import clsx from 'clsx'
import { setArchiveState } from 'actions/profile'
import { useDispatch } from 'react-redux'
import addModalStyles from '../AddUserModal/styles.module.scss'
import styles from './styles.module.scss'
import { IAddDriverModalProps } from '../AddUserModal'
import { Button, ModalBox } from 'common/components/base/.raw'

export function DeleteUserModal({
  onSucceed,
  user,
  deleteFilter,
  ...props
}: IAddDriverModalProps & { deleteFilter?: boolean }) {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [showLoadingLabel, setShowLoadingLabel] = useState(false)

  const handleLongPressClick = useCallback(async () => {
    setShowLoadingLabel(true)
    if (deleteFilter) {
      await dispatch(setArchiveState(user, false))
    } else {
      await dispatch(setArchiveState(user, true))
    }
    setShowLoadingLabel(false)
    return onSucceed()
  }, [deleteFilter, dispatch, onSucceed, user])

  const { starting, onMouseLeave, ...buttonProps } =
    useWithLongClick(handleLongPressClick)

  const handleMouseLeave = useCallback(() => {
    if (!showLoadingLabel && onMouseLeave) {
      setShowLoadingLabel(false)
      onMouseLeave()
    }
  }, [onMouseLeave, showLoadingLabel])

  return (
    <ModalBox {...props}>
      <Box className={styles.container}>
        <Box className={styles.body}>
          <div className={styles.title}>{t('DELETE_USER_TITLE')}</div>
          <div className={styles.subtitle}>{t('DELETE_USER_SUBTEXT')}</div>
          <div className={clsx(addModalStyles.footer, styles.marginTop)}>
            <Button
              className={addModalStyles['cancel-button']}
              onClick={props.onClose}
            >
              {t('ADD_USER_CANCEL_BUTTON')}
            </Button>
            <Button
              {...buttonProps}
              onMouseLeave={handleMouseLeave}
              className={clsx(
                styles.loadingButton,
                starting && !showLoadingLabel && styles.loading,
              )}
            >
              <span>
                {showLoadingLabel && (
                  <span className={addModalStyles.loading} />
                )}
                {showLoadingLabel
                  ? t('LONGPRESS_DONE')
                  : t('LONGPRESS_DELETE_USER')}
              </span>
            </Button>
          </div>
        </Box>
      </Box>
    </ModalBox>
  )
}

export default DeleteUserModal
