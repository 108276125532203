/* eslint-disable import/no-cycle */

import * as Sentry from '@sentry/browser'
import get from 'lodash/get'
import defaultTo from 'lodash/defaultTo'
import { IReturnedShipments, ITYPES } from 'reducers/hublogs'
import { store } from 'App'
import trunkrs, { wait } from 'common/utils/Sdk'
import { setLoading } from '../ui'

export enum ACTION_TYPES {
  SET_HUB_INVENTORY = 'SET_HUB_INVENTORY',
  SET_SHIPMENT_NEXT_STEP = 'SET_SHIPMENT_NEXT_STEP',
  SET_BATCH_HUB_OUT_DONE = 'SET_BATCH_HUB_OUT_DONE',
  SET_RETURNED_SHIPMENTS = 'SET_RETURNED_SHIPMENTS',
  MASS_SET_HUB_INVENTORY_TYPE_UNSCANNABLE = 'MASS_SET_HUB_INVENTORY_TYPE_UNSCANNABLE',
}

export interface INextStep {
  subcoId: number
  trunkrsNrOrBarcode: string
}

const getDriverName = (driverId: number, drivers: Array<any>) =>
  get(
    drivers.find((driver) => driver.id === driverId),
    'name',
    '',
  )

const getScannedState = (
  shipmentId: number,
  returnedShipments: Array<IReturnedShipments>,
) =>
  get(
    returnedShipments.find((s) => s.shipmentId === shipmentId),
    'type',
  )

export const getHubInventoryList =
  (subcoId: number, drivers: Array<{ id: number; name: string }>) =>
  async (dispatch: any) => {
    try {
      dispatch(setLoading(true))

      const hubInventory = await trunkrs
        .Shipment()
        .fetchHubLogsBySubCoId(subcoId)
      const returnedShipments = await trunkrs
        .Shipment()
        .fetchReturnedShipments(subcoId)

      const hubInventoryWithDriverName = defaultTo(hubInventory, []).map(
        (log: any) => ({
          ...log,
          driverName: getDriverName(get(log, 'lastDriver'), drivers),
          type: getScannedState(get(log, 'shipmentId'), returnedShipments),
        }),
      )

      dispatch({
        type: ACTION_TYPES.SET_HUB_INVENTORY,
        payload: hubInventoryWithDriverName,
      })
      dispatch({
        type: ACTION_TYPES.SET_RETURNED_SHIPMENTS,
        payload: returnedShipments,
      })
    } catch (err) {
      Sentry.captureException(err)
      dispatch({ type: ACTION_TYPES.SET_HUB_INVENTORY, payload: [] })
    } finally {
      dispatch(setLoading(false))
    }
  }

// eslint-disable-next-line consistent-return
export const getShipmentNextStep = async (
  data: INextStep,
  isHublogNotInList?: boolean,
) => {
  try {
    store.dispatch(setLoading(true))

    const nextStep = await trunkrs
      .Shipment()
      .markShipmentAsNextStep(data.trunkrsNrOrBarcode, data.subcoId)

    store.dispatch({
      type: ACTION_TYPES.SET_SHIPMENT_NEXT_STEP,
      payload: {
        shipmentData: nextStep.shipmentData,
        trunkrsNrOrBarcode: data.trunkrsNrOrBarcode,
        type: get(nextStep, 'type') || ITYPES.ERROR,
      },
    })
  } catch (err) {
    Sentry.captureException(err)
    store.dispatch({
      type: ACTION_TYPES.SET_SHIPMENT_NEXT_STEP,
      payload: {
        ...data,
        type: ITYPES.ERROR,
      },
    })
  } finally {
    store.dispatch(setLoading(false))
  }
}

export const batchHubOut = async (
  payload: Array<{
    shipmentId: number
    subcoId: number
  }>,
  inventoryScanCompleted?: boolean,
) => {
  try {
    store.dispatch(setLoading(true))
    await trunkrs.Shipment().batchHubOut(payload, inventoryScanCompleted)
    store.dispatch({ type: ACTION_TYPES.SET_BATCH_HUB_OUT_DONE })
  } catch (err) {
    Sentry.captureException(err)
    console.warn(err)
  } finally {
    store.dispatch(setLoading(false))
  }
}
