import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const styles = ({ spacing, palette }: Theme) =>
  createStyles({
    inventoryScan: {
      height: 'calc(100vh - 275px)',
    },
    snowflakeIcon: {
      width: 24,
      height: 24,
    },
    closeButton: {
      backgroundColor: 'transparent',
    },
  })

export default makeStyles(styles)
