export const COLORS = {
  BLUE: '#13d2fc',
  BLUE_HOVER: '#24aac8',
  GRAY_LIGHT: '#f4f7fc',
  GRAY_LIGHT_2: '#F9F9F9',
  GRAY_LIGHT_3: '#f2f2f2',
  GRAY_FADED: '#888888',
  GREEN: '#07FBB1',
  GREEN_1: '#2CE27E',
  GREEN_2: '#00cf5f',
  GREEN_3: '#02D763',
  ORANGE: '#FF8F5E',
  PURPLE: '#201047',
  RED: '#cc0000',
  RED_2: '#d3413f',
  RED_3: '#b71c1c',
  RED_4: '#aa1a1a',
  RED_HOVER: '#880000',
  RED_LIGHT: '#ff4949',
  RED_DANGER: '#ff5451',
  RED_DANGER_HOVER: '#ff3333',
  WHITE: '#ffffff',
}

export const tourColors = [
  '#b71c1c',
  '#3A3485',
  '#017FCA',
  '#009587',
  '#30322c',
  '#48687f',
  '#785447',
  '#EE6E01',
  '#EC407A',
  '#4CAF50',
  '#b71c1c',
  '#3A3485',
  '#017FCA',
  '#009587',
  '#30322c',
  '#48687f',
  '#785447',
  '#ed2789',
  '#4CAF50',
  '#EE6E01',
]
