import { STATE } from '../../constants'

export const isNotDelivered = (state) => {
  return state === STATE.SHIPMENT_NOT_DELIVERED /* 12 */
}

export const isCancelled = (state) => {
  return state === STATE.EXCEPTION_SHIPMENT_CANCELLED_BY_SENDER || state === STATE.EXCEPTION_SHIPMENT_CANCELLED_BY_TRUNKRS /* 17 */
}

export const isLaterThanProcessed = (state) => {
  switch (state) {
  case STATE.SHIPMENT_SORTED:
  case STATE.SHIPMENT_SORTED_AT_SUB_DEPOT:
  case STATE.SHIPMENT_ACCEPTED_BY_DRIVER:
  case STATE.SHIPMENT_DELIVERED:
  case STATE.SHIPMENT_DELIVERED_TO_NEIGHBOR:
  case STATE.SHIPMENT_NOT_DELIVERED:
  case STATE.EXCEPTION_SHIPMENT_CANCELLED_BY_SENDER:
  case STATE.EXCEPTION_SHIPMENT_CANCELLED_BY_TRUNKRS:
  case STATE.CHANGE_SHIPMENT_POST_PONED:
  case STATE.EXCEPTION_SHIPMENT_NOT_ARRIVED:
  case STATE.EXCEPTION_SHIPMENT_MIS_SORTED:
  case STATE.EXCEPTION_SHIPMENT_DECLINED_BY_DRIVER:
  case STATE.EXCEPTION_SHIPMENT_MISSING:
  case STATE.RETURN_SHIPMENT_TO_SENDER:
    return true
  default:
    return false
  }
}

export const availableForHandover = (state) => {
  switch(state) {
  case STATE.SHIPMENT_SORTED:
    return true
  default:
    return false
  }
}

export const isDataReceived = (state) => {
  return state === STATE.DATA_RECEIVED
}

export const isDataProcessed = (state) => {
  return state === STATE.DATA_PROCESSED
}

export const isSorted = (state) => {
  return state === STATE.SHIPMENT_SORTED || state === STATE.SHIPMENT_SORTED_AT_SUB_DEPOT
}

export const isAcceptable = (state) => {
  return isDataReceived(state) || isDataProcessed(state) || isSorted(state)
}

export const isHandedOver = (state) => state === STATE.SHIPMENT_SORTED_AT_SUB_DEPOT

export const isLaterThanReceived = (state) => {
  return isDataProcessed(state) || isLaterThanProcessed(state)
}
