import React from 'react'
import clsx from 'clsx'
import get from 'lodash/get'

import styles from './styles.module.scss'

const Avatar = ({ className, children }: React.HTMLAttributes<any>) => {
  return (
    <div className={clsx(styles.avatarContainer, className)}>
      {typeof children === 'string'
        ? `${
            get(children.split(' '), '[0][0]') +
            get(children.split(' '), '[1][0]', '')
          }`
        : children}
    </div>
  )
}

export default React.memo(Avatar)
