import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Card, Box, CardActions } from '@material-ui/core'

import { useSelector } from 'react-redux'
import * as fromProfile from 'selectors/profile'

import Typography from 'common/components/base/Typography'
import Button from 'common/components/base/Button'
import ProfileCard from 'common/components/features/Profile/Card'

import useStyles from './useStyles'

const ProfilePage = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const classes = useStyles()

  const subco = useSelector(fromProfile.getSubco)
  const location = useSelector((state: any) => state.profile.location)

  return (
    <>
      <Typography variant="header">{t('PARTNER_PROFILE')}</Typography>
      <Box height={32} />
      <Card className={classes.card}>
        <ProfileCard
          name={subco?.name}
          address={location?.address}
          postalCode={location?.postalCode}
          city={location?.city}
          country={location?.country}
          latitude={location?.latitude}
          longitude={location?.longitude}
        />
        <CardActions className={classes.actions}>
          <Button color="action" onClick={() => navigate('/profile/edit')}>
            <Typography color="white" transform="capitalize">
              {t('EDIT_PROFILE')}
            </Typography>
          </Button>
        </CardActions>
      </Card>
    </>
  )
}

export default ProfilePage
