import { createSelector } from 'reselect'
import get from 'lodash/get'

import * as fromTour from '../../reducers/collection/tours'
import { createFeatureSelector } from '../core'

const selectFeature = createFeatureSelector('tour')

export const getHasFetched = createSelector(
  selectFeature,
  fromTour.selectHasFetched,
)

export const getIsLoading = createSelector(
  selectFeature,
  fromTour.selectIsLoading,
)

export const getTourDates = createSelector(
  selectFeature,
  fromTour.selectTourDates,
)

export const getToursOnDates = createSelector(
  selectFeature,
  fromTour.selectToursOnDate,
)

export const getIdentifiers = createSelector(
  selectFeature,
  fromTour.selectIdentifiers,
)

export const getData = createSelector(
  selectFeature,
  fromTour.selectData,
)

const getSelectedTourId = createSelector(
  selectFeature,
  fromTour.selectSelectedId,
)

export const getSelectedTour = createSelector(
  getData,
  getSelectedTourId,
  get,
)

