import React from 'react'
import { useTranslation } from 'react-i18next'

import { ReactComponent as AllShipments } from 'common/assets/images/empty/all-shipments.svg'
import EmptyState from '..'

import useStyles from '../useStyles'

const NoShipmentsFound: React.FC = () => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <EmptyState
      illustration={<AllShipments className={classes.illustrationIcon} />}
      heading={t('EMPTY_SCREENS.THIS_FEATURE_DISABLED')}
      description={t('EMPTY_SCREENS.INVENTORY_SCAN_300_1400')}
    />
  )
}

export default NoShipmentsFound
