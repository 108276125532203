/* eslint-disable import/no-cycle */

import React from 'react'
import * as AWS from 'aws-sdk'
import { useNavigate } from 'react-router-dom'
import { Card, CardContent, Box } from '@material-ui/core'
import get from 'lodash/get'
import defaultTo from 'lodash/defaultTo'
import { FormikHelpers } from 'formik'

import { useDispatch, useSelector } from 'react-redux'
import trunkrs, { responseHandler } from 'common/utils/Sdk'
import * as profileActions from 'actions/profile'
import * as uiActions from 'actions/ui'

import OtpForm from 'common/components/features/Forms/OtpForm'
import PhoneNumberForm, {
  PhoneNumberFormfields,
} from 'common/components/features/Forms/PhoneNumberForm'
import Typography from 'common/components/base/Typography'

import { transformPhoneNumber } from 'common/utils/PhoneNumber'

import Logo from 'common/assets/images/logo.png'

import styles from './styles.module.scss'
import { NETWORK_TYPE } from '../../common/models/typings/TourOverview'
import * as fromNetworkType from '../../selectors/networkType'

const Login: React.FC = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [phone, setPhone] = React.useState<string>('')
  const [otp, setOtp] = React.useState<string>('')
  const [isDisabled, setIsDisabled] = React.useState<'phone' | 'code'>('phone')
  const [isSending, setIsSending] = React.useState<null | 'phone' | 'code'>(
    null,
  )
  const [isError, setIsError] = React.useState<null | 'phone' | 'code'>(null)
  const [optRequested, setOtpRequested] = React.useState<boolean>(false)
  const [magickLinkParams, setMagickLinkParams] =
    React.useState<AWS.CognitoIdentityServiceProvider.InitiateAuthResponse>({})

  const handlePhoneChange = React.useCallback((value: string) => {
    setPhone(transformPhoneNumber(value))
  }, [])

  const networkType: NETWORK_TYPE = useSelector(fromNetworkType.getNetworkType)

  const handleOtpChange = React.useCallback((value: string) => {
    setOtp(value)
  }, [])

  const handlePhoneSend = React.useCallback(
    async (
      values?: PhoneNumberFormfields,
      formikHelpers?: FormikHelpers<PhoneNumberFormfields>,
    ) => {
      setIsError(null)
      setIsSending('phone')

      const [, result] =
        await responseHandler<AWS.CognitoIdentityServiceProvider.InitiateAuthResponse>(
          trunkrs.Auth().requestMagickLink(phone.replace(/[^\\+\d]/g, '')),
        ).finally(() => setIsSending(null))

      if (result !== null) {
        setMagickLinkParams(result)
        setOtpRequested(true)
        setOtpRequested(true)
      } else {
        setIsError('phone')
        formikHelpers?.setFieldError(
          'phoneNumber',
          'Sorry, this is not a registered mobile number. Please try again.',
        )
      }
    },
    [phone],
  )

  const handleOtpSend = React.useCallback(async () => {
    setIsError(null)
    setIsSending('code')

    const [, verificationResult] = await responseHandler<any>(
      trunkrs
        .Auth()
        .verifyMagickLink(
          magickLinkParams,
          phone.replace(/[^\\+\d]/g, ''),
          otp,
        ),
    )

    if (verificationResult) {
      const [, subcos] = await responseHandler(
        trunkrs.Auth().fetchSubcoForUser(),
      )

      if (subcos) {
        const subcontractors = !Array.isArray(subcos) ? [subcos] : subcos

        const [, driverInstances] = await responseHandler(
          get(subcontractors, '[0].getDrivers'),
        )

        if (driverInstances) {
          const [, result] = await responseHandler(
            Promise.all(driverInstances as any),
          )

          const drivers = defaultTo(result, []).map((driver) => ({
            id: get(driver, 'id'),
            name: get(driver, 'name'),
            email: get(driver, 'getEmailAddress'),
            phoneNumber: get(driver, 'getPhoneNumber'),
            active: get(driver, 'active'),
            isPlanner: get(driver, 'isPlanner'),
            isArchived: get(driver, 'isArchived'),
            picture: get(driver, 'picture'),
            Instance: driver,
          }))

          const [, location] = await responseHandler(
            get(subcontractors, '[0].getLocation'),
          )

          dispatch(
            profileActions.login({
              id: get(subcontractors, '[0].getId'),
              accesstoken: get(verificationResult, 'AccessToken'),
              subcos: subcontractors,
              selectedSubco: 0,
              drivers,
              location,
            }),
          )

          dispatch(profileActions.setRoles())
          await trunkrs
            .Auth()
            .checkAccess()
            .then((isLoggedIn) => {
              if (isLoggedIn) {
                dispatch(uiActions.setIsAuthenticated(isLoggedIn))
                navigate('/')
              }
            })
        }
      }
    } else {
      setOtp('')
      setIsError('code')
    }
    setIsSending(null)
  }, [dispatch, magickLinkParams, navigate, otp, phone])

  const handleOtpResend = React.useCallback(
    () => handlePhoneSend(),
    [handlePhoneSend],
  )

  return (
    <div className={styles.root}>
      <div className={styles.container}>
        <Card elevation={2} style={{ padding: 0 }}>
          <CardContent style={{ padding: 40 }}>
            <Box marginBottom={5} display="flex" justifyContent="center">
              <img src={Logo} alt="Trunkrs Logo" />
            </Box>
            {optRequested ? (
              <OtpForm
                isSending={isSending === 'code'}
                isBtnDisabled={isDisabled === 'code'}
                error={isError === 'code'}
                phone={phone}
                value={otp}
                onChange={handleOtpChange}
                onSend={handleOtpSend}
                onResend={handleOtpResend}
              />
            ) : (
              <PhoneNumberForm
                isSending={isSending === 'phone'}
                isBtnDisabled={isDisabled === 'phone'}
                error={isError === 'phone'}
                value={phone}
                onChange={handlePhoneChange}
                onSubmit={handlePhoneSend}
              />
            )}
          </CardContent>
        </Card>
        <div className={styles.copyright}>
          <Typography color="grey" component="span">
            © 2021 Trunkrs
          </Typography>
        </div>
      </div>
    </div>
  )
}

export default Login
