import React from 'react'
import { useTranslation } from 'react-i18next'

import { ReactComponent as TrackNTrace } from 'common/assets/images/empty/track-n-trace.svg'
import EmptyState from '..'

import useStyles from '../useStyles'
import { NETWORK_TYPE } from '../../../../models/typings/TourOverview'
import { useSelector } from 'react-redux'
import * as fromNetworkType from '../../../../../selectors/networkType'
import EmptyMonitoringDayAni from '../../../animations/EmptyMonitoringDay'
import EmptyMonitoringNightAni from '../../../animations/EmptyMonitoringNight'

const NoActiveTour: React.FC = () => {
  const classes = useStyles()
  const networkType: NETWORK_TYPE = useSelector(fromNetworkType.getNetworkType)
  const [t] = useTranslation()

  return (
    <EmptyState
      illustration={
        networkType === NETWORK_TYPE.DAY ? (
          <EmptyMonitoringDayAni />
        ) : (
          <EmptyMonitoringNightAni />
        )
      }
      heading={t('QUICK_TOURS_NO_TOURS_YET')}
      description={t('NO_ACTIVE_TOURS_YET')}
    />
  )
}

export default NoActiveTour
