import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const styles = ({ spacing, palette, transitions, shadows }: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      height: 100,
      width: '100%',
      padding: spacing(4),
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      background: '#5642A6',
      transition: transitions.create('background', {
        duration: transitions.duration.standard,
      }),
      '&$day': {
        background: '#48AFF0',
      },
      overflow: 'hidden',
    },
    AntSwitch: {
      display: 'flex',
      flexDirection: 'row',
    },
    header: {
      flexDirection: 'row',
      color: 'white',
      position: 'relative',
      top: 3,
    },
    lottie: {
      zIndex: 1,
      position: 'relative',
      top: 16,
      marginRight: -40,
      'path[stroke-linejoin="miter"]': {
        'stroke-linejoin': 'round !important',
      },
    },
    day: {},
    dialogButtonWrapper: {
      display: 'flex',
    },
    dialogIcon: {
      height: 24,
      width: 24,
      marginRight: 9,
      position: 'relative',
      top: -2,

      '& > path': {
        fill: '#220C4A',
      },
    },
  })

export default makeStyles(styles)
