import get from 'lodash/get'
import isEqual from 'lodash/isEqual'
import isEmpty from 'lodash/isEmpty'
import { HubInventory } from 'common/models/typings/HubInventory'

export const setShipmentNextStep = (state: any, action: any) => {
  const inventories: HubInventory[] = state.inventory
  const shipmentData: HubInventory | undefined = action.payload?.shipmentData

  if (shipmentData && !isEmpty(shipmentData)) {
    if (
      inventories.findIndex(
        (inv) => inv.shipmentId === shipmentData.shipmentId,
      ) === -1
    ) {
      inventories.push(shipmentData)
    }
  }

  const updatedList = inventories.reduce((acc: HubInventory[], hublog) => {
    const isTrunkrsNrEqual = isEqual(
      get(hublog, 'trunkrsNr'),
      get(action, 'payload.trunkrsNrOrBarcode'),
    )
    const isBarcodeEqual = isEqual(
      get(hublog, 'barcode'),
      get(action, 'payload.trunkrsNrOrBarcode'),
    )

    if (isTrunkrsNrEqual || isBarcodeEqual) {
      /* eslint-disable no-param-reassign */
      hublog.type = action.payload.type
    }
    return [...acc, hublog]
  }, [])
  return updatedList
}

export default setShipmentNextStep
