import createStyles from '@material-ui/core/styles/createStyles'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { Theme } from '@material-ui/core/styles'

const styles = ({ spacing, palette }: Theme) =>
  createStyles({
    content: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
    },
    image: {
      marginBottom: spacing(4),
    },
    spinner: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      marginTop: 32,
    },
    scrollPaper: {
      alignItems: 'center',
    },
    paperScrollBody: {
      verticalAlign: 'unset',
    },
  })

export default makeStyles(styles)
