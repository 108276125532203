import React from 'react'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'

import Typography from 'common/components/base/Typography'
import Button from 'common/components/base/Button'

import useStyles from './useStyles'

export interface EmptyStateProps {
  className?: string
  illustration: React.ReactNode
  heading?: string
  description?: string
  primaryText?: string
  secondaryText?: string
  primaryLink?: string
  secondaryLink?: string
  primaryLeftIcon?: React.ReactNode
  primaryRightIcon?: React.ReactNode
  onPrimaryAction?: () => void
  onSecondaryAction?: () => void
}

export const EmptyState: React.FC<EmptyStateProps> = ({
  className,
  illustration,
  heading,
  description,
  primaryText,
  secondaryText,
  primaryLink,
  secondaryLink,
  primaryLeftIcon,
  primaryRightIcon,
  onPrimaryAction,
  onSecondaryAction,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <div className={clsx(classes.root, className)}>
      <div className={classes.illustrationCont}>{illustration}</div>
      {heading && (
        <div className={classes.headerCont}>
          <Typography
            variant="headerRegular"
            transform="capitalize"
            align="center"
          >
            {heading}
          </Typography>
        </div>
      )}
      {description && (
        <div className={classes.descriptionCont}>
          <Typography color="indigo" align="center">
            {description}
          </Typography>
        </div>
      )}
      <div className={classes.actionCont}>
        {primaryText && (
          <Button
            color="green"
            size="small"
            className={classes.btn}
            startIcon={primaryLeftIcon}
            endIcon={primaryRightIcon}
            href={primaryLink}
            onClick={onPrimaryAction}
          >
            {primaryText}
          </Button>
        )}
        {secondaryText && (
          <Button
            variant="flat"
            size="small"
            className={clsx(classes.btn, classes.secondaryBtn)}
            href={secondaryLink}
            onClick={onSecondaryAction}
          >
            {secondaryText}
          </Button>
        )}
      </div>
    </div>
  )
}

export default EmptyState
