import get from 'lodash/get'
import uniq from 'lodash/uniq'

import * as actionTypes from '../../constants/ActionTypes'

const initialState = {
  hasFetched: false,
  isLoading: false,
  tourDates: [],
  toursOnDate: {},
  identifiers: [],
  data: {},
  selected: null,
}

function reducer(
  state = initialState,
  action,
) {
  const { type, payload } = action

  switch (type) {
  case actionTypes.LOAD_COLLECTION_TOURS: {
    return { ...state, isLoading: true }
  }

  case actionTypes.LOAD_COLLECTION_TOURS_SUCCESS: {
    const { tourDates, toursOnDate, tours } = payload

    return {
      ...state,
      hasFetched: true,
      isLoading: false,
      tourDates,
      toursOnDate,
      identifiers: Object.keys(tours || {}),
      data: tours || {},
    }
  }

  case actionTypes.LOAD_COLLECTION_TOURS_FAILED: {
    return { ...state, isLoading: false, hasFetched: true }
  }

  case actionTypes.SAVE_TOURS: {
    const { date } = payload
    const { toursOnDate: { [date]: tourDate } } = state

    return {
      ...state,
      toursOnDate: {
        ...state.toursOnDate,
        [date]: {
          ...tourDate,
          isLoading: true,
        },
      },
    }
  }

  case actionTypes.SAVE_TOURS_SUCCESS: {
    const { toursOnDate, tours } = payload

    return {
      ...state,
      toursOnDate: {
        ...state.toursOnDate,
        ...toursOnDate,
      },
      identifiers: uniq(state.identifiers.concat(Object.keys(tours))),
      data: {
        ...state.data,
        ...tours,
      },
    }
  }

  case actionTypes.SAVE_TOURS_FAILED: {
    const { date } = payload
    const { toursOnDate: { [date]: tourDate } } = state

    return {
      ...state,
      toursOnDate: {
        ...state.toursOnDate,
        [date]: {
          ...tourDate,
          isLoading: false,
        },
      },
    }
  }

  case actionTypes.SELECT_COLLECTION_TOUR: {
    return { ...state, selected: payload }
  }

  case actionTypes.CHANGE_SUBCO: {
    return initialState
  }

  default: {
    return state
  }
  }
}

export const selectHasFetched = state => get(state, 'hasFetched')
export const selectIsLoading = state => get(state, 'isLoading')
export const selectTourDates = state => get(state, 'tourDates')
export const selectToursOnDate = state => get(state, 'toursOnDate')
export const selectIdentifiers = state => get(state, 'identifiers')
export const selectData = state => get(state, 'data')
export const selectSelectedId = state => get(state, 'selected')

export default reducer
